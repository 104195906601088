import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetMoney, GetPrice, Init, LoadProductsDisplay, Loading, Menu, MenuTitle, MenuTop, PlusMinus, QuickHelp, SaveProductsDisplay, ShowError, ShowOK, TemplateWindow, TextSearch } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCheckCircle, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faShare, faTableCellsLarge, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview } from './product_dialog';
import { GetProductColor, GetProductColor2 } from './functions';


export const ProductsInfo = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState(GetMoney(props.language));

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = global.items_max;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showProduct, setShowProduct] = useState(false);
    const [typ, setTyp] = useState(props.isSmall == true ? 0 : LoadProductsDisplay());

    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = 100;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const iconSize = 175;
    let color = global.themes[props.theme];

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    default: lang.products_my,
                    cabine: lang.cabine,
                    action: lang.action,
                    no_others: props.user.typ == global.account_salon ? false : true
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);
                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(json.categories);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_export = async (pricelist) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_export_stack', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    pricelist: pricelist,
                    lang: {
                        text: lang.products_export_email,
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,
                        label: lang.products_export_label,
                        sub_label: lang.products_list,
                        created: lang.created,

                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,

                        products_list: lang.products_list,
                        product_name: lang.product_name,
                        code: lang.code_,
                        cost: lang.cost,
                        price: lang.price,
                        stock_items: lang.stock_items,

                        money: lang.money,
                        pieces: lang.pieces,

                        products: lang.products,
                        ie: lang.institut,
                        cabine: lang.cabine,
                        products_mine: lang.products_mine,
                        pricelist: lang.pricelist,

                        sumar: lang.sumar
                    }
                })
            })

            const json = await response.json();

            setBusy(false);

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(false);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-99);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-99);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSubCategoryID(0);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        var value = id;
        if (id != subCategoryID) {
            setSubCategoryID(id);
        } else {
            setSubCategoryID(0);
            value = 0;
        }
        Redraw(items, 0, search, value);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-10);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        if (items != false) {
            var data = items;
            if (search.trim() != '') {
                // filtrovanie podla hladaneho retazca
                //var words = search.split(' ');
                //data = items.filter(x => FindWords(x.label, x.keywords, words));
            } else {
                if (sub_category > 0) {
                    // filtrovanie vybratej kategórie
                    data = items.filter(x => parseInt(x.category_id) == sub_category);
                }
            }

            var count = items.length;
            count = Math.ceil(count / itemsMax);
            setPageMax(count);

            data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax);
            setItemsFiltered(data);

            if (count == 0) {
                setItemsFiltered(false);
            }

            setPage(p);
            GoToStart();
        } else {
            setItemsFiltered(false);
        }
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ShowProduct = (item) => {
        setItemSelected(item);
        setShowProduct(true);
    }

    const ProductDialogResult = (value, data) => {
        setShowProduct(false);
    }

    const ExportResult = (value, pricelist) => {
        setShowExport(false);

        if (value == true) {
            db_export(pricelist);
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ChangeTyp = (value) => {
        SaveProductsDisplay(value);
        setTyp(value);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '48%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall == false ?
                    <div style={{ ...styles.BlockRowRaw, justifyContent: 'center', alignItems: 'center', width: 140, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => ChangeTyp(0)} style={{ width: 36, height: 36, backgroundColor: typ == 0 ? global.theme_white : undefined }}>
                            <FontAwesomeIcon style={{ width: 16 }} icon={faBars} />
                        </IconButton>
                        <IconButton onClick={() => ChangeTyp(1)} style={{ width: 36, height: 36, backgroundColor: typ == 1 ? global.theme_white : undefined, marginLeft: 10 }}>
                            <FontAwesomeIcon style={{ width: 16 }} icon={faTableCellsLarge} />
                        </IconButton>
                    </div>
                    : null}
                <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'flex-end', width: props.isSmall ? 160 : 300, height: global.dialog_bar_height }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 160 : 250 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                */}
                </div>
                {props.isSmall == true ?
                    <div style={{ ...styles.Block, width: props.isSmall ? 80 : 80, height: global.dialog_bar_height, alignItems: props.isSmall ? 'center' : 'flex-end' }}>
                        {props.user.typ == global.account_salon ?
                            <IconButton onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 14, color: global.themes[props.theme].theme_dark }} icon={faShare} />
                            </IconButton>
                            : null}
                    </div>
                    :
                    <div style={{ ...styles.BlockRight, width: 200, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium, color: global.theme_white, width: 150 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                            {lang.export}
                        </Button>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ != 100 ?
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                            : null
                                    ))
                                    : null}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div style={{ ...styles.Block, width: '98%' }}>
                            <div id={'id_products'}>
                                {subCategories != false ?
                                    subCategories.length > 1 ?
                                        <CategorySelect background={global.theme_back} width={props.width - menuWidth} items={subCategories} value={subCategoryID} func={CategorySelectResult.bind(this)} />
                                        : null
                                    : null}
                            </div>

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {typ == 0 ?
                                <div style={{ ...styles.BlockCenter }}>
                                    {itemsFiltered != false ?
                                        itemsFiltered.map((item, index) => (
                                            <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: GetProductColor(item.background_color), marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                    </div>
                                                    {props.isSmall ?
                                                        <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            {item.flag_id == 2 ?
                                                                <div style={{ ...styles.Block, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10 }}>
                                                                    <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                                </div>
                                                                : null}

                                                            <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                                        </div>
                                                        :
                                                        <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '80%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                {item.flag_id == 2 ?
                                                                    <div style={{ ...styles.Block, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, marginLeft: 10 }}>
                                                                        <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                            <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.note_short}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div style={{ ...styles.Block, height: rowHeight, width: 170 }}>
                                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw }}>
                                                            <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, fontWeight: '500' }}>{GetPrice(item.eur)} {money}</p>
                                                            <QuickHelp text={lang.price_final} color={global.theme_medium_xgray} theme={props.theme} marginLeft={10} lang={props.lang} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </Paper>

                                        )) :
                                        // ZIADNE PRODUKTY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }
                                </div>
                                : null}

                            {typ == 1 ?
                                <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {itemsFiltered != false ?
                                        itemsFiltered.map((item, index) => (
                                            <Paper
                                                elevation={overID == item.id ? 5 : 2}
                                                key={item.id}
                                                style={{
                                                    ...styles.Block,
                                                    width: iconSize + 8,
                                                    height: (iconSize * 2) + 8,
                                                    backgroundColor: GetProductColor2(item.background_color, props.theme),
                                                    borderRadius: 10,
                                                    cursor: 'pointer',
                                                    margin: 5,
                                                    marginBottom: 10
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockCenter, width: iconSize }}>
                                                    <div onClick={() => ShowProduct(item)} style={{ ...styles.Block, height: iconSize, width: iconSize, position: 'relative' }}>
                                                        <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: iconSize, height: iconSize, objectFit: 'contain', borderRadius: 10 }}></img>
                                                        {item.flag_id == 2 ?
                                                            <div style={{ ...styles.BlockCenter, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, position: 'absolute', top: 0, right: 0 }}>
                                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                            </div>
                                                            : null}
                                                    </div>

                                                    <div style={{ ...styles.BlockCenter, height: iconSize, width: iconSize, justifyContent: 'center' }}>
                                                        <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockCenter, height: 70, overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'center', marginTop: 8 }}>{item.label}</p>
                                                        </div>
                                                        <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockCenter, height: 76, overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                                            <div style={{ ...styles.BlockCenter, marginTop: 10 }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{item.note_short}</p>
                                                            </div>
                                                        </div>

                                                        <div style={{ ...styles.Block, height: iconSize - 70 - 76 }}>
                                                            <div style={{ ...styles.BlockRow, width: '99%' }}>
                                                                <div style={{ ...styles.BlockLeft, width: 30 }}>
                                                                </div>
                                                                <div style={{ ...styles.Block, width: '80%' }}>
                                                                    <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, fontWeight: '500' }}>{GetPrice(item.eur)} {money}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: 30 }}>
                                                                    <QuickHelp text={lang.price_final} color={color.theme_light} theme={props.theme} marginLeft={10} lang={props.lang} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Paper>

                                        )) :
                                        // ZIADNE PRODUKTY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }
                                </div>
                                : null}

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                showExport == true ?
                    <ProductsInfoExportAsk theme={props.theme} radius={props.radius} title={lang.products_stack_export} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} lang={lang} func={ExportResult.bind(this)} />
                    : null
            }

            {
                showProduct == true ?
                    <ProductPreview remoteApp={props.remoteApp} isSmall={props.isSmall} offset={props.offset} theme={props.theme} editing={editing} user={props.user} user_id={props.user.id} product={itemSelected} lang={lang} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} language={props.language} func={ProductDialogResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError offset={props.offset} text={lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Paper >
    );
}


export const ProductsInfoExportAsk = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <ProductsInfoExportAsk theme={props.theme} radius={props.radius} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
    */

    const [priceList, setPriceList] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 650;
    const dialogHeight = 420;

    let lang = props.lang;
    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value, priceList);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        {props.hideClose == true ? null :
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        }
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5 }}>{props.sub_text}</p>
                            : null}

                        <div style={{ ...styles.Block, marginTop: 40 }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.products_export_selection}</p>
                            <div style={{ ...styles.Block, marginTop: 5 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{lang.products_export_selection_pricelist}</p>}
                                    control={<Checkbox checked={priceList} size={'small'} onChange={() => setPriceList(!priceList)} />}
                                />
                            </div>
                            {priceList == true ?
                                <p style={{ ...styles.TextTiny }}>{lang.products_export_selection_pricelist_yes}</p>
                                :
                                <p style={{ ...styles.TextTiny }}>{lang.products_export_selection_pricelist_no}</p>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color == undefined ? global.themes[props.theme].button_dark : props.yes_color }}>{props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color == undefined ? global.themes[props.theme].button_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}

