/*
*
* =================================================================================================================
* SK - LANGUAGE
* =================================================================================================================
*
*/
import { faBookOpen, faChartBar, faCircle, faGlobe, faHome, faInfo, faLayerGroup, faPenAlt, faQuestion, faShoppingBag, faShoppingCart } from "@fortawesome/free-solid-svg-icons"

export const sk = {
    title: 'Esthederm Salon',
    country: 'Slovenská republika',
    country_: 'Krajina',
    language: 'SK',
    slogan: 'Správa kozmetických salónov',
    vision_system: 'Esthederm Salon',
    money: '€',
    money_payment: 'Platobná mena',
    www_eshop: 'https://esthedermsalon.sk',
    help_link: 'https://support.datadream.sk/guide/esthederm-salon-v2/sk',
    help_link_rep: 'https://support.datadream.sk/guide/esthederm-salon-v2-rep/sk',
    web_link: 'esthedermsalon.com',
    web_link_register: 'esthedermsalon.com/register',
    state: 'Štát',
    register_form: 'Sprievodca registráciou',
    version: 'Verzia aplikácie',
    salon: 'Kozmetický salón',
    salon_: 'Salón',
    salon_card_export: 'Karta kozmetického salónu',
    salons_export: 'Zoznam kozmetických salónov',
    salons: 'Kozmetické salóny',
    salons_rep: 'Salóny reprezentantov',
    salons_rep_: 'Salóny reprezentanta',
    salons_list: 'Zoznam kozmetických salónov',
    salon_hidden: 'Neaktívny',
    account_test: 'Test',
    login_user: 'Prihlasený užívateľ',
    created: 'Vytvorené',
    ipad_app: 'Aplikácia pre Apple iOS',
    ipad_app_salon: 'Aplikácia pre kozmetické salóny',
    ipad_app_text: 'Podporované zariadenia: iPhone a iPad',
    android_app_text: 'Podporované zariadenia: mobilné telefóny',
    application: 'Aplikácia',
    web_application: 'Webová aplikácia',
    mobile_apps: 'Aplikácie pre mobilné zariadenia',
    not_selected: 'Neurčené',

    app_error: 'Pri vstupe do aplikácie nastala chyba. Skontrolujte internetové pripojenie a reštartujte aplikáciu!',
    internet_error: 'Zdá sa, že nie ste pripojený k internetu.',
    internet_error_text: 'Skontrolujte internetové pripojenie a reštartujte aplikáciu!',

    booking_status_agent: ['Čaká na prijatie', 'V príprave', 'Odoslaná', 'Žiadosť o zrušenie', '', 'Ukončená', 'Zrušená', 'Preklápanie do CRM', 'Odoslaná do CRM', 'Chyba pri odosielaní do CRM'],
    booking_status_agent_text: ['Čaká na prijatie', 'V príprave', 'Manuálne vkladanie objednávky', 'Žiadosť o zrušenie', '', 'Ukončená', 'Objednávka bude zrušená', 'Objednávka bude odoslaná do CRM automaticky systémom', 'Odoslaná do CRM', 'Chyba pri odosielaní do CRM'],
    booking_status_salon: ['Čaká na prijatie', 'V príprave', 'Odoslaná', 'Žiadosť o zrušenie', '', 'Ukončená', 'Zrušená', 'V príprave', 'V príprave', 'V príprave'],

    eshop_status: ['Čaká na prijatie', 'V príprave', 'Pripravená na výdaj', 'Odoslaná', 'Ukončená', 'Zrušená'],
    reklamacia_status: ['Vytvorená', 'Posudzovanie', 'Schválená', 'Zamietnutá', 'Ukončená'],

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December', 'Dnes'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.', 'Dnes'],
    all_months: 'Celý rok',
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned', 'Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob'],
    days_short_label: ['Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob', 'Ned'],
    sviatky: ['01-01', '01-06', '05-01', '05-08', '07-05', '08-29', '09-01', '09-15', '11-01', '11-17', '12-24', '12-25', '12-26'],
    month: 'Mesiac',
    week: 'Týždeň',
    day: 'deň',
    new: 'Nová udalosť',
    event: 'Udalosť',
    medical: 'Lekáreň',
    medical_: 'Lekárne',
    typ_other: 'Iný typ',
    medical_salon: 'Salón/Lekáreň',

    save: 'Uložiť',
    save_continue: 'Uložiť a pokračovať',
    save_changes: 'Uložiť zmeny',
    close: 'Zavrieť',
    time_from: 'Čas od',
    time_error: 'Nesprávny formát zadaného času. Použite formát HH:MM.',
    time_error_: 'Nesprávna dĺžka trvania. Čas od musí byť menší ako čas do.',
    from: 'od',
    to: 'do',
    hours_order: 'Na objednávku',
    dinner_from: 'Obed od',
    dinner_to: 'Obed do',

    list: 'list',
    waiting: 'Čaká na potvrdenie!',
    waiting_text: 'Stlačením "Uložiť", termín potvrdíte',
    sviatok: 'sviatok',
    none: 'Žiadne voľné termíny',
    calendar_free: 'Voľné termíny',
    time: 'Čas',
    start: 'Začiatok',
    end: 'Koniec',
    client_select: 'Vybrať zákazníka',

    minutes: 'minút',
    minutes_: 'min',
    hour: 'hodina',
    hour_short: 'hod.',
    hours: 'hodiny',
    hours_: 'hodín',
    delete: 'Vymazať',
    menu_title: 'Menu',
    cabine: 'Kabína',
    products_mine: 'Vlastné produkty',
    action: 'Akcia',
    action_offer: 'Akciová ponuka',
    cancel: 'Zrušiť',
    send: 'Odoslať',
    not_send: 'Neodoslať',
    send_err: 'Pri odosielaní nastala chyba. Skúste požiadavku opakovať.',
    back: 'Späť',
    back_app: 'Späť do aplikácie',
    without_dph: 'bez dph',
    with_dph: 's dph',
    agree: 'Súhlasím',
    disagree: 'Nesúhlasím',
    error: 'Chyba',
    remind: 'Pripomenúť',
    show: 'Zobraziť',
    read: 'Prečítať',
    permission: 'Povolenie',
    permissions: 'Povolenia',
    permission_added: 'Udelené povolenie',
    permission_removed: 'Odobraté povolenie',

    // **********************************************************************    
    // STRUKTURA MENU
    // **********************************************************************
    /*
        enabled         -> menu sa zobrazuje
        employee: true  -> LEN AK SU KOZMETICKY
    */
    menu: [
        {
            id: 0,
            account: 0, // pre vsetky typy uctov
            enabled: true,
            name: 'Domov',
            label: 'Domov',
            icon: faHome,
            color: global.theme_light_blue,
            icon_color: global.theme_white,
        },
        // ************************************************************************************************
        // SALON
        // ************************************************************************************************

        {
            id: 10,
            account: global.account_salon,
            enabled: true,
            name: 'Obchod',
            label: 'Obchod',
            icon: faShoppingBag,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 100,
                    divider: true,
                    enabled: true,
                    name: 'Predajňa'
                },
                {
                    id: 101,
                    enabled: true,
                    name: 'Predaj produktov',
                    label: 'Predaj produktov - predajňa',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 102,
                    enabled: true,
                    name: 'História',
                    label: 'História predaja produktov',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 103,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 104,
                    enabled: true,
                    name: 'Prijaté objednávky',
                    label: 'Prijaté objednávky z e-shopu',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 105,
                    divider: true,
                    enabled: true,
                    name: 'Sklad'
                },
                {
                    id: 106,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka produktov na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 107,
                    enabled: true,
                    name: 'História',
                    label: 'História objednávok produktov na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 108,
                    divider: true,
                    enabled: true,
                    name: 'Bonusový program'
                },
                {
                    id: 109,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 110,
                    enabled: true,
                    name: 'História',
                    label: 'História objednávok - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 111,
                    divider: true,
                    enabled: true,
                    name: 'Reklamácie',
                },
                {
                    id: 112,
                    enabled: true,
                    name: 'História reklamácii',
                    label: 'História reklamácii',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 115,
                    enabled: true,
                    name: 'Expirácie',
                    label: 'Expirácie produktov',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 113,
                    divider: true,
                    enabled: true,
                    name: 'Fakturácia',
                },
                {
                    id: 114,
                    enabled: true,
                    name: 'Faktúry',
                    label: 'Faktúry',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 20,
            account: global.account_salon,
            enabled: true,
            name: 'Štatistiky',
            label: 'Štatistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 200,
                    divider: true,
                    enabled: true,
                    name: 'Denná štatistika'
                },
                {
                    id: 201,
                    enabled: true,
                    name: 'Dnes',
                    label: 'Denná štatistika',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 202,
                    divider: true,
                    enabled: true,
                    name: 'Kozmetický salón'
                },
                {
                    id: 203,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 204,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 205,
                    enabled: true,
                    name: 'Služby',
                    label: 'Služby',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 206,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 207,
                    enabled: true,
                    name: 'Produky',
                    label: 'Produky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 208,
                    divider: true,
                    enabled: true,
                    name: 'Štatistiky'
                },
                {
                    id: 209,
                    enabled: true,
                    name: 'Jednotlivé produkty',
                    label: 'Jednotlivé produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 210,
                    enabled: true,
                    small: false,
                    name: 'Zákazníci',
                    label: 'Zákazníci',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 211,
                    enabled: true,
                    employee: true,
                    small: false,
                    name: 'Kozmetičky',
                    label: 'Kozmetičky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 212,
                    enabled: true,
                    name: 'Objednávky produktov',
                    label: 'Objednávky produktov na sklad',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 30,
            account: global.account_salon,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie o produktoch',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 301,
                    enabled: true,
                    name: 'Úprava produktov',
                    label: 'Úprava produktov',
                    icon: faPenAlt,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 302,
                    enabled: true,
                    name: 'Kategórie',
                    label: 'Kategórie',
                    icon: faLayerGroup,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 40,
            account: global.account_salon,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
                {
                    id: 401,
                    enabled: true,
                    name: 'Úprava služieb',
                    label: 'Úprava služieb',
                    icon: faPenAlt,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
                {
                    id: 402,
                    enabled: true,
                    name: 'Skupiny služieb',
                    label: 'Skupiny služieb',
                    icon: faLayerGroup,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
            ]
        },
        {
            id: 60,
            account: global.account_salon,
            enabled: true,
            name: 'Informácie',
            label: 'Informácie',
            icon: faInfo,
            color: global.theme_light_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Cenník',
                    label: 'Aktuálny cenník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Prebiehajúca akcia',
                    label: 'Informácie k prebiehajúcej akcii',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitný vzdelávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikácie',
                    label: 'Aplikácie Esthedermsalon pre tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },
        {
            id: 50,
            account: global.account_salon,
            enabled: true,
            name: 'Webová stránka',
            label: 'Moja webová stránka',
            icon: faGlobe,
            color: global.theme_light_blue,
            icon_color: global.theme_black,
            children: [
                {
                    id: 500,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 501,
                    enabled: true,
                    name: 'Náhľad stránky',
                    label: 'Náhľad webovej stránky a e-shopu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 510,
                    enabled: true,
                    name: 'Nastavenie stránky',
                    label: 'Nastavenie webovej stránky a eshopu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 80,
            account: global.account_salon,
            enabled: true,
            name: 'Školenia',
            label: 'Školenia',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 800,
                    enabled: true,
                    name: 'Plánované školenia',
                    label: 'Zoznam plánovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 801,
                    enabled: true,
                    name: 'Absolvované školenia',
                    label: 'Zoznam absolvovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 802,
                    enabled: true,
                    masterclass: true,
                    name: 'Úprava školení',
                    label: 'Úprava školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 803,
                    enabled: true,
                    //masterclass: true,
                    name: 'Video z webinárov',
                    label: 'Video z webinárov',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 99,
            account: 0,
            enabled: false,
            name: 'Testovanie',
            label: 'Testovacie scripty',
            icon: faQuestion,
            color: global.theme_light_blue,
            icon_color: global.theme_black,
            children: [
                {
                    id: 9999,
                    enabled: true,
                    name: 'Test',
                    label: 'test',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },

        // ************************************************************************************************
        // OBCHODNY ZASTUPCA
        // ************************************************************************************************
        /*
        {
            id: 500,
            account: global.account_agent,
            enabled: true,
            name: 'Kozmetické salóny',
            label: 'Kozmetické salóny',
            icon: faList,
            color: global.theme_light_cyan,
            icon_color: global.theme_black,
            children: [
                {
                    id: 5001,
                    enabled: true,
                    name: 'Zoznam',
                    label: 'Zoznam kozmetických salónov',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5002,
                    enabled: true,
                    name: 'Štatistika',
                    label: 'Štatistika predaja jednotlivých salónov',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5003,
                    enabled: true,
                    name: 'Skladové stavy',
                    label: 'Skladové stavy',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
        */
        {
            id: 510,
            account: global.account_agent,
            enabled: true,
            name: 'Objednávky',
            label: 'Objednávky produktov',
            icon: faShoppingCart,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 5101,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Objednávky produktov na sklad',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5102,
                    enabled: true,
                    name: 'Bonus',
                    label: 'Objednávky bonusu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5103,
                    enabled: true,
                    name: 'Reklamácie',
                    label: 'Reklamácie objednávok',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5104,
                    enabled: true,
                    name: 'Expirácie',
                    label: 'Expirácie produktov',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 520,
            account: global.account_agent,
            enabled: true,
            name: 'Štatistiky',
            label: 'Štatistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 5202,
                    divider: true,
                    enabled: true,
                    name: 'Kozmetické salóny'
                },
                {
                    id: 5203,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5204,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5205,
                    enabled: true,
                    name: 'Služby',
                    label: 'Služby',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5206,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 5207,
                    enabled: true,
                    name: 'Produky',
                    label: 'Produky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5208,
                    divider: true,
                    enabled: true,
                    name: 'Štatistiky'
                },
                {
                    id: 5209,
                    enabled: true,
                    name: 'Jednotlivé produkty',
                    label: 'Jednotlivé produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5211,
                    enabled: true,
                    small: false,
                    name: 'Kozmetické salóny',
                    label: 'Kozmetické salóny',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5212,
                    enabled: true,
                    small: false,
                    name: 'Ošetrenia',
                    label: 'Vykonávané ošetrenia',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5213,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Reprezentanti',
                    label: 'Reprezentanti',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5290,
                    divider: true,
                    enabled: true,
                    name: 'Školenia'
                },
                {
                    id: 5214,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Podľa služieb',
                    label: 'Absolvované školenia k jednotlivým ošetreniam',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5215,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Podľa produktov',
                    label: 'Absolvované školenia k jednotlivým produktom',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 570,
            account: global.account_agent,
            enabled: true,
            name: 'Aktuality',
            label: 'Aktuality',
            icon: null,
            color: global.theme_medium_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 5701,
                    enabled: true,
                    name: 'Príspevky',
                    label: 'Zoznam príspevkov',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 700,
            account: global.account_agent,
            enabled: true,
            name: 'Školenia',
            label: 'Školenia',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 701,
                    enabled: true,
                    name: 'Zoznam školení',
                    label: 'Zoznam školení',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 702,
                    enabled: true,
                    name: 'Kategórie školení',
                    label: 'Kategórie školení',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 530,
            account: global.account_agent,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Informácie o produktoch',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 304,
                    enabled: true,
                    name: 'Bonus',
                    label: 'Informácie o bonuse',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                }
            ]
        },
        {
            id: 540,
            account: global.account_agent,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                }
            ]
        },
        {
            id: 560,
            account: global.account_agent,
            enabled: true,
            name: 'Informácie',
            label: 'Informácie',
            icon: faInfo,
            color: global.theme_light_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Cenník',
                    label: 'Aktuálny cenník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Prebiehajúca akcia',
                    label: 'Informácie k prebiehajúcej akcii',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitný vzdelávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikácie',
                    label: 'Aplikácie Esthedermsalon pre tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },
        // ************************************************************************************************
        // MEDICAL - ACCOUNT
        // ************************************************************************************************

        {
            id: 1000,
            account: global.account_medical,
            enabled: true,
            name: 'Obchod',
            label: 'Obchod',
            icon: faShoppingBag,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 105,
                    divider: true,
                    enabled: true,
                    name: 'Sklad'
                },
                {
                    id: 106,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka produktov na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 107,
                    enabled: true,
                    name: 'História',
                    label: 'História objednávok produktov na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 108,
                    divider: true,
                    enabled: true,
                    name: 'Bonusový program'
                },
                {
                    id: 109,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 110,
                    enabled: true,
                    name: 'História',
                    label: 'História objednávok - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 1020,
            account: global.account_medical,
            enabled: true,
            name: 'Štatistiky',
            label: 'Štatistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 212,
                    enabled: true,
                    name: 'Objednávky produktov',
                    label: 'Objednávky produktov na sklad',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 1030,
            account: global.account_medical,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie o produktoch',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                }
            ]
        },
        {
            id: 1040,
            account: global.account_medical,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informácie',
                    label: 'Informácie o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                }
            ]
        },
        {
            id: 1060,
            account: global.account_medical,
            enabled: true,
            name: 'Informácie',
            label: 'Informácie',
            icon: faInfo,
            color: global.theme_light_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Cenník',
                    label: 'Aktuálny cenník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Prebiehajúca akcia',
                    label: 'Informácie k prebiehajúcej akcii',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitný vzdelávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikácie',
                    label: 'Aplikácie Esthedermsalon pre tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },
        {
            id: 1080,
            account: global.account_medical,
            enabled: true,
            name: 'Školenia',
            label: 'Školenia',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 800,
                    enabled: true,
                    name: 'Plánované školenia',
                    label: 'Zoznam plánovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 801,
                    enabled: true,
                    name: 'Absolvované školenia',
                    label: 'Zoznam absolvovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 803,
                    enabled: true,
                    masterclass: true,
                    name: 'Video z webinárov',
                    label: 'Video z webinárov',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
    ],
    institut: 'Institut Esthederm',
    menu_back: 'Domov',
    agent: 'Obchodný zástupca',
    agent_change: 'Zmena obchodného zástupcu',
    rep: 'Reprezentant',
    rep_: 'Rep.',
    gm: 'GM',
    agent_choose: 'Výber obchodného zástupcu',
    salon_choose: 'Výber kozmetického salónu',
    agent_error: 'Nie je vybratý obchodný zástupca',
    calendar: 'Kalendár',
    calendar_todays: 'Dnes naplánované',
    today: 'Dnes',
    birthday: 'Narodeniny',
    birthday_date: 'Dátum narodenia',
    yes: 'Áno',
    no: 'Nie',
    ok: 'OK',
    todo: 'Poznámky',
    todo_text: 'Zoznam poznámok a pripomienok',
    todo_new: 'Nová poznámka',
    todo_edit: 'Úprava poznámky',
    address: 'Adresa',

    deliver_address: 'Miesto určenia',
    dodavatel: 'Dodávateľ',
    prevadzka_nazov: 'Názov prevádzky',
    zodpovedna_osoba: 'Meno zodpovednej osoby',

    sex: 'Pohlavie',
    on: 'zapnutý',
    on_: 'zapnuta',
    off: 'Vypnuté',
    off_: 'Vypnutá',
    print: 'Tlačiť',
    export_data: 'Exportovanie údajov',
    export_data_: 'Export údajov',
    export_stats: 'Export štatistiky',
    export_stats_text: 'Export štatistických informácii',
    insert: 'Doplniť',

    login: 'Prihlásenie',
    login_email: 'E-mailová adresa',
    loginname: 'Prihlasovacie meno (e-mail)',
    loginname_error: 'Prihlasovanie meno musí byť e-mailová adresa',
    login_data: 'Prihlasovacie údaje',
    email_error: 'Nesprávny formát e-mailovej adresy',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_old: 'Pôvodné heslo',
    password_old_err: 'Pôvodné heslo je nesprávne',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_error_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    password_change: 'Zmeniť heslo',
    password_change_: 'Zmena hesla',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje!',
    lost_password: 'Zabudnuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa sa nenachádza v systéme',
    login_pin_code_error: 'Nesprávny overovací kód',
    login_pin_code: 'Zadajte kód z e-mailu',
    login_email_send: 'Na Vašu e-mailovú adresu sme zaslali overovací kód. Skontrolujte aj spamový adresár.',
    password_changed: 'Zmena hesla prebehla úspešne',
    continue: 'Pokračovať',

    news: 'Aktuality',
    news_: 'Aktuálne informácie',
    news_show_all: 'Zobraziť všetko',
    news_published: 'Publikované príspevky',
    news_not_published: 'Nezverejnené príspevky',
    news_draft: 'Rozpracované príspevky',
    news_add: 'Nový príspevok',
    news_item: 'Príspevok',
    news_add_title: 'Nový príspevok',
    news_edit_title: 'Úprava príspevku',
    news_list_year: 'Zoznam príspevkov za rok',
    news_icon_preview: 'Náhľad ikony príspevku',
    news_icon_create: 'Tvorba náhľadu príspevku',
    news_content_make: 'Tvorba obsahu',
    news_content_create: 'Obsahu príspevku',
    news_save_publish: 'Publikovať',
    news_country: 'Príspevok určený pre krajinu',
    news_title: 'Názov príspevku',
    news_description: 'Krátky popis príspevku',
    news_author: 'Autor príspevku',
    news_status: ['Nezverejnený', 'Publikovaný', 'Rozpracovaný', 'Vymazaný'],
    news_choose_typ: 'Výber typu obsahu',
    news_item_enabled: 'Aktívny obsah',
    news_divider_text: 'Oddeľovač obsahu',
    news_qrcode_text: 'Link - generovanie QR kódu',
    news_type_label: ['Text', 'Obrázok', 'Produkt', 'Oddelovač', 'Bonus', 'QR kód', 'Ošetrenie'],
    news_export: 'Exportovanie príspevku',
    news_export_question: 'Chcete exportovať príspevok?',
    news_export_text: 'Exportovaný príspevok bude odoslaný na Vašu e-mailovú adresu',

    qrcode: 'QR kód',
    qrcode_note: 'Text pod QR kódom',
    priority_enter: 'Zadajte číslo priority zobrazovania',

    image: 'Obrázok',
    image_choose: 'Výber obrázku',
    image_change: 'Zmena obrázku',
    image_note: 'Text pod obrázkom',

    db_error: 'Nastala chyba pri prístupe do databázy. Skúste požiadavku opakovať',
    register: 'Registrácia',
    register_: 'Registrovať',
    register_new: 'Nová registrácia',
    register_new_salon: 'Nová registrácia kozmetického salóna',

    contact: 'Kontakt',
    settings: 'Nastavenia',
    settings_basic: 'Kontaktné údaje',
    settings_company: 'Firemné údaje',
    settings_salon: 'Salón',
    settings_salon_: 'Adresa',
    settings_salon_address: 'Adresa kozmetického salóna',
    settings_salon_address_: 'Adresa salóna',
    settings_medical_address: 'Adresa lekárne',

    settings_calendar: 'Rezervačný systém',
    settings_calendar_text: 'Rezervácie termínov na ošetrenie zákazníkmi cez mobilnú aplikáciu',
    settings_calendar_label: 'Povolenie rezervácie termínov',
    settings_calendar_: 'Povoliť rezervácie',
    settings_calendar_time: 'Prestávka medzi ošetreniami',
    settings_calendar_hours: 'Nastavenie časov, počas ktorých sa môžu zákazníci objednávať (čas od - do)',
    settings_calendar_hours_text: 'Každý zadaný čas musí mať tvar: napr. 08:00, 18:00',
    settings_calendar_hours_text_: 'Ak nevyužívate obednú prestávku, nechajte políčka "Obed od" - "do" prázdne',
    settings_calendar_hours_equal: 'Nastaviť každý deň jednotlivo',
    calendar_days: 'Pracovné dni',
    calendar_remind_day: 'Pripomenúť celý deň',
    calendar_remind_label: 'Pripomenúť',
    calendar_remind_text1a: 'Odoslať všetkým zákazníkom e-mail s pripomienkou termínu?',
    calendar_remind_text1b: 'Správa bude odoslaná len zákazníkom s platnou e-mailovou adresou!',
    calendar_remind_text2: 'Odoslať zákazníkovi e-mail s pripomienkou termínu?',

    calendar_email_header: 'Pripomenutie termínu v kozmetickom salóne',
    calendar_email_label: 'Pripomenutie termínu',
    calendar_email_google: 'Vložiť udalosť do kalendára google',

    calendar_remind_result_label: 'Pripomienka termínu',
    calendar_remind_result_text: 'Pripomienka bola úspešne odoslaná!',
    calendar_remind_result_text_multi: 'Počet úspešne odoslaných pripomienok:',
    calendar_remind_result_error: 'Pri odosielaní nastala chyba. Skúste požiadavku opakovať!',
    calendar_notify: 'Odosielať notifikácie zákazníkom s pripomienkou termínu na ošetrenie deň vopred',

    settings_system: 'Aplikácia',
    settings_logout: 'Odhlásiť sa',

    logout: 'Odhlásiť sa',
    logout_label: 'Odhlásiť sa',
    logout_title: 'Odhlásenie sa z aplikácie',
    logout_text: 'Chcete sa odhlásiť z aplikácie?',

    eshop: 'E-shop',
    page_eshop: 'E-shop a stránka',
    page_eshop_: 'E-shop a webová stránka',

    required: 'Povinný údaj',
    required_red: 'Položky označené červenou farbou sú povinný údaj',
    name: 'Meno',
    surname: 'Priezvisko',
    mobil: 'Mobil',
    mobil_format: 'Mobil  (tvar +421 xxx xxx xxx)',
    format_error: 'Nesprávny formát',
    non_zero_error: 'Nesmie byť 0',
    email: 'E-mailová adresa',
    email_no_exist: 'Neexistujúca e-mailová adresa',
    email_: 'e-mail',
    email_wrong_format: 'Nesprávný formát e-mailové adresy',
    email_exists: 'Zadaná e-mailová adresa už je registrovaná v systéme. Zvoľte inú.',
    form_error: 'Nesprávne vyplnené údaje',
    show_on_web: 'Zobraziť vo webovom prehliadači',
    account: 'Číslo účtu',
    account_eshop: 'Číslo bankového účtu (platby z e-shopu)',

    account_cancel: 'Zrušiť účet',
    account_cancel_request: 'Požiadať o zrušenie účtu',
    account_cancel_label: 'Žiadosť o zrušenie účtu',
    account_cancel_question: 'Chcete odoslať správcovi systému požiadavku  o zrušení účtu?',
    account_cancel_text: 'Odoslať správcovi systému Esthederm Salon žiadosť o pozastavenie členstva, alebo zrušenie účtu',
    account_cancel_option_1: 'Dočasné pozastavenie',
    account_cancel_option_1_: 'Účet bude dočasne vypnutý a v budúcnosti bude opäť možné účet používať.',
    account_cancel_option_2: 'Zrušenie účtu',
    account_cancel_option_2_: 'Účet bude zrušený a prístupové údaje vymazané do 24 hod.',
    account_cancel_option_3: 'Zrušenie účtu a vymazanie údajov',
    account_cancel_option_3_: 'Účet bude zrušený, osobné údaje správcu účtu a klientské karty budú vymazané do 10 dní.',
    account_cancel_ok: 'O výsledku vás budeme informovať do 24 hod. na vášu e-mailovú adresu',
    send_request: 'Odoslať požiadavku',
    send_request_ok: 'Požiadavka bola úspešne odoslaná.',
    send_request_error: 'Pri odosielaní nastala chyba. Skúste požiadavku opakovať. Ak sa chyba bude opakovať kontaktujte podporu.',

    company_name: 'Názov firmy',
    street: 'Ulica',
    street_number: 'Číslo',
    psc: 'PSČ',
    town: 'Mesto',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    dph_platca: 'Som platca DPH',
    number: 'Číslo',

    prevadzka: 'Kozmetický salón',
    prevadzka_name: 'Názov salónu',
    medical_name: 'Názov prevádzky',
    edit: 'Upraviť',
    edit_data: 'Upraviť údaje',
    employees: 'Zamestnanci',
    employee: 'Zamestnanec',
    employee_enabled: 'Zamestnanec je aktívny',
    employees_all: 'Všetky záznamy',

    records_none: 'Žiadne záznamy',
    record_new: 'Nový záznam',
    record_edit: 'Úprava záznamu',

    client_app: 'Aplikácia',
    client_app_: 'Klientská aplikácia',
    clients: 'Zákazníci',
    client: 'Zákazník',
    client_new: 'Nový zákazník',
    client_enabled: 'Zákazník je aktívny',
    clients_list: 'Zoznam zákazníkov',
    clients_today: 'Dnes objednaný',
    clients_hidden: 'Neaktívny',
    client_with_app: 'Zákazník s klientskou aplikáciou',
    cliant_all: 'Zoznam',
    client_choose: 'Výber zákazníka',
    male: 'Muž',
    female: 'Žena',
    client_gdpr: 'Zákazník bol oboznámený so spracovaním osobných údajov a dal súhlas',

    client_registered: 'Registrácia v kozmetickom salóne',
    client_registered_label: 'Vitajte v Esthederm Salon!',
    client_registered_text: 'Vaša registrácia bola úspešne dokončená.',
    client_registered_link: 'Do svojho účtu sa môžete prihlásiť sa adrese:',
    client_registered_login: 'Prihlasovacie údaje:',
    client_registered_username: 'Prihlasovacie meno:',
    client_registered_password: 'Heslo:',
    client_register_send_email: 'Chcete odoslať zákazníkovi e-mail o úspešnej registrácii v systéme Esthederm  Salon?',
    client_register_send_email_1: 'E-mailová správa bude obsahovať prístupové údaje do klientskej aplikácie.',
    client_register_send_email_2: 'Upozornenie: poznámky, galéria a diagnostika nebudú prístupné na karte zákazníka bez jeho súhlasu.',

    birthdate: 'Rok narodenia',
    gdpr: 'Ochrana osobných údajov',
    gdpr_: 'GDPR',
    gdpr_logs: 'GDPR záznamy',
    gdpr_logs_send_label: 'Odoslať GDPR záznamy',
    gdpr_logs_send_text: 'Chcete odoslať všetky záznamy GDPR z karty zákazníka?',
    gdpr_logs_send_email_text: 'Export záznamov GDPR zákazníka',
    gdpr_logs_send_table_text: 'Zoznam vykonaných zmien v nastavení GDPR účtu zákazníka',

    gdpr_changes: 'Zmena nastavení GDPR',
    gdpr_export_log: 'Exportovať všetky aktivity spojené so zmenou nastavení GDPR',
    gdpr_show_log: 'Zobraziť a exportovať všetky aktivity spojené so zmenou nastavení GDPR zákazníka',
    gdpr_export_ask: 'Chcete exportovat všechny záznamy GDPR zákazníka?',
    gdpr_export_gdpr_log: 'Exportovať záznamy',
    gdpr_show_gdpr_log: 'Zobraziť záznamy',
    gdpr_enter_email: 'Zadajte e-mailovú adresu, na ktorú bude dokument odoslaný',

    gdpr_details: 'Detailné nastavenie GDPR',
    gdpr_details_: 'Nastavenie GDPR',
    gdpr_read: 'Zobraziť dokument',
    gdpr_agree: 'Súhlasím so spracovaním osobných údajov',
    gdpr_vop_agree: 'Súhlasím so spracovaním osobných údajov a všeobecnými obchodnými podmienkami',
    gdpr_info: 'Informácie o ochrane osobných údajov',
    gdpr_agree_question: 'Súhlasíte so spracovaním osobných údajov?',
    gdpr_vop_agree_question: 'Súhlasíte so spracovaním osobných údajov a všeobecnými obchodnými podmienkami?',
    gdpr_agree_text: 'Pred pokračovaním je potrebné vyjadrenie súhlasu.',

    gdpr_client_info: 'Informácie o zákazníkovi',
    gdpr_client_info_text: 'Odoslať zákazníkovi informácie, ktoré sú uložené v jeho karte zákazníka',
    gdpr_export_client: 'Odoslať údaje zákazníkovi',
    gdpr_export_card: 'Exportovať',
    gdpr_export_card_text: 'Export údajov z karty zákazníka',
    gdpr_export_email_error: 'Chýba platná e-mailová adresa',
    gdpr_saved_data: 'Osobné údaje o zákazníkovi',

    gdpr_email_client_text1: 'Dobrý deň,',
    gdpr_email_client_text2: 'na základe Vašej požiadavky Vám v prílohe tohto e-mailu zasielame súbor PDF s informáciami, ktoré máme o Vás uložené v systéme Esthederm Salon.',
    gdpr_email_client_text3: 'Heslo na odomknutie súboru bude odoslané na Vašu e-mailovú adresu.',
    gdpr_email_client_text4: 'Informácie o zakazníkovi',
    gdpr_email_client_text5: 'Heslo k odomknutiu súboru s informáciami zo systému Esthederm Salon je',
    gdpr_email_client_text6: 'Heslo k odomknutiu súboru',
    gdpr_email_client_text7: 'Export údajov z karty zákazníka',

    gdpr_none: 'Žiadne osobné údaje',
    gdpr_settings_text: 'Zákazník dal súhlas k spracovaniu svojich osobných údajov:',
    gdpr_settings_basic: 'Osobné údaje',
    gdpr_settings_basic_text: '(meno, priezvisko, pohlavie, rok, narodenia)',
    gdpr_settings_basic_email: 'Evidencia absolvovaných kozmetických ošetrení/služieb a súvisiacich údajov',
    gdpr_settings_gallery: 'Galéria fotografií',
    gdpr_settings_gallery_text: 'Ukladanie fotografií zákazníka',
    gdpr_settings_gallery_email: 'Vaše fotografie (galéria)',
    gdpr_settings_notes: 'Poznámky',
    gdpr_settings_notes_text: 'Vedenie poznámok o zákazníkovi',
    gdpr_settings_notes_email: 'Určité súvisiace údaje o zdravotnom stave (poznámky)',
    gdpr_settings_diagnostic: 'Diagnostika pleti',
    gdpr_settings_diagnostic_text: 'Vytváranie diagnostiky pleti na základe údajov zákazníka',
    gdpr_settings_diagnostic_email: 'Záznam o diagnostike pleti (diagnostika)',
    gdpr_settings_change: 'Zmena nastavení spracovania osobných údajov zákazníka',
    gdpr_settings_buttom: 'Zmeniť nastavenia',
    gdpr_agreements_changed: 'Na základe Vašej požiadavky sme upravili nastavenie spracovania Vaších osobných údajov.',

    gdpr_delete_card_title: 'Vymazanie karty zákazníka',
    gdpr_delete_card_button: 'Vymazať kartu',
    gdpr_delete_card_attention: 'Pozor chystáte sa vymazať celú kartu zákazníka!',
    gdpr_delete_card_sub_title: 'Kompletné vymazanie karty zákazníka.',
    gdpr_delete_card_items_title: 'Vymazanie údajov z karty zákazníka',
    gdpr_delete_card_text: 'Pretože ste zmenili nastavenia GDPR, kde zákazník nesúhlasí so spracovaním svojích osobných údajov, bude jeho karta vymazaná.',
    gdpr_delete_card_text_: 'Súhlasíte s vymazaním karty zákazníka?',
    gdpr_delete_card_text_2: 'Pozor, táto požiadavka je nevratná! Zmazané údaje sa už nebudú dať obnoviť!',
    gdpr_delete_email: 'Vymazanie osobných údajov',

    gdpr_send_text: 'Dobrý deň,</br>posielame Vám dokument GDPR (Ochrana osobných údajov).</br></br>Prajeme príjemný deň.</br>Esthederm Salon',

    client_agreements: 'Udelené povolenia',

    confirmation_needed: 'Táto požiadavka vyžaduje dodatočné potvrdenie!',
    gdpr_delete_items_label: 'Pretože ste zmenili nastavenia GDPR, budú nasledujúce záznamy vymazané z karty zákazníka:',

    gdpr_logs_type: ['Vytvorená karta zákazníka', 'Zmena nastavení GDPR', 'Zmena nastavení GDPR a vymazanie záznamov', 'Vymazaná karta zákazníka', 'Export záznamov GDPR LOGS', 'Export karty a odoslanie zákazníkovi', 'Export karty zákazníka pre účely salóna'],

    file_export: 'Odoslanie súboru',
    file_export_ok: 'Súbor bol úspešne odoslaný',
    file_export_error: 'Pri odosielaní súboru nastala chyba. Skúste požiadavku opakovať',

    gdpr_export: 'Export údajov',
    gdpr_export_question: 'Chcete exportovať údaje z karty zákazníka?',
    gdpr_export_question_: 'Exportované údaje budú odoslané na vašu e-mailovú adresu:',
    gdpr_send: 'Odoslanie údajov',
    gdpr_send_question: 'Chcete odoslať zákazníkovi informácie z jeho klientskej karty?',
    gdpr_send_question_: 'Údaje budú odoslané na e-mailovú adresu zákazníka:',
    gdpr_doc: 'Dokument o ochrane osobných údajov',

    vop: 'Všeobecné obchodné podmienky',
    gdpr_vop: 'Ochrana osobných údajov a všeobecné obchodné podmienky',
    gdpr_agreement_doc: 'Súhlas so spracovaním osobných údajov',
    cookies: 'Cookies',

    chat: 'Diskusia',
    chat_enable: 'Povoliť diskusiu',
    chat_public: 'Verejná diskusia',
    chat_public_item: 'Verejný príspevok',
    chat_new: 'Nový príspevok',
    chat_edit: 'Úprava príspevku',
    chat_prispevok: 'Príspevok',
    chat_text: 'Text príspevku',
    chat_delete: 'Vymazanie príspevku',
    chat_delete_ask: 'Chcete vymazať publikovaný príspevok?',
    chat_none: 'Žiadne príspevky',
    chat_error: 'Pri odosielaní nastala chyba!',
    chat_not_readed: 'Počet neprečítaných',
    chat_private: 'Súkromná diskusia',
    chat_notify_label: 'Diskusia - nový príspevok',

    card: 'Karta',
    salon_card: 'Karta zákazníka',
    client_card: 'Karta zákazníka',
    client_cards: 'Karty zákazníkov',
    age: 'Vek',
    points: 'Vernostné body',
    points_edit: 'Upraviť počet bodov',
    client_points: 'Počet vernostných bodov',
    client_vouchers: 'Počet darčekových poukážok získaných za vernostné body',
    vouchers_used: 'Počet použitých darčekových poukážok',
    vouchers_used_value: 'v hodnote',
    voucher: 'Darčeková poukážka',
    value: 'Hodnota',
    bonus_use: 'Uplatniť',
    bonus_system: 'Vernostný systém',
    bonus_system_enable: 'Používať vernostný systém',
    bonus_system_label: 'Vernostné body sa zbierajú predávaním produktov, kde 1 € = 1 bod',
    bonus_system_label_: 'Nazbieraním preddefinovaného počtu bodov sa vygeneruje poukážka s hodnotou v €, ktorú je možné použiť ako zľavu na ďalší nákup.',
    bonus_system_points: 'Počet bodov potrebných na vygenerovanie jednej poukážky',
    bonus_system_price: 'Hodnota vygenerovanej poukážky v €',
    bonus_system_example: 'Príklad: Ak predáte produkty v hodnote @price €, systém vygeneruje jednu poukážku zákazníkovi, ktorej hodnota je @bonus €. Zákazník môže túto poukážku použiť na ďalší nákup. Použitím poukážky získava zľavu na nákup v hodnote poukážky -@bonus €',
    bonus_click_to_use: 'Označte poukážky a stlačte uplatniť',
    bonus_delete: 'Vymazať vernostnú poukážku',
    bonus_delete_ask: 'Vymazať vernostnú poukážku zo zoznamu? Body budú pripočítané do konta zákazníka.',


    preview: 'Náhľad',
    text_editor: 'Textový editor',

    product: 'Produkt',
    products: 'Produkty',
    products_my: 'Ostatné produkty',
    buyed_products: 'Zoznam zakúpených produktov v salóne',
    buyed_products_eshop: 'Zoznam zakúpených produktov cez e-shop',
    products_categories: 'Pridávanie a úprava kategórii produktov',
    products_list: 'Zoznam produktov',
    product_new: 'Nový produkt',
    products_none: 'Žiadne produkty',
    products_buy_eshop: 'Produkty zakúpene cez e-shop',
    products_structure: 'Zloženie',
    products_structure_label: 'Zloženie produktu',
    products_export: 'Export produktov',
    products_export_label: 'Skladové stavy produktov',
    products_export_email: 'Export skladových stavov produktov do PDF',
    products_export_selection: 'Výber typu exportu zoznamu produktov',
    pricelist: 'Cenník produktov',
    product_card: 'Popis produktu',
    products_export_selection_pricelist: 'Exportovať zoznam ako cenník',
    products_export_selection_pricelist_yes: 'V exportovanom súbore budú zahrnuté len predajné ceny',
    products_export_selection_pricelist_no: 'V exportovanom súbore budú zahrnuté nákupné ceny, predajné ceny a skladové stavy',

    product_name: 'Názov produktu',
    note_short: 'Krátky popis',
    note_long: 'Detailný popis produktu',
    keywords: 'Kľúčové slová pri vyhľadávaní produktu (jednotlivé slová oddeľujte medzerou)',
    cost: 'Nákupná cena (s DPH)',
    cost_: 'Nákupná cena',
    price: 'Predajná cena (s DPH)',
    price_: 'Predajná cena',
    price__: 'Cena',
    price_dph: 'Cena s DPH',
    sum: 'Suma',
    sum_dph: 'Suma celkom s DPH',
    sum_dph_: 'Suma celkom',
    price_dph_piece: 'Cena s DPH za 1 ks',
    price_piece: 'Cena za 1 ks',
    price_final: 'Nezáväzne doporučená cena s DPH',
    cost_final: 'Nákupná cena pre kozmetičku bez DPH',
    stock_items: 'Počet kusov skladom',
    details: 'Objemové množstvo',
    code: 'Obj. kód produktu',
    code_: 'Obj. kód',
    units: 'Počet kusov v balení',
    product_action: 'Akciový produkt',
    product_news: 'Novinka',
    product_active: 'Produkt aktívny',
    product_eshop_enabled: 'Produkt sa zobrazuje na e-shope',
    stock: 'Skladom',
    no_stock: 'Nie je skladom',
    quantity: 'Počet kusov',
    quantity_: 'Počet',
    quantity_bals: 'Počet balení',
    pack: 'balenie',
    sumar: 'Spolu',

    service: 'Služba',
    service_: 'Ošetrenie',
    services: 'Služby',
    services_: 'Ošetrenia',
    absolved_services: 'Zoznam poskytnutých služieb',
    services_my: 'Ostatné služby',
    services_news: 'Nové ošetrenia',
    services_categories: 'Pridávanie a úprava skupín služieb',
    services_list: 'Zoznam služieb',
    services_new: 'Nová služba',
    services_none: 'Žiadne služby',
    service_cost: 'Náklady na službu (s DPH)',
    service_cost_: 'Náklady na službu',
    service_enabled: 'Služba je aktívna',
    service_name: 'Názov služby',
    service_time: 'Dĺžka služby v minutách',
    service_time_: 'Dĺžka služby',
    service_note: 'Poznámka k službe',
    service_materials: 'Školiace materiály',
    service_select: 'Výber služby',
    service_select_: 'Výber ošetrenia',
    service_change: 'Zmena ošetrenia',
    service_date: 'Dátum služby',
    service_delete: 'Vymazať službu',
    service_delete_ask: 'Vymazať službu z karty zákazníka?',
    service_eshop: 'Služba sa zobrazuje na webovej stránke',
    service_reserve: 'Objednávanie cez rezervačný systém',
    service_reserve_time: 'Čas na prípravu v minutách',
    service_reserve_time_: 'Čas na prípravu - rezervačný systém',
    service_reserve_time_short: 'Príprava',

    product_select: 'Výber produktu',
    product_change: 'Zmena produktu',

    bonus_select: 'Výber bonusu',
    bonus_change: 'Zmena bonusu',

    note: 'Poznámka',
    notes: 'Poznámky',
    gallery: 'Galéria',
    statistic: 'Štatistika',
    stats_sell: 'Štatistika predaja',
    sum_to_pay: 'Suma k zaplateniu',
    categories: 'Kategórie',
    category: 'Kategória',
    category_enabled: 'Kategória je aktívna',
    category_eshop: 'Kategória sa zobrazuje na e-shope',
    label: 'Názov',
    slug: 'Textový identifikátor',

    photos: 'Fotografie',
    photo: 'Fotografia',
    photo_select_title: 'Výber a úprava fotografie',
    photo_select: 'Vybrať fotografiu',
    photo_change: 'Zmeniť fotografiu',
    photo_delete: 'Vymazať',
    photo_delete_text: 'Chcete vymazať fotografiu?',
    photo_save: 'Vložiť',
    photo_min: 'Minimálna veľkosť',
    select: 'Vybrať',

    search: 'Hľadať',
    search_in_category: 'Hľadať',

    pieces: 'ks',
    groups: 'Skupiny',
    group: 'Skupina',
    group_enabled: 'Skupina je aktívna',
    group_eshop: 'Skupina sa zobrazuje na web stránke',
    video_preview: 'Video',

    empty_list: 'Žiadne položky',

    register_from_eshop: 'Registrácia z e-shopu',

    send_email: 'Odoslať e-mail',
    send_email_clients: 'Odoslať hromadný e-mail zákazníkom',
    clients_with_email: 'E-mail bude odoslaný len zákazníkom s e-mailovou adresou',
    send_notify_clients: 'Odoslať hromadne správu zákazníkom',
    clients_with_app: 'Správa bude odoslaná len zákazníkom s klientskou aplikáciou ako notifikácia',
    send_email_client: 'Odoslať e-mail',
    send_notify: 'Odoslať správu',
    age_years: 'rokov',
    year: 'rok',
    selected_season: 'Za dané obdobie',
    stats_season: 'Štatistika za rok',
    stats_season_date: 'Štatistika za obdobie',
    info: 'Informácie',
    info_: 'Informácia',
    diagnostics: 'Diagnostika',
    add: 'Pridať',
    delete_note: 'Vymazanie poznámky',
    delete_note_ask: 'Vymazať poznámku z karty zákazníka?',
    note_default: 'Text novej poznámky ...',
    visits: 'História návštev',

    galery_add: 'Pridať fotografiu',
    galery_delete: 'Vymazať fotografiu',
    galery_delete_ask: 'Vymazať fotografiu z karty zákazníka?',

    photo_choose: 'Výber fotografie',
    photo_choose_: 'Výbrať fotografiu',
    date: 'Dátum',
    card_delete: 'Vymazať z karty',
    sell_data: 'Dátum predaja',

    diagnose: 'Vyhodnotiť',
    diagnose_services: 'Výsledok diagnostiky',
    addon: 'Príloha',
    email_header: 'Predmet',
    email_header_error: 'Predmet e-mailu nesmie byť prázdny',
    emails_sended: 'Počet úspešne odoslaných e-mailov:',
    email_sended: 'E-mail bol úspešne odoslaný.',
    notify_send: 'Odoslať správu',
    notify: 'Správa',
    notify_text: 'Text správy',
    notifies_sended: 'Počet úspešne odoslaných správ',
    notify_sended: 'Správa bola úspešne odoslaná.',

    pin: 'Prihlasovací kód do klientskej aplikácie',
    pin_life: 'Platnosť kódu je 60 sekúnd!',
    pin_life_end: 'Platnosť kódu vypršala.',
    pin_generate: 'Vygenerovať nový kód',
    login_code_send: 'Odoslať prihlasovací kód zákazníkovi prostredníctvom e-mailu',
    login_code_send_label: 'Prihlasovací kód do klientskej aplikácie',
    login_code_send_text_1: 'Prihlasovací kód do aplikácie - Kozmetické salóny klient',
    login_code_send_text_2: 'Kód skopírujte do aplikácie a prihláste sa.</br>Pozor, platnosť prihlasovacieho kódu je 24 hodín!',

    calendar_delete: 'Vymazať udalosť',
    calendar_delete_item: 'Vymazať udalosť z kalendára?',
    calendar_holiday: 'Dovolenka',
    calendar_holiday_show: 'Výber dní dovolenky',
    calendar_holiday_list: 'Zoznam dni dovolenky',

    month_list: 'Podľa jednotlivých mesiacov',

    todo_delete: 'Vymazanie poznámky',
    todo_delete_text: 'Vymazať poznámku zo zoznamu?',

    products_shop: 'Predaj produktov',
    products_shop_sum: 'Suma nákupu',
    products_shop_client: 'Pripísať predaj zákazníkovi',
    products_shop_delete: 'Vymazať predaj',
    products_shop_deletes: 'Produkt vymazaný z predaja na karte zákazníka',
    products_shop_delete_text1: 'Chcete vymazať predané produkty?',
    products_shop_delete_text2: 'Produkty budú vymazané z karty zákazníka a pripočítané do skladových zásob.',
    products_shop_number: 'Číslo',
    products_shop_history: 'Historia predaja produktov',
    product_buy: 'Do košíka',
    product_in_basket: 'V košíku',
    products_shop_details: 'Náhľad predaja',
    products_stack_export: 'Export produktov',
    basket: 'Nákupný košík',
    shop: 'Predajňa',
    shop_finish: 'Dokončiť nákup',
    shop_finish_text: 'Zo skladových zásob budú odpočítané predané množstvá produktov',

    booking_label: 'Objednávka produktov na sklad',
    booking_products: 'Objednávky produktov',
    booking_history_label: 'História objednávok produktov',
    booking_stock: 'Sklad',
    booking_preview: 'Objednávka',
    booking_pieces: 'Objednať (ks)',
    booking_pack: 'Objednať (balenie)',
    booking_card: 'Náhľad objednávky produktov',
    booking_sum: 'Suma s DPH',
    booking_checkout: 'Odoslať objednávku',
    booking_checkout_question: 'Objednávka bude odoslaná obchodnému zástupcovi.',
    booking_min_sum: 'Minimálna suma objednávky musí byť',
    booking_note: 'Pridať poznámku',
    booking_note_edit: 'Upraviť poznámku',
    booking_note_included: 'Objednávka obsahuje poznámku!',
    booking_note_not_included: 'Objednávka neobsahuje poznámku!',
    booking_email_label: 'Objednávka produktov',
    booking_sended_label: 'Odoslaná objednávka',
    booking_sended_text: 'Objednávka produktov bola úspešne odoslaná obchodnému zástupcovi',
    booking_error_label: 'Chyba',
    booking_error_text: 'Pri odosielaní objednávky nastala chyba, skúste požiadavku opakovať!',
    booking_items: 'Počet položiek',
    booking_number: 'Číslo objednávky',
    booking_date: 'Dátum objednávky',
    booking_cancel: 'Zrušiť objednávku',
    booking_cancel_text: 'Žiadosť o zrušenie objednávky bude odoslaná obchodnému zástupcovi.',
    booking_status: 'Stav objednávky',
    booking_changes: 'V objednávke sa nachádzajú zmeny',
    booking_product_ordered: 'Objednaný počet',
    booking_product_received: 'Dodaný počet',
    booking_reklamacia: 'Reklamácia',
    booking_reklamacia_label: 'Reklamácia objednávky produktov',
    booking_reklamacia_order: 'Reklamácia objednávky',
    booking_reklamacia_notify: 'Zmena stavu reklamácie objednávky na',
    booking_finish: 'Pripísať na sklad',
    booking_finish_text: 'Produkty budú pripísané do skladových zásob.',
    booking_stat_label: 'Predaj produktu za posledných 12 mesiacov',
    booking_stat_recommended: 'Odporúčané množstvo k objednávke',
    booking_stat_last_order: 'Posledná objednávka na sklad',
    booking_ask_status: 'Opýtať sa na stav',
    booking_ask_status_label: 'Opýtať sa na stav objednávky',
    booking_ask_status_text: 'Požiadavka bude odoslaná obchodnému zástupcovi',
    booking_ask_status_message: 'Prosím o informáciu o stave objednávky č.',
    booking_ask_status_notify: 'Zisťovanie stavu objednávky produktov',
    booking_ask_status_send_ok: 'Požiadavka bola úspešne odoslaná obchodnému zástupcovi.',
    booking_reklamacia_select: 'Označte produkty, ktoré reklamujete',
    booking_reklamacia_text: 'Text reklamácie',
    booking_reklamacia_text_photos: 'Doplňte dôvod reklamácie a fotografie',
    booking_reklamacia_note: 'Vaše kontaktné údaje budú doplnené systémom',
    booking_reklamacia_note2: 'Fotografie (max. počet 5 ks fotografií)',
    booking_reklamacia_add_photo: 'Pridať fotografiu',
    booking_reklamacia_send_label: 'Odoslať reklamáciu',
    booking_reklamacia_send_text: 'Reklamácia bude odoslaná obchodnému zástupcovi',
    booking_reklamacia_products: 'Reklamované produkty',
    booking_reklamacia_true: 'Vytvorená reklamácia',
    booking_reklamacia_status: 'Stav reklamácie',
    booking_reklamacia_count: 'Počet reklamácii',
    booking_filter_active: 'Nevybavené objednávky',
    booking_filter_sended: 'Odoslané objednávky',
    booking_filter_finished: 'Ukončené objednávky',
    booking_create_new: 'Nová objednávka',
    booking_create_title: 'Vytvorenie novej objednávky',
    booking_create_text: 'Chcete vytvoriť novú objednávku pre salón?',

    booking_button_send_manual: 'Odoslané manuálne',
    booking_button_send_auto: 'Preklopiť do CRM',
    booking_crm_sended: 'Odoslané do CRM',
    booking_manual_sended: 'Manuálne do CRM',
    booking_missing_id: 'Chýbajúce prepojenie na CRM',
    booking_missing_id_text: 'Zákazník nie je prepojený na CRM. Objednávku nie je možné preklopiť.',
    booking_missing_id_sub_text: 'Prosím o zaslanie ID zo CRM na adresu info@datadream.sk',


    booking_export: 'Export objednávok',
    booking_export_: 'Export objednávky',
    booking_export_label: 'Export objednávok',
    booking_export_text: 'Ukončené objednávky za daný rok',
    bonus_export_email: 'Export objednávok bonusu',
    product_export_email: 'Export objednávok produktov',

    order_verify_label: 'Kontrola objednávky podľa prijatých kusov',
    order_verify_text: 'Produkty budú pripísané na sklad v upravených množstvách',
    order_verify_quantity: 'Počet prijatých kusov',

    bonus: 'Bonus',
    bonus_booking: 'Objednávka bonusu',
    bonus_bookings: 'Objednávky bonusu',
    bonus_sum: 'Suma bez DPH',
    bonus_pay: 'Doplatok bez DPH',
    bonus_pay_sum: 'Doplatok',
    bonus_pay_: 'Platba',
    bonus_order_preview: 'Náhľad objednávky bonusu',
    bonus_from_credit: 'Z kreditu',
    bonus_from_credit_pieces: 'Počet kusov z kreditu',
    bonus_from_credit_pieces_: 'ks z kreditu',
    bonus_to_buy_pieces_: 'ks k platbe',
    bonus_to_buy_pieces: 'Počet kusov k platbe',
    bonus_to_pay: 'doplatiť',
    bonus_credit_left: 'Zostávajúci počet bodov',
    bonus_credit: 'Body',
    bonus_credit_status: 'Stav bodov',
    bonus_ask_status_notify: 'Zisťovanie stavu objednávky bonusu',
    bonus_email_label: 'Objednávka bonusu',
    bonus_order_close: 'Ukončiť objednávku',
    bonus_finist_text: 'Objednávka bude označená ako ukončená',
    bonus_credit_history: 'História kreditu',
    bonus_history_set: 'Zmena kreditu adminom',
    bonus_history_buy: 'Nákup produktov',
    bonus_history_return: 'Vrátený kredit po zmene objednávky',
    bonus_credit_: 'Body',
    bonus_credit_quantity: 'Počet bodov',

    bonus_credit_year: 'Ročný počet bodov',
    bonus_credit_year_note1: 'Celkový počet bodov pridelený na postupné čerpanie.',
    bonus_credit_year_note2: 'Čerpanie bodov je rozložené na obdobie 12 mesiacov.',
    bonus_credit_year_note3: 'Každé @n mesiace sa budú body postupne uvoľňovať z celkového ročného množstva.',
    bonus_credit_actual: 'Aktuálny stav bodov na nakupovanie',
    bonus_credit_periode: 'Periodické navyšovanie o @n bodov',
    bonus_credit_periode_left: 'Zostávajúci počet navýšení',
    bonus_credit_year_left: 'Zostávajúci ročný počet bodov',
    bonus_credit_year_left_note: 'Počet bodov, ktoré neboli zatiaľ uvoľnené',
    bonus_credit_status_small: 'Zobraziť stav bodov',

    do_not_reply: 'NEODPOVEDAŤ',
    do_not_reply_text: 'Prosím, neodpovedajte na e-mail! Toto je automaticky generovaná správa systémom Esthederm Salon.',
    do_not_reply_text_: 'Prosím, neodpovedajte na tento email, na odpoveď použite emailovu adresu:',

    export_agent_data_ask: 'Chcete exportovať údaje?',
    export_agent_data_text: 'Exportované údaje budú odoslané na Vašu e-mailovú adresu:',

    email_table_number: 'p.č.',
    email_table_code: 'kód',
    email_table_product: 'produkt',
    email_table_quantity: 'počet kusov',
    email_table_quantity_from_credit: 'počet ks z kreditu',
    email_table_quantity_to_pay: 'počet ks k platbe',
    email_thanks: 'Ďakujeme a prajeme príjemný deň.',

    eshop_history: 'Prijaté objednávky z eshopu',
    eshop_quantity: 'Objednaný počet',
    eshop_card: 'Objednávka produktov',
    eshop_products: 'Zoznam objednaných produktov',
    eshop_trade: 'Spôsob dopravy',
    eshop_payment: 'Spôsob platby',
    eshop_note: 'Poznámka k objednávke',
    eshop_item_delete: 'Vymazanie položky',
    eshop_item_delete_text: 'Chcete vymazať položku z objednávky?',
    eshop_order_delete: 'Zrušenie objednávky',
    eshop_order_delete_text: 'Chcete zrušiť objednávku?',
    eshop_order_delete_text_: 'Rezervované množstvá produktov budu pripísane na sklad.',
    eshop_item_add: 'Doplniť produkt',
    eshop_ask_email: 'Zmena stavu objednávky',
    eshop_ask_email_text: 'Chcete odoslať e-mail zákazníkovi so zmenou statusu objednávky?',
    eshop_email_text: 'Zmena stavu objednávky číslo:',
    show_active: 'Zobraziť len nevybavené',
    show_mine: 'Zobraziť len moje',
    eshop_received: 'Počet nevybavených objednávok z e-shopu',
    orders_products_received: 'Počet nevybavených objednávok produktov na sklad',
    orders_bonus_received: 'Počet nevybavených objednávok bonusu',
    ask_order_change_status: 'Chcete zmeniť stav objednávky',
    orders_year_list: 'Zoznam objednávok vytvorených v roku',

    reklamacia_select: 'Výber dôvodu',
    reklamacia_reason: 'Dôvod reklamacie',
    reklamacia_dovod: [
        { id: 0, text: 'poškodený tovar / zásielka' },
        { id: 1, text: 'nesprávne množstvo / druh' },
        { id: 2, text: 'nedostatočná kvalita produktu' },
        { id: 3, text: 'nesprávna cena' },
        { id: 4, text: 'iné' },
    ],
    reklamacia: 'Reklamácia',
    reklamacia_date: 'Dátum reklamácie',
    reklamacia_number: 'Číslo reklamácie',
    reklamacia_message: 'Text reklamácie',
    reklamacia_email: 'Export reklamácie',
    reklamacie: 'Reklamácie',
    reklamacia_products: 'Reklamácia produktov',
    reklamacia_bonus: 'Reklamácia bonusu',
    reklamacie_products_bonus: 'Reklamácie objednávok produktov a bonusu',
    reklamacie_open: 'Neukončené reklamácie',
    reklamacie_close: 'Ukončené reklamácie',
    reklamacie_export: 'Export reklamácií',
    reklamacie_export_text: 'Všetky reklamácie za daný rok',
    reklamacie_year_list: 'Zoznam reklamácií vytvorených v roku',
    reklamacie_open_count: 'Počet nevybavených reklamácii',
    reklamacie_orders: 'Reklamácie objednávok',
    reklamacie_deny: 'Zamietnutie reklamácie',
    reklamacie_deny_reason: 'Dôvod zamietnutia',
    reklamacie_send_title: 'Odoslanie reklamácie',
    reklamacie_send_subtitle: 'Zadajte e-mailovú adresu, kde bude reklamácia odoslaná',
    reklamacie_message: 'Komentár k reklamácii pre zákazníka',
    reklamacie_message_label: 'Komentár k reklamácii',
    reklamacie_message_add: 'Pridať komentár',
    reklamacie_message_edit: 'Upraviť komentár',
    reklamacie_message_agent: 'Vyjadrenie k reklamácii',

    dodaci_list_cislo: 'Číslo dodacieho listu',

    link_iphone: 'Aplikácia pre zariadenia Apple',
    link_android: 'Aplikácia pre zariadenia Android',

    stats_products: 'Predaj produktov v salóne',
    stats_products_eshop: 'Predaj produktov cez e-shop',

    stats_sum_dph: 'Celkom s DPH',
    stats_today: 'Denná štatistika',
    stats_today_text: 'Predaj produktov a služieb v salóne',
    stats_sum: 'Suma celkom za produkty a služby',
    stats_cost: 'Celkom zisk za predaj produktov a služieb',
    stats_sum_vouchers: 'Suma za darčekové poukážky',
    stats_sum_products: 'Suma za produkty',
    stats_sum_services: 'Suma za služby',
    stats_cost_products: 'Zisk za predaj produktov',
    stats_cost_services: 'Zisk za predaj služieb',

    stats_info: 'Informácie',
    stats_info_text: 'Informácie o zákazníkoch kozmetického salónu',
    stats_info_texts: 'Informácie o zákazníkoch kozmetických salónov',
    stats_info_active: 'Počet aktívnych zákazníkov',
    stats_info_young: 'Najmladší zákazník',
    stats_info_old: 'Najstarší zákazník',
    stats_info_average: 'Priemerný vek zákazníkov',
    stats_info_age: 'Vek zákazníkov',
    stats_info_man: 'Počet mužov',
    stats_info_man_: 'mužov',
    stats_info_woman: 'Počet žien',
    stats_info_web: 'Počet zákazníkov registrovaných cez e-shop',
    stats_info_presents: 'Počet darovaných produktov zákazníkom',
    stats_info_presents_: 'Darčekové predmety',
    stats_info_discount: 'Poskytnutá zľava vo výške',

    graph_click: 'Kliknutím na stĺpce grafu sa zobrazia podrobnejšie informácie',
    rabat: 'Zisk',

    stats_products_text: 'Štatistika predaja produktov zakúpených v salóne',
    stats_products_salon: 'Produkty zakúpené v salóne',
    stats_products_eshop_: 'Štatistika predaja produktov zakúpených cez e-shop',
    stats_services: 'Štatistika predaja služieb',
    stats_product: 'Predaj produktu v salóne aj cez e-shop',
    stats_product_salons: 'Predaj produktu v salónoch aj cez e-shop',
    stats_product_salon: 'Zakúpený v salóne',
    stats_product_eshop: 'Zakúpený cez e-shop',
    stats_product_label: 'Štatistika predaja jednotlivých produktov',
    stats_services_label: 'Počet vykonávaných ošetrení jednotlivých kozmetických salónov',
    stats_salons: 'Súhrnná štatistika kozmetických salónov',

    stats_clients_label: 'Štatistika zákazníkov',
    stats_clients_text: 'Predaj produktov a služieb',
    stats_booking_text: 'Štatistika objednávok produktov na sklad',
    stats_booking_label: 'Objednávky na sklad',

    stats_employees: 'Štatistika predaja služieb',
    obrat: 'Obrat',

    info_newsletter: 'Novinky Institut Esthederm',
    info_cennik: 'Aktuálny cenník',
    info_action: 'Informácie k prebiehajúcej akcii',

    web_page: 'Webová stránka',
    web_page_eshop: 'Webová stránka a e-shop',
    web_page_preview: 'Náhľad webovej stránky a e-shopu',
    web_page_settings: 'Nastavenia webovej stránky a e-shopu',
    web_page_texts: 'Texty',
    web_page_hours: 'Otváracie hodiny',
    web_page_banners: 'Reklamné bannery',
    web_page_banners_text: 'Maximálny počet sú tri reklamné bannery',
    web_page_photo: 'Fotografia salóna',

    register_state: 'Krajina prevádzkovania kozmetického salónu',
    register_state_: 'Krajina prevádzkovania',
    register_typ: 'Typ registrácie',
    register_typ_note: 'Zmena typu účtu zákazníka',
    register_typ_full: 'Plná verzia',
    register_typ_light: 'Light verzia',
    register_settings: 'Nastavenia',
    register_sumar: 'Zhrnutie',
    register_finish: 'Dokončiť',
    register_alias: 'Prístup na webovú stránku a e-shop',
    register_alias_text: 'Aplikácia obsahuje vlastný e-shop a webovú stránku, ktoré budú prístupné na webovej adrese',
    register_alias_info: 'Doporučujeme zadať názov salónu',
    register_alias_input: 'Zadajte text, ktorý bude za lomítkom',
    alias_error: 'Zadanú adresu už používa iný užívateľ. Zvoľte inú!',
    alias: 'Doména',

    settings_page_enable: 'Publikovať moju webovú stránku a e-shop na stránkach esthedermsalon',
    settings_eshop_enable: 'Používať e-shop',
    settings_eshop: 'Nastavenie e-shopu',
    eshop_enabled: 'E-shop je zapnutý a zákazníci môžu nakupovať',
    eshop_enabled_: 'E-shop je zapnutý',
    eshop_disabled: 'E-shop je vypnutý a nachádza sa v stave údržby',
    trade: 'Doprava',
    trade_enabled: 'Využívať dopravu kuriérom',
    trade_text: 'Dopravu kuriérom môžete využiť pri nákupe tovaru o celkovej hmotnosti do 30 kg',
    trade_name: 'Názov kuriérskej spoločnosti',
    trade_eur: 'Cena poštovného',
    trade_note: 'Poznámka k doprave',
    trade_free: 'Využívať dopravu zdarma pri nákupe od určitej sumy',
    trade_free_eur: 'Suma nákupu, od ktorej je poštovné zdarma',
    payment: 'Platba',
    payment_bank: 'Využívať možnosť platby cez účet',
    public_services: 'Publikovať zoznam služieb na webe a v klientskej aplikácii',
    public_services_show: 'Zobrazovať zoznam služieb',
    public_prices_show: 'Zobrazovať ceny služieb',

    settings_desktop: 'Nastavenie plochy',
    settings_dektop_text: 'Nastavenie zobrazovania jednotlivých prvkov na ploche aplikácie',
    desktop: 'Pracovná plocha',
    shortcuts: 'Skratky',
    eshop_orders: 'Prijaté objednávky z eshopu',
    desktop_booking_products: 'Objednávka produktov',
    desktop_booking_bonus: 'Objednávka bonusu',

    sc_products_info: 'Informácie o produktoch',
    sc_selling: 'Predaj produktov',
    sc_products_booking: 'Objednávka produktov na sklad',
    sc_products_settings: 'Úprava produktov',
    sc_bonus_booking: 'Objednávka bonusu',
    sc_services_info: 'Informácie o službách',
    sc_stats_day: 'Denná štatistika',
    sc_info_newsletter: 'Nesletter Institut Esthederm',
    sc_info_cennik: 'Cenník',
    sc_info_action: 'Prebiehajúca akcia',
    sc_page_settings: 'Nastavenie webovej stránky',
    sc_page_web: 'Webová stránka',
    sc_client_last: 'Posledná otvorená karta zákazníka',

    sc_salon_last: 'Posledný otvorený salón',
    sc_salon_stock: 'Skladové stavy',
    sc_salon_booking_products: 'Objednávky produktov',
    sc_salon_booking_bonus: 'Objednávky bonusu',

    settings_app: 'Nastavenie aplikácie',
    settings_pin_label: 'Ochrana údajov PIN kódom',
    settings_pin: 'Ochrana PIN kódom',
    settings_pin_code: 'Pin kód',
    settings_pin_code_6: '6 miestny',
    settings_pin_enabled: 'Ochrana PIN kódom je aktivovaná',
    settings_pin_disabled: 'Ochrana PIN kódom je vypnutá',
    settings_pin_enter: 'Zadať PIN kód',
    settings_pin_change: 'Zmeniť PIN kód',
    settings_pin_enter_label: 'Zadajte PIN kód',
    settings_pin_enter_old_label: 'Zadajte pôvodný PIN kód',
    settings_pin_enter_new_label: 'Zadajte nový PIN kód',
    settings_pin_reenter_label: 'Zopakujte PIN kód',
    settings_pin_changed: 'PIN kód bol úspešne zmenený',
    settings_pin_not_equal: 'Zadané PIN kódy sa nezhodujú',
    settings_pin_error: 'Nesprávny PIN kód',

    settings_forum_label: 'Zobrazovať príspevky diskusie nie staršie ako',
    settings_forum_1: 'Jeden mesiac',
    settings_forum_3: 'Tri mesiace',
    settings_forum_6: 'Šesť mesiacov',

    token_error: 'Neautorizovaný vstup do databáze',
    token_error_text: 'Odhláste sa z aplikácie a opäť prihláste.',

    settings_web_texts: 'Texty na webovej stránke',
    settings_web_label: 'Nadpis na stránke',
    settings_web_text: 'Text na stránke',
    settings_web_info: 'Oznam na stránke',
    settings_web_map_label: 'Mapa s označením kozmetického salóna',
    settings_web_map_check: 'Zobrazovať mapu na stránke',
    settings_web_photo: 'Fotografia salóna zobrazená na webovej stránke',

    reserve_label: 'Rezervácie',
    reserve_desktop_label: 'Nepotvrdené rezervácie termínov požadované zákazníkmi',
    reserve_waiting: 'Čaká na potvrdenie',
    reserve_waiting_: 'Potvrdiť',
    reserve_canceled: 'Zrušené zákazníkom',
    reserve_canceled_: 'Rezervácia zrušená zákazníkom',
    reserve_canceled_small: 'Zrušena',
    reserve_client_waiting: 'Rezervácia zákazníkom - čaká na potvrdenie!',
    reserve_accept: 'Potvrdiť termín',
    reserve_accepted: 'E-mail s potvrdením termínu bol úspešne odoslaný',
    reserve_accepted_label: 'Potvrdenie termínu rezervácie',
    reserve_accepted_text: 'Vaša rezervácia v kozmetickom salóne bola potvrdená!',

    show_file: 'Zobraziť súbor',
    help: 'Pomocník',
    helper: 'Návod k aplikácii',
    help_contact_us: 'Kontaktujte nás',

    contact_us_label: 'Potrebujete poradiť s aplikáciou',
    contact_us_text1: 'Napíšte nám a my Vám ochotne poradíme.',
    contact_us_text2: 'Hotline (mobil): +421 944 411 144',
    contact_us_note: 'Text správy (Do textu nemusíte zadávať meno ani kontaktné údaje. Systém údaje doplní pri odosielaní)',

    sell_products_quantity: 'Počet predaných produktov',
    sell_services_quantity: 'Počet predaných služieb',

    info_clients_age: 'Vek zákazníkov',
    info_clients_count: 'Počet zákazníkov',
    info_clients_young: 'Najmladší zákazník',
    info_clients_old: 'Najstarší zákazník',
    info_clients_average: 'Priemerný vek zákazníkov',
    info_clients_mans: 'Počet mužov',
    info_clients_womans: 'Počet žien',
    info_salon_orders: 'Celkový predaj do salóna',
    info_salons_orders: 'Celkový predaj do salónov',
    info_salon_orders_stack: 'Objednávky produktov na sklad (nákupná cena)',
    info_salon_products: 'Počet predaných produktov v salóne',
    info_salon_top_product: 'Top produkt',
    info_eshop_products: 'Počet predaných produktov cez e-shop',
    info_salon_services: 'Počet ošetrení',
    info_salon_top_service: 'Top ošetrenie',

    salon_stat_products_salon: 'Produkty (salón)',
    salon_stat_products_salon_label: 'Štatistika produktov zakúpených v salóne',
    salon_stat_products_eshop: 'Produkty (e-shop)',
    salon_stat_products_eshop_label: 'Štatistika produktov zakúpených cez e-shop',
    salon_stat_services: 'Služby',
    salon_stat_services_label: 'Štatistika služieb - ošetrení',
    salon_stat_sum_cost: 'Suma celkom (* nákupné ceny)',
    salon_stat_products_quantity: 'Počet predaných produktov za dané obdobie',
    salon_stat_services_quantity: 'Počet predaných služieb za dané obdobie',

    salon_stats_cards_quantity: 'Počet zákazníckych kariet',
    salon_stats_products_salon_quantity: 'Počet predaných produktov v salóne',
    salon_stats_products_eshop_quantity: 'Počet predaných produktov cez e-shop',
    salon_stats_services_quantity: 'Počet predaných ošetrení',
    salon_stats_orders_value: 'Hodnota objednávok produktov',
    salon_stats_bonus_value: 'Hodnota objednávok bonusu (bez DPH)',
    salon_stats_credit_value: 'Zostávajúci počet kreditov (bez DPH)',
    salon_stats_salons_quantity: 'Počet salónov',

    export: 'Exportovať',
    reps: 'Reprezentanti',

    geo: 'Geolokácia salóna',
    geo_map: 'Mapa s označením polohy kozmetického salónu',
    geo_google_maps: 'Poloha kozmetického salónu v google maps',
    geo_lat: 'Zemepisná šírka (lat)',
    geo_lng: 'Zemepisná dĺžka (lng)',
    geo_get: 'Získať aktuálnu polohu',
    geo_web_show: 'Zobrazovať na webovej stránke',
    geo_show_map: 'Zobraziť mapu',
    geo_error: 'Pri načítaní aktuálnej polohy nastala chyba. Skúste požiadavku opakovať!',

    lessons: 'Školenia',
    lesson: 'Školenie',
    lesson_details: 'Informácie o školení',
    lesson_choose: 'Výber školenia',
    lesson_choose_text: 'Zkopírovanie údajov zo školenia',
    lessons_list_year: 'Zoznam školení za rok',
    lessons_planned: 'Plánované školenia',
    lessons_finished: 'Absolvované školenia',
    lessons_canceled: 'Zrušené školenia',
    lesson_canceled: 'Zrušené školenie',
    lessons_draft: 'Rozpracované',
    lessons_list: 'Zoznam školení',
    lessons_year: 'Zoznam školení za rok',
    lesson_new: 'Nové školenie',
    lesson_new_title: 'Tvorba nového školenia',
    lesson_update: 'Úprava školenia',
    lesson_publish: 'Publikovať',
    lesson_date: 'Dátum školenia',
    lesson_time: 'Čas',
    lesson_duration: 'Dĺžka trvania',
    lesson_title: 'Názov školenia',
    lesson_description: 'Popis školenia',
    lesson_address: 'Miesto konania školenia',
    lesson_online: 'Školenie formou webináru',
    lesson_online_: 'Webinár',
    lesson_teacher: 'Školiteľ',
    lesson_teacher_search: 'Výber školiteľa',
    lesson_note: 'Interná poznámka (nebude zverejnená)',
    lesson_data: 'Fotografia a popis školenia',
    lesson_users: 'Zoznam prihlásených',
    lesson_users_list: 'Zoznam prihlásených',
    lesson_registered: 'Počet prihlásených',
    lesson_registered_free: 'Počet náhradníkov',
    lesson_register: 'Prihlásiť sa na školenie',
    lesson_register_reserve: 'Prihlásiť sa ako náhradník',
    lesson_register_cancel: 'Zrušiť prihlásenie',
    lesson_register_cancel_ask: 'Chcete zrušiť prihlášku na školenie?',
    lesson_categories_label: 'Pridávanie a úprava kategórii školení',
    lesson_categories: 'Kategórie',
    lesson_category: 'Kategória',
    lesson_category_select: 'Tematická kategória',
    lesson_category_new: 'Nová kategória',
    lesson_capacity: 'Kapacita',
    lesson_capacity_reserve: 'Náhradníci',
    lesson_for_: 'Školenie určené pre',
    lesson_for: ['', 'Kozmetičky', 'Lekárnici'],
    lesson_url: 'Link na aplikáciu',
    lesson_youtube_link: 'Link na youtube video',
    lesson_video: 'Pozrieť video',
    lesson_url_: 'Link na školenie',
    lesson_url_note: 'Po otvorení linku stlačte "Otvoriť v Safari"',
    lesson_register_to: 'Registrácia do',
    lesson_points: 'Počet bodov',
    lesson_credits: 'Počet kreditov',
    lesson_cancel: 'Zrušiť školenie',
    lesson_cancel_label: 'Zrušenie školenia',
    lesson_cancel_ask: 'Chcete školenie zrušiť?',
    lesson_cancel_text: 'Dané školenie bude označené ako zrušené.',
    lesson_copy: 'Kopírovať link',
    lesson_registered_label: 'Prihlásenie na školenie',
    lesson_registered_subtext: 'Termín školenia bude vložený do kalendára',
    lesson_unregistered_label: 'Zrušenie prihlásenia na školenie',
    lesson_registered_ask: 'Chcete sa záväzne prihlásiť na školenie?',
    lesson_registered_text: 'Boli ste úspešne prihlásený na školenie.',
    lesson_registered_full: 'Dané školenie je už obsadené pre zadaný počet osôb.',
    lesson_registered_user: 'Na školenie ste prihlásený',
    lesson_registered_user_reserve: 'Na školenie ste prihlásený ako náhradník',
    lesson_registered_user_: 'Prihlásený',
    lesson_user_free: 'Náhradník',
    lesson_finished: 'Ukončené školenie',
    lesson_not_absolved: 'Neabsolvoval',
    lesson_absolved: 'Absolvoval',
    lesson_absolved_count: 'Absolvoval počet',
    lesson_delete_label: 'Vymazať zákazníka',
    lesson_delete_ask: 'Chcete vymazať zákazníka zo školenia?',
    lesson_delete_text: 'Zákazník bude označený statusom - neabsolvoval',
    lesson_add_user: 'Doplniť zákazníka na školenie',
    lesson_typ: ['Publikované', 'Rozpracované', 'Zrušené'],
    lessons_stats: 'Štatistika školení',
    lessons_stats_text: 'Štatistika školení podľa jednotlivých ošetrení',
    lessons_stats_absolved_month: 'Počet absolventov školení v jednotlivých mesiacoch',
    lesson_meeting_id: 'Meeting ID',
    lesson_passcode: 'Passcode',
    lesson_count: 'Zadajte počet osôb, ktoré prihlasujete na školenie',
    lesson_count_change: 'Zadajte celkový počet osôb, ktoré sa zúčastnia školenia',
    lesson_count_persons: 'Počet osôb',
    lesson_count_persons_: 'v počte osôb',
    lesson_persons_edit: 'Upraviť počet osôb',
    lesson_services_count: 'Počet služieb',
    lesson_products_count: 'Počet produktov',
    lesson_link_copy_label: 'Skopírovanie linku',
    lesson_link_copy_text_1: 'Link na školenie bol skopírovaný.',
    lesson_link_copy_text_2: 'Vložte skopírovaný link do internetového prehliadača.',
    lesson_required: 'Povinné školenie',
    lesson_required_: 'Povinné',
    touch_to_copy: 'Kliknutím skopírovať',

    // ==========================================================================================================================
    lang_emails: {
        do_not_reply: 'NEODPOVEDAŤ',
        do_not_reply_text: 'Prosím, neodpovedajte na e-mail! Toto je automaticky generovaná správa systémom Esthederm Salon.',
        do_not_reply_text_: 'Prosím, neodpovedajte na tento email, na odpoveď použite emailovu adresu:',

        welcome: 'Dobrý deň!',
        greating: 'Ďakujeme a prajeme príjemný deň.</br>Tím Esthederm Salon',
        gdpr_list: 'Vaše osobné údaje spracovávame, a to v nasledovnom rozsahu:',
        gdpr_info: 'Viac informácii o spracovaní Vašich osobných údajov nájdete na',
        gdpr_cancel: 'Súhlas so spracovaním osobných údajov môžete kedykoľvek odvolať, a to na emailovej adrese:',

        // VYTVORENIE NOVEJ KARTY ZAKAZNIKA
        card_created: 'Súhlas so spracovaním osobných údajov',
        card_created_welcome: '<h2>Vitajte v Esthederm Salon!</h2>',
        card_created_text: 'Vaša registrácia bola úspešne dokončená.</br>Do svojho účtu sa môžete prihlásiť sa adrese: <b>https://esthedermsalon.sk</b></br></br><b>Prihlasovacie údaje:</b></br>Prihlasovacie meno: @userbname</br>Heslo: @password',

        // OZNAMENIE O SPRACOVANI OSOBNYCH UDAJOV (po registrácii karty zákazníka)
        card_data: 'Súhlas so spracovaním osobných údajov',
        card_data_text: 'Dobrý deň,</br>ďakujeme, že využívate naše služby.</br></br>Týmto Vám súčasne potvrdzujeme prijatie Vášho súhlasu so spracovaním osobných údajov, ktorý ste udelili pri Vašej návšteve a to v priloženom znení.',

        // ZMENA OSOBNYCH UDAJOV
        card_changed: 'Zmena súhlasu so spracovaním osobných údajov',

        // ODOSLANIE UDAJOV, KTORE SU V SYSTEME ZAKAZNIKOVI
        card_send: 'Uplatnenie práva prístupu k osobným údajom',
        card_send_text: 'Dobrý deň, na základe Vašej požiadavky Vám v prílohe tohto e-mailu zasielame súbor PDF s informáciami, ktoré máme o Vás uložené v systéme Esthederm Salon.</br>Heslo na odomknutie súboru bude odoslané na Vašu e-mailovú adresu.',

        // VYMAZANIE OSOBNÝCH UDAJOV - CELEJ KARTY
        card_deleted: 'Vymazanie osobných údajov',
        card_delete_text: 'Dobrý deň,</br>na základe Vašej žiadosti o vymazanie Vaších osobných údajov, Vám týmto potvrdzujeme</br>že Vaše osobné údaje boli vymazané v nasledujúcom rozsahu:<br>',
    },

    faktury: 'Faktúry',
    faktury_list: 'Zoznam faktúr',
    faktury_list_year: 'Zoznam faktúr vytvorených v roku',
    faktura: 'Faktúra',
    faktura_new: 'Nová faktúra',
    faktura_create: 'Vytvoriť faktúru',
    faktura_cislo: 'Číslo faktúry',
    faktura_odberatel: 'Odberateľ',
    faktura_items: 'Položky faktúry',
    faktura_item_name: 'Názov položky',
    faktura_item_price: 'Jedn.cena',
    faktura_item_sum: 'Celkom',
    faktura_item_add: 'Pridať položku',
    faktura_item_new: 'Nová položka',
    faktura_item_edit: 'Úprava položky',
    faktura_item_deleted: 'Vymazaná položka',
    faktura_date: 'Dátum vystavenia faktúry',
    faktura_address: 'Fakturačná adresa',
    faktura_var_symbol: 'Variabilný symbol',
    faktura_created: 'Faktúra je vytvorená',
    faktura_create_ask: 'Chcete vytvoriť faktúru k danej objednávke?',
    faktura_create_note: 'Faktúra bude uložená do zoznamu faktúr',
    faktura_sum_to_pay: 'Suma na úhradu',
    faktura_numbering: 'p.č.',
    faktura_date_to_pay: 'Dátum splatnosti',
    faktura_date_: 'Dátum vystavenia',
    faktura_delete: 'Vymazať faktúru',
    faktura_delete_ask: 'Chcete vymazať danú faktúru?',
    faktura_data: 'Fakturačné údaje',
    faktura_send: 'Zadajte e-mailovú adresu, na ktorú bude faktúra odoslaná',
    faktura_error: 'Pri vytváraní faktúry nastala chyba. Skúste požiadavku opakovať!',
    faktura_created_success: 'Faktúra bola úspešne vytvorená',
    faktura_created_success_note: 'Vytvorená faktúra sa nachádza v zozname faktúr',

    email_of_client: 'e-mail zákazníka',
    email_mine: 'môj e-mail',
    email_reklamacie: 'Reklamačné odd.',

    chat_agents: 'Komunikácia',
    chat_agents_salon: 'medzi kozmetickým salónom a obchodným zástupcom',
    chat_agents_new: 'Správa pre obchodného zástupcu',
    chat_agents_new_message: 'Nová správa',
    chat_agents_text: 'Text správy',
    chat_agents_salons: 'Správa pre všetky salóny',
    chat_agents_salons_reps: 'zvoleného reprezentanta',

    select_period: 'Výber obdobia',

    lesson_change_status: 'Zmeniť status',
    lesson_change_status_yes: 'Zmeniť status na "Absolvoval"',
    lesson_change_status_no: 'Zmeniť status na "Neabsolvoval"',

    lessons_send_notify: 'Odoslať notifikácie',
    lessons_send_notify_ask: 'Chcete odoslať notifikáciu o nových školenia?',
    lessons_send_notify_ask_note: 'Notifikácia bude odoslaná kozmetickým salónom s aplikáciou EsthedermSalon.',
    lessons_notify_sended: 'Notifikácie boli úspešne odoslané',
    lessons_notify: 'Notifikácie o školeniach',

    help_video: 'Video návod',
    help_video_sk: 'Video návod v slovenskom jazyku',

    video: 'Video',
    video_select: 'Výber video záznamu',
    video_lesson: 'Video zo školenia',
    video_lessons: 'Video zo školení',
    video_webinar: 'Video z webinárov',

    action_text: 'na základe objednávky máte nárok na',
    action_text_: 'na základe objednávky má salón nárok na',
    action_product_2: 'Letná plážová taška',
    action_product_1: 'Sun Sheen Tinted Powder',
    action_product_3: 'Ľahko na cesty - balíček',
    action_product_add: 'Pre objednanie je potrebné produkty pridať do objednávky v daných množstvách!',

    bonus_missing: 'Chýbajúci kredit',
    bonus_left: 'Zostávajúci kredit',
    bonus_order_missing: 'Nedostatočné množstvo kreditu',
    bonus_order_missing_note: 'Kontaktujte svojho obchodného zástupcu, alebo upravte objednávku.',
    bonus_order_max: 'Max.počet objednaných kusov',
    bonus_order_limited: 'Limitovaná objednávka',
    bonus_order_quantity_history: 'Predchádzajúce objednávky',

    prices_without_dph: 'Uvádzané sumy sú bez DPH',
    sum_selected_season: 'Suma za dané obdobie',

    expirations: 'Expirácie produktov',
    expirations_: 'Expirácie',
    expiration: 'Expirácia',
    expiration_month: 'Mesiac expirácie',
    expiration_year: 'Rok expirácie',
    expirations_in_year: 'Zoznam expirácii vytvorených v roku',
    expiration_new: 'Nová expirácia',
    expiration_date: 'Dátum expirácie',
    expiration_date_error: 'Prekročená minimálna doba pred expiráciou (3 mesiace)',
    expiration_date_error_: 'Prekročená min. doba pred expiráciou (3 mes.)',
    expiration_photo_date: 'Fotografia dátumu expirácie',
    expiration_photo_date_: 'Vyberte fotografiu, ktorá zachytáva dátum expirácie',
    expiration_photo_date_error: 'Chýba fotografia dátumu expirácie na produkte',
    expiration_photo_product: 'Fotografia produktu',
    expiration_photo_product_: 'Vyberte fotografiu produktu',
    expiration_photo_product_error: 'Chýba fotografia produktu',
    expiration_status: 'Stav schvaľovania',
    expiration_statuses: ['Čaká na schválenie', 'Schválená', 'Zamietnutá'],
    expiration_send: 'Odoslať na schválenie',
    expiration_send_ask: 'Požiadavka bude odoslaná obchodnému zástupcovi',
    expiration_product_change: 'Zmeniť produkt',
    expiration_product_select: 'Výber produktu expirácie',
    expiration_deny: 'Zamietnutie expirácie',
    expiration_accept: 'Schválenie požiadavky',
    expiration_accept_text: 'Chcete zmeniť stav požiadavky na schválenú?',
    expiration_deny_reason: 'Dôvod zamietnutia',
    expiration_not_finished: 'Neukončené expirácie',
    expiration_finished: 'Ukončené expirácie',
    expiration_quantity: 'Počet kusov produktu',
    expiration_photos_missing: 'Chýbajúce fotografie dátumu a výrobku',
    expiration_photos_missing1: 'Chýbajúca fotografia dátumu',
    expiration_photos_missing2: 'Chýbajúca fotografia produktu',

}


/*
*
* =================================================================================================================
* CZ - LANGUAGE
* =================================================================================================================
*
*/
export const cz = {
    title: 'Esthederm Salon',
    language: 'CZ',
    country: 'Česká republika',
    country_: 'Země',
    slogan: 'Správa kosmetických salonů',
    vision_system: 'Esthederm Salon',
    money: 'Kč',
    money_payment: 'Platební měna',
    www_eshop: 'https://esthedermsalon.cz',
    help_link: 'https://support.datadream.sk/guide/esthederm-salon-v2/cz',
    help_link_rep: 'https://support.datadream.sk/guide/esthederm-salon-v2-rep/cz',
    web_link: 'esthedermsalon.com',
    web_link_register: 'esthedermsalon.com/register',
    state: 'Stát',
    register_form: 'Průvodce registrací',
    version: 'Verze aplikace',
    salon: 'Kosmetický salon',
    salon_card_export: 'Karta kosmetického salonu',
    salons_export: 'Seznam kosmetických salonů',
    salons: 'Kosmetické salony',
    salons_rep: 'Salony reprezentantů',
    salons_rep_: 'Salony reprezentanta',
    salons_list: 'Seznam kosmetických salonů',
    salon_hidden: 'Neaktivní',
    account_test: 'Test',
    login_user: 'Přihlášený uživatel',
    created: 'Vytvořeno',
    ipad_app: 'Aplikace pro Apple iOS',
    ipad_app_salon: 'Aplikace pro kosmetické salony',
    ipad_app_text: 'Podporovaná zařízení: iPhone a iPad',
    android_app_text: 'Podporovaná zařízení: mobilní telefony',
    application: 'Aplikácia',
    web_application: 'Webová aplikace',
    mobile_apps: 'Aplikace pro mobilní zařízení',
    not_selected: 'Neurčeno',

    app_error: 'Při vstupu do aplikace nastala chyba. Zkontrolujte internetové připojení a restartujte aplikaci!',
    internet_error: 'Zdá se, že nejste připojeni k internetu.',
    internet_error_text: 'Zkontrolujte internetové připojení a restartujte aplikaci!',

    booking_status_agent: ['Čeká na přijetí', 'V přípravě', 'Odesláno', 'Žádost o zrušení', '', 'Ukončena', 'Zrušena', 'Překlápění do CRM', 'Odeslána do CRM', 'Chyba při odesílání do CRM'],
    booking_status_agent_text: ['Čeká na přijetí', 'V přípravě', 'Manuálne vkladanie objednávky', 'Žádost o zrušení', '', 'Ukončena', 'Objednávka bude zrušena', 'Objednávka bude odeslána do CRM automaticky systémem', 'Odeslána do CRM', 'Chyba při odesílání do CRM'],
    booking_status_salon: ['Čeká na přijetí', 'V přípravě', 'Odesláno', 'Žádost o zrušení', '', 'Ukončena', 'Zrušena', 'V přípravě', 'V přípravě', 'V přípravě'],
    eshop_status: ['Čeká na přijetí', 'V přípravě', 'Připraveno k výdeji', 'Odesláno', 'Ukončeno', 'Zrušeno'],
    reklamacia_status: ['Vytvořeno', 'Posuzování', 'Schváleno', 'Zamítnuto', 'Ukončeno'],

    months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec', 'Dnes'],
    months_short: ['Led.', 'Úno.', 'Bře.', 'Dub.', 'Kvě.', 'Čer.', 'Čer.', 'Srp.', 'Zař.', 'Říj.', 'Lis.', 'Pro.', 'Dnes'],
    all_months: 'Celý rok',
    days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
    days_short: ['Ned', 'Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob'],
    days_short_label: ['Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob', 'Ned'],
    sviatky: ['01-01', '01-06', '05-01', '05-08', '07-05', '08-29', '09-01', '09-15', '11-01', '11-17', '12-24', '12-25', '12-26'],
    month: 'Měsíc',
    week: 'Týden',
    day: 'den',
    new: 'Nová událost',
    event: 'Udalost',
    medical: 'Lékárna',
    medical_: 'Lékárny',
    typ_other: 'Jiný typ',
    medical_salon: 'Salon/Lékárna',

    save: 'Uložit',
    save_continue: 'Uložit a pokračovat',
    save_changes: 'Uložit změny',
    close: 'Zavřít',
    time_from: 'Čas od',
    time_error: 'Nesprávný formát zadaného času. Použijte formát HH:MM.',
    time_error_: 'Nesprávná délka trvání. Čas od musí být menší než čas do.',
    from: 'od',
    to: 'do',
    hours_order: 'Na objednávku',
    dinner_from: 'Oběd od',
    dinner_to: 'Oběd do',

    list: 'list',
    waiting: 'Čeká na potvrzení!',
    waiting_text: 'Stisknutím "Uložit", termín potvrdíte',
    sviatok: 'svátek',
    none: 'Žádné volné termíny',
    calendar_free: 'Volné termíny',
    time: 'Čas',
    start: 'Začátek',
    end: 'Konec',
    client_select: 'Vybrat zákazníka',

    minutes: 'minut',
    minutes_: 'min',
    hour: 'hodina',
    hour_short: 'hod.',
    hours: 'hodiny',
    hours_: 'hodin',
    delete: 'Vymazat',
    menu_title: 'Menu',
    cabine: 'Kabina',
    products_mine: 'Vlastní produkty',
    action: 'Akce',
    action_offer: 'Akční nabídka',
    cancel: 'Zrušit',
    send: 'Odeslat',
    not_send: 'Neodeslat',
    send_err: 'Při odesílání nastala chyba. Zkuste požadavek opakovat.',
    back: 'Zpět',
    back_app: 'Zpět do aplikace',
    without_dph: 'bez dph',
    with_dph: 's dph',
    agree: 'Souhlasím',
    disagree: 'Nesouhlasím',
    error: 'Chyba',
    remind: 'Připomenout',
    show: 'Zobrazit',
    read: 'Přečíst',
    permission: 'Povolení',
    permissions: 'Povolení',
    permission_added: 'Uděleno povolení',
    permission_removed: 'Odebrané povolení',

    // **********************************************************************
    // STRUKTURA MENU - CZ
    // **********************************************************************
    menu: [
        {
            id: 0,
            account: 0,
            enabled: true,
            name: 'Domů',
            label: 'Domů',
            icon: faHome,
            color: global.theme_light_blue,
            icon_color: global.theme_white,
        },
        {
            id: 10,
            account: global.account_salon,
            enabled: true,
            name: 'Obchod',
            label: 'Obchod',
            icon: faShoppingBag,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 100,
                    divider: true,
                    enabled: true,
                    name: 'Prodejna'
                },
                {
                    id: 101,
                    enabled: true,
                    name: 'Prodej produktů',
                    label: 'Prodej produktů - prodejna',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 102,
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie prodeje produktů',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 103,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 104,
                    enabled: true,
                    name: 'Přijaté objednávky',
                    label: 'Přijaté objednávky z e-shopu',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 105,
                    divider: true,
                    enabled: true,
                    name: 'Sklad'
                },
                {
                    id: 106,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka produktů na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 107,
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie objednávek produktů na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 108,
                    divider: true,
                    enabled: true,
                    name: 'Bonusový program'
                },
                {
                    id: 109,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 110,
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie objednávek - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 111,
                    divider: true,
                    enabled: true,
                    name: 'Reklamace',
                },
                {
                    id: 112,
                    enabled: true,
                    name: 'Historie reklamaci',
                    label: 'Historie reklamaci',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 115,
                    enabled: true,
                    name: 'Expirace',
                    label: 'Expirace produktů',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 113,
                    divider: true,
                    enabled: true,
                    name: 'Fakturace',
                },
                {
                    id: 114,
                    enabled: true,
                    name: 'Faktury',
                    label: 'Faktury',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 20,
            account: global.account_salon,
            enabled: true,
            name: 'Statistiky',
            label: 'Statistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 200,
                    divider: true,
                    enabled: true,
                    name: 'Denní statistika'
                },
                {
                    id: 201,
                    enabled: true,
                    name: 'Dnes',
                    label: 'Denní statistika',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 202,
                    divider: true,
                    enabled: true,
                    name: 'Kosmetický salon'
                },
                {
                    id: 203,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 204,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 205,
                    enabled: true,
                    name: 'Služby',
                    label: 'Služby',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 206,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 207,
                    enabled: true,
                    name: 'Produky',
                    label: 'Produky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 208,
                    divider: true,
                    enabled: true,
                    name: 'Statistiky'
                },
                {
                    id: 209,
                    enabled: true,
                    name: 'Jednotlivé produkty',
                    label: 'Jednotlivé produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 210,
                    enabled: true,
                    small: false,
                    name: 'Zákazníci',
                    label: 'Zákazníci',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 211,
                    enabled: true,
                    employee: true,
                    small: false,
                    name: 'Kosmetičky',
                    label: 'Kosmetičky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 212,
                    enabled: true,
                    name: 'Objednávky produktů',
                    label: 'Objednávky produktů na sklad',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 30,
            account: global.account_salon,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o produktech',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 301,
                    enabled: true,
                    name: 'Úprava produktů',
                    label: 'Úprava produktů',
                    icon: faPenAlt,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 302,
                    enabled: true,
                    name: 'Kategorie',
                    label: 'Kategorie',
                    icon: faLayerGroup,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 40,
            account: global.account_salon,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
                {
                    id: 401,
                    enabled: true,
                    name: 'Úprava služeb',
                    label: 'Úprava služeb',
                    icon: faPenAlt,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
                {
                    id: 402,
                    enabled: true,
                    name: 'Skupiny služeb',
                    label: 'Skupiny služeb',
                    icon: faLayerGroup,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                },
            ]
        },
        {
            id: 60,
            account: global.account_salon,
            enabled: true,
            name: 'Informace',
            label: 'Informace',
            icon: faInfo,
            color: global.theme_light_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Ceník',
                    label: 'Aktuální ceník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Probíhající akce',
                    label: 'Informace k probíhající akci',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitní vzdělávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikace',
                    label: 'Aplikace Esthedermsalon pro tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },
        {
            id: 50,
            account: global.account_salon,
            enabled: true,
            name: 'Webová stránka',
            label: 'Moje webová stránka',
            icon: faGlobe,
            color: global.theme_light_blue,
            icon_color: global.theme_black,
            children: [
                {
                    id: 500,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 501,
                    enabled: true,
                    name: 'Náhled stránky',
                    label: 'Náhled webové stránky a e-shopu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 510,
                    enabled: true,
                    name: 'Nastavení stránky',
                    label: 'Nastavení webové stránky a eshopu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 80,
            account: global.account_salon,
            enabled: true,
            name: 'Školení',
            label: 'Školení',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 800,
                    enabled: true,
                    name: 'Plánovaná školení',
                    label: 'Seznam plánovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 801,
                    enabled: true,
                    name: 'Absolvovaná školení',
                    label: 'Seznam absolvovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 802,
                    enabled: true,
                    masterclass: true,
                    name: 'Úprava školení',
                    label: 'Úprava školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 803,
                    enabled: true,
                    //masterclass: true,
                    name: 'Video z webinářů',
                    label: 'Video z webinářů',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 99,
            account: global.account_salon,
            enabled: false,
            name: 'Testovanie',
            label: 'Testovacie scripty',
            icon: faQuestion,
            color: global.theme_light_blue,
            icon_color: global.theme_black,
        },
        // ************************************************************************************************
        // OBCHODNY ZASTUPCA
        // ************************************************************************************************        
        {
            id: 510,
            account: global.account_agent,
            enabled: true,
            name: 'Objednávky',
            label: 'Objednávky produktů',
            icon: faShoppingCart,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 5101,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Objednávky produktů na sklad',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5102,
                    enabled: true,
                    name: 'Bonus',
                    label: 'Objednávky bonusu',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5103,
                    enabled: true,
                    name: 'Reklamace',
                    label: 'Reklamace objednávek',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 5104,
                    enabled: true,
                    name: 'Expirace',
                    label: 'Expirace produktů',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 520,
            account: global.account_agent,
            enabled: true,
            name: 'Statistiky',
            label: 'Statistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 5202,
                    divider: true,
                    enabled: true,
                    name: 'Kosmetické salony'
                },
                {
                    id: 5203,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5204,
                    enabled: true,
                    name: 'Produkty',
                    label: 'Produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5205,
                    enabled: true,
                    name: 'Služby',
                    label: 'Služby',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5206,
                    divider: true,
                    enabled: true,
                    name: 'E-shop'
                },
                {
                    id: 5207,
                    enabled: true,
                    name: 'Produky',
                    label: 'Produky',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5208,
                    divider: true,
                    enabled: true,
                    name: 'Statistiky'
                },
                {
                    id: 5209,
                    enabled: true,
                    name: 'Jednotlivé produkty',
                    label: 'Jednotlivé produkty',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5211,
                    enabled: true,
                    small: false,
                    name: 'Kosmetické salony',
                    label: 'Kosmetické salony',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5212,
                    enabled: true,
                    small: false,
                    name: 'Ošetření',
                    label: 'Prováděná ošetření',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5213,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Reprezentanti',
                    label: 'Reprezentanti',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5290,
                    divider: true,
                    enabled: true,
                    name: 'Školení'
                },
                {
                    id: 5214,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Podle služeb',
                    label: 'Absolvovaná školení k jednotlivým ošetřením',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
                {
                    id: 5215,
                    admin: true,
                    enabled: true,
                    small: false,
                    name: 'Podle produktů',
                    label: 'Absolvovaná školení k jednotlivým produktům',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 570,
            account: global.account_agent,
            enabled: true,
            name: 'Aktuality',
            label: 'Aktuality',
            icon: null,
            color: global.theme_medium_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 5701,
                    enabled: true,
                    name: 'Příspěvky',
                    label: 'Seznam příspěvků',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 700,
            account: global.account_agent,
            enabled: true,
            name: 'Školení',
            label: 'Školení',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 701,
                    enabled: true,
                    name: 'Seznam školení',
                    label: 'Seznam školení',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 702,
                    enabled: true,
                    name: 'Kategorie školení',
                    label: 'Kategorie školení',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
            ]
        },
        {
            id: 530,
            account: global.account_agent,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o produktech',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                },
                {
                    id: 304,
                    enabled: true,
                    name: 'Bonus',
                    label: 'Informace o bonuse',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                }
            ]
        },
        {
            id: 540,
            account: global.account_agent,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                }
            ]
        },
        {
            id: 560,
            account: global.account_agent,
            enabled: true,
            name: 'Informace',
            label: 'Informace',
            icon: faInfo,
            color: global.theme_light_cyan,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Ceník',
                    label: 'Aktuální ceník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Probíhající akce',
                    label: 'Informace k probíhající akci',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitní vzdělávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikace',
                    label: 'Aplikace Esthedermsalon pro tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },

        // ************************************************************************************************
        // MEDICAL - ACCOUNT
        // ************************************************************************************************

        {
            id: 1000,
            account: global.account_medical,
            enabled: true,
            name: 'Obchod',
            label: 'Obchod',
            icon: faShoppingBag,
            color: global.theme_orange,
            icon_color: global.theme_black,
            children: [
                {
                    id: 105,
                    divider: true,
                    enabled: true,
                    name: 'Sklad'
                },
                {
                    id: 106,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka produktů na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 107,
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie objednávek produktů na sklad',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 108,
                    divider: true,
                    enabled: true,
                    name: 'Bonusový program'
                },
                {
                    id: 109,
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
                {
                    id: 110,
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie objednávek - bonusový program',
                    icon: faCircle,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 1020,
            account: global.account_medical,
            enabled: true,
            name: 'Statistiky',
            label: 'Statistiky',
            icon: faChartBar,
            color: global.theme_light_violet,
            icon_color: global.theme_black,
            protect: true,
            children: [
                {
                    id: 212,
                    enabled: true,
                    name: 'Objednávky produktů',
                    label: 'Objednávky produktů na sklad',
                    icon: faChartBar,
                    color: global.theme_orange,
                },
            ]
        },
        {
            id: 1030,
            account: global.account_medical,
            enabled: true,
            name: 'Produkty',
            label: 'Produkty',
            icon: null,
            color: global.theme_dark_green,
            icon_color: global.theme_black,
            children: [
                {
                    id: 303,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o produktech',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_dark_green,
                }
            ]
        },
        {
            id: 1040,
            account: global.account_medical,
            enabled: true,
            name: 'Služby',
            label: 'Služby',
            icon: null,
            color: global.theme_light_red,
            icon_color: global.theme_black,
            children: [
                {
                    id: 403,
                    enabled: true,
                    name: 'Informace',
                    label: 'Informace o službách',
                    icon: faInfo,
                    icon_size: 16,
                    round: true,
                    color: global.theme_light_red,
                }
            ]
        },
        {
            id: 1060,
            account: global.account_medical,
            enabled: true,
            name: 'Informace',
            label: 'Informace',
            icon: faInfo,
            color: global.theme_light_gray,
            icon_color: global.theme_black,
            children: [
                {
                    id: 601,
                    enabled: true,
                    name: 'Newsletter',
                    label: 'Novinky Institut Esthederm',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 602,
                    enabled: true,
                    name: 'Ceník',
                    label: 'Aktuální ceník',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                /*
                {
                    id: 603,
                    enabled: true,
                    name: 'Probíhající akce',
                    label: 'Informace k probíhající akci',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                */
                {
                    id: 605,
                    enabled: false,
                    name: 'MasterClass',
                    label: 'MasterClass - Elitní vzdělávací program',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 604,
                    enabled: true,
                    name: 'Aplikace',
                    label: 'Aplikace Esthedermsalon pro tablet iPad, iPhone a Android',
                    icon: faInfo,
                    color: global.theme_orange,
                }
            ]
        },
        {
            id: 1080,
            account: global.account_medical,
            enabled: true,
            name: 'Školení',
            label: 'Školení',
            icon: faBookOpen,
            color: '#FF77AA',
            icon_color: global.theme_black,
            children: [
                {
                    id: 800,
                    enabled: true,
                    name: 'Plánovaná školení',
                    label: 'Seznam plánovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 801,
                    enabled: true,
                    name: 'Absolvovaná školení',
                    label: 'Seznam absolvovaných školení',
                    icon: faInfo,
                    color: global.theme_orange,
                },
                {
                    id: 803,
                    enabled: true,
                    masterclass: true,
                    name: 'Video z webinářů',
                    label: 'Video z webinářů',
                    icon: faInfo,
                    color: global.theme_orange,
                },
            ]
        },
    ],

    institut: 'Institut Esthederm',
    menu_back: 'Domů',
    agent: 'Obchodní zástupce',
    agent_change: 'Změna obchodního zástupce',
    rep: 'Reprezentant',
    rep_: 'Rep.',
    gm: 'GM',
    agent_choose: 'Výběr obchodního zástupce',
    salon_choose: 'Výběr kosmetického salonu',
    agent_error: 'Není vybrán obchodní zástupce',
    calendar: 'Kalendář',
    calendar_todays: 'Dnes naplánováno',
    today: 'Dnes',
    birthday: 'Narozeniny',
    birthday_date: 'Datum narození',
    yes: 'Ano',
    no: 'Ne',

    ok: 'OK',
    todo: 'Poznámky',
    todo_text: 'Seznam poznámek a připomínek',
    todo_new: 'Nová poznámka',
    todo_edit: 'Úprava poznámky',
    address: 'Adresa',

    deliver_address: 'Místo určení',
    dodavatel: 'Dodavatel',
    prevadzka_nazov: 'Název provozu',
    zodpovedna_osoba: 'Jméno zodpovědné osoby',

    sex: 'Pohlaví',
    on: 'zapnutý',
    on_: 'zapnutá',
    off: 'Vypnuto',
    off_: 'Vypnuto',
    print: 'Tlačiť',
    export_data: 'Exportování dat',
    export_data_: 'Export dat',
    export_stats: 'Export statistiky',
    export_stats_text: 'Export statistických informací',
    insert: 'Doplniť',

    login: 'Přihlášení',
    login_email: 'E-mailová adresa',
    loginname: 'Přihlašovací jméno (e-mail)',
    loginname_error: 'Přihlašování jméno musí být e-mailová adresa',
    login_data: 'Přihlašovací údaje',
    username: 'Přihlašovací jméno',
    email_error: 'Nesprávný formát e-mailové adresy',
    password: 'Heslo',
    password_old: 'Původní heslo',
    password_old_err: 'Původní heslo je nesprávné',
    password_: 'Heslo (minimální délka 8 znaků)',
    password_new: 'Nové heslo (minimálně 8 znaků)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadaná hesla se neshodují',
    password_ok: 'Heslo bylo úspěšně změněno',
    password_error: 'Při změně hesla nastala chyba. Zkuste požadavek opakovat.',
    password_error_len: 'Minimální délka hesla musí být 8 znaků',
    password_change: 'Změnit heslo',
    password_change_: 'Změna hesla',
    login_button: 'Přihlásit se',
    lost_password_button: 'Zapomněli jste heslo?',
    login_error: 'Nesprávné přihlašovací údaje!',
    lost_password: 'Zapomenuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa se nenachází v systému',
    login_pin_code: 'Zadejte kód z e-mailu',
    login_pin_code_error: 'Nesprávný ověřovací kód',
    login_email_send: 'Na Vaši e-mailovou adresu jsme zaslali ověřovací kód. Zkontrolujte také spamový adresář.',
    password_changed: 'Změna hesla proběhla úspěšně',
    continue: 'Pokračovat',

    news: 'Aktuality',
    news_: 'Aktuální informace',
    news_show_all: 'Zobrazit vše',
    news_published: 'Publikované příspěvky',
    news_not_published: 'Nezveřejněné příspěvky',
    news_draft: 'Rozpracované příspěvky',
    news_add: 'Nový příspěvek',
    news_item: 'Příspěvek',
    news_add_title: 'Nový příspěvek',
    news_edit_title: 'Úprava příspěvku',
    news_list_year: 'Seznam příspěvků za rok',
    news_icon_preview: 'Náhled ikony příspěvku',
    news_icon_create: 'Tvorba náhledu příspěvku',
    news_content_make: 'Tvorba obsahu',
    news_content_create: 'Obsahu příspěvku',
    news_title: 'Název příspěvku',
    news_description: 'Krátký popis příspěvku',
    news_author: 'Autor příspěvku',
    news_status: ['Nezveřejněno', 'Publikován', 'Rozpracováno', 'Vymazáno'],
    news_save_publish: 'Publikovat',
    news_country: 'Příspěvek určený pro zemi',
    news_choose_typ: 'Výběr typu obsahu',
    news_item_enabled: 'Aktivní obsah',
    news_divider_text: 'Oddělovač obsahu',
    news_qrcode_text: 'Link - generování QR kódu',
    news_type_label: ['Text', 'Obrázek', 'Produkt', 'Oddělovač', 'Bonus', 'QR kód', 'Ošetření'],
    news_export: 'Exportování příspěvku',
    news_export_question: 'Chcete exportovat příspěvek?',
    news_export_text: 'Exportovaný příspěvek bude odeslán na Vaši e-mailovou adresu',


    qrcode: 'QR kód',
    qrcode_note: 'Text pod QR kódem',
    priority_enter: 'Zadejte číslo priority zobrazování',

    image: 'Obrázek',
    image_choose: 'Výběr obrázku',
    image_change: 'Změna obrázku',
    image_note: 'Text pod obrázkem',

    db_error: 'Nastala chyba při přístupu do databáze. Zkuste požadavek opakovat',
    register: 'Registrace',
    register_: 'Registrovat',
    register_new: 'Nová registrace',
    register_new_salon: 'Nová registrace kosmetického salonu',

    contact: 'Kontakt',
    settings: 'Nastavení',
    settings_basic: 'Kontaktní údaje',
    settings_company: 'Firemní údaje',
    settings_salon: 'Salon',
    settings_salon_: 'Adresa',
    settings_salon_address: 'Adresa kosmetického salonu',
    settings_salon_address_: 'Adresa salonu',
    settings_medical_address: 'Adresa lékárny',

    settings_calendar: 'Rezervační systém',
    settings_calendar_text: 'Rezervace termínů pro ošetření zákazníky přes mobilní aplikaci',
    settings_calendar_label: 'Povolení rezervace termínů',
    settings_calendar_: 'Povolit rezervace',
    settings_calendar_time: 'Přestávka mezi ošetřeními',
    settings_calendar_hours: 'Nastavení časů, po které se mohou zákazníci objednávat (čas od - do)',
    settings_calendar_hours_text: 'Každý zadaný čas musí mít tvar: Např. 08:00, 18:00',
    settings_calendar_hours_text_: 'Pokud nevyužíváte polední přestávku, nechte políčka "Oběd od" - "do" prázdná',
    settings_calendar_hours_equal: 'Nastavit každý den jednotlivě',
    calendar_days: 'Pracovní dny',
    calendar_remind_day: 'Připomenout celý den',
    calendar_remind_label: 'Připomenout',
    calendar_remind_text1a: 'Odeslat všem zákazníkům e-mail s připomínkou termínu?',
    calendar_remind_text1b: 'Zpráva bude odeslána pouze zákazníkům s platnou e-mailovou adresou!',
    calendar_remind_text2: 'Odeslat zákazníkovi e-mail s připomínkou termínu?',

    calendar_email_header: 'Připomenutí termínu v kosmetickém salonu',
    calendar_email_label: 'Připomenutí termínu',
    calendar_email_google: 'Vložit událost do kalendáře google',

    calendar_remind_result_label: 'Připomínka termínu',
    calendar_remind_result_text: 'Připomínka byla úspěšně odeslána!',
    calendar_remind_result_text_multi: 'Počet úspěšně odeslaných připomínek:',
    calendar_remind_result_error: 'Při odesílání nastala chyba. Zkuste požadavek opakovat!',
    calendar_notify: 'Odesílat notifikace zákazníkům s připomínkou termínu k ošetření den předem',

    settings_system: 'Aplikace',
    settings_logout: 'Odhlásit se',

    logout: 'Odhlásit se',
    logout_title: 'Odhlášení se z aplikace',
    logout_label: 'Odhlásit se',
    logout_text: 'Chcete se odhlásit z aplikace?',

    eshop: 'E-shop',
    page_eshop: 'E-shop a stránka',
    page_eshop_: 'E-shop a webová stránka',

    required: 'Povinný údaj',
    required_red: 'Položky označené červenou barvou jsou povinný údaj',
    name: 'Jméno',
    surname: 'Příjmení',
    mobil: 'Mobil',
    mobil_format: 'Mobil (tvar +420 xxx xxx xxx)',
    format_error: 'Nesprávný formát',
    non_zero_error: 'Nesmí být 0',
    email: 'E-mailová adresa',
    email_no_exist: 'Neexistující e-mailová adresa',
    email_: 'e-mail',
    email_wrong_format: 'Nesprávny formát e-mailovej adresy',
    email_exists: 'Zadaná e-mailová adresa je již registrována v systému. Zvolte jinou.',
    form_error: 'Nesprávně vyplněné údaje',
    show_on_web: 'Zobrazit ve webovém prohlížeči',
    account: 'Číslo účtu',
    account_eshop: 'Číslo bankovního účtu (platby z e-shopu)',

    account_cancel: 'Zrušit účet',
    account_cancel_request: 'Požádat o zrušení účtu',
    account_cancel_label: 'Žádost o zrušení účtu',
    account_cancel_question: 'Chcete odeslat správci systému požadavek na zrušení účtu?',
    account_cancel_text: 'Odeslat správci systému Esthederm Salon žádost o pozastavení členství, nebo zrušení účtu',
    account_cancel_option_1: 'Dočasné pozastavení',
    account_cancel_option_1_: 'Účet bude dočasně vypnut a v budoucnu bude opět možné účet používat.',
    account_cancel_option_2: 'Zrušení účtu',
    account_cancel_option_2_: 'Účet bude zrušen a přístupové údaje vymazány do 24 hod.',
    account_cancel_option_3: 'Zrušení účtu a vymazání dat',
    account_cancel_option_3_: 'Účet bude zrušen, osobní údaje správce účtu a klientské karty budou vymazány do 10 dnů.',
    account_cancel_ok: 'O výsledku vás budeme informovat do 24 hodin. na vaši e-mailovou adresu',
    send_request: 'Odeslat požadavek',
    send_request_ok: 'Požadavek byl úspěšně odeslán.',
    send_request_error: 'Při odesílání nastala chyba. Zkuste požadavek opakovat. Pokud se chyba bude opakovat kontaktujte podporu.',

    company_name: 'Název firmy',
    street: 'Ulice',
    street_number: 'Číslo',
    psc: 'PSČ',
    town: 'Město',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    dph_platca: 'Jsem plátce DPH',
    number: 'Číslo',

    prevadzka: 'Kosmetický salon',
    prevadzka_name: 'Název salonu',
    medical_name: 'Název',
    edit: 'Upravit',
    edit_data: 'Upravit údaje',
    employees: 'Zaměstnanci',
    employee: 'Zaměstnanec',
    employee_enabled: 'Zaměstnanec je aktivní',
    employees_all: 'Všechny záznamy',

    records_none: 'Žádné záznamy',
    record_new: 'Nový záznam',
    record_edit: 'Úprava záznamu',

    client_app: 'Aplikace',
    client_app_: 'Klientská aplikace',
    clients: 'Zákazníci',
    client: 'Zákazník',
    client_enabled: 'Zákazník je aktivní',
    client_new: 'Nový zákazník',
    clients_list: 'Seznam zákazníků',
    clients_today: 'Dnes objednaný',
    clients_hidden: 'Neaktivní',
    client_with_app: 'Zákazník s klientskou aplikací',
    cliant_all: 'Seznam',
    client_choose: 'Výběr zákazníka',
    male: 'Muž',
    female: 'Žena',
    client_gdpr: 'Zákazník bol oboznámený so spracovaním osobných údajov a dal súhlas',

    client_registered: 'Registrace v kosmetickém salonu',
    client_registered_label: 'Vítejte v Esthederm Salon!',
    client_registered_text: 'Vaše registrace byla úspěšně dokončena.',
    client_registered_link: 'Do svého účtu se můžete přihlásit se adrese:',
    client_registered_login: 'Přihlašovací údaje:',
    client_registered_username: 'Přihlašovací jméno:',
    client_registered_password: 'Heslo:',
    client_register_send_email: 'Chcete odeslat zákazníkovi e-mail o úspěšné registraci v systému Esthederm Salon?',
    client_register_send_email_1: 'E-mailová zpráva bude obsahovat přístupové údaje do klientské aplikace.',
    client_register_send_email_2: 'Upozornění: poznámky, galerie a diagnostika nebudou přístupné na kartě zákazníka bez jeho souhlasu.',

    birthdate: 'Rok narození',
    gdpr: 'Ochrana osobních údajů',
    gdpr_: 'GDPR',
    gdpr_logs: 'GDPR záznamy',
    gdpr_logs_send_label: 'Odeslat GDPR záznamy',
    gdpr_logs_send_text: 'Chcete odeslat všechny záznamy GDPR z karty zákazníka?',
    gdpr_logs_send_email_text: 'Export záznamů GDPR zákazníka',
    gdpr_logs_send_table_text: 'Seznam provedených změn v nastavení GDPR účtu zákazníka',

    gdpr_changes: 'Změna nastavení GDPR',
    gdpr_export_log: 'Exportovat všechny aktivity spojené se změnou nastavení GDPR',
    gdpr_show_log: 'Zobrazit a exportovat všechny aktivity spojené se změnou nastavení GDPR zákazníka',
    gdpr_export_ask: 'Chcete exportovať všetky záznamy GDPR zákazníka?',
    gdpr_export_gdpr_log: 'Exportovat záznamy',
    gdpr_show_gdpr_log: 'Zobrazit záznamy',
    gdpr_enter_email: 'Zadejte e-mailovou adresu, na kterou bude dokument odeslán',

    gdpr_details: 'Detailní nastavení GDPR',
    gdpr_details_: 'Nastavení GDPR',
    gdpr_read: 'Zobrazit dokument',
    gdpr_agree: 'Souhlasím se zpracováním osobních údajů',
    gdpr_vop_agree: 'Souhlasím se zpracováním osobních údajů a všeobecnými obchodními podmínkami',
    gdpr_info: 'Informace o ochraně osobních údajů',
    gdpr_agree_question: 'Souhlasíte se zpracováním osobních údajů?',
    gdpr_vop_agree_question: 'Souhlasíte se zpracováním osobních údajů a všeobecnými obchodními podmínkami?',
    gdpr_agree_text: 'Před pokračováním je třeba vyjádření souhlasu.',

    gdpr_client_info: 'Informace o zákazníkovi',
    gdpr_client_info_text: 'Odeslat zákazníkovi informace, které jsou uloženy v jeho kartě zákazníka',
    gdpr_export_client: 'Odeslat údaje zákazníkovi',
    gdpr_export_card: 'Exportovat',
    gdpr_export_card_text: 'Export dat z karty zákazníka',
    gdpr_export_email_error: 'Chybí platná e-mailová adresa',
    gdpr_saved_data: 'Osobní údaje o zákazníkovi',

    gdpr_email_client_text1: 'Dobrý den,',
    gdpr_email_client_text2: 'na základě Vašeho požadavku Vám v příloze tohoto e-mailu zasíláme soubor PDF s informacemi, které máme o Vás uloženy v systému Esthederm Salon.',
    gdpr_email_client_text3: 'Heslo k odemčení souboru bude odesláno na Vaši e-mailovou adresu.',
    gdpr_email_client_text4: 'Informace o zakazníkovi',
    gdpr_email_client_text5: 'Heslo k odemknutí souboru s informacemi ze systému Esthederm Salon je',
    gdpr_email_client_text6: 'Heslo k odemknutí souboru',
    gdpr_email_client_text7: 'Export dat z karty zákazníka',

    gdpr_none: 'Žiadne osobné údaje',
    gdpr_settings_text: 'Zákazník dal souhlas ke zpracování svých osobních údajů:',
    gdpr_settings_basic: 'Osobní údaje',
    gdpr_settings_basic_text: 'Jméno, příjmení, pohlaví, rok, narození',
    gdpr_settings_basic_email: 'Evidence absolvovaných kosmetických ošetření/služeb a souvisejících údajů',
    gdpr_settings_gallery: 'Galerie fotografií',
    gdpr_settings_gallery_text: 'Ukládání fotografií zákazníka',
    gdpr_settings_gallery_email: 'Vaše fotografie (galerie)',
    gdpr_settings_notes: 'Poznámky',
    gdpr_settings_notes_text: 'Vedení poznámek o zákazníkovi',
    gdpr_settings_notes_email: 'Určité související údaje o zdravotním stavu (poznámky)',
    gdpr_settings_diagnostic: 'Diagnostika pleti',
    gdpr_settings_diagnostic_text: 'Vytváření diagnostiky pleti na základě údajů zákazníka',
    gdpr_settings_diagnostic_email: 'Záznam o diagnostice pleti (diagnostika)',
    gdpr_settings_change: 'Změna nastavení zpracování osobních údajů zákazníka',
    gdpr_settings_buttom: 'Změnit nastavení',
    gdpr_agreements_changed: 'Na základě Vašeho požadavku jsme upravili nastavení zpracování Vašich osobních údajů.',

    gdpr_delete_card_title: 'Vymazání karty zákazníka',
    gdpr_delete_card_button: 'Vymazat kartu',
    gdpr_delete_card_attention: 'Pozor chystáte se vymazat celou kartu zákazníka!',
    gdpr_delete_card_sub_title: 'Kompletní vymazání karty zákazníka.',
    gdpr_delete_card_items_title: 'Vymazanie údajov z karty zákazníka',
    gdpr_delete_card_text: 'Protože jste změnili nastavení GDPR, kde zákazník nesouhlasí se zpracováním svých osobních údajů, bude jeho karta vymazána.',
    gdpr_delete_card_text_: 'Souhlasíte s vymazáním karty zákazníka?',
    gdpr_delete_card_text_2: 'Pozor, tento požadavek je nevratný! Smazané údaje již nebudou možné obnovit!',
    gdpr_delete_email: 'Vymazání osobních údajů',

    gdpr_send_text: 'Dobrý den,</br>posíláme Vám dokument GDPR (Ochrana osobních údajů).</br></br>Přejeme příjemný den.</br>Esthederm Salon',

    client_agreements: 'Udelené povolenia',

    confirmation_needed: 'Tento požadavek vyžaduje dodatečné potvrzení!',
    gdpr_delete_items_label: 'Protože jste změnili nastavení GDPR, budou následující záznamy vymazány z karty zákazníka:',

    gdpr_logs_type: ['Vytvořená karta zákazníka', 'Změna nastavení GDPR', 'Změna nastavení GDPR a vymazání záznamů', 'Vymazaná karta zákazníka', 'Export záznamů GDPR LOGS', 'Export karty a odeslání zákazníkovi', 'Export karty zákazníka pro účely salonu'],

    file_export: 'Odeslání souboru',
    file_export_ok: 'Soubor byl úspěšně odeslán',
    file_export_error: 'Při odesílání souboru nastala chyba. Zkuste požadavek opakovat',

    gdpr_export: 'Export dat',
    gdpr_export_question: 'Chcete exportovat data z karty zákazníka?',
    gdpr_export_question_: 'Exportované údaje budou odeslány na vaši e-mailovou adresu:',
    gdpr_send: 'Odeslání dat',
    gdpr_send_question: 'Chcete odeslat zákazníkovi informace z jeho klientské karty?',
    gdpr_send_question_: 'Údaje budou odeslány na e-mailovou adresu zákazníka:',
    gdpr_doc: 'Dokument o ochraně osobních údajů',

    vop: 'Všeobecné obchodní podmínky',
    gdpr_vop: 'Ochrana osobních údajů a všeobecné obchodní podmínky',
    gdpr_agreement_doc: 'Souhlas se zpracováním osobních údajů',

    cookies: 'Cookies',

    chat: 'Diskuse',
    chat_enable: 'Povoliť diskusiu',
    chat_public: 'Veřejná diskuse',
    chat_public_item: 'Veřejný příspěvek',
    chat_new: 'Nový příspěvek',
    chat_edit: 'Úprava příspěvku',
    chat_prispevok: 'Příspěvek',
    chat_text: 'Text příspěvku',
    chat_delete: 'Vymazání příspěvku',
    chat_delete_ask: 'Chcete vymazat publikovaný příspěvek?',
    chat_none: 'Žádné příspěvky',
    chat_error: 'Při odesílání nastala chyba!',
    chat_not_readed: 'Počet nepřečtených',
    chat_private: 'Soukromá diskuse',
    chat_notify_label: 'Diskuse - nový příspěvek',

    card: 'Karta',
    salon_card: 'Karta zákazníka',
    client_card: 'Karta zákazníka',
    client_cards: 'Karty zákazníků',
    age: 'Věk',
    points: 'Věrnostní body',
    points_edit: 'Upravit počet bodů',
    client_points: 'Počet věrnostních bodů',
    client_vouchers: 'Počet dárkových poukázek získaných za věrnostní body',
    vouchers_used: 'Počet použitých dárkových poukázek',
    vouchers_used_value: 'v hodnotě',
    voucher: 'Dárková poukázka',
    value: 'Hodnota',
    bonus_use: 'Uplatniť',
    bonus_system: 'Věrnostní systém',
    bonus_system_enable: 'Používat věrnostní systém',
    bonus_system_label: 'Věrnostní body se sbírají prodáváním produktů, kde 1 Kč = 1 bod',
    bonus_system_label_: 'Nasbíráním předdefinovaného počtu bodů se vygeneruje poukázka s hodnotou v Kč, kterou lze použít jako slevu na další nákup.',
    bonus_system_points: 'Počet bodů potřebných pro vygenerování jedné poukázky',
    bonus_system_price: 'Hodnota vygenerované poukázky v Kč',
    bonus_system_example: 'Příklad: Pokud prodáte produkty v hodnotě @price Kč, systém vygeneruje jednu poukázku zákazníkovi, jejíž hodnota je @bonus Kč. Zákazník může tuto poukázku použít k dalšímu nákupu. Použitím poukázky získává slevu na nákup v hodnotě poukázky -@bonus Kč',
    bonus_click_to_use: 'Označte poukážky a stlačte uplatniť',
    bonus_delete: 'Vymazať vernostnú poukážku',
    bonus_delete_ask: 'Vymazat věrnostní poukázku ze seznamu? Body budou připočteny do konta zákazníka.',

    preview: 'Náhled',
    text_editor: 'Textový editor',

    product: 'Produkt',
    products: 'Produkty',
    buyed_products: 'Seznam zakoupených produktů v salonu',
    buyed_products_eshop: 'Seznam zakoupených produktů přes e-shop',
    products_my: 'Ostatní produkty',
    products_categories: 'Přidávání a úprava kategorii produktů',
    products_list: 'Seznam produktů',
    product_new: 'Nový produkt',
    products_none: 'Žádné produkty',
    products_buy_eshop: 'Produkty zakoupené přes e-shop',
    products_structure: 'Složení',
    products_structure_label: 'Složení produktu',
    products_export: 'Export produktů',
    products_export_label: 'Skladové stavy produktů',
    products_export_email: 'Export skladových stavů produktů do PDF',
    products_export_selection: 'Výběr typu exportu seznamu produktů',
    product_card: 'Popis produktu',
    pricelist: 'Ceník produktů',
    products_export_selection_pricelist: 'Exportovat seznam jako ceník',
    products_export_selection_pricelist_yes: 'V exportovaném souboru budou zahrnuty pouze prodejní ceny',
    products_export_selection_pricelist_no: 'V exportovaném souboru budou zahrnuty nákupní ceny, prodejní ceny a skladové stavy',

    product_name: 'Název produktu',
    note_short: 'Krátký popis',
    note_long: 'Detailní popis produktu',
    keywords: 'Klíčová slova při vyhledávání produktu (jednotlivá slova oddělujte mezerou)',
    cost: 'Nákupní cena (s DPH)',
    cost_: 'Nákupní cena',
    price: 'Prodejní cena (s DPH)',
    price_: 'Prodejní cena',
    price__: 'Cena',
    price_dph: 'Cena s DPH',
    sum: 'Suma',
    sum_dph: 'Částka celkem s DPH',
    sum_dph_: 'Částka celkem',
    price_dph_piece: 'Cena s DPH za 1 ks',
    price_piece: 'Cena za 1 ks',
    price_final: 'Nezávazně doporučená cena s DPH',
    cost_final: 'Nákupní cena pro kosmetičku bez DPH',
    stock_items: 'Počet kusů skladem',
    details: 'Objemové množství',
    code: 'Obj. kód produktu',
    code_: 'Obj. kód',
    units: 'Počet kusů v balení',
    product_action: 'Akciový produkt',
    product_news: 'Novinka',
    product_active: 'Produkt aktivní',
    product_eshop_enabled: 'Produkt se zobrazuje na e-shopu',
    stock: 'Skladem',
    no_stock: 'Není skladem',
    quantity: 'Počet kusů',
    quantity_: 'Počet',
    quantity_bals: 'Počet balení',
    pack: 'balení',
    sumar: 'Celkem',

    service: 'Služba',
    service_: 'Ošetření',
    services: 'Služby',
    services_: 'Ošetření',
    absolved_services: 'Seznam poskytnutých služeb',
    services_my: 'Ostatní služby',
    services_news: 'Nová ošetření',
    services_categories: 'Přidávání a úprava skupin služeb',
    services_list: 'Seznam služeb',
    services_new: 'Nová služba',
    services_none: 'Žádné služby',
    service_cost: 'Náklady na službu (s DPH)',
    service_cost_: 'Náklady na službu',
    service_enabled: 'Služba je aktivní',
    service_name: 'Název služby',
    service_time: 'Délka služby v minutách',
    service_time_: 'Délka služby',
    service_note: 'Poznámka ke službě',
    service_materials: 'Školicí materiály',
    service_select: 'Výběr služby',
    service_select_: 'Výběr ošetření',
    service_change: 'Změna ošetření',
    service_date: 'Datum služby',
    service_delete: 'Vymazat službu',
    service_delete_ask: 'Vymazat službu z karty zákazníka?',
    service_eshop: 'Služba se zobrazuje na webové stránce',
    service_reserve: 'Objednávání přes rezervační systém',
    service_reserve_time: 'Čas na přípravu v minutách',
    service_reserve_time_: 'Čas pro přípravu - rezervační systém',
    service_reserve_time_short: 'Příprava',

    product_select: 'Výběr produktu',
    product_change: 'Změna produktu',

    bonus_select: 'Výběr bonusu',
    bonus_change: 'Změna bonusu',

    note: 'Poznámka',
    notes: 'Poznámky',
    gallery: 'Galerie',
    statistic: 'Statistika',
    stats_sell: 'Statistika prodeje',
    sum_to_pay: 'Částka k zaplacení',
    categories: 'Kategorie',
    category: 'Kategorie',
    category_enabled: 'Kategorie je aktivní',
    category_eshop: 'Kategorie se zobrazuje na e-shopu',
    label: 'Název',
    slug: 'Textový identifikátor',

    photos: 'Fotografie',
    photo: 'Fotografie',
    photo_select_title: 'Výběr a úprava fotografie',
    photo_select: 'Vybrat fotografii',
    photo_change: 'Změnit fotografii',
    photo_delete: 'Vymazat',
    photo_delete_text: 'Chcete vymazat fotografii?',
    photo_save: 'Vložit',
    photo_min: 'Minimální velikost',
    select: 'Vybrat',

    search: 'Hledat',
    search_in_category: 'Hledat v kategorii',

    pieces: 'ks',
    groups: 'Skupiny',
    group: 'Skupina',
    group_enabled: 'Skupina je aktivní',
    group_eshop: 'Skupina se zobrazuje na web stránce',
    video_preview: 'Video',

    empty_list: 'Žádné položky',

    register_from_eshop: 'Registrace z e-shopu',

    send_email: 'Odeslat e-mail',
    send_email_clients: 'Odeslat hromadný e-mail zákazníkům',
    clients_with_email: 'E-mail bude odeslán pouze zákazníkům s e-mailovou adresou',
    send_notify_clients: 'Odeslat hromadně zprávu zákazníkům',
    clients_with_app: 'Zpráva bude odeslána pouze zákazníkům s klientskou aplikací jako notifikace',

    send_email_client: 'Odeslat e-mail',
    send_notify: 'Odeslat zprávu',
    age_years: 'let',
    year: 'rok',
    selected_season: 'Za dané období',
    stats_season: 'Statistika za rok',
    stats_season_date: 'Statistika za období',
    info: 'Informace',
    info_: 'Informace',
    diagnostics: 'Diagnostika',
    add: 'Přidat',
    delete_note: 'Vymazání poznámky',
    delete_note_ask: 'Vymazat poznámku z karty zákazníka?',
    note_default: 'Text nové poznámky ...',
    visits: 'Historie návštěv',

    galery_add: 'Přidat fotografii',
    galery_delete: 'Vymazat fotografii',
    galery_delete_ask: 'Vymazat fotografii z karty zákazníka?',

    photo_choose: 'Výběr fotografie',
    photo_choose_: 'Výbrat fotografii',
    date: 'Datum',
    card_delete: 'Vymazat z karty',
    sell_data: 'Datum prodeje',

    diagnose: 'Vyhodnotit',
    diagnose_services: 'Výsledek diagnostiky',
    addon: 'Příloha',
    email_header: 'Předmět',
    email_header_error: 'Předmět e-mailu nesmí být prázdný',
    emails_sended: 'Počet úspěšně odeslaných e-mailů:',
    email_sended: 'E-mail bol úspešne odoslaný.',
    notify_send: 'Odeslat zprávu',
    notify: 'Zpráva',
    notify_text: 'Text zprávy',
    notifies_sended: 'Počet úspěšně odeslaných zpráv',
    notify_sended: 'Zpráva byla úspěšně odeslána.',

    pin: 'Přihlašovací kód do klientské aplikace',
    pin_life: 'Platnost kódu je 60 sekund!',
    pin_life_end: 'Platnost kódu vypršela.',
    pin_generate: 'Vygenerovat nový kód',
    login_code_send: 'Odeslat přihlašovací kód zákazníkovi prostřednictvím e-mailu',
    login_code_send_label: 'Přihlašovací kód do klientské aplikace',
    login_code_send_text_1: 'Přihlašovací kód do aplikace - Kosmetické salony klient',
    login_code_send_text_2: 'Kód zkopírujte do aplikace a přihlaste se.</br>Pozor, platnost přihlašovacího kódu je 24 hodin!',

    calendar_delete: 'Vymazat událost',
    calendar_delete_item: 'Vymazat událost z kalendáře?',
    calendar_holiday: 'Dovolená',
    calendar_holiday_show: 'Výběr dní dovolené',
    calendar_holiday_list: 'Seznam dny dovolené',

    month_list: 'Podle jednotlivých měsíců',

    todo_delete: 'Vymazání poznámky',
    todo_delete_text: 'Vymazat poznámku ze seznamu?',

    products_shop: 'Prodej produktů',
    products_shop_sum: 'Částka nákupu',
    products_shop_client: 'Připsat prodej zákazníkovi',
    products_shop_delete: 'Vymazat prodej',
    products_shop_deletes: 'Produkt vymazán z prodeje na kartě zákazníka',
    products_shop_delete_text1: 'Chcete vymazat prodané produkty?',
    products_shop_delete_text2: 'Produkty budou vymazány z karty zákazníka a přičteny do skladových zásob.',
    products_shop_number: 'Číslo',
    products_shop_history: 'Historia predaja produktov',
    product_buy: 'Do košíku',
    products_shop_details: 'Náhled prodeje',
    products_stack_export: 'Export produktů',
    product_in_basket: 'V košíku',
    basket: 'Nákupný košík',
    shop: 'Prodejna',
    shop_finish: 'Dokončit nákup',
    shop_finish_text: 'Ze skladových zásob budou odečtena prodaná množství produktů',

    booking_label: 'Objednávka produktů na sklad',
    booking_products: 'Objednávky produktů',
    booking_history_label: 'Historie objednávek produktů',
    booking_stock: 'Sklad',
    booking_preview: 'Objednávka',
    booking_pieces: 'Objednat (ks)',
    booking_pack: 'Objednat (balení)',
    booking_card: 'Náhled objednávky produktů',
    booking_sum: 'Částka s DPH',
    booking_checkout: 'Odeslat objednávku',
    booking_checkout_question: 'Objednávka bude odeslána obchodnímu zástupci.',
    booking_min_sum: 'Minimální částka objednávky musí být',
    booking_note: 'Přidat poznámku',
    booking_note_edit: 'Upravit poznámku',
    booking_note_included: 'Objednávka obsahuje poznámku!',
    booking_note_not_included: 'Objednávka neobsahuje poznámku!',
    booking_email_label: 'Objednávka produktů',
    booking_sended_label: 'Odeslaná objednávka',
    booking_sended_text: 'Objednávka produktů byla úspěšně odeslána obchodnímu zástupci',
    booking_error_label: 'Chyba',
    booking_error_text: 'Při odesílání objednávky nastala chyba, zkuste požadavek opakovat!',
    booking_items: 'Počet položiek',
    booking_number: 'Číslo objednávky',
    booking_date: 'Datum objednávky',
    booking_cancel: 'Zrušit objednávku',
    booking_cancel_text: 'Žádost o zrušení objednávky bude odeslána obchodnímu zástupci.',
    booking_status: 'Stav objednávky',
    booking_changes: 'V objednávce se nacházejí změny',
    booking_product_ordered: 'Objednaný počet',
    booking_product_received: 'Dodaný počet',
    booking_reklamacia: 'Reklamace',
    booking_reklamacia_label: 'Reklamace objednávky produktů',
    booking_reklamacia_order: 'Reklamace objednávky',
    booking_reklamacia_notify: 'Změna stavu reklamace objednávky na',
    booking_finish: 'Připsat na sklad',
    booking_finish_text: 'Produkty budou připsány do skladových zásob.',
    booking_stat_label: 'Prodej produktu za posledních 12 měsíců',
    booking_stat_recommended: 'Doporučené množství k objednávce',
    booking_stat_last_order: 'Poslední objednávka na sklad',
    booking_ask_status: 'Zeptat se na stav',
    booking_ask_status_label: 'Zeptat se na stav objednávky',
    booking_ask_status_text: 'Požadavek bude odeslán obchodnímu zástupci',
    booking_ask_status_message: 'Prosím o informaci o stavu objednávky č.',
    booking_ask_status_notify: 'Zjišťování stavu objednávky produktů',
    booking_ask_status_send_ok: 'Požiadavka bola úspešne odoslaná obchodnému zástupcovi.',
    booking_reklamacia_select: 'Označte produkty, ktoré reklamujete',
    booking_reklamacia_text: 'Text reklamace',
    booking_reklamacia_text_photos: 'Doplňte důvod reklamace a fotografie',
    booking_reklamacia_note: 'Vaše kontaktní údaje budou doplněny systémem',
    booking_reklamacia_note2: 'Fotografie (max. počet 5 ks fotografií)',
    booking_reklamacia_add_photo: 'Pridať fotografiu',
    booking_reklamacia_send_label: 'Odeslat reklamaci',
    booking_reklamacia_send_text: 'Reklamace bude odeslána obchodnímu zástupci',
    booking_reklamacia_products: 'Reklamované produkty',
    booking_reklamacia_true: 'Vytvořená reklamace',
    booking_reklamacia_status: 'Stav reklamace',
    booking_reklamacia_count: 'Počet reklamací',
    booking_filter_active: 'Nevyřízené objednávky',
    booking_filter_sended: 'Odeslány objednávky',
    booking_filter_finished: 'Ukončené objednávky',
    booking_create_new: 'Nová objednávka',
    booking_create_title: 'Vytvoření nové objednávky',
    booking_create_text: 'Chcete vytvořit novou objednávku pro salon?',

    booking_button_send_manual: 'Odesláno manuálně',
    booking_button_send_auto: 'Překlopit do CRM',
    booking_crm_sended: 'Odoslané do CRM',
    booking_manual_sended: 'Manuálně do CRM',
    booking_missing_id: 'Chybějící propojení na CRM',
    booking_missing_id_text: 'Zákazník není propojen na CRM. Objednávku nelze překlopit.',
    booking_missing_id_sub_text: 'Prosím o zaslání ID ze CRM na adresu info@datadream.sk',

    booking_export: 'Export objednávek',
    booking_export_: 'Export objednávky',
    booking_export_label: 'Export objednávek',
    booking_export_text: 'Ukončené objednávky za daný rok',
    bonus_export_email: 'Export objednávek bonusu',
    product_export_email: 'Export objednávek produktů',

    order_verify_label: 'Kontrola objednávky podľa prijatých kusov',
    order_verify_text: 'Produkty budou připsány na sklad v upravených množstvích',
    order_verify_quantity: 'Počet přijatých kusů',

    bonus: 'Bonus',
    bonus_booking: 'Objednávka bonusu',
    bonus_bookings: 'Objednávky bonusu',
    bonus_sum: 'Částka bez DPH',
    bonus_pay: 'Doplatek bez DPH',
    bonus_pay_sum: 'Doplatek',
    bonus_pay_: 'Platba',
    bonus_order_preview: 'Náhled objednávky bonusu',
    bonus_from_credit: 'Z kreditu',
    bonus_from_credit_pieces: 'Počet kusů z kreditu',
    bonus_from_credit_pieces_: 'ks z kreditu',
    bonus_to_buy_pieces_: 'ks k platbě',
    bonus_to_buy_pieces: 'Počet kusů k platbě',
    bonus_to_pay: 'doplatit',
    bonus_credit_left: 'Zbývající počet bodů',
    bonus_credit: 'Body',
    bonus_credit_status: 'Stav bodů',
    bonus_ask_status_notify: 'Zjišťování stavu objednávky bonusu',
    bonus_email_label: 'Objednávka bonusu',
    bonus_order_close: 'Ukončit objednávku',
    bonus_finist_text: 'Objednávka bude označena jako ukončená',
    bonus_credit_history: 'Historie kreditu',
    bonus_history_set: 'Změna kreditu adminem',
    bonus_history_buy: 'Nákup produktů',
    bonus_history_return: 'Vrácený kredit po změně objednávky',
    bonus_credit_: 'Body',
    bonus_credit_quantity: 'Počet bodů',

    bonus_credit_year: 'Roční počet bodů',
    bonus_credit_year_note1: 'Celkový počet bodů přidělený na postupné čerpání.',
    bonus_credit_year_note2: 'Čerpání bodů je rozloženo na dobu 12 měsíců.',
    bonus_credit_year_note3: 'Každé @n měsíce se budou body postupně uvolňovat z celkového ročního množství.',
    bonus_credit_actual: 'Aktuální stav bodů pro nakupování',
    bonus_credit_periode: 'Periodické navyšování o @n bodů',
    bonus_credit_periode_left: 'Zbývající počet navýšení',
    bonus_credit_year_left: 'Zbývající roční počet bodů',
    bonus_credit_year_left_note: 'Počet bodů, které nebyly dosud uvolněny',
    bonus_credit_status_small: 'Zobrazit stav bodů',

    do_not_reply: 'NEODPOVÍDAT',
    do_not_reply_text: 'Prosím, neodpovídejte na e-mail! Toto je automaticky generována zpráva systémem Esthederm Salon.',
    do_not_reply_text_: 'Prosím, neodpovídejte na tento email, na odpověď použijte emailovou adresu:',

    export_agent_data_ask: 'Chcete exportovat údaje?',
    export_agent_data_text: 'Exportované údaje budou odeslány na Vaši e-mailovou adresu:',

    email_table_number: 'p.č.',
    email_table_code: 'kód',
    email_table_product: 'produkt',
    email_table_quantity: 'počet kusů',
    email_table_quantity_from_credit: 'počet ks z kreditu',
    email_table_quantity_to_pay: 'počet ks k platbě',
    email_thanks: 'Děkujeme a přejeme příjemný den.',

    eshop_history: 'Přijaté objednávky z e-shopu',
    eshop_quantity: 'Objednaný počet',
    eshop_card: 'Objednávka produktů',
    eshop_products: 'Seznam objednaných produktů',
    eshop_trade: 'Způsob dopravy',
    eshop_payment: 'Způsob platby',
    eshop_note: 'Poznámka k objednávce',
    eshop_item_delete: 'Vymazání položky',
    eshop_item_delete_text: 'Chcete vymazat položku z objednávky?',
    eshop_order_delete: 'Zrušení objednávky',
    eshop_order_delete_text: 'Chcete zrušit objednávku?',
    eshop_order_delete_text_: 'Rezervovaná množství produktů budou připsána na sklad.',
    eshop_item_add: 'Doplnit produkt',
    eshop_ask_email: 'Změna stavu objednávky',
    eshop_ask_email_text: 'Chcete odeslat e-mail zákazníkovi se změnou statusu objednávky?',
    eshop_email_text: 'Změna stavu objednávky číslo:',
    show_active: 'Zobrazit jen nevyřízené',
    show_mine: 'Zobrazit jen moje',
    eshop_received: 'Počet nevyřízených objednávek z e-shopu',
    orders_products_received: 'Počet nevyřízených objednávek produktů na sklad',
    orders_bonus_received: 'Počet nevyřízených objednávek bonusu',
    ask_order_change_status: 'Chcete změnit stav objednávky',
    orders_year_list: 'Seznam objednávek vytvořených v roce',

    reklamacia_select: 'Výběr důvodu',
    reklamacia_reason: 'Důvod reklamace',
    reklamacia_dovod: [
        { id: 0, text: 'poškozené zboží / zásilka' },
        { id: 1, text: 'nesprávné množství / druh' },
        { id: 2, text: 'nedostatečná kvalita produktu' },
        { id: 3, text: 'nesprávná cena' },
        { id: 4, text: 'jiné' },
    ],
    reklamacia: 'Reklamace',
    reklamacia_date: 'Datum reklamace',
    reklamacia_number: 'Číslo reklamace',
    reklamacia_message: 'Text reklamace',
    reklamacia_email: 'Export reklamace',
    reklamacie: 'Reklamace',
    reklamacia_products: 'Reklamace produktů',
    reklamacia_bonus: 'Reklamace bonusu',
    reklamacie_products_bonus: 'Reklamace objednávek produktů a bonusu',
    reklamacie_open: 'Neukončené reklamace',
    reklamacie_close: 'Ukončené reklamace',
    reklamacie_export: 'Export reklamací',
    reklamacie_export_text: 'Veškeré reklamace za daný rok',
    reklamacie_year_list: 'Seznam reklamací vytvořených v roce',
    reklamacie_open_count: 'Počet nevyřízených reklamací',
    reklamacie_orders: 'Reklamace objednávek',
    reklamacie_deny: 'Zamítnutí reklamace',
    reklamacie_deny_reason: 'Důvod zamítnutí',
    reklamacie_send_title: 'Odeslání reklamace',
    reklamacie_send_subtitle: 'Zadejte e-mailovou adresu, kde bude reklamace odeslána',
    reklamacie_message: 'Komentář k reklamaci pro zákazníka',
    reklamacie_message_label: 'Komentář k reklamaci',
    reklamacie_message_add: 'Přidat komentář',
    reklamacie_message_edit: 'Upravit komentář',
    reklamacie_message_agent: 'Vyjádření k reklamaci',

    dodaci_list_cislo: 'Číslo dodacího listu',

    link_iphone: 'Aplikace pro zařízení Apple',
    link_android: 'Aplikace pro zařízení Android',

    stats_products: 'Prodej produktů v salonu',
    stats_products_eshop: 'Prodej produktů přes e-shop',

    stats_sum_dph: 'Celkem s DPH',
    stats_today: 'Denní statistika',
    stats_today_text: 'Prodej produktů a služeb v salonu',
    stats_sum: 'Částka celkem za produkty a služby',
    stats_cost: 'Celkem zisk za prodej produktů a služeb',
    stats_sum_vouchers: 'Částka za dárkové poukázky',
    stats_sum_products: 'Částka za produkty',
    stats_sum_services: 'Částka za služby',
    stats_cost_products: 'Zisk za prodej produktů',
    stats_cost_services: 'Zisk za prodej služeb',

    stats_info: 'Informace',
    stats_info_text: 'Informace o zákaznících kosmetického salonu',
    stats_info_texts: 'Informace o zákaznících kosmetických salonů',
    stats_info_active: 'Počet aktivních zákazníků',
    stats_info_young: 'Nejladší zákazník',
    stats_info_old: 'Nejstarší zákazník',
    stats_info_average: 'Průměrný věk zákazníků',
    stats_info_age: 'Věk zákazníků',
    stats_info_man: 'Počet mužů',
    stats_info_man_: 'mužů',
    stats_info_woman: 'Počet žen',
    stats_info_web: 'Počet zákazníků registrovaných přes e-shop',
    stats_info_presents: 'Počet darovaných produktů zákazníkům',
    stats_info_presents_: 'Dárkové předměty',
    stats_info_discount: 'Poskytnutá sleva ve výši',

    graph_click: 'Klepnutím na sloupce grafu se zobrazí podrobnější informace',
    rabat: 'Zisk',

    stats_products_text: 'Statistika prodeje produktů zakoupených v salonu',
    stats_products_salon: 'Produkty zakoupené v salonu',
    stats_products_eshop_: 'Statistika prodeje produktů zakoupených přes e-shop',
    stats_services: 'Statistika prodeje služeb',
    stats_product: 'Prodej produktu v salonu i přes e-shop',
    stats_product_salons: 'Prodej produktu v salonech i přes e-shop',
    stats_product_salon: 'Zakoupeno v salonu',
    stats_product_eshop: 'Zakoupeno přes e-shop',
    stats_product_label: 'Statistika prodeje jednotlivých produktů',
    stats_services_label: 'Počet prováděných ošetření jednotlivých kosmetických salonů',
    stats_salons: 'Souhrnná statistika kosmetických salonů',

    stats_clients_label: 'Statistika zákazníků',
    stats_clients_text: 'Prodej produktů a služeb',
    stats_booking_text: 'Statistika objednávek produktů na sklad',
    stats_booking_label: 'Objednávky na sklad',

    stats_employees: 'Statistika prodeje služeb',
    obrat: 'Obrat',

    info_newsletter: 'Novinky Institut Esthederm',
    info_cennik: 'Aktuální ceník',
    info_action: 'Informace k probíhající akci',

    web_page: 'Webová stránka',
    web_page_eshop: 'Webová stránka a e-shop',
    web_page_preview: 'Náhled webové stránky a e-shopu',
    web_page_settings: 'Nastavení webové stránky a e-shopu',
    web_page_texts: 'Texty',
    web_page_hours: 'Otevírací hodiny',
    web_page_banners: 'Reklamní bannery',
    web_page_banners_text: 'Maximální počet jsou tři reklamní bannery',
    web_page_photo: 'Fotografie salonu',

    register_state: 'Země provozování kosmetického salonu',
    register_state_: 'Země provozování',
    register_typ: 'Typ registrace',
    register_typ_note: 'Změna typu účtu zákazníka',
    register_typ_full: 'Plná verze',
    register_typ_light: 'Light verze',
    register_settings: 'Nastavení',
    register_sumar: 'Shrnutí',
    register_finish: 'Dokončit',
    register_alias: 'Přístup na webovou stránku a e-shop',
    register_alias_text: 'Aplikace obsahuje vlastní e-shop a webovou stránku, které budou přístupné na webové adrese',
    register_alias_info: 'Doporučujeme zadat název salonu',
    register_alias_input: 'Zadejte text, který bude za lomítkem',
    alias_error: 'Zadanou adresu již používá jiný uživatel. Zvolte jinou!',
    alias: 'Doména',

    settings_page_enable: 'Publikovat mou webovou stránku a e-shop na stránkách esthedermsalon',
    settings_eshop_enable: 'Používat e-shop',
    settings_eshop: 'Nastavení e-shopu',
    eshop_enabled: 'E-shop je zapnutý a zákazníci mohou nakupovat',
    eshop_enabled_: 'E-shop je zapnutý',
    eshop_disabled: 'E-shop je vypnutý a nachází se ve stavu údržby',
    trade: 'Doprava',
    trade_enabled: 'Využívat dopravu kurýrem',
    trade_text: 'Dopravu kurýrem můžete využít při nákupu zboží o celkové hmotnosti do 30 kg',
    trade_name: 'Název kurýrní společnosti',
    trade_eur: 'Cena poštovného',
    trade_note: 'Poznámka k dopravě',
    trade_free: 'Využívat dopravu zdarma při nákupu od určité částky',
    trade_free_eur: 'Suma nákupu, od které je poštovné zdarma',
    payment: 'Platba',
    payment_bank: 'Využívat možnost platby přes účet',
    public_services: 'Publikovat seznam služeb na webu a v klientské aplikaci',
    public_services_show: 'Zobrazovat seznam služeb',
    public_prices_show: 'Zobrazovat ceny služeb',

    settings_desktop: 'Nastavení plochy',
    settings_dektop_text: 'Nastavení zobrazování jednotlivých prvků na ploše aplikace',
    desktop: 'Pracovní plocha',
    shortcuts: 'Zkratky',
    eshop_orders: 'Přijaté objednávky z e-shopu',
    desktop_booking_products: 'Objednávka produktů',
    desktop_booking_bonus: 'Objednávka bonusu',


    sc_products_info: 'Informace o produktech',
    sc_selling: 'Prodej produktů',
    sc_products_booking: 'Objednávka produktů na sklad',
    sc_products_settings: 'Úprava produktů',
    sc_bonus_booking: 'Objednávka bonusu',
    sc_services_info: 'Informace o službách',
    sc_stats_day: 'Denní statistika',
    sc_info_newsletter: 'Nesletter Institut Esthederm',
    sc_info_cennik: 'Ceník',
    sc_info_action: 'Probíhající akce',
    sc_page_settings: 'Nastavení webové stránky',
    sc_page_web: 'Webová stránka',
    sc_client_last: 'Poslední otevřená karta zákazníka',

    sc_salon_last: 'Poslední otevřený salon',
    sc_salon_stock: 'Skladové stavy',
    sc_salon_booking_products: 'Objednávky produktů',
    sc_salon_booking_bonus: 'Objednávky bonusu',

    settings_app: 'Nastavení aplikace',
    settings_pin_label: 'Ochrana dat PIN kódem',
    settings_pin: 'Ochrana PIN kódem',
    settings_pin_code: 'Pin kód',
    settings_pin_code_6: '6 místný',
    settings_pin_enabled: 'Ochrana PIN kódem je aktivována',
    settings_pin_disabled: 'Ochrana PIN kódem je vypnuta',
    settings_pin_enter: 'Zadat PIN kód',
    settings_pin_change: 'Změnit PIN kód',
    settings_pin_enter_label: 'Zadejte PIN kód',
    settings_pin_enter_old_label: 'Zadejte původní PIN kód',
    settings_pin_enter_new_label: 'Zadejte nový PIN kód',
    settings_pin_reenter_label: 'Zopakujte PIN kód',
    settings_pin_changed: 'PIN kód byl úspěšně změněn',
    settings_pin_not_equal: 'Zadané PIN kódy se neshodují',
    settings_pin_error: 'Nesprávný PIN kód',

    settings_forum_label: 'Zobrazovat příspěvky diskuse ne starší než',
    settings_forum_1: 'Jeden měsíc',
    settings_forum_3: 'Tři měsíce',
    settings_forum_6: 'Šest měsíců',

    token_error: 'Neautorizovaný vstup do databáze',
    token_error_text: 'Odhlaste se z aplikace a opět přihlaste.',

    settings_web_texts: 'Texty na webové stránce',
    settings_web_label: 'Nadpis na stránce',
    settings_web_text: 'Text na stránce',
    settings_web_info: 'Oznam na stránce',
    settings_web_map_label: 'Mapa s označením kosmetického salonu',
    settings_web_map_check: 'Zobrazovat mapu na stránce',
    settings_web_photo: 'Fotografie salonu zobrazená na webové stránce',

    reserve_label: 'Rezervace',
    reserve_desktop_label: 'Nepotvrzené rezervace termínů požadované zákazníky',
    reserve_waiting: 'Čeká na potvrzení',
    reserve_waiting_: 'Potvrdit',
    reserve_canceled: 'Zrušeno zákazníkem',
    reserve_canceled_: 'Rezervace zrušena zákazníkem',
    reserve_canceled_small: 'Zrušena',
    reserve_client_waiting: 'Rezervace zákazníkem - čeká na potvrzení!',
    reserve_accept: 'Potvrdit termín',
    reserve_accepted: 'E-mail s potvrzením termínu byl úspěšně odeslán',
    reserve_accepted_label: 'Potvrzení termínu rezervace',
    reserve_accepted_text: 'Vaše rezervace v kosmetickém salonu byla potvrzena!',

    show_file: 'Zobrazit soubor',
    help: 'Nápověda',
    helper: 'Návod k aplikaci',
    help_contact_us: 'Kontaktujte nás',

    contact_us_label: 'Potřebujete poradit s aplikací',
    contact_us_text1: 'Napište nám a my Vám ochotně poradíme.',
    contact_us_text2: 'Hotline (mobil): +421 944 411 144',
    contact_us_note: 'Text zprávy (Do textu nemusíte zadávat jméno ani kontaktní údaje. Systém údaje doplní při odesílání)',

    sell_products_quantity: 'Počet prodaných produktů',
    sell_services_quantity: 'Počet prodaných služeb',

    info_clients_age: 'Stáří zákazníků',
    info_clients_count: 'Počet zákazníků',
    info_clients_young: 'Nejladší zákazník',
    info_clients_old: 'Nejstarší zákazník',
    info_clients_average: 'Průměrný věk zákazníků',
    info_clients_mans: 'Počet mužů',
    info_clients_womans: 'Počet žen',
    info_salon_orders: 'Celkový prodej do salonu',
    info_salons_orders: 'Celkový prodej do salonů',
    info_salon_orders_stack: 'Objednávky produktů na sklad (nákupní cena)',
    info_salon_products: 'Počet prodaných produktů v salonu',
    info_salon_top_product: 'Top produkt',
    info_eshop_products: 'Počet prodaných produktů přes e-shop',
    info_salon_services: 'Počet ošetření',
    info_salon_top_service: 'Top ošetření',

    salon_stat_products_salon: 'Produkty (salon)',
    salon_stat_products_salon_label: 'Statistika produktů zakoupených v salonu',
    salon_stat_products_eshop: 'Produkty (e-shop)',
    salon_stat_products_eshop_label: 'Statistika produktů zakoupených přes e-shop',
    salon_stat_services: 'Služby',
    salon_stat_services_label: 'Statistika služeb - ošetření',
    salon_stat_sum_cost: 'Částka celkem (* nákupní ceny)',
    salon_stat_products_quantity: 'Počet prodaných produktů za dané období',
    salon_stat_services_quantity: 'Počet prodaných služeb za dané období',

    salon_stats_cards_quantity: 'Počet zákaznických karet',
    salon_stats_products_salon_quantity: 'Počet prodaných produktů v salonu',
    salon_stats_products_eshop_quantity: 'Počet prodaných produktů přes e-shop',
    salon_stats_services_quantity: 'Počet prodaných ošetření',
    salon_stats_orders_value: 'Hodnota objednávek produktů',
    salon_stats_bonus_value: 'Hodnota objednávek bonusu (bez DPH)',
    salon_stats_credit_value: 'Zbývající počet kreditů (bez DPH)',
    salon_stats_salons_quantity: 'Počet salonů',

    export: 'Exportovat',
    reps: 'Reprezentanti',

    geo: 'Geolokace salona',
    geo_map: 'Mapa s označením polohy kosmetického salonu',
    geo_google_maps: 'Poloha kosmetického salonu v google maps',
    geo_lat: 'Zeměpisná šířka (lat)',
    geo_lng: 'Zeměpisná délka (lng)',
    geo_get: 'Získat aktuální polohu',
    geo_web_show: 'Zobrazovat na webové stránce',
    geo_error: 'Při načtení aktuální polohy nastala chyba. Zkuste požadavek opakovat!',
    geo_show_map: 'Zobrazit mapu',

    lessons: 'Školení',
    lesson: 'Školení',
    lesson_details: 'Informace o školení',
    lesson_choose: 'Výběr školení',
    lesson_choose_text: 'Zkopírování údajů ze školení',
    lessons_list_year: 'Seznam školení za rok',
    lessons_planned: 'Plánovaná školení',
    lessons_finished: 'Absolvovaná školení',
    lessons_canceled: 'Zrušená školení',
    lesson_canceled: 'Zrušené školení',
    lessons_draft: 'Rozpracováno',
    lessons_list: 'Seznam Školení',
    lessons_year: 'Seznam Školení za rok',
    lesson_new: 'Nové školení',
    lesson_new_title: 'Tvorba nového školení',
    lesson_update: 'Úprava školení',
    lesson_publish: 'Publikovat',
    lesson_date: 'Datum školení',
    lesson_time: 'Čas',
    lesson_duration: 'Délka trvání',
    lesson_title: 'Název školení',
    lesson_description: 'Popis školení',
    lesson_address: 'Místo konání školení',
    lesson_online: 'Školení formou webináře',
    lesson_online_: 'Webinář',
    lesson_teacher: 'Školitel',
    lesson_teacher_search: 'Výběr školitele',
    lesson_note: 'Interní poznámka (nebude zveřejněna)',
    lesson_data: 'Fotografie a popis školení',
    lesson_users: 'Seznam přihlášených',
    lesson_users_list: 'Seznam přihlášených',
    lesson_registered: 'Počet přihlášených',
    lesson_registered_free: 'Počet náhradníků',
    lesson_register: 'Přihlásit se na školení',
    lesson_register_reserve: 'Přihlásit se jako náhradník',
    lesson_register_cancel: 'Zrušit přihlášení',
    lesson_register_cancel_ask: 'Chcete zrušit přihlášku ke školení?',
    lesson_categories_label: 'Přidávání a úprava kategorii školení',
    lesson_categories: 'Kategorie',
    lesson_category: 'Kategorie',
    lesson_category_select: 'Tématická kategorie',
    lesson_category_new: 'Nová kategorie',
    lesson_capacity: 'Kapacita',
    lesson_capacity_reserve: 'Náhradníci',
    lesson_for_: 'Školení určené pro',
    lesson_for: ['', 'Kosmetičky', 'Lékárníci'],
    lesson_url: 'Link na aplikaci',
    lesson_youtube_link: 'Link na youtube video',
    lesson_video: 'Prohlédnout video',
    lesson_url_: 'Link na školení',
    lesson_url_note: 'Po otevření linku stiskněte "Otevřít v Safari"',
    lesson_register_to: 'Registrace do',
    lesson_points: 'Počet bodů',
    lesson_credits: 'Počet kreditů',
    lesson_cancel: 'Zrušit školení',
    lesson_cancel_label: 'Zrušení školení',
    lesson_cancel_ask: 'Chcete školení zrušit?',
    lesson_cancel_text: 'Dané školení bude označeno jako zrušeno.',
    lesson_copy: 'Kopírovat link',
    lesson_registered_label: 'Přihlášení ke školení',
    lesson_registered_subtext: 'Termín školení bude vložen do kalendáře',
    lesson_unregistered_label: 'Zrušení přihlášení ke školení',
    lesson_registered_ask: 'Chcete se závazně přihlásit ke školení?',
    lesson_registered_text: 'Byli jste úspěšně přihlášen ke školení.',
    lesson_registered_full: 'Dané školení je již obsazeno pro zadaný počet osob.',
    lesson_registered_user: 'Na školení jste přihlášen',
    lesson_registered_user_reserve: 'Ke školení jste přihlášen jako náhradník',
    lesson_registered_user_: 'Přihlášen',
    lesson_user_free: 'Náhradník',
    lesson_finished: 'Ukončené školení',
    lesson_not_absolved: 'Neabsolvoval',
    lesson_absolved: 'Absolvoval',
    lesson_absolved_count: 'Absolvoval počet',
    lesson_delete_label: 'Vymazat zákazníka',
    lesson_delete_ask: 'Chcete vymazat zákazníka ze školení?',
    lesson_delete_text: 'Zákazník bude označen statusem - neabsolvoval',
    lesson_add_user: 'Doplnit zákazníka na školení',
    lesson_typ: ['Publikováno', 'Rozpracováno', 'Zrušeno'],
    lessons_stats: 'Statistika školení',
    lessons_stats_text: 'Statistika školení podle jednotlivých ošetření',
    lessons_stats_absolved_month: 'Počet absolventů školení v jednotlivých měsících',
    lesson_meeting_id: 'Meeting ID',
    lesson_passcode: 'Passcode',
    lesson_count: 'Zadejte počet osob, které přihlašujete ke školení',
    lesson_count_change: 'Zadejte celkový počet osob, které se zúčastní školení',
    lesson_count_persons: 'Počet osob',
    lesson_count_persons_: 'v počtu osob',
    lesson_persons_edit: 'Upravit počet osob',
    lesson_services_count: 'Počet služeb',
    lesson_products_count: 'Počet produktů',

    lesson_link_copy_label: 'Zkopírování linku',
    lesson_link_copy_text_1: 'Link na školení byl zkopírován.',
    lesson_link_copy_text_2: 'Vložte zkopírovaný link do internetového prohlížeče.',
    lesson_required: 'Povinné školení',
    lesson_required_: 'Povinné',
    touch_to_copy: 'Klepnutím zkopírovat',

    // ==========================================================================================================================
    lang_emails: {
        do_not_reply: 'NEODPOVÍDAT',
        do_not_reply_text: 'Prosím, neodpovídejte na e-mail! Toto je automaticky generována zpráva systémem Esthederm Salon.',
        do_not_reply_text_: 'Prosím, neodpovídejte na tento email, na odpověď použijte emailovou adresu:',
    },

    faktury: 'Faktury',
    faktury_list: 'Seznam faktur',
    faktury_list_year: 'Seznam faktur vytvořených v roce',
    faktura: 'Faktura',
    faktura_new: 'Nová faktura',
    faktura_create: 'Vytvořit fakturu',
    faktura_cislo: 'Číslo faktury',
    faktura_odberatel: 'Odběratel',
    faktura_items: 'Položky faktury',
    faktura_item_name: 'Název položky',
    faktura_item_price: 'Jedn.cena',
    faktura_item_sum: 'Celkem',
    faktura_item_add: 'Přidat položku',
    faktura_item_new: 'Nová položka',
    faktura_item_edit: 'Úprava položky',
    faktura_item_deleted: 'Vymazaná položka',
    faktura_date: 'Datum vystavení faktury',
    faktura_address: 'Fakturační adresa',
    faktura_var_symbol: 'Variabilní symbol',
    faktura_created: 'Faktura je vytvořena',
    faktura_create_ask: 'Chcete vytvořit fakturu k dané objednávce?',
    faktura_create_note: 'Faktura bude uložena do seznamu faktur',
    faktura_sum_to_pay: 'Částka k úhradě',
    faktura_numbering: 'p.č.',
    faktura_date_to_pay: 'Datum splatnosti',
    faktura_date_: 'Datum vystavení',
    faktura_delete: 'Vymazat fakturu',
    faktura_delete_ask: 'Chcete vymazat danou fakturu?',
    faktura_data: 'Fakturační údaje',
    faktura_send: 'Zadejte e-mailovou adresu, na kterou bude faktura odeslána',
    faktura_error: 'Při vytváření faktury nastala chyba. Zkuste požadavek opakovat!',
    faktura_created_success: 'Faktura byla úspěšně vytvořena',
    faktura_created_success_note: 'Vytvořená faktura se nachází v seznamu faktur',

    email_of_client: 'e-mail zákazníka',
    email_mine: 'můj e-mail',
    email_reklamacie: 'Reklamační odd.',

    chat_agents: 'Komunikace',
    chat_agents_salon: 'mezi kosmetickým salonem a obchodním zástupcem',
    chat_agents_new: 'Zpráva pro obchodního zástupce',
    chat_agents_new_message: 'Nová zpráva',
    chat_agents_text: 'Text zprávy',
    chat_agents_salons: 'Zpráva pro všechny salony',
    chat_agents_salons_reps: 'zvoleného reprezentanta',

    select_period: 'Výběr období',

    lesson_change_status: 'Změnit status',
    lesson_change_status_yes: 'Změnit status na "Absolvoval"',
    lesson_change_status_no: 'Změnit status na "Neabsolvoval"',

    lessons_send_notify: 'Odeslat notifikace',
    lessons_send_notify_ask: 'Chcete odeslat notifikaci o nových školení?',
    lessons_send_notify_ask_note: 'Notifikace bude odeslána kosmetickým salonům s aplikací EsthedermSalon.',
    lessons_notify_sended: 'Notifikace byly úspěšně odeslány',
    lessons_notify: 'Notifikace o školeních',

    help_video: 'Video návod',
    help_video_sk: 'Video návod v slovenskom jazyku',

    video: 'Video',
    video_select: 'Výběr video záznamu',
    video_lesson: 'Video ze školení',
    video_lessons: 'Video ze školení',
    video_webinar: 'Video z webinářů',

    action_text: 'na základe objednávky máte nárok na',
    action_text_: 'na základě objednávky má salon nárok na',
    action_product_2: 'Letní plážová taška',
    action_product_1: 'Sun Sheen Tinted Powder',
    action_product_3: 'Lehce na cesty - balíček',
    action_product_add: 'Pro objednání je třeba produkty přidat do objednávky v daných množstvích!',

    bonus_missing: 'Chybějící kredit',
    bonus_left: 'Zbývající kredit',
    bonus_order_missing: 'Nedostatečné množství kreditu',
    bonus_order_missing_note: 'Kontaktujte svého obchodního zástupce, nebo upravte objednávku.',
    bonus_order_max: 'Max.počet objednaných kusů',
    bonus_order_limited: 'Limitovaná objednávka',    
    bonus_order_quantity_history: 'Předchozí objednávky',

    prices_without_dph: 'Uváděné částky jsou bez DPH',
    sum_selected_season: 'Částka za dané období',

    expirations: 'Expirace produktů',
    expirations_: 'Expirace',
    expiration: 'Expirace',
    expiration_month: 'Měsíc expirace',
    expiration_year: 'Rok expirace',
    expirations_in_year: 'Seznam expirací vytvořených v roce',
    expiration_new: 'Nová expirace',
    expiration_date: 'Datum expirace',
    expiration_date_error: 'Překročena minimální doba před expirací (3 měsíce)',
    expiration_date_error_: 'Překročena min. doba před expirací (3 měs.)',
    expiration_photo_date: 'Fotografie data expirace',
    expiration_photo_date_: 'Vyberte fotografii, která zachycuje datum expirace',
    expiration_photo_date_error: 'Chybí fotografie data expirace na produktu',
    expiration_photo_product: 'Fotografie produktu',
    expiration_photo_product_: 'Vyberte fotografii produktu',
    expiration_photo_product_error: 'Chybí fotografie produktu',
    expiration_status: 'Stav schvalování',
    expiration_statuses: ['Čeká ke schválení', 'Schváleno', 'Zamítnuto'],
    expiration_send: 'Odeslat ke schválení',
    expiration_send_ask: 'Požadavek bude odeslán obchodnímu zástupci',
    expiration_product_change: 'Změnit produkt',
    expiration_product_select: 'Výběr produktu expirace',
    expiration_deny: 'Zamítnutí expirace',
    expiration_accept: 'Schválení požadavku',
    expiration_accept_text: 'Chcete změnit stav požadavku na schválený?',
    expiration_deny_reason: 'Důvod zamítnutí',
    expiration_not_finished: 'Neukončené expirace',
    expiration_finished: 'Ukončené expirace',
    expiration_quantity: 'Počet kusů produktu',
    expiration_photos_missing: 'Chybějící fotografie data a výrobku',
    expiration_photos_missing1: 'Chybějící fotografie data',
    expiration_photos_missing2: 'Chybějící fotografie produktu',
}
