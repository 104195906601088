import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, FormError, GetDate, GetDate2, GetDate3, GetImageLink, GetPrice, GetTodayTime, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, TextInput, TextSearch, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faL, faLayerGroup, faList, faListAlt, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { Select, MenuItem } from '@mui/material';
import { GetProductColor } from './functions';


export const ProductSelect = (props) => {

    /*

        <ProductSelect note quantityOnly employee_id={props.employee_id} employees={props.employees} user={props.user} lang={lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius}  func={ProductSelectResult.bind(this)} />

        ---------------------------------------------------------
        note            -> pridať poznámku po výbere produktu
        quantityOnly    -> zadat len pocet kusov po výbere
        ---------------------------------------------------------

        const ProductSelectResult = (typ, item) => {
            if(typ == true){

            }
        }

    */

    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [showNote, setShowNote] = useState(false);
    const [overID, setOverID] = useState(-1);

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const menuWidth = 250;
    const rowHeight = props.isSmall ? 120 : 100;
    const rowWidth = dialogWidth - menuWidth - 20;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    default: props.order_id == undefined ? props.esthederm == undefined ? lang.products_my : undefined : undefined,
                    cabine: props.cabine == true ? lang.cabine : undefined,
                    action: lang.action,
                })
            })

            const json = await response.json();
            //setBusy(false);

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);
                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    order_id: props.order_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(json.categories);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'product_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                    cabine: props.cabine == true ? lang.cabine : undefined,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(false);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-99);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-99);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    /*
    const db_update = async (data) => {

        try {
            const response = await fetch(
                global.db_url + 'product_stock_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
        }
    }
    */

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSubCategoryID(0);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        var value = id;
        if (id != subCategoryID) {
            setSubCategoryID(id);
        } else {
            setSubCategoryID(0);
            value = 0;
        }
        Redraw(items, 0, search, value);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-10);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        if (items != false) {
            var data = items;
            if (search.trim() != '') {
                // filtrovanie podla hladaneho retazca
                //var words = search.split(' ');
                //data = items.filter(x => FindWords(x.label, x.keywords, words));
            } else {

                if (sub_category > 0) {
                    // filtrovanie vybratej kategórie
                    data = items.filter(x => parseInt(x.category_id) == sub_category);
                }

            }

            var count = data.length;
            count = Math.ceil(count / itemsMax);
            setPageMax(count);

            data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
            setItemsFiltered(data);

            if (count == 0) {
                setItemsFiltered(false);
            }

            setPage(p);
            GoToStart();
        } else {
            setItemsFiltered(false);
        }
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    /*
    const PlusMinusResult = (value, data) => {
        data.stock = value;
        db_update(data);
    }
    */

    const SelectItem = (item) => {
        if (props.note == true) {
            // PRIDANIE POZNAMKY K PRODUKTU
            var tmp = item;
            tmp.note = '';
            tmp.worker_id = props.employee_id;
            tmp.quantity = 0;
            tmp.score = 0;
            setItemSelected(tmp);
            setShowNote(true);
        } else {
            Press(true, item);
        }
    }

    const AddNoteResult = (typ, item) => {
        setShowNote(false);

        if (typ == true) {
            var data = itemSelected;
            data.note = item.note;
            data.date = item.date;
            data.eur = item.eur;
            data.quantity = item.quantity;
            data.stock_status = item.stock_status;
            data.stock = item.stock;
            data.worker_id = item.worker_id;
            data.score = item.score;
            data.points_add = item.points_add;

            Press(true, data);
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>

                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.product_select}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.products}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                        <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                        {/*
                        <TextField
                            value={search}
                            onInput={e => Search(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: props.isSmall ? 160 : 300 }}
                            placeholder={lang.search_in_category}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                            }}
                            variant="outlined"
                        />
                        */}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: dialogHeight - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >

                                    {categories != false ?
                                        categories.map((item) => (
                                            item.typ != 100 ?
                                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                : null
                                        ))
                                        : null}
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {categories != false ?
                                        categories.map((item) => (
                                            <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                                </div>
                                            </Button>
                                        ))
                                        : null}

                                </div>
                            </div>}

                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? dialogWidth : dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            {/* BODY */}
                            <div id={'id_products'}>
                                {subCategories != false ?
                                    subCategories.length > 1 ?
                                        <CategorySelect background={global.theme_back} width={props.isSmall ? dialogWidth : dialogWidth - menuWidth} items={subCategories} value={subCategoryID} func={CategorySelectResult.bind(this)} />
                                        : null
                                    : null}
                            </div>

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} onClick={() => SelectItem(item)} key={item.id} style={{ ...styles.Block, maxWidth: props.isSmall ? dialogWidth - 10 : rowWidth, height: rowHeight, backgroundColor: GetProductColor(item.background_color), marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div style={{ ...styles.Block, height: rowHeight, width: rowHeight - 20, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 30, height: rowHeight - 30, objectFit: 'contain', borderRadius: rowHeight - 20, marginLeft: 5 }}></img>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    {item.flag_id == 2 ?
                                                        <div style={{ ...styles.Block, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10 }}>
                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                        </div>
                                                        : null}
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{item.code}</p>
                                                    {props.user.id > 0 ?
                                                        <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{lang.stock}: {item.instock} {lang.pieces}</p>
                                                        : null}
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                        {item.flag_id == 2 ?
                                                            <div style={{ ...styles.Block, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, marginLeft: 10 }}>
                                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{item.code}</p>
                                                        {props.user.id > 0 ?
                                                            <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{lang.stock}: {item.instock} {lang.pieces}</p>
                                                            : null}
                                                        {item.note_short != '' && props.order_id == undefined ?
                                                            <div style={{ ...styles.BlockRow, marginTop: 5, height: 18, overflowY: 'hidden' }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.note_short}</p>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, height: rowHeight, width: 120, justifyContent: 'center' }}>
                                                {props.order_id == undefined ?
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(item.eur)} {lang.money}</p>
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 30, color: global.theme_dark_gray }} icon={faListAlt} />
                                    <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.products_none}</p>
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                                : null}

                        </div>
                    </div>
                </div>

                {isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null}

                {showNote == true ?
                    <AddProductNote isSmall={props.isSmall} theme={props.theme} stock quantityOnly={props.quantityOnly == true ? true : false} lang={lang} employees={props.employees} radius={props.radius} item={itemSelected} backcolor={true} background={true} func={AddNoteResult.bind(this)} />
                    : null}

            </Paper>
        </Backdrop>
    );
}



/*
   *
   * =================================================================================================================
   * PRIDAT POZNAMKU
   * =================================================================================================================
   *
   */

export const AddProductNote = (props) => {
    /*
            PRIDAT POZNAMKU K PRODUKTU - PRI VKLADANI DO KARTY ZAKAZNIKA
            
            USAGE:
            <AddProductNote item={serviceSelected} employees={props.employees} lang={lang} radius={props.radius} backcolor={true} background={false} func={AddProductNoteResult.bind(this)} />

            -----------------------------------------------------------------------------------------------------------------------
            delete  -> zobrazi tlačítko na vymazanie ošetrenie z karty zákazníka
            stock   -> zobrazí počet skladom
            -----------------------------------------------------------------------------------------------------------------------
            
            const AddProductNoteResult = (typ,item) => {
                console.log(item)
            }
    */

    const no_image = require('./react/app/no_image.jpg');

    const [error, setError] = useState(false);
    const [date, setDate] = useState(props.item.date != undefined ? GetDate3(props.item.date) : Today());
    const [note, setNote] = useState(props.item.note);
    const [price, setPrice] = useState(props.item.eur);
    const [quantity, setQuantity] = useState(props.item.quantity == 0 ? 1 : parseInt(props.item.quantity));
    const [employeeID, setEmployeeID] = useState(props.item.worker_id);
    const [showAskDelete, setShowAskDelete] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 950 ? 950 : width;
    const dialogHeight = props.isSmall ? height : props.quantityOnly == true ? 350 : 450;
    const titleHeight = 120;
    const errorHeight = 40;
    const buttonHeight = 80;
    const employeeWidth = 250;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);

    const Press = (typ, data) => {
        func(typ, data);
    }

    const Close = () => {
        Press(false);
    }

    const Process = () => {
        var stock_status = 0; // KOLKO BOLO ZOBRATYCH KUSOV ZO SKLADU
        var stock_add = 0; // VRATIT SPAT NA SKLAD (+), ALEBO ODPOCITAT ZO SKLADU (-) (pocet kusov) - AK JE EDITACIA
        var stock = parseInt(props.item.instock);
        var points = 0;
        var price_ = ConvertToPrice(price);

        if (props.item.quantity == 0) {
            // NOVA POLOZKA
            stock_status = props.item.instock >= quantity ? quantity : parseInt(props.item.instock);
            stock_add = 0 - stock_status;
            stock = stock + parseInt(stock_add);
            points = parseInt(parseInt(price_) * parseInt(quantity));

        } else {
            // EDITACIA POLOZKY
            var stock_add = parseInt(props.item.quantity) - quantity;

            stock_status = props.item.instock >= quantity - props.item.quantity ? quantity - props.item.quantity : parseInt(props.item.instock);
            stock_add = 0 - stock_status;
            stock = stock + parseInt(stock_add);
            stock_status = parseInt(props.item.stock_status) + stock_status;
            if (stock_status < 0) {
                stock += stock_status;
                stock_status = 0;
            }

            // VYPOCET BODOV
            points = (parseInt(price_) * parseInt(quantity)) - parseInt(props.item.score);
        }

        var data = {
            enabled: true,
            date: date + ' ' + GetTodayTime(),
            note: note,
            quantity: quantity,
            stock_status: stock_status,
            stock: stock,
            eur: price_,
            score: parseInt(quantity) * parseInt(price_),
            points_add: points,
            worker_id: employeeID,
        };

        Press(true, data);
    }

    const ProcessDelete = (value) => {
        // VYMAZANIE PRODUKTU Z KARTY
        setShowAskDelete(false);
        if (value == true) {
            var data = {
                enabled: false,
                stock: parseInt(props.item.stock_status) + parseInt(props.item.instock),
                points_add: 0 - parseInt(props.item.score),
                score: 0
            };

            Press(true, data);
        }
    }

    const PlusMinusResult = (value) => {
        setQuantity(value);
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: props.isSmall ? undefined : GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            <img src={props.item.image != '' && props.item.image != null ? GetImageLink(props.item.image) : no_image} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 20, maxHeight: titleHeight - 20, objectFit: 'contain', borderRadius: props.radius / 2 }}></img>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '50%' : dialogWidth - titleHeight - 100 - employeeWidth, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, height: titleHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.item.label}</p>
                            {props.stock == true ?
                                <p style={{ ...styles.TextTiny, marginLeft: props.isSmall ? 10 : 0, color: props.item.instock > 0 ? global.theme_black : global.theme_dark_red, marginTop: 5, fontWeight: props.item.stock > 0 ? '400' : '600' }}>{props.item.instock > 0 ? lang.stock + ': ' + props.item.instock + ' ' + lang.pieces : lang.no_stock}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 160 : employeeWidth, height: titleHeight }}>
                        {props.quantityOnly == false ? props.employees.length > 1 ?
                            <EmployeeSelect theme={props.theme} select small radius background={global.theme_lighter} width={props.isSmall ? 150 : employeeWidth} employees={props.employees} value={employeeID} func={(value) => setEmployeeID(value)} />
                            : null : null}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? '#FFFFFFB0' : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - titleHeight - errorHeight - buttonHeight, paddingTop: 20 }}>
                        {/* BODY */}
                        {props.quantityOnly == false ?
                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft }}>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <TextInput type={'date'} redraw enabled={true} lang={lang} value={date} label={lang.sell_data} func={(txt) => { setDate(txt) }} />
                                </div>
                            </div>
                            : null}
                        {props.quantityOnly == false ?
                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '60%' }}>
                                    <TextInput redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => { setNote(txt) }} />
                                </div>
                                <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '20%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                    <TextInput redraw money enabled={true} lang={lang} value={price} label={lang.price_dph + '/1' + lang.pieces} func={(txt) => { setPrice(txt) }} />
                                </div>
                                <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '20%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextInput, color: global.theme_dark_gray }}>{lang.quantity}</p>
                                    <PlusMinus no_wait min={1} value={quantity} func={PlusMinusResult.bind(this)} />
                                </div>
                            </div>
                            :
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.Block, width: props.isSmall ? 160 : 200 }}>
                                        <TextInput redraw money enabled={true} lang={lang} value={price} label={lang.price_dph + '/1' + lang.pieces} func={(txt) => { setPrice(txt) }} />
                                    </div>
                                    <div style={{ ...styles.Block, width: props.isSmall ? 160 : 220, marginLeft: 10 }}>
                                        <p style={{ ...styles.TextInput, color: global.theme_dark_gray }}>{lang.quantity}</p>
                                        <PlusMinus no_wait min={1} value={quantity} func={PlusMinusResult.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.BlockRowRaw, height: buttonHeight }}>
                            {props.delete == true ?
                                <Button onClick={() => setShowAskDelete(true)} style={{ ...styles.ButtonRed, width: 180, marginRight: 20 }}>{lang.card_delete}</Button>
                                : null}
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showAskDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.service_delete} sub_title={lang.service} text={lang.service_delete_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={(value) => ProcessDelete(value)} />
                : null}
        </Backdrop>
    );
}

