import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faUser, faImage, faBars, faInfoCircle, faTrash, faQuestionCircle, faLayerGroup, faCog, faList, faPlus, faAngleLeft, faEye, faCheckCircle, faWarning, faMinus, faEnvelope, faPaperPlane, faFile, faQuestion, faDesktop, faLock, faPrint, faExclamation, faExclamationTriangle, faShare, faXmarkCircle, faPallet, faPalette, faBookOpen, faPhone, faAt, faPersonMilitaryToPerson, faCircleInfo, faFilm, faVideo } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Collapse, Drawer, FormControlLabel, Grow, InputAdornment, Popper, useMediaQuery } from '@mui/material';
import { Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './items.css';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { Photos } from './photos.js';
import { PinCode } from './pin_code.js';

//import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

export const GetDPH = (language) => {
    var result = 1;
    if (language == 0) {
        result = 1.2;
    }
    if (language == 1) {
        result = 1.21;
    }

    return result;
}

export const Debug = (data) => {
    if (global.testing == true) {
        console.log(data);
    }
}


export const GetPages = (count, itemsMax) => {
    // PAGIMATION - výpočet počtu strán
    return Math.ceil(count / itemsMax);
}


export const CopyData = (source, dest, exclude) => {
    // SKOPIROVANIE VSYTKYCH KEYS Z OBJEKTU - source DO OBJEKTU - dest
    // exclude - array[id, ...] - ktory keys nebudú skopírované

    var keys = Object.keys(source);
    keys.forEach(key => {
        if (!exclude.includes(key)) {
            dest[key] = source[key];
        }
    });
}

export const DataToBinary = (data1, data2, data3, data4, data5, data6, data7, data8) => {
    // VYTVORI DECIMAL CISLO Z UDAJOV    
    var result = 0;

    let d1 = data1 == true ? 1 : 0;
    let d2 = data2 == true ? 1 : 0;
    let d3 = data3 == true ? 1 : 0;
    let d4 = data4 == true ? 1 : 0;
    let d5 = data5 == true ? 1 : 0;
    let d6 = data6 == true ? 1 : 0;
    let d7 = data7 == true ? 1 : 0;
    let d8 = data8 == true ? 1 : 0;

    result = d1 << 1 | d2;
    result = result << 1 | d3;
    result = result << 1 | d4;
    result = result << 1 | d5;
    result = result << 1 | d6;
    result = result << 1 | d7;
    result = result << 1 | d8;

    return result;
}

export const BinaryToData = (value) => {
    // Z BINARY CISLA VYTVORI ITEMS

    let d1 = (value & 0B10000000) == 0B10000000 ? true : false;
    let d2 = (value & 0B01000000) == 0B01000000 ? true : false;
    let d3 = (value & 0B00100000) == 0B00100000 ? true : false;
    let d4 = (value & 0B00010000) == 0B00010000 ? true : false;
    let d5 = (value & 0B00001000) == 0B00001000 ? true : false;
    let d6 = (value & 0B00000100) == 0B00000100 ? true : false;
    let d7 = (value & 0B00000010) == 0B00000010 ? true : false;
    let d8 = (value & 0B00000001) == 0B00000001 ? true : false;

    let data = {
        d1: d1,
        d2: d2,
        d3: d3,
        d4: d4,
        d5: d5,
        d6: d6,
        d7: d7,
        d8: d8,
    };

    return data;
}

export const BinaryToList = (value, lang) => {
    // Z BINARY CISLA VYTVORI ZOZNAM SPRACOVANYCH DAT GDPR ZAKAZNIKA
    var result = [];

    if (value > 0) {
        let d1 = (value & 0B10000000) == 0B10000000 ? true : false;
        let d2 = (value & 0B01000000) == 0B01000000 ? true : false;
        let d3 = (value & 0B00100000) == 0B00100000 ? true : false;
        let d4 = (value & 0B00010000) == 0B00010000 ? true : false;
        let d5 = (value & 0B00001000) == 0B00001000 ? true : false;
        let d6 = (value & 0B00000100) == 0B00000100 ? true : false;
        let d7 = (value & 0B00000010) == 0B00000010 ? true : false;
        let d8 = (value & 0B00000001) == 0B00000001 ? true : false;

        if (d1 == true) {
            // Osobne udaje
            result.push(lang.gdpr_settings_basic_email);
        }
        if (d2 == true) {
            // Poznámky
            result.push(lang.gdpr_settings_notes_email);
        }
        if (d3 == true) {
            // Galéria fotografii
            result.push(lang.gdpr_settings_gallery_email);
        }
        if (d4 == true) {
            // Diagnostika pleti
            result.push(lang.gdpr_settings_diagnostic_email);
        }
    } else {
        result.push(lang.gdpr_none);
    }

    return result;
}

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export function GetVersion(data) {
    // vráti aktualnu veriu aplikácie
    var tmp = parseInt(data);
    var number = parseInt(tmp / 1000);
    var decimal = parseInt(tmp % 1000);

    return number.toString().padStart(1, '0') + '.' + decimal.toString().padStart(3, '0');
}

export function GetTopPosition(height, dialogHeight) {
    // posun okna hore, ak je zapnuta klavesnica
    var offset = 0;

    if (height < 900) {
        offset = 0 - (height - dialogHeight) + global.topmenu_height * 2;
    }

    return offset;
}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');
    number = number.replaceAll(',', '.');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function notTime(time) {
    // nie je čas?
    var result = false;

    var patt = new RegExp("^(0?[1-9]|1[0-9]|2[0-4]):[0-5][0-9]$");
    var result = patt.test(time);
    /*
    if (! "([01]?[0-9]|2[0-3]):[0-5][0-9]".test(time)) {
        result = true;
    }
    */
    return !result;
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

export function strShuffle(s) {
    // tvorba náhodného kluca 
    var arr = s.split('');

    arr.sort(function () {
        return 0.5 - Math.random();
    });
    s = arr.join('');
    return s;
}


export function strShuffleCode(key, pass) {
    // tvorba kluca na základe hesla
    // key - kodovaci retazec
    // pass - heslo k zakodovaniu

    var n = 0;
    var sume = 0;
    while (n < pass.length) {
        sume += parseInt(pass.charCodeAt(n));
        n++;
    }
    sume = sume % 10;
    var offset = (sume * 4) % key.length;
    var code = key.substring(offset, key.length) + key.substring(0, offset);
    var arr = code.split('');

    n = 0;
    arr.sort(function () {
        let rand = pass.charCodeAt(n) % 10;
        n++;
        if (n == pass.length) {
            rand = sume;
            n = 0;
        }
        return rand - 5;
    });

    var str = arr.join('');
    return str;
}

export function GetTid(text) {
    var txt = '';
    if (text != null && text != undefined) {

        txt = text.trim();
        txt = text.toLowerCase();
        txt = txt.replaceAll('    ', '-');
        txt = txt.replaceAll('   ', '-');
        txt = txt.replaceAll('  ', '-');
        txt = txt.replaceAll(' ', '-');
        txt = txt.replaceAll('+', '');
        txt = txt.replaceAll('/', '');
        txt = txt.replaceAll('@', '');
        txt = txt.replaceAll('#', '');
        txt = txt.replaceAll('$', '');
        txt = txt.replaceAll('*', '');
        txt = txt.replaceAll('=', '');
        txt = txt.replaceAll(',', '');
        txt = txt.replaceAll('.', '');
        txt = txt.replaceAll('_', '-');

        txt = txt.replaceAll('ľ', 'l');
        txt = txt.replaceAll('š', 's');
        txt = txt.replaceAll('č', 'c');
        txt = txt.replaceAll('ť', 't');
        txt = txt.replaceAll('ž', 'z');
        txt = txt.replaceAll('ý', 'y');
        txt = txt.replaceAll('á', 'a');
        txt = txt.replaceAll('í', 'i');
        txt = txt.replaceAll('é', 'e');
        txt = txt.replaceAll('ú', 'u');
        txt = txt.replaceAll('ä', 'a');
        txt = txt.replaceAll('ô', 'o');
        txt = txt.replaceAll('ň', 'n');
        txt = txt.replaceAll('ř', 'r');
        txt = txt.replaceAll('ó', 'o');
        txt = txt.replaceAll('ď', 'd');
    }

    return txt;
}

export function GetDefaultDesktop(lang, typ) {
    var result = {};

    if (typ == global.account_salon) {
        // KOZMETICKY SALON

        let desktop = {
            calendar: true,
            todo: true,
            birthday: true,
            eshop: true,
            reserve: true
        };

        let shortcuts = [
            { id: 0, tid: 'stat_day', label: lang.sc_stats_day, value: false },
            { id: 1, tid: 'product_selling', label: lang.sc_selling, value: false },
            { id: 2, tid: 'product_booking', label: lang.sc_products_booking, value: false },
            { id: 3, tid: 'product_settings', label: lang.sc_products_settings, value: false },
            { id: 4, tid: 'bonus_booking', label: lang.sc_bonus_booking, value: false },
            { id: 5, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 6, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 7, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 8, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 9, tid: 'info_action', label: lang.sc_info_action, value: false },
            { id: 10, tid: 'web_settings', label: lang.sc_page_settings, value: false },
            { id: 11, tid: 'web_preview', label: lang.sc_page_web, value: false },
            { id: 12, tid: 'last_client', label: lang.sc_client_last, value: false },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    } else if (typ == global.account_medical) {
        let desktop = {
            todo: true,
        };

        let shortcuts = [
            { id: 2, tid: 'product_booking', label: lang.sc_products_booking, value: false },
            { id: 4, tid: 'bonus_booking', label: lang.sc_bonus_booking, value: false },
            { id: 5, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 6, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 7, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 8, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 9, tid: 'info_action', label: lang.sc_info_action, value: false },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    } else {
        // AGENT

        let desktop = {
            products: true,
            bonus: true,
            reklamacie: true,
            todo: true
        };

        let shortcuts = [
            { id: 0, tid: 'booking_products', label: lang.sc_salon_booking_products, value: true },
            { id: 1, tid: 'booking_bonus', label: lang.sc_salon_booking_bonus, value: true },
            { id: 2, tid: 'reklamacie', label: lang.reklamacie, value: true },
            { id: 9, tid: 'expiracie', label: lang.expirations_, value: true },
            { id: 3, tid: 'service_info', label: lang.sc_services_info, value: false },
            { id: 4, tid: 'product_info', label: lang.sc_products_info, value: false },
            { id: 5, tid: 'info_newsletter', label: lang.sc_info_newsletter, value: false },
            { id: 6, tid: 'info_cennik', label: lang.sc_info_cennik, value: false },
            { id: 7, tid: 'info_action', label: lang.sc_info_action, value: false },
            { id: 8, tid: 'last_salon', label: lang.sc_salon_last, value: true },
        ]

        result = { desktop: desktop, shortcuts: shortcuts };
    }

    return result;
}

export function GetPrice(price) {
    if (price != undefined) {
        var txt = price.toString().replace('.', ',');
    } else {
        txt = '';
    }
    return txt;
}

export function ConvertToPrice(price) {
    var txt = price.toString().replace(',', '.');
    txt = txt.trim();
    if (txt == '') {
        txt = '0';
    }
    txt = parseFloat(txt).toFixed(2);

    return txt;
}

/*
export const ConvertDate = (str) => {
    var date = '';

    if (str != undefined && str != null) {
        date = str.toString().replaceAll('-', '/');
    }

    return date;
}
*/

export const GetAge = (str_) => {
    var result = '';

    if (str_ != undefined && str_ != null) {
        var _date = str_.toString().replaceAll('-', '/');

        var date = new Date(_date);
        var diff_ms = Date.now() - date.getTime();
        var age_dt = new Date(diff_ms);
        result = Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    return result;
}

export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetTodayDatum = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}

export const GetTodayYearMonth = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m;

    return today;
}

export function AddDays(date_, days) {
    var result = new Date();
    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        result = new Date(date);

        result.setDate(result.getDate() + days);
    }

    return result;
}

export function dateAddMonths(date_, months) {

    var date = date_.toString().replaceAll('-', '/');
    let today = new Date(date);
    let futureDate = new Date(today);
    futureDate.setMonth(futureDate.getMonth() + months);

    var d = String(futureDate.getDate()).padStart(2, '0');
    var m = String(futureDate.getMonth() + 1).padStart(2, '0');
    var y = String(futureDate.getFullYear());

    return y + '-' + m + '-' + d;
}

export function DateSubMonths(date_, months) {

    var date = date_.toString().replaceAll('-', '/');
    let today = new Date();
    let futureDate = new Date(today);
    futureDate.setMonth(futureDate.getMonth() - months);

    var d = String(futureDate.getDate()).padStart(2, '0');
    var m = String(futureDate.getMonth() + 1).padStart(2, '0');
    var y = String(futureDate.getFullYear());

    return y + '-' + m + '-' + d;
}

export function SubDays(date_, days) {
    var result = new Date();

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        result = new Date(date);

        result.setDate(result.getDate() - days);
    }

    return result;
}


export const GetDateYear = (date_) => {
    var year = 2023;

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        var today = new Date(date);
        year = today.getFullYear();
    }

    return year;
}

export const GetDateMonth = (date_) => {
    var month = 1;
    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');

        var today = new Date(date);
        month = today.getMonth() + 1;
    }

    return month;
}

export const GetDateDay = (date_) => {
    var day = 1;

    if (date_ != null && date_ != undefined) {
        var date = date_.toString().replaceAll('-', '/');
        var today = new Date(date);
        day = today.getDate();
    }

    return day;
}

export const GetTodayYear = () => {
    return new Date().getFullYear();
}


export const GetTodayMonth = () => {
    var today = new Date();
    var month = today.getMonth();
    return month;
}

export const GetHoursFromMinutes = (minutes, lang) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    if (minute > 0) {
        if (minutes < 60) {
            hour = minute.toString().padStart(2, '0') + ' ' + lang.minutes;
        } else {
            hour = hour.toString() + ' ' + lang.hour_short + ' ' + minute.toString().padStart(2, '0') + ' ' + lang.minutes;
        }
    } else {
        hour = hour.toString() + ' ' + lang.hour_short;
    }
    return hour;
}

export const GetTodayTime = () => {
    // ------------------------------------
    // RETURN HH:MM:SS
    // ------------------------------------
    var today = new Date();
    var h = String(today.getHours()).padStart(2, '0');
    var m = String(today.getMinutes()).padStart(2, '0');
    var s = String(today.getSeconds()).padStart(2, '0');
    return h + ':' + m + ':' + s;
}


export const GetDatum = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY / DNES z dátumu
    // ------------------------------------
    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(Date.parse(str));

        var today = Today();

        if (GetDate3(date) != today) {
            var d = String(date.getDate()).padStart(2, '0');
            var m = months[date.getMonth()].toLowerCase();
            var y = String(date.getFullYear());
            result = d + '.' + m + ' ' + y;
        } else {
            result = months[12];
        }
    }

    return result;
}


export const GetDatum4 = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY HH:MM z dátumu
    // ------------------------------------
    var result = '';
    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);

        var d = String(date.getDate()).padStart(2, '0');
        var m = months[date.getMonth()].toLowerCase();
        var y = String(date.getFullYear());
        result = d + '.' + m + ' ' + y;
    }
    return result;
}

export const GetDatumTime = (months, str_) => {
    // ------------------------------------
    // RETURN DD.MESIAC YYYY HH:MM / DNES z dátumu
    // ------------------------------------

    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);
        var today = Today();

        if (GetDate3(date) != today) {
            var d = String(date.getDate()).padStart(2, '0');
            var m = months[date.getMonth()].toLowerCase();
            var y = String(date.getFullYear());
            var hour = String(date.getHours()).padStart(2, '0');
            var minutes = String(date.getMinutes()).padStart(2, '0');
            result = d + '.' + m + ' ' + y + ', ' + hour + ':' + minutes;
        } else {
            var hour = String(date.getHours()).padStart(2, '0');
            var minutes = String(date.getMinutes()).padStart(2, '0');
            result = months[12] + ', ' + hour + ':' + minutes;
        }
    }
    return result;
}

export const GetDatumTime2 = (str_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY HH:MM
    // ------------------------------------
    var result = '';

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        var date = new Date(str);

        var d = String(date.getDate()).padStart(2, '0');
        var m = String(date.getMonth() + 1).padStart(2, '0');
        var y = String(date.getFullYear());
        var hour = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        result = d + '.' + m + '.' + y + ', ' + hour + ':' + minutes;
    }
    return result;
}

export const GetTimeHM = (time_) => {
    // ------------------------------------
    // HH:MM
    // ------------------------------------

    var result = '';
    let time = time_.split(':');

    var hour = time[0];
    var minutes = time[1];
    result = hour + ':' + minutes;

    return result;
}

export const GetDatum2 = (date_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY z dátumu
    // ------------------------------------

    var d = '01';
    var m = '01';
    var y = '2023';

    if (date_ != null && date_ != undefined) {

        var date = date_.toString().replaceAll('-', '/');
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());
    }

    return d + '.' + m + '.' + y;
}


export const GetDateMinusDays = (str_, days) => {
    // ------------------------------------
    // ODPOCITA DEN Z DATUMU A VRATI NAJBLIZSI PRACOVNY DEN
    // ------------------------------------

    var today = new Date();

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate() - days).toString().padStart(2, '0');
        var m = String(today.getMonth() + 1).toString().padStart(2, '0');
        var y = String(today.getFullYear());

        today = y + '-' + m + '-' + d;
        var newToday = new Date(today);
        var dow = newToday.getDay();
        if (dow == 0) {
            // nedela
            var d = String(newToday.getDate() - 2).toString().padStart(2, '0');
            var m = String(newToday.getMonth() + 1).toString().padStart(2, '0');
            var y = String(newToday.getFullYear());
        }
        if (dow == 6) {
            // nedela
            var d = String(newToday.getDate() - 1).toString().padStart(2, '0');
            var m = String(newToday.getMonth() + 1).toString().padStart(2, '0');
            var y = String(newToday.getFullYear());
        }

        today = y + '-' + m + '-' + d;
    }

    return today;
}

export const GetDate = (str_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY z dátumu
    // ------------------------------------

    var today = new Date();

    if (str_ != null && str_ != undefined) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate());
        var m = String(today.getMonth() + 1);
        var y = String(today.getFullYear());
        today = d + '.' + m + '.' + y;
    }

    return today;
}


export const GetDate2 = (str) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD / YYYY-MM z dátumu
    // ------------------------------------

    var tmp = str.split('.');
    var date = null;
    if (tmp.length == 3) {
        var d = tmp[0].toString().padStart(2, '0');
        var m = tmp[1].toString().padStart(2, '0');;
        var y = tmp[2];
        date = y + '-' + m + '-' + d;
    }
    if (tmp.length == 2) {
        var m = tmp[0].toString().padStart(2, '0');;
        var y = tmp[1];
        date = y + '-' + m;
    }
    if (tmp.length == 1) {
        var y = tmp[0];
        date = y;
    }

    return date;
}

export const GetDate3 = (str_) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD z dátumu
    // ------------------------------------
    var today = '';
    if (str_ != undefined && str_ != null) {
        var str = str_.toString().replaceAll('-', '/');
        today = new Date(str);

        var d = String(today.getDate()).padStart(2, '0');
        var m = String(today.getMonth() + 1).padStart(2, '0');
        var y = String(today.getFullYear());
        today = y + '-' + m + '-' + d;
    }

    return today;
}


export const CreateDate = (year, month, day) => {
    // ------------------------------------
    // RETURN YYYY-MM-DD z Y,M,D
    // ------------------------------------
    var today = '';

    var d = String(day).padStart(2, '0');
    var m = String(month).padStart(2, '0');
    var y = String(year);

    today = y + '-' + m + '-' + d;

    return today;
}

export const GetHours = (minutes) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

export const GetMinutesFromHours = (time_) => {
    // vrati pocet minut z hodin
    var time = time_.split(':');
    return (parseInt(time[0]) * 60) + parseInt(time[1]);
}

export const GetVoucher = (client_points, points) => {
    var count = 0;

    count = parseInt(parseInt(client_points) / parseInt(points));
    return count;
}

export const GetVoucherPrice = (vouchers, price) => {
    var price_ = 0;

    price_ = ConvertToPrice(parseInt(vouchers) * parseFloat(price));
    return price_;
}

export function UniqueID() {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}

export function GetImageLink(path_) {
    var path = path_;

    if (global.testing == true) {
        path = path.replace('https://esthedermsalon.com', 'https://salony.datadream-test.sk');
    }
    return path.substring(0, 4) == 'http' ? path : global.web_images + "/" + path;
}

/*
*
* =================================================================================================================
* 
* =================================================================================================================
*
*/

export const Init = () => {

}

export const SaveBookingNote = (txt) => {
    localStorage.setItem('esthederm-salon-app-booking', txt);
}

export const LoadBookingNote = () => {
    var result = '';

    var txt = localStorage.getItem('esthederm-salon-app-booking');
    if (txt != null) {
        result = txt;
    }

    return result;
}

export const SaveBonusNote = (txt) => {
    localStorage.setItem('esthederm-salon-app-bonus', txt);
}

export const LoadBonusNote = () => {
    var result = '';

    var txt = localStorage.getItem('esthederm-salon-app-bonus');
    if (txt != null) {
        result = txt;
    }

    return result;
}

export const SaveProductsDisplay = (txt) => {
    localStorage.setItem('esthederm-salon-app-products-display', txt);
}

export const LoadProductsDisplay = () => {
    var result = 0;

    var txt = localStorage.getItem('esthederm-salon-app-products-display');
    if (txt != null) {
        result = txt;
    }

    return result;
}

// ********************************************************
// KRAJINA
// ********************************************************

export const SaveCountry = (id) => {
    localStorage.setItem('esthederm-salon-app-country', id);
}

export const LoadCountry = () => {
    var id = localStorage.getItem('esthederm-salon-app-country');

    if (id != null) {
        return parseInt(id);
    } else {
        return -1;
    }
}

// ********************************************************
// LANGUAGE
// ********************************************************

export const SaveLanguage = (lang) => {
    localStorage.setItem('esthederm-salon-app-language', lang);
}

export const LoadLanguage = () => {
    var lang = localStorage.getItem('esthederm-salon-app-language');
    if (lang != null) {
        var result = sk;

        if (lang == 0) {
            result = sk;
        }
        if (lang == 1) {
            result = cz;
        }
        return result;
    } else {
        return sk;
    }
}


export const GetLanguage = (lang) => {
    var result = sk;
    if (lang == 0) {
        result = sk;
    }
    if (lang == 1) {
        result = cz;
    }

    return result;
}

export function GetMoney(lang) {
    var result = sk.money;
    if (lang == 0) {
        result = sk.money;
    }
    if (lang == 1) {
        result = cz.money;
    }
    return (result);
};

export const GetCountry = (lang) => {
    var result = sk;
    if (lang == 0) {
        result = sk;
    }
    if (lang == 1) {
        result = cz;
    }

    return result.language;
}

export function FormatMoney(value) {
    var result = Math.round(value);
    result = result.toLocaleString('sk-SK')
    return (result);
};

// ********************************************************
// LOGIN TOKEN
// ********************************************************
export const ResetLogin = () => {
    var data = { token: '', expire: 0, version: 2.0 };
    SaveLogin(data);
    localStorage.removeItem('esthederm-salon-app-country');
}

export const LoadLogin = () => {
    var data = localStorage.getItem('esthederm-salon-app-login');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { token: '', date: 0, version: 2.0 };
    }
}

export const SaveLogin = (data) => {
    localStorage.setItem('esthederm-salon-app-login', JSON.stringify(data));
}
// ********************************************************


export const MenuTitle = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <MenuTitle func={MenuTitlePress.bind(this)} />

            const MenuTitlePress = (value) => {
                Debug(value)
            }

    */

    const [isBusy, setBusy] = useState(false);
    const [userPhoto, setUserPhoto] = useState(false);
    const [showTyp, setShowTyp] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');
    const avatar = require('./react/app/avatar.jpg');

    const avatar_size = 100;


    var lang = props.lang;
    let { func } = props;
    let color = global.themes[props.theme];
    var running = false;

    useEffect(() => {
        if (running == false) {
            if (props.user != false) {
                setUserPhoto(props.user.photo != '' && props.user.photo != null ? GetImageLink(props.user.photo) : avatar);
                setShowTyp(true);
            } else {
                setUserPhoto(false);
            }

            running = true;
        }
        return () => {
        };

    }, [props.user, props.appFocus]);

    const db_user_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                Press(1, data);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (type, data) => {
        func(type, data);
    }

    const PhotosResult = (typ, path, file) => {
        setShowPhoto(false);
        if (typ == true) {
            setUserPhoto(path + file);

            var data = {
                id: props.user.id,
                photo: path + file
            };

            db_user_update(data);
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ ...styles.BlockMenuTitle, backgroundColor: global.themes[props.theme].title_card, height: props.height - props.offset, marginTop: props.offset, borderRadius: props.radius }} >
            <div style={{ ...styles.Block, position: 'relative' }}>
                <div style={{ ...styles.Block, position: 'absolute', left: 0, top: 0, width: avatar_size + 10, height: avatar_size + 10, borderRadius: (avatar_size + 10) / 2, backgroundColor: '#FFFFFF77' }}></div>
                <div onDoubleClick={() => setShowPhoto(true)} style={{ ...styles.Block, position: 'absolute', left: avatar_size / 16, top: avatar_size / 16, width: avatar_size, height: avatar_size, borderRadius: avatar_size / 2 }}>
                    <img src={props.employeeSelected == false ? userPhoto != false ? userPhoto : avatar : props.employeeSelected.icon == 0 ? avatar_woman : props.employeeSelected.icon == 1 ? avatar_man : avatar} style={{ width: '100%', height: '100%', maxWidth: avatar_size - 10, maxHeight: avatar_size - 10, objectFit: 'contain', borderRadius: avatar_size / 2 }}></img>
                </div>
            </div>
            <div style={{ ...styles.Block, height: props.height - props.offset, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                {showTyp == true ? props.user.typ == global.account_agent ?
                    <p style={{ ...styles.TextTiny, marginBottom: 2, marginRight: 8, color: color.theme_dark }}>{props.user.id == global.admin_salon ? 'Admin' : props.admin == true ? lang.gm : lang.rep}</p>
                    : null : null}
                <p style={{ ...styles.TextSmall, marginBottom: props.user.typ == global.account_salon || props.user.typ == global.account_medical ? 0 : 4, marginRight: 8, color: props.appFocus == true ? global.theme_black : color.theme_dark }}>{props.employeeSelected == false ? props.name : props.employeeSelected.label}</p>
                {props.salon_name != '' ?
                    <p style={{ ...styles.TextTiny, marginBottom: 4, marginRight: 8, color: props.appFocus == true ? color.theme_dark : color.theme_dark, textAlign: 'left' }}>{props.salon_name}</p>
                    : null}
            </div>

            {showPhoto == true ?
                <Photos offset={props.offset} theme={props.theme} lang={props.lang} typ={0} max_size={600} width={600} height={600} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} func={PhotosResult.bind(this)} />
                : null}
        </Paper >
    )

};



export const Menu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

    */

    // OCHRANA PIN KODOM
    const [ID, setID] = useState(-1);
    const [showPin, setShowPin] = useState(false);

    // MENU ITEMS
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(props.menuID);

    const img_background = require('./react/app/background_menu2.jpg');
    const logo = require('./react/app/logo_large.png');
    const logo_size = 50;
    const icon_size = 40;

    let masterclass = props.masterclass;

    let { func } = props;

    useEffect(() => {
        setOpenAcc(props.menuID);
        return () => {
        };

    }, [props.language]);

    const Press = (type, item) => {
        if (type > 0) {
            setChildrenIndex(item.id);
        }
        func(type, item);
    }

    const SetOpenAcc = (item) => {
        if (item.id == 0) {
            setOpenAcc(-1);
            Press(0)
        } else {
            if (openAcc == item.id) {
                setOpenAcc(-1);
                func(999, -1);
            } else {
                if (item.protect == true && props.pinEnabled == true) {
                    setID(item);
                    setShowPin(true);
                } else {
                    setOpenAcc(item.id);
                    func(999, item);
                }
            }
            setChildrenIndex(-1);
        }
        GoToStart(item.id);
    }

    const PinResult = (typ, code) => {
        setShowPin(false);
        if (typ == true) {
            if (code == props.pin) {
                setOpenAcc(ID.id);
                func(999, ID);
            }
        }
    }

    const GoToStart = (id) => {
        Debug(id);
        if (id == 80 || id == 560) {
            setTimeout(() => {
                const element = document.getElementById('main-menu-id-' + id);

                if (element != null) {
                    element.scrollIntoView({ behavior: 'smooth', block: "end" },);
                }
            }, 300);
        }
    }

    const Submenu = (props) => {
        var children = props.item;

        return (
            <div style={{ width: '100%', marginBottom: 0 }}>
                {
                    /* ------------------------------------------------
                        SUBMENU - CHILDRENS
                    ------------------------------------------------ */
                }
                {children != null ?
                    children.map(item => {
                        return (
                            item.enabled == true ?
                                (props.isSmall == true && item.small != false) || props.isSmall != true ?
                                    item.admin == true && props.admin == true || item.admin == undefined ?
                                        item.masterclass == true && masterclass == true || item.masterclass == undefined ?
                                            item.divider == true ?
                                                <div key={item.id} style={{ ...styles.BlockLeft, marginTop: 8 }}>
                                                    <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_light, marginLeft: 20 }}>{item.name.toUpperCase()}</p>
                                                    <div style={{ ...styles.Block, height: 1, backgroundColor: '#FFFFFF30', marginTop: 2 }}></div>
                                                </div>
                                                :
                                                item.employee == true && props.employees.length > 1 || item.employee == undefined ?
                                                    <Button key={item.id} onClick={() => Press(1, item)} style={{ ...styles.ButtonMenu, width: props.isSmall ? '90%' : global.menu_width - 20, height: 40, background: childrenIndex == item.id ? global.themes[props.theme].theme_lighter : null }}>
                                                        <FontAwesomeIcon style={{ width: 8, height: 8, marginRight: 10, marginLeft: 15, color: childrenIndex == item.id ? global.theme_black : global.theme_light_gray }} icon={faCircle} />
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextTiny, color: childrenIndex == item.id ? global.theme_black : global.theme_white }}>
                                                                {item.name}
                                                            </p>
                                                        </div>
                                                    </Button >
                                                    : null : null : null : null : null
                        )
                    })
                    : null
                }

            </div >
        )
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ ...styles.BlockMenu, width: props.isSmall ? '100%' : global.menu_width, height: props.height, height: props.height - (2 * props.offset), marginTop: props.offset, marginBottom: props.offset, borderRadius: props.radius, backgroundImage: `url(${global.themes[props.theme].menu_image})`, backgroundSize: 'cover' }}>
            {/*
            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div onClick={() => Press(0)} style={{ ...styles.Block, width: logo_size * 2, height: logo_size + 5, cursor: 'pointer' }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size * 2, maxHeight: logo_size, marginTop: 0, objectFit: 'contain' }}></img>
                </div>
            </div>
            */}
            <div style={{ ...styles.BlockLeft, paddingTop: 10, height: props.height - (2 * props.offset) - 30, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {props.menuData != undefined ?
                    props.menuData.map((item, index) => (
                        item.enabled == true && (item.account == props.user.typ || item.account == 0) ?
                            <Accordion id={'main-menu-id-' + item.id} key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item)} style={{ ...styles.Block, width: '95%', marginTop: 10, backgroundColor: '#00000000', cursor: 'pointer' }}  >
                                <AccordionSummary style={{ ...styles.BlockRow, height: icon_size }}>
                                    <div style={{ ...styles.Block, width: icon_size, height: icon_size, borderRadius: 10, backgroundColor: item.icon == faCircle ? undefined : item.color, marginLeft: 10 }}>
                                        {item.icon != null ?
                                            <FontAwesomeIcon style={{ height: item.icon == faCircle ? 10 : icon_size / 2, color: item.icon == faCircle ? global.theme_white : item.icon_color }} icon={item.icon} />
                                            :
                                            <p style={{ ...styles.TextNormal }}>{item.name[0]}</p>
                                        }
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '90%', height: icon_size, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextNormal, color: global.theme_white, marginLeft: 10 }}>{item.name}</p>
                                    </div>
                                    {item.protect == true && props.pinEnabled == true ?
                                        <div style={{ ...styles.Block, width: 14, height: icon_size, }}>
                                            <FontAwesomeIcon style={{ height: 14, color: global.theme_white }} icon={faLock} />
                                        </div>
                                        : null}
                                </AccordionSummary>
                                <AccordionDetails style={{ ...styles.Block, width: props.isSmall ? props.width : '85%' }} >
                                    <Submenu theme={props.theme} employees={props.employees} isSmall={props.isSmall} item={item.children} menu_id={item.id} admin={props.admin} />
                                </AccordionDetails>
                            </Accordion>
                            : null
                    ))
                    : null
                }
            </div>


            {/* OCHRANA PIN KODOM */}
            {showPin == true ?
                <PinCode theme={props.theme} type={0} alert={true} pin={props.pin} lang={props.lang} radius={props.radius} title={props.lang.settings_pin_enter_label} func={PinResult.bind(this)} />
                : null}

        </Paper >
    )

};


export const MenuTopOld = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [showLogout, setShowLogout] = useState(false);

    const logo = require('./react/app/logo.png');

    let { func } = props;
    var lang = props.language;

    const BarHeight = 50;

    useEffect(() => {

        return () => {
        };

    }, [props.logged, props.isSmall]);

    const Press = (type, value, item) => {
        func(type, value);
    }

    const Add = () => {
        Press(1, null);
    }

    const Settings = () => {
        Press(2, null);
    }

    const Help = () => {
        Press(3, null);
    }

    return (
        <div style={{ ...styles.BlockRow, background: global.theme_titlebar_gradient, height: global.topmenu_height }}>

            <div style={{ ...styles.BlockLeft, width: global.menu_width + (2 * props.offset), height: global.topmenu_height, justifyContent: 'center' }}>
                {/*
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 100, maxHeight: global.topmenu_height - 10, marginLeft: props.offset, objectFit: 'contain' }}></img>
                */}
                <p style={{ ...styles.TextXLarge, color: global.theme_white, marginLeft: 20, fontWeight: '600' }}>Esthederm <span style={{ fontWeight: '300' }}>Salon</span></p>
            </div>

            <div style={{ ...styles.Block, width: props.width - 2 * (global.menu_width + (2 * props.offset)), justifyContent: 'center', alignItems: 'center', height: global.topmenu_height }}>
                {props.label != null && props.bodyID > 0 ?
                    <p style={{ ...styles.TextNormal, fontWeight: 400, color: global.theme_white, marginLeft: props.screenWidth > global.max_screen ? 1 * (global.menu_width + (2 * props.offset)) : 0 }}>{props.label}</p>
                    :
                    null
                }
            </div>

            <div style={{ ...styles.BlockRight, width: global.menu_width + (2 * props.offset), flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: global.topmenu_height }}>
                <div style={{ ...styles.BlockRowRaw, marginRight: 10, height: global.topmenu_height, alignItems: 'center' }}>
                    <IconButton onClick={() => Settings()} style={{ width: 26, height: 26, borderRadius: 17, backgroundColor: global.theme_lighter_blue }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faCog} />
                    </IconButton>
                    <IconButton onClick={() => Help()} style={{ width: 26, height: 26, borderRadius: 0, marginLeft: 30 }}>
                        <FontAwesomeIcon style={{ height: 26, color: global.theme_lighter_blue }} icon={faQuestionCircle} />
                    </IconButton>
                    <IconButton onClick={() => Add()} style={{ width: 34, height: 34, borderRadius: 18, marginLeft: 30, marginRight: 10, border: '2px solid ' + global.theme_white }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                    </IconButton>
                </div>
            </div>

        </div>
    )

}

export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [employeeID, setEmployeeID] = useState(props.employees.length > 1 ? props.employee_id : -1);
    const [country, setCountry] = useState(props.country);
    const [showLogout, setShowLogout] = useState(false);

    const imageSize = 30;
    const titleHeight = 50;
    const submenuHeight = 100;

    const logo = require('./react/app/logo.png');

    let { func } = props;
    var lang = props.lang;
    const navigate = useNavigate();

    const BarHeight = 50;

    useEffect(() => {

        setEmployeeID(props.employees.length > 1 ? props.employee_id : -1);
        setCountry(props.country);

        return () => {
        };

    }, [props.body_id, props.logged, props.isSmall, props.employee_id, props.employees, props.country]);

    const Press = (type, value, item) => {
        func(type, value, item);
    }

    const Add = () => {
        setShowMenu(false);
        Press(1, null);
    }

    const Settings = () => {
        setShowMenu(false);
        Press(2, null);
    }

    const ChangeTheme = () => {
        Press(8, null);
    }

    const Help = () => {
        setShowMenu(false);
        Press(3, null);
    }

    const OpenMenu = () => {
        setShowMenu(true);
    }

    const MenuPress = (typ, item) => {
        if (typ != 999) {
            setShowMenu(false);
        }
        Press(4, typ, item);
    }

    const EmployeeSelectResult = (value) => {
        setEmployeeID(value);
        Press(5, 0, value);
    }

    const CountryResult = (value) => {
        Press(7, 0, value);
    }

    const ChangeLanguage = () => {
        // ZMENA JAZYKA V TESTOVACIOM UCTE
        if (props.user.demo == true) {
            Press(6, 0, 0);
        }

        return false;
    }

    const Logout = (value) => {
        setShowLogout(false);
        if (value == true) {
            ResetLogin();
            navigate('/logout');
        }
    }

    return (
        <div style={{ ...styles.BlockRow, width: props.width, background: global.themes[props.theme].top_bar, height: global.topmenu_height }}>

            <div onDoubleClick={() => ChangeLanguage()} style={{ ...styles.BlockLeft, width: props.isSmall == true ? '70%' : global.menu_width + (2 * props.offset), height: global.topmenu_height, justifyContent: 'center', cursor: 'pointer' }}>
                <p style={{ ...styles.TextXLarge, color: global.theme_white, marginLeft: 20, fontWeight: '600' }}>Esthederm <span style={{ fontWeight: '300' }}>Salon</span ></p>
                {global.testing == true ?
                    <p style={{ ...styles.TextXTiny, marginLeft: 20, color: global.themes[props.theme].theme_light, textAlign: 'left' }}>v{GetVersion(props.version)}<span style={{ marginLeft: 10 }}>TEST, app verzia: {props.appVersion}</span></p>
                    :
                    props.user != false ? props.user.demo == true ?
                        <p style={{ ...styles.TextXTiny, marginLeft: 20, color: global.themes[props.theme].theme_light, textAlign: 'left' }}>v{GetVersion(props.version)}<span style={{ marginLeft: 10 }}>{props.user.id == global.admin_salon ? '(Admin)' : props.user.typ == global.account_salon ? '' : props.admin == true ? '(' + lang.gm + ')' : props.user.typ == global.account_medical ? '(' + lang.medical + ')' : '(' + lang.rep + ')'}</span></p>
                        :
                        props.isSmall ?
                            <p style={{ ...styles.TextXTiny, marginLeft: 20, color: global.themes[props.theme].theme_light, textAlign: 'left' }}>{props.user.name} {props.user.surname} {props.user.id == global.admin_salon ? '(Admin)' : props.user.typ == global.account_salon ? '' : props.admin == true ? '(' + lang.gm + ')' : '(' + lang.rep + ')'}</p>
                            :
                            <p style={{ ...styles.TextXXTiny, marginLeft: 20, color: global.themes[props.theme].theme_light, textAlign: 'left' }}>v{GetVersion(props.version)}</p>
                        : null}
            </div>

            {props.isSmall == false && props.bodyID > 0 ?
                <div style={{ ...styles.Block, width: props.body_id == 0 ? 0 : props.width - global.menu_width + (2 * props.offset) - global.menu_width, justifyContent: 'center', alignItems: 'center', height: global.topmenu_height }}>
                    {props.label != null ?
                        <p style={{ ...styles.TextNormal, fontWeight: 400, color: global.theme_white,/* marginLeft: props.screenWidth > global.max_screen ? 1 * (global.menu_width + (2 * props.offset)) : 0 */ }}>{props.label}</p>
                        : null}
                </div>
                : null}

            {
                props.isSmall == false && props.body_id == 0 ?
                    <div style={{ ...styles.Block, width: props.width - global.menu_width + (2 * props.offset) - global.menu_width, justifyContent: 'center', alignItems: 'center', height: global.topmenu_height }}>
                        {props.employees.length > 1 ?
                            <EmployeeSelect theme={props.theme} dark select small radius background={global.themes[props.theme].theme_lighter} width={global.menu_width} employees={props.employees} value={employeeID} func={EmployeeSelectResult.bind(this)} />
                            : null}
                        {props.user.typ == global.account_agent && props.body_id == 0 ?
                            <CountrySelect theme={props.theme} dark radius width={200} countries={props.countries} background={global.themes[props.theme].theme_lighter} value={country} lang={lang} func={CountryResult.bind(this)} />
                            : null}
                    </div>
                    : null
            }

            {
                props.isSmall == false ?
                    <div style={{ ...styles.BlockRight, width: global.menu_width, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: global.topmenu_height }}>
                        <div style={{ ...styles.BlockRowRaw, marginRight: 10, height: global.topmenu_height, alignItems: 'center' }}>
                            {props.user.typ == global.account_agent && props.body_id > 0 ?
                                <img src={props.country.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', marginRight: 30 }}></img>
                                : null}
                            {(props.user.typ == global.account_agent && props.body_id == 0) || (props.user.typ == global.account_salon || props.user.typ == global.account_medical) ?
                                <IconButton onClick={() => ChangeTheme()} style={{ width: 26, height: 26, borderRadius: 17, background: global.theme_rainbow, marginRight: 30 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPalette} />
                                </IconButton>
                                : null}

                            <IconButton onClick={() => Settings()} style={{ width: 26, height: 26, borderRadius: 17, backgroundColor: global.themes[props.theme].theme_light }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faCog} />
                            </IconButton>

                            <IconButton onClick={() => Help()} style={{ width: 26, height: 26, borderRadius: 17, marginLeft: 30, backgroundColor: global.themes[props.theme].theme_light }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faQuestion} />
                            </IconButton>
                            <IconButton disabled={props.body_id == 0 ? false : true} onClick={() => Add()} style={{ width: 34, height: 34, borderRadius: 18, marginLeft: 30, marginRight: 10, border: '2px solid ' + global.theme_white, opacity: props.body_id == 0 ? 1.0 : 0.15 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                            </IconButton>
                        </div>
                    </div>
                    : null
            }

            {
                props.isSmall ?
                    <div style={{ ...styles.BlockRight, width: 70, height: global.topmenu_height, justifyContent: 'center' }}>
                        {props.user.typ == global.account_agent && props.body_id == 0 ?
                            <CountrySelect tiny theme={props.theme} dark radius width={70} countries={props.countries} background={global.themes[props.theme].theme_lighter} value={country} lang={lang} func={CountryResult.bind(this)} />
                            : null}
                    </div>
                    : null
            }

            {
                props.isSmall ?
                    <div style={{ ...styles.Block, width: 70, height: global.topmenu_height, justifyContent: 'center' }} >
                        <IconButton onClick={() => OpenMenu()} style={{ width: 40, height: 40, backgroundColor: global.themes[props.theme].theme_darker }}>
                            <FontAwesomeIcon style={{ width: 16, color: global.theme_white }} icon={faBars} />
                        </IconButton>
                    </div>
                    : null
            }

            {
                showMenu == true ?
                    <Backdrop open={true} style={{ backgroundColor: global.themes[props.theme].theme_dark, zIndex: 999 }}>
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockRow, height: titleHeight, justifyContent: 'center', background: global.themes[props.theme].theme_darker, borderRadius: 0 }}>
                                <div style={{ ...styles.BlockLeft, width: '36%', height: titleHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextNormal, color: global.theme_white, marginLeft: 20, letterSpacing: 1 }}>{props.lang.menu_title}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 60, height: titleHeight, justifyContent: 'center' }}>
                                    <IconButton onClick={() => ChangeTheme()} style={{ width: 30, height: 30, borderRadius: 15, background: global.theme_rainbow }}>
                                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white }} icon={faPalette} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 60, height: titleHeight, justifyContent: 'center' }}>
                                    <IconButton onClick={() => Settings()} style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: global.themes[props.theme].theme_light }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faCog} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 60, height: titleHeight, justifyContent: 'center' }}>
                                    <IconButton onClick={() => Help()} style={{ width: 30, height: 30, borderRadius: 15 }}>
                                        <FontAwesomeIcon style={{ height: 30, color: global.themes[props.theme].theme_light }} icon={faQuestionCircle} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 60, height: titleHeight, justifyContent: 'center' }}>
                                    <IconButton onClick={() => Add()} style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid ' + global.theme_white }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 70, height: titleHeight, justifyContent: 'center' }}>
                                    <IconButton onClick={() => setShowMenu(false)} style={{ width: 36, height: 36 }}>
                                        <FontAwesomeIcon style={{ height: 24, color: global.theme_white }} icon={faXmark} />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockCenter, maxHeight: props.height - titleHeight, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...styles.Block, height: submenuHeight, background: global.themes[props.theme].theme_darker, borderTop: '1px solid ' + global.theme_black }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.Block, width: '60%' }}>
                                            {props.user != false ?
                                                <div style={{ ...styles.Block, width: '85%', alignItems: 'flex-start' }}>
                                                    <p style={{ ...styles.TextXTiny, color: global.themes[props.theme].theme_medium }}>{props.lang.login_user}:</p>
                                                    <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_light, marginTop: 2 }}>{props.user.name} {props.user.surname}</p>
                                                    {props.user.typ == global.account_salon || props.user.typ == global.account_medical ?
                                                        <p style={{ ...styles.TextXTiny, color: global.themes[props.theme].theme_medium, marginTop: 2, textAlign: 'left' }}>{props.user.salon_name}</p>
                                                        : null}
                                                </div>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: '40%' }}>
                                            <Button onClick={() => setShowLogout(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].theme_dark, width: 130 }}>
                                                <p style={{ ...styles.TextTinyMobile, color: global.theme_white }}>{lang.logout}</p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <Menu masterclass={props.masterclass} theme={props.theme} menuID={props.menuID} pinEnabled={props.pinEnabled} pin={props.pin} version={props.version} user={props.user} employees={props.employees} label={props.menuLabel} width={props.width - 20} offset={0} radius={0} isSmall={props.isSmall} body_id={props.bodyID} token={props.token} lang={props.lang} menuData={props.menuData} height={props.height - 40 - submenuHeight} func={MenuPress.bind(this)} />
                            </div>
                        </div>

                        {showLogout == true ?
                            <DialogYesNo theme={props.theme} radius={props.radius} title={lang.logout_label} sub_title={''} text={lang.logout_text} icon={faUser} yes={lang.yes} no={lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={Logout.bind(this)} />
                            : null}

                    </Backdrop>
                    : null
            }

        </div >
    )

};


export const Loading = (props) => {
    /*
    
        USAGE: 
            <Loading checkout screenWidth={props.screenWidth}  offset={props.offset} theme={props.theme}  side={menu_width} func={() => setBusy(false)}/>

    */

    const size = 46;
    const [showCancel, setShowCancel] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const logo = require('./react/app/logo_small.png');

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            if (props.checkout == true) {
                var timer = setTimeout(() => {
                    setShowCancel(true);
                }, 20000);
            }
        }

        return () => {
            clearTimeout(timer);
        };

    }, [props.lang]);

    const Cancel = () => {
        func(true);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', top: -100 }}>
            {showCancel == false ?
                <Paper elevation={5} style={{ ...styles.Block, marginLeft: (props.center == true) || (width <= global.max_screen) ? props.side == undefined ? 0 : props.side : global.menu_width + (2 * props.offset == undefined ? 16 : props.offset) + (props.side == undefined ? 0 : props.side - (size / 2)), width: size + 4, height: size + 4, backgroundColor: global.theme_blue, borderRadius: (size + 5) / 2 }}>
                    <img src={logo} style={{ marginTop: 0, width: size - 10, height: size - 10 }}></img>
                    <CircularProgress style={{ position: 'absolute', color: global.theme_light }} thickness={2} size={size} />
                </Paper>
                :
                <Paper elevation={5} style={{ ...styles.Block, marginLeft: (props.center == true) || (width <= global.max_screen) ? props.side == undefined ? 0 : props.side : global.menu_width + (2 * props.offset == undefined ? 16 : props.offset) + (props.side == undefined ? 0 : props.side - (size / 2)), width: size + 100, height: size + 100, backgroundColor: global.theme_light_blue, borderRadius: (size + 5) / 2 }}>
                    <div style={{ ...styles.BlockRight, height: 50 }}>
                        <IconButton onClick={() => Cancel()} style={{ marginRight: 5, marginTop: 5, width: size - 10, height: size - 10, borderRadius: (size - 10) / 2, backgroundColor: global.theme_black }}>
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faXmark} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, position: 'relative', height: 100 + size }}>
                        <img src={logo} style={{ marginTop: 0, width: size, height: size, marginBottom: 35 }}></img>
                        <CircularProgress style={{ position: 'absolute', color: global.theme_white, marginBottom: 35 }} thickness={2} size={size + 20} />
                    </div>
                </Paper>
            }
        </Backdrop>);
}



export const DialogPasswordChange = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogPasswordChange radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogPasswordChangeResult.bind(this)} />

            const DialogPasswordChangeResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [password, setPassword] = useState({ old: '', new1: '', new2: '' });
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 400;
    const dialogHeight = 500;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_password_change = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'password_change', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user_id,
                    password: password.old,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.check == false) {
                    setError(lang.password_old_err);
                } else {
                    if (json.changed == false) {
                        setShowError(true);
                    } else {
                        setShowOK(true);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func();
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press();
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {

        var pw1 = password.new1.trim();
        var pw2 = password.new2.trim();
        setError('');

        if (pw1 != '' && pw2 != '') {
            if (pw1.length < 8) {
                setError(lang.password_error_len);
            } else if (pw1 != pw2) {
                setError(lang.passwords_not_equal);
            } else {

                var data = {
                    password: password.new1
                }

                db_password_change(data);
            }
        }
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ ...styles.Block, width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '80%', height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block }}>
                            <TextInput password lang={props.lang} value={password.old} label={lang.password_old} func={(txt) => { password.old = txt }} />
                        </div>
                        <div style={{ ...styles.Block, marginTop: rowOffset * 2 }}>
                            <TextInput password lang={props.lang} value={password.new1} label={lang.password_new} func={(txt) => { password.new1 = txt }} />
                        </div>
                        <div style={{ ...styles.Block, marginTop: rowOffset }}>
                            <TextInput password lang={props.lang} value={password.new2} label={lang.password_new_again} func={(txt) => { password.new2 = txt }} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}  ></Loading>
                : null}

        </Backdrop>
    );
}


export const DialogYesNo = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
    */

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = props.sub_text == '' && (props.warning_text == undefined || props.warning_text == '') ? 280 : 330;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        {props.hideClose == true ? null :
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        }
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5 }}>{props.sub_text}</p>
                            : null}
                        {props.warning_text != undefined ?
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, textAlign: 'center', marginTop: 12, fontWeight: '500' }}>{props.warning_text}</p>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color == undefined ? global.themes[props.theme].button_dark : props.yes_color }}>{props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color == undefined ? global.themes[props.theme].button_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}


export const DialogWarningYesNo = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogWarningYesNo center red confirm radius={props.radius} lang={lang} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center  -> v strede
            ------------------------------------------------------------
            
            const DialogWarningYesNoResult = (value) => {
                console.log(value)
            }
    */
    const [showConfirm, setShowConfirm] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = 360;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    const Process = () => {
        if (showConfirm == false && props.confirm == true) {
            setShowConfirm(true)
        } else {
            Press(true);
        }
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.red == true ? '#400000F0' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {showConfirm == false ?
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100 }}>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <FontAwesomeIcon style={{ height: 35, color: global.theme_dark_red }} icon={faExclamationTriangle} />
                                <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'center' }}>{props.text}</p>
                                {props.sub_text != undefined ?
                                    <p style={{ ...styles.TextNormal, textAlign: 'center', marginTop: 15, fontWeight: '600' }}>{props.sub_text}</p>
                                    : null}
                                {props.sub_text_2 != undefined ?
                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 15 }}>{props.sub_text_2}</p>
                                    : null}
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: showConfirm == true ? global.theme_black : undefined }}>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                <p style={{ ...styles.TextNormal, color: global.theme_white, marginTop: 0, textAlign: 'center' }}>{props.lang.confirmation_needed}</p>
                                {props.sub_text != undefined ?
                                    <p style={{ ...styles.TextNormal, color: global.theme_white, textAlign: 'center', marginTop: 15, fontWeight: '600' }}>{props.sub_text}</p>
                                    : null}
                            </div>
                        </div>

                    }
                    <div style={{ ...styles.BlockRow, height: 100 }}>
                        <div style={{ ...styles.Block, height: 100 }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color }}>{props.yes}</Button>
                        </div>
                        <div style={{ ...styles.Block, height: 100 }}>
                            <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color }}>{props.no}</Button>
                        </div>
                    </div>
                </div>

            </Paper>
        </Backdrop>
    );
}

export const DialogInfo = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogInfo radius={props.radius} title={'Informácia'} sub_title={''} text={'Text informácie'} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} theme={props.theme} func={DialogInfoResult.bind(this)} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
    */

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 650;
    const dialogHeight = props.sub_text != undefined ? 320 : 300;

    let { func } = props;
    let color = global.themes[props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: color.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: color.icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {props.icon_show != undefined ?
                        <FontAwesomeIcon style={{ height: 40, color: props.icon_color, marginBottom: 20 }} icon={props.icon_show} />
                        : null}
                    <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'center' }}>{props.sub_text}</p>
                        : null}
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const DialogEnterText = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogEnterText rows={1} radius={props.radius} title={'Informácia'} sub_title={''} text={'Text informácie'} icon={faQuestion} button={props.lang.close} theme={props.theme} func={DialogEnterTextResult.bind(this)} />
 
            --------------------------------------------------------
            rows    - pocet riadkov
            button1   -> predefinovaný text v tlacitku 1
            button2   -> predefinovaný text v tlacitku 2
            --------------------------------------------------------

            const DialogEnterTextResult = (value) => {
                console.log(value)
            }
    */
    const [text, setText] = useState(props.text);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = props.rows == 1 ? props.button1 != undefined ? 300 : 250 : props.button1 != undefined ? 450 : 400;

    let { func } = props;
    const inputRef = useRef(null);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    var running = false;
    useEffect(() => {

        // FOCUS TO TEXTAREA
        if (props.button1 == undefined) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);
        }


        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value, text);
    }

    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: 50, marginBottom: 10 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {props.button1 != undefined ?
                                <div style={{ ...styles.Block, width: isSmall ? 160 : 200, height: 50 }}>
                                    <Button onClick={() => setText(props.button1_text)} style={{ ...styles.ButtonGray, backgroundColor: global.theme_white, width: '100%' }}>{props.button1}</Button>
                                </div>
                                : null}
                            {props.button2 != undefined ?
                                <div style={{ ...styles.Block, width: isSmall ? 160 : 200, height: 50, marginLeft: 20 }}>
                                    <Button onClick={() => setText(props.button2_text)} style={{ ...styles.ButtonGray, backgroundColor: global.theme_white, width: '100%' }}>{props.button2}</Button>
                                </div>
                                : null}

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <TextField
                            inputRef={inputRef}
                            value={text}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            multiline={props.rows > 1 ? true : false}
                            rows={props.rows}
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                style: { backgroundColor: global.theme_white, borderRadius: 20 },
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const DialogEnterNumber = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogEnterNumber radius={props.radius} title={'Informácia'} sub_title={''} value={0} min={0} max={100} icon={faQuestion} button={props.lang.close} func={EnterNumberResult.bind(this)} />
 
            --------------------------------------------------------
            rows    - pocet riadkov

            --------------------------------------------------------

            const EnterNumberResult = (typ, value) => {
                console.log(value)
            }
    */
    const [value, setValue] = useState(props.value);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 250;

    let { func } = props;

    useEffect(() => {


        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ, value);
    }

    const PlusMinusResult = (value_) => {
        setValue(value_);
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '98%' }}>
                        <PlusMinus min={props.min} value={value} func={PlusMinusResult.bind(this)} />
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop>
    );
}

export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);


            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme}  func={ShowOKPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: (props.center == true) || (width <= global.max_screen) ? props.side == undefined ? 0 : props.side : global.menu_width + (2 * props.offset == undefined ? 16 : props.offset) + (props.side == undefined ? 0 : props.side - (60 / 2)), top: -100 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 60, height: 60, backgroundColor: global.theme_darker_green, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_white }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const ShowError = (props) => {
    /*
            USAGE:
            const [showError, setShowError] = useState(false);
            setShowError(true);

            const ShowErrorPress = (value) => {                
                setShowError(false);
            }

            {showError == true ?
                <ShowError text={'Chyba'} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 4000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: width > global.max_screen ? props.center == true ? 0 : global.menu_width : 0 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 320, backgroundColor: global.theme_black, borderRadius: 16, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: global.theme_dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: global.theme_white }} icon={props.icon == undefined ? faXmark : props.icon} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginTop: 15 }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 8 }}>{props.sub_text}</p>
                        : null}
                </div>
            </Paper>
        </Backdrop >
    );
}


export const ShowTokenError = (props) => {
    /*
            USAGE:
            const [showTokenError, setShowTokenError] = useState(false);
            setShowTokenError(true);

            {showTokenError == true ?
                <ShowTokenError center remoteApp={props.remoteApp} text={lang.token_error} sub_text={lang.token_error_text} func={TokenErrorPress.bind(this)} />
                : null}

            const TokenErrorPress = () => {                
                setShowTokenError(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 3000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: width > global.max_screen ? props.center == true ? 0 : global.menu_width : 0 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 400, height: 150, backgroundColor: global.theme_black, borderRadius: 16 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: global.theme_dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: global.theme_white }} icon={faXmark} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextNormal, color: global.theme_white, marginTop: 15 }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3 }}>{props.sub_text}</p>
                        : null}
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogLogin = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogLogin lang={props.lang} radius={props.radius} func={DialogLoginResult.bind(this)} />

            const DialogLoginResult = (user) => {
                console.log(user)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [codeSystem, setCodeSystem] = useState('');
    const [userID, setUserID] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const logo = require('./react/app/logo_vision.png');
    const logo_size = 150;

    const dialogWidth = 350;
    const dialogHeight = 480;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const db_login = async () => {
        // Prihlásenie sa
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: username,
                        password: password
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    if (json.login == true) {
                        // VSETKO OK
                        Press(json.user, json.token);
                    } else {
                        // ZLE PRIHLASOVACIE UDAJE
                        setError(props.lang.login_error);
                    }
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_recover = async () => {
        // obnova hesla - kontrola e-mailovej adresy
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login_recover', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,

                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    if (json.checked == true) {
                        setCodeSystem(json.code);
                        setUserID(json.user_id);
                        setIndex(2);
                    } else {
                        setError(props.lang.lost_login_error);
                    }

                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_recover_update = async (data) => {
        // zmena hesla
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login_recover_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    // predvyplnit prihlasovací dialog s novym heslom
                    setUsername(email);
                    setIndex(4);
                } else {
                    setShowError(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    const Press = (user, token) => {
        func(user, token);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }


    const Login = () => {
        setError('');
        if (username.trim() != '' && password.trim() != '') {
            db_login();
        }
    }

    const Home = () => {
        setError('');
        setUsername('');
        setPassword('');
        setIndex(0);
    }


    const Forgot = () => {
        setError('');
        setEmail('');
        setPassword('');
        setPassword2('');
        setCode('');
        setIndex(1);
    }

    const ForgotProcess = () => {
        // KONTROLA EMAILOVEJ ADRESY
        setError('');
        if (email.trim != '') {
            db_recover();
        }
    }

    const ForgotCheckCode = () => {
        // KONTROLA ZADANEHO KODU
        setError('');
        if (codeSystem == code.trim()) {
            setIndex(3);
        } else {
            setError(props.lang.login_pin_code_error);
        }
    }

    const ChangePassword = () => {
        // ULOZENIE NOVEHO HESLA
        setError('');
        var p1 = password.trim();
        var p2 = password2.trim();

        if (p1.length < 8) {
            setError(props.lang.password_error_len);
        } else if (p1 != p2) {
            setError(props.lang.passwords_not_equal);
        } else {
            var data = {
                id: userID,
                password: p1
            }
            db_recover_update(data);
        }
    }

    return (

        <Backdrop open={true} style={{ backgroundColor: '#000000FF' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, position: 'relative', height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>

                <Paper elevation={6} style={{ ...styles.Block, position: 'relative', left: dialogWidth / 2 - ((logo_size + 0) / 2), width: logo_size, top: -(logo_size) / 2, height: logo_size, backgroundColor: global.theme_white, borderRadius: (logo_size + 40) / 2 }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size, objectFit: 'contain', marginTop: 0 }}></img>
                </Paper>

                {index == 0 ?
                    // PRIHLASENIE
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.login.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 30 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.loginname}</p>
                            <TextField
                                value={username}
                                onInput={e => setUsername(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} style={{ width: 20, height: 20 }}>
                                            <FontAwesomeIcon style={{ height: 14, color: showPassword == true ? global.theme_dark_gray : global.theme_light_gray }} icon={faEye} />
                                        </IconButton>
                                    </InputAdornment>,
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type={showPassword == true ? 'text' : 'password'}
                            />
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => Forgot()} style={{ ...styles.ButtonLink, width: 200 }}>{props.lang.lost_password_button}</Button>
                        </div>
                        <div style={{ ...styles.Block, height: 40 }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_red }}>{error}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Button onClick={() => Login()} style={{ ...styles.ButtonDark }}>{props.lang.login_button}</Button>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <Button onClick={() => Press(false, '')} style={{ ...styles.ButtonLink }}>{props.lang.close}</Button>
                        </div>

                    </div>
                    : null}

                {index == 1 ?
                    // ZABUDNUTE HESLO
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 80 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.login_email}</p>
                            <TextField
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotProcess()} style={{ ...styles.ButtonDark }}>{props.lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 2 ?
                    // VLOZENIE PIN KODU
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '95%', height: 40 }}>
                            <p style={{ ...styles.TextTiny, marginTop: 10 }}>{props.lang.login_email_send}</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.login_pin_code}</p>
                            <TextField
                                value={code}
                                onInput={e => setCode(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ForgotCheckCode()} style={{ ...styles.ButtonDark }}>{props.lang.continue}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 3 ?
                    // ZADANIE NOVEHO HESLA
                    <div style={{ ...styles.BlockCenter, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <p style={{ ...styles.TextXLarge, letterSpacing: 2, fontWeight: '500', marginTop: 20 }}>{props.lang.lost_password.toUpperCase()}</p>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password_new}</p>
                            <TextField
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 20 }}>
                            <p style={{ ...styles.TextInput }}>{props.lang.password_new_again}</p>
                            <TextField
                                value={password2}
                                onInput={e => setPassword2(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '100%' }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                }}
                                variant="outlined"
                                type='password'
                            />
                        </div>

                        <div style={{ ...styles.Block, height: 60 }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_red }}>{error}</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: 50 }}>
                                    <IconButton onClick={() => Home()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleLeft} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 200 }}>
                                    <Button onClick={() => ChangePassword()} style={{ ...styles.ButtonDark }}>{props.lang.save}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: 50 }}></div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 4 ?
                    // HESLO BOLO ZMENENE
                    <div style={{ ...styles.Block, position: 'absolute', top: (logo_size + 40) / 2, height: dialogHeight - ((logo_size + 40) / 2) }}>
                        <FontAwesomeIcon style={{ height: 30, color: global.theme_darker_green }} icon={faCheckCircle} />
                        <p style={{ ...styles.TextNormal, marginTop: 20 }}>{props.lang.password_ok}</p>

                        <div style={{ ...styles.Block, width: '90%', marginTop: 50 }}>
                            <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonDark }}>{props.lang.continue}</Button>
                        </div>
                    </div>
                    : null}

            </Paper>

            {isBusy == true ?
                <Loading theme={props.theme} center={true}></Loading>
                : null}

            {showError == true ?
                <ShowError theme={props.theme} center={true} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

        </Backdrop>
    );
}


export const TextInput = (props) => {
    /* USAGE        
        
        const [userData, setUserData] = useState({mobil: ''});

        <TextInput required redraw enabled={true} lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />

        required    -> text je vyzadovany
        not_zero    -> nesie byt nulovy
        time        -> vklad casu
        outline     -> ak je chyba, zobrazí sa cervený ramik bez textu
        phone       -> jedna sa o tel. cislo v medzinárodnom formate
        email       -> kontroluje správnost e-mailovej adresy
        enabled     -> editovanie true / false
        label       -> place holder
        redraw      -> text bude prekreslovaný pri zmene iných textových polí - setRedraw(!redraw)
        type        -> default = 'text', 'date'
        placeholder -> placeholder text
        money       -> zobrazi menu na konci textboxu
        money_text  -> text na konci textboxu (undefined == money)
        width       -> širka boxu
        center      -> vycentrovať titulok aj box
        numeric     -> zadávanie len číslic [0-9]
        rows        -> pocet riadkov
    */


    const [text, setText] = useState(props.value);
    const [error, setError] = useState('');

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        Check(props.value);
        if (props.redraw != undefined) {
            setText(props.value);
        }

        return () => {
        };

    }, [props.value]);

    const ChangeText = (txt) => {
        if (props.numeric == true) {
            if (/^[0123456789:,.]+$/.test(txt)) {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
            if (txt == '') {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
        } else {
            setText(txt);
            Check(txt);
            func(txt);
        }
    }

    const Check = (text) => {
        if (error != '') {
            setError('');
        }
        if (text != undefined) {
            if (props.phone) {
                if (text.trim() != '') {
                    if (text[0] != '+') {
                        setError(lang.format_error);
                    }
                }
            }

            if (props.numeric) {
                if (props.not_zero) {
                    if (text.trim() != '') {
                        if (parseInt(text) == 0) {
                            setError(lang.non_zero_error);
                        }
                    }
                }
            }

            if (props.email) {
                if (text.trim() != '') {
                    if (nonEmail(text.trim()) == true)
                        setError(lang.format_error);
                }
            }
        }
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 12, textAlign: 'left' }}>{props.label}</p>

            <TextField
                inputRef={props.inputRef}
                value={text}
                //onBlur={() => Check(text)}
                onInput={e => ChangeText(e.target.value)}
                size="small"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                style={{ width: props.width == undefined ? '100%' : props.width }}
                placeholder={props.placeholder == undefined ? '' : props.placeholder}
                multiline={props.rows != undefined ? true : false}
                rows={props.rows != undefined ? props.rows : 1}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                    }
                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                    fieldset: { borderWidth: props.enabled == false ? 0 : 1, borderColor: error != '' ? global.theme_red : global.theme_dark_gray }
                }}
                InputProps={{
                    endAdornment: props.money == true ? <InputAdornment position="end">
                        <p style={{ ...styles.TextSmall }}>{props.money_text == undefined ? lang.money : props.money_text}</p>
                    </InputAdornment> : null,
                    style: { backgroundColor: props.enabled == false ? '#00000007' : global.theme_white, borderRadius: 20, color: global.theme_black },
                }}
                type={props.date == true ? 'date' : props.password == true ? 'password' : props.time == true ? 'time' : props.type != undefined ? props.type : 'text'}
                variant="outlined"
                disabled={props.enabled == false ? true : false}
                error={error != '' ? true : props.required == true ? text == '' ? true : false : false}
                helperText={props.enabled == false ? '' : error != '' ? error : props.required == true ? text == '' ? props.outline == true ? '' : lang.required : '' : ''}
                FormHelperTextProps={{ style: { color: global.theme_dark_red, margin: 0, padding: 0, marginLeft: 12, fontSize: 12 } }}
            />
        </div>
    );
}


export const FormError = (props) => {
    /*

        <FormError error={error} small />
        -----------------------------------------------------
        small   -> menšia verzia
        margin  -> marginfrom top
    */
    const height = props.small == true ? 28 : 40;
    return (
        props.error != '' ?
            <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', height: height, backgroundColor: props.backgroundColor == undefined ? global.theme_light_red : props.backgroundColor, borderRadius: 20, marginTop: props.margin == undefined ? 20 : props.margin }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    {props.isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white, marginLeft: 15 }} icon={faWarning} />
                    }
                    <p style={{ ...props.small == true ? props.isSmall ? styles.TextXTiny : styles.TextTiny : styles.TextSmall, color: global.theme_white, marginLeft: 10, marginRight: 15 }}>{props.error}</p>
                </div>
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}

export const FormOK = (props) => {
    /*
        <FormOK text={'text'} small />
        -----------------------------------------------------
        small       -> menšia verzia
        sub_text    -> pod text
        color       -> farba pozadia
    */

    const height = props.small == true ? 28 : props.sub_text == undefined ? 40 : 46;
    return (
        props.text != '' ?
            <div style={{ ...styles.Block, height: height, backgroundColor: props.color == undefined ? global.theme_darker_green : props.color, borderRadius: height / 2, marginTop: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <FontAwesomeIcon style={{ height: 18, color: global.theme_white }} icon={faInfoCircle} />
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{props.text}</p>
                </div>
                {props.sub_text != undefined ?
                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{props.sub_text}</p>
                    : null}
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}


export const DialogTemplate = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogTemplate lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogResult.bind(this)} />

            const DialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 700;
    const dialogHeight = 500;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Process = () => {

    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        {/* BODY */}

                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}



export const DialogEmployee = (props) => {
    /*
            ZAMESTNANEC - údaje

            USAGE:
            <DialogEmployee radius={props.radius} func={DialogEmployeeResult.bind(this)} />

            --------------------------------------------------------------------------------------------------
            small       -> small verzia
            select      -> combobox verzia
            background  -> farba scroll pozadia
            --------------------------------------------------------------------------------------------------

            const DialogEmployeeResult = (typ,value) => {
                console.log(value)
            }
    */

    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState(props.editing == true ? props.employee : {
        name: '',
        surname: '',
        alias: '',
        color: 0,
        note: '',
        photo: '',
        enabled: false
    });
    const [enabled, setEnabled] = useState(false);
    const [avatar, setAvatar] = useState(props.editing == true ? props.employee.photo : 0);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 700;
    const dialogHeight = props.isSmall ? 500 : 400;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;
    const avatar_size = 150;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                setEnabled(props.employee.enabled == false ? false : true);

            }
        }
        return () => {
        };

    }, []);

    const db_employee_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'employee_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(data.name) == true ? true : false;
        error += IsEmpty(data.surname) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var user = {
                id: props.editing == true ? props.employee.id : 0,
                salon_id: props.user_id,
                name: data.name.trim(),
                surname: data.surname.trim(),
                note: data.note.trim(),
                color: data.color,
                enabled: enabled,
                photo: avatar
            }
            db_employee_update(user);
        }

    }

    const ColorsResult = (value) => {
        data.color = value;
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faUser} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: props.isSmall ? '65%' : '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.employee}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        {props.isSmall == true ? null :
                            <div style={{ ...styles.Block, height: 45 }} >
                                <Colors small value={data.color} func={ColorsResult.bind(this)} />
                            </div>
                        }
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight, width: avatar_size }}>
                            <img onClick={() => setAvatar(avatar == 0 ? 1 : 0)} src={avatar == 0 ? avatar_woman : avatar_man} style={{ width: '100%', height: '100%', maxWidth: avatar_size - 40, maxHeight: avatar_size - 40, objectFit: 'contain', marginTop: 20, borderRadius: 10, cursor: 'pointer' }}></img>
                        </div>

                        <div style={{ ...styles.BlockCenter, width: dialogWidth - avatar_size, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.employee_enabled}</p>}
                                        control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                    />
                                </div>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, marginTop: rowOffset }}>
                                    <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '50%' }}>
                                        <TextInput required outline enabled={true} lang={lang} value={data.name} label={lang.name} func={(txt) => { data.name = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '50%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 10 : 0 }}>
                                        <TextInput required outline enabled={true} lang={lang} value={data.surname} label={lang.surname} func={(txt) => { data.surname = txt }} />
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, marginTop: props.isSmall ? 20 : 10 }}>
                                    <TextInput enabled={true} lang={lang} value={data.note} label={lang.note} func={(txt) => { data.note = txt }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '95%', height: buttonHeight + errorHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError error={error} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>


            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}



export const Colors = (props) => {
    /*
            VYBER FARIEB
            
            USAGE:
            <Colors small light value={value} func={ColorsResult.bind(this)} />

            const ColorsResult = (value) => {
                console.log(value)
            }
    */

    const [value, setValue] = useState(props.value);

    let { func } = props;

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);


    const Press = (value) => {
        setValue(value);
        func(value);
    }

    return (

        <div style={{ ...styles.BlockRowRaw }}>
            {props.light == true ?
                global.colors.map((item, index) => (
                    <div key={index} onClick={() => Press(index)} style={{ ...styles.Block, width: props.small == true ? 20 : 40, height: 20, borderRadius: 12, marginLeft: 10, border: index == value ? '2px solid #000000' : '2px solid ' + item, backgroundColor: item, cursor: 'pointer' }}>
                    </div>
                ))
                :
                global.colors_dark.map((item, index) => (
                    <div key={index} onClick={() => Press(index)} style={{ ...styles.Block, width: props.small == true ? 20 : 40, height: 20, borderRadius: 12, marginLeft: 10, border: index == value ? '2px solid #000000' : '2px solid ' + item, backgroundColor: item, cursor: 'pointer' }}>
                    </div>
                ))}
        </div>
    );
}


export const EmployeeSelect = (props) => {
    /*
            VYBER ZAMESTNANCA
            
            USAGE:
            <EmployeeSelect select small radius background={global.theme_lighter} width={600} employees={employees} value={value} func={EmployeeSelectResult.bind(this)} />

            --------------------------------------------------------
            all -> všetk záznamy
            --------------------------------------------------------

            const EmployeeSelectResult = (value) => {
                console.log(value)
            }
    */

    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');
    const avatar = require('./react/app/avatar.jpg');

    const [id, setID] = useState(props.value);
    const [overID, setOverID] = useState(-1);

    const icon_size = props.small == true ? 30 : 36;
    const button_size = props.small == true ? 180 : 200;
    const offset = 3;

    let { func } = props;

    useEffect(() => {
        setID(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        setID(value);
        func(value);
    }

    const ChangeSelect = (value) => {
        setID(value);
        func(value);
    }

    return (
        props.select == true ?
            <div style={{ ...styles.Block, width: props.width == undefined ? '100%' : props.width }}>
                <Select
                    value={id}
                    onChange={event => ChangeSelect(event.target.value)}
                    size='small'
                    style={{ backgroundColor: props.dark == true ? global.themes[props.theme].theme_dark : global.theme_white, color: props.dark == true ? global.theme_white : global.theme_black, height: 34, width: props.width == undefined ? '100%' : props.width, borderRadius: props.radius == true ? 17 : 0 }}
                    MenuProps={{
                        style: { zIndex: 35001 }
                    }}
                >
                    {props.all == true ?
                        <MenuItem value={-1}><FontAwesomeIcon style={{ height: 14, color: '#00000000', marginRight: 10 }} icon={faCircle} /> {props.lang.employees_all}</MenuItem>
                        : null}
                    {props.employees.map((item, index) => (
                        <MenuItem key={item.id} value={item.id}><FontAwesomeIcon style={{ height: 10, color: item.color > 0 ? global.colors_dark[item.color] : '#00000000', marginRight: 10 }} icon={faCircle} /> {item.name} {item.surname}</MenuItem>
                    ))}
                </Select>

            </div>

            :
            <div style={{ ...styles.Block, alignItems: 'flex-start', width: props.width, overflowX: 'scroll', backgroundColor: props.background != undefined ? props.background : global.theme_white, borderRadius: props.radius == true ? (icon_size + 10) / 2 : 0, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                <div style={{ ...styles.BlockRowRaw, marginTop: 5, marginBottom: 5 }}>
                    {props.employees.map((item, index) => (
                        <Paper
                            elevation={item.id == overID ? 4 : 0}
                            onClick={() => Press(item.id)} key={item.id} style={{
                                ...styles.BlockRow, width: button_size, height: icon_size, backgroundColor: item.id == id ? global.theme_dark : global.theme_light, marginLeft: offset, marginRight: offset, borderRadius: icon_size / 2, cursor: 'pointer',
                            }}
                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                        >
                            {props.small == undefined ?
                                <div style={{ ...styles.Block, width: icon_size, height: icon_size }}>
                                    <img src={item.photo == 0 ? avatar_woman : item.photo == 1 ? avatar_man : avatar} style={{ width: '100%', height: '100%', maxWidth: icon_size - 6, maxHeight: icon_size - 6, objectFit: 'contain', borderRadius: (icon_size - 6) / 2 }}></img>
                                </div>
                                : null}
                            <div style={{ ...styles.Block, width: props.small == undefined ? button_size - icon_size : button_size, height: icon_size }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', color: item.id == id ? global.theme_white : global.theme_black }}>{item.name} {item.surname}</p>
                            </div>
                        </Paper>
                    ))}
                </div>
            </div >
    );
}


export const CountrySelect = (props) => {
    /*
            VYBER KRAJINY
            
            USAGE:
            <CountrySelect radius width={200} countries={props.countries} background={global.theme_lighter} width={600} value={value} func={CountryResult.bind(this)} />

            --------------------------------------------------------
            all -> všetky záznamy
            allCountries -> všetky krajiny
            --------------------------------------------------------

            const CountryResult = (value) => {
                console.log(value)
            }
    */

    const [item, setItem] = useState(false);
    const [id, setID] = useState(-1);

    const imageSize = 24;

    let { func } = props;

    useEffect(() => {
        Debug(props.countries);
        if (props.value != false) {
            setItem(props.value);
            setID(props.value != false ? props.value.id : -1);
        }

        return () => {
        };

    }, [props.value]);


    const ChangeSelect = (value) => {
        Debug(value);
        if (value > -1) {
            var tmp = props.countries.find(x => x.id == value);
            if (tmp != undefined) {
                setItem(tmp);
                setID(value);
                func(tmp);
            }
        } else {
            let tmp = { id: -1, language_id: -1, country: props.lang.news_show_all };
            setItem(tmp);
            setID(value);
            func(tmp);
        }
    }

    return (
        <div style={{ ...styles.Block, width: props.width == undefined ? '100%' : props.width }}>
            {props.countries != false ?
                <Select
                    value={id}
                    onChange={event => ChangeSelect(event.target.value)}
                    size='small'
                    style={{ backgroundColor: props.dark == true ? global.themes[props.theme].theme_lighter : global.theme_white, color: props.dark == true ? global.theme_white : global.theme_black, height: 34, width: props.width == undefined ? '100%' : props.width, borderRadius: props.radius == true ? 17 : 0 }}
                    MenuProps={{
                        style: { zIndex: 35001 }
                    }}
                >
                    {props.allCountries == true ?
                        <MenuItem value={-1}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.BlockLeft, height: imageSize, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 34 }}>{props.lang.news_show_all}</p>
                                </div>
                            </div>
                        </MenuItem>
                        : null}

                    {props.countries.map((item, index) => (
                        <MenuItem key={item.id} value={item.id}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <img src={item.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                {props.tiny == true ? null :
                                    <div style={{ ...styles.BlockLeft, height: imageSize, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.country}</p>
                                    </div>
                                }
                            </div>
                        </MenuItem>
                    ))}
                </Select>
                : null}

        </div>
    );
}



export const DialogImagePreview = (props) => {
    /*
            NAHLAD OBRAZKU

            USAGE:
            <DialogImagePreview theme={props.theme} file={ global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />

            --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
            delete  => možnosť vymazania obrázku (pri diskusii)
            --------------------------------------------------------------------------------------------------------------------

            const DialogImagePreviewResult = () => {                
            }

    */

    const [showDelete, setShowDelete] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    //const dialogWidth = 900;
    //const dialogHeight = 700;

    let { func } = props;
    var lang = props.lang == undefined ? sk : props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, delete_) => {
        func(typ, delete_);
    }

    const Close = () => {
        Press(false, false);
    }

    const DeleteImage = () => {
        setShowDelete(true);
    }

    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            Press(true, true);
        }
    }

    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000E0', zIndex: 999 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faImage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '70%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                        {props.delete == true ?
                            <Button onClick={() => DeleteImage()} style={{ ...styles.ButtonLight }}>
                                <FontAwesomeIcon style={{ height: 12, color: global.theme_white, marginRight: 10 }} icon={faTrash} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.delete}</p>
                            </Button>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: props.background, borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}>
                    {props.crop == true ?
                        <img src={props.file} style={{ width: '100%', height: '100%', maxWidth: dialogWidth, maxHeight: dialogHeight - global.dialog_bar_height, objectFit: 'cover' }}></img>
                        :
                        <img src={props.file} style={{ width: '100%', height: '100%', maxWidth: dialogWidth, maxHeight: dialogHeight - global.dialog_bar_height, objectFit: 'contain', borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}></img>
                    }
                </div>
            </Paper>

            {showDelete == true ?
                <DialogYesNo center theme={props.theme} radius={props.radius} title={lang.photo_delete} sub_title={''} text={lang.photo_delete_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

        </Backdrop >
    );
}


export const DialogCategory = (props) => {
    /*
            KATEGÓRIE - UPRAVA / NOVA

            USAGE:
            <DialogCategory lang={lang} radius={props.radius} func={DialogCategoryResult.bind(this)} />

            const DialogCategoryResult = (typ,value) => {
                console.log(value)
            }
    */


    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState(props.editing == true ? props.item.label : '');
    const [slug, setSlug] = useState(props.editing == true ? props.item.slug : '');
    const [enabled, setEnabled] = useState(true);
    const [eshop, setEshop] = useState(true);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 500;
    const dialogHeight = 380;
    const rowOffset = 20;
    const errorHeight = 30;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                setEnabled(props.item.enabled == false ? false : true);
                setEshop(props.item.eshop == false ? false : true);
            } else {
                setEnabled(true);
            }
        }
        return () => {
        };

    }, []);

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'category_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(label) == true ? true : false;
        error += IsEmpty(slug) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var category = {
                id: props.editing == true ? props.item.id : 0,
                enabled: enabled,
                priority: 10,
                label: label.trim(),
                color: '',
                icon: '',
                salon_id: props.user_id,
                brand_id: 0,
                section_id: 0,
                eshop: eshop,
                slug: slug.trim(),
                language: props.language,
            }

            db_update(category);
        }

    }

    const ChangeLabel = (txt) => {
        setLabel(txt);
        setSlug(GetTid(txt));
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.category}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 45 }} >
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.category_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 0 }}>
                                {enabled == true ?
                                    <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.category_eshop}</p>}
                                        control={<Checkbox checked={eshop} size={'small'} onChange={() => setEshop(!eshop)} />}
                                    />
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <TextInput required enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => ChangeLabel(txt)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '90%', height: buttonHeight + errorHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError error={error} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>


            {showOK == true ?
                <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}



export const CategorySelect = (props) => {
    /*
            Výber kategorie
            
            USAGE:
            <CategorySelect radius background={global.theme_lighter} width={600} items={items} value={value} func={CategorySelectResult.bind(this)} />

            const CategorySelectResult = (value) => {
                console.log(value)
            }
    */

    const [id, setID] = useState(props.value);
    const [overID, setOverID] = useState(-1);

    const icon_size = 28;
    const buttonSize = 100;
    const offset = 3;

    let { func } = props;

    useEffect(() => {
        setID(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        setID(value);
        func(value);
    }

    return (
        <div style={{ ...styles.Block, alignItems: props.center == true ? 'center' : 'flex-start', width: props.width, overflowX: 'scroll', backgroundColor: props.background != undefined ? props.background : global.theme_white, borderRadius: props.radius == true ? (icon_size + 10) / 2 : 0, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
            <div style={{ ...styles.BlockRowRaw, marginTop: 5, marginBottom: 5 }}>
                {props.items.map((item, index) => (
                    <Paper
                        elevation={item.id == overID ? 4 : 0}
                        onClick={() => Press(item.id)} key={item.id} style={{
                            ...styles.BlockRow, width: undefined, alignItems: 'center', justifyContent: 'center', height: icon_size, backgroundColor: item.id == id ? global.theme_dark : global.theme_light, marginLeft: offset, marginRight: offset, borderRadius: icon_size / 2, cursor: 'pointer',
                        }}
                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                    >

                        <div style={{ display: 'flex', minWidth: buttonSize, height: icon_size, alignItems: 'center', justifyContent: 'center', overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>
                            <p style={{ ...styles.TextTiny, fontSize: 13, textAlign: 'left', color: item.id == id ? global.theme_white : global.theme_black }}>{item.label}</p>
                        </div>

                    </Paper>
                ))}
            </div>
        </div >
    );
}



export const PlusMinus = (props) => {
    /*
            PLUS - MINUS HODNOTY
            
            USAGE:
            <PlusMinus min={1} value={value} func={PlusMinusResult.bind(this)} />

            ----------------------------------------------------------------------------------------
            min         -> minimalna hodnota 
            max         -> maximálna hodnota 
            no_wait     -> bez cakacej rutiny


            vertical        -> vertikálne vykreslenie
            label           -> popis políčka (vertical mode)
            dot             -> bodka za číslom
            radius_left     -> radius z lavej strany
            radius_right    -> radius z pravej strany
            ----------------------------------------------------------------------------------------
            const PlusMinusResult = (value) => {
                console.log(value)
            }
    */

    const [value, setValue] = useState(props.value);

    let { func } = props;

    const width = 120;
    const widthVertical = 50;
    const height = 36;

    const timer = useRef();

    useEffect(() => {
        setValue(props.value);
        Debug(props.value);
        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        func(value, props.data);
    }

    const Process = (add) => {
        var old_value = parseInt(value);
        var number = parseInt(parseInt(value) + parseInt(add));
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }
        } else {
            if (number < 0) {
                number = 0;
            }
        }
        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }

        }
        setValue(number);

        // opozdovacia rutina
        if (old_value != number) {
            clearTimeout(timer.current);
            if (props.no_wait == undefined) {
                timer.current = setTimeout(() => {
                    Press(number);
                }, 500);
            } else {
                Press(number);
            }
        }
    }


    return (
        props.vertical == true ?
            <div style={{ ...styles.Block, minWidth: widthVertical }}>
                <IconButton onClick={() => Process(1)} style={{ width: height, height: height, backgroundColor: props.max != undefined ? props.max == value ? global.theme_gray : global.theme_light : global.theme_light, border: '2px solid ' + global.theme_dark }}>
                    <FontAwesomeIcon style={{ width: 12, color: props.max != undefined ? props.max == value ? global.theme_medium_gray : global.theme_black : global.theme_black }} icon={faPlus} />
                </IconButton>
                <div style={{ ...styles.Block, marginLeft: 10, marginRight: 10 }}>
                    {props.label != undefined ?
                        <p style={{ ...styles.TextTiny, marginTop: 12 }}>{props.label}</p>
                        : null}
                    <div style={{ ...styles.Block, backgroundColor: global.theme_white, marginTop: 10, marginBottom: 15, borderTopLeftRadius: props.radius_left == undefined ? 0 : 10, borderTopRightRadius: props.radius_right == undefined ? 0 : 10, borderBottomLeftRadius: props.radius_left == undefined ? 0 : 10, borderBottomRightRadius: props.radius_right == undefined ? 0 : 10 }}>
                        <p style={{ ...styles.TextLarge, fontWeight: '600', marginTop: 5, marginBottom: 5 }}>{value}{props.dot == true ? '.' : ''}</p>
                    </div>
                </div>
                <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, backgroundColor: props.min != undefined ? props.min == value ? global.theme_gray : global.theme_light : global.theme_light, border: '2px solid ' + global.theme_dark }}>
                    <FontAwesomeIcon style={{ width: 12, color: props.min != undefined ? props.min == value ? global.theme_medium_gray : global.theme_black : global.theme_black }} icon={faMinus} />
                </IconButton>
            </div >
            :
            <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: props.backgroundColor == undefined ? global.theme_white : props.backgroundColor, borderRadius: height / 2, borderTop: props.border == true ? '1px solid #707070' : null, borderBottom: props.border == true ? '1px solid #707070' : null }}>
                <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, backgroundColor: global.theme_dark }}>
                    <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faMinus} />
                </IconButton>
                <div style={{ ...styles.Block, width: width - (height * 2), height: height }}>
                    <p style={{ ...styles.TextTiny }}>{value}</p>
                </div>
                <IconButton onClick={() => Process(1)} style={{ width: height, height: height, backgroundColor: props.max != undefined ? props.max == value ? global.theme_light : global.theme_dark : global.theme_dark }}>
                    <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faPlus} />
                </IconButton>
            </div >
    );
}



export const DialogGroup = (props) => {
    /*
            SKUPINY - SLUŽBY - UPRAVA / NOVA

            USAGE:
            <DialogGroup lang={lang} radius={props.radius} func={DialogGroupResult.bind(this)} />

            const DialogGroupResult = (typ,value) => {
                console.log(value)
            }
    */


    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState(props.editing == true ? props.item.label : '');
    const [slug, setSlug] = useState(props.editing == true ? props.item.slug : '');
    const [enabled, setEnabled] = useState(true);
    const [eshop, setEshop] = useState(true);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 500;
    const dialogHeight = 380;
    const rowOffset = 20;
    const errorHeight = 30;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                setEnabled(props.item.enabled == false ? false : true);
                setEshop(props.item.eshop == false ? false : true);
            } else {
                setEnabled(true);
                setEshop(true);
            }
        }
        return () => {
        };

    }, []);

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'group_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(label) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var category = {
                id: props.editing == true ? props.item.id : 0,
                priority: 20,
                brand_id: 0,
                language: props.language,
                salon_id: props.user_id,
                enabled: enabled,
                eshop: eshop,
                label: label.trim(),
                slug: '',
                photo: ''
            }

            db_update(category);
        }

    }

    const ChangeLabel = (txt) => {
        setLabel(txt);
        setSlug(GetTid(txt));
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.group}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 45 }} >
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.group_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                {enabled == true ?
                                    <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.group_eshop}</p>}
                                        control={<Checkbox checked={eshop} size={'small'} onChange={() => setEshop(!eshop)} />}
                                    />
                                    : null}
                            </div>

                            <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <TextInput required enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => ChangeLabel(txt)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '90%', height: buttonHeight + errorHeight }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            <FormError error={error} />
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>


            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}


export const DialogVideo = (props) => {
    /*
            PREHRAVANIE VIDEA

            USAGE:
            <DialogVideo file={ global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogVideoResult.bind(this)} />

            --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
            --------------------------------------------------------------------------------------------------------------------

            const DialogVideoResult = () => {                
            }

    */

    //const [isBusy, setBusy] = useState(false);

    const dialogWidth = 900;
    const dialogHeight = 700;

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA', zIndex: 1200 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faImage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, overflow: 'hidden' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: props.background, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <video controls style={{ maxWidth: '100%' }}>
                        <source src={props.file} type="video/mp4" />
                    </video>
                </div>
            </Paper>
        </Backdrop >
    );
}


export const DialogPDF = (props) => {
    /*
            PDF SUBOR

            USAGE:
            <DialogPDF file={ global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogPDFResult.bind(this)} />

            --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
            --------------------------------------------------------------------------------------------------------------------

            const DialogPDFResult = () => {                
            }

    */

    const [link, setLink] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const buttonSize = 36;

    let { func } = props;
    var lang = sk;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;
            setLink("https://docs.google.com/gview?url=" + props.file + "&embedded=true");
        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const PrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }

    }

    const NextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }


    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, maxWidth: 1200, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faImage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    {/* props.remoteApp == false ?
                        <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}  >
                                <Page pageNumber={pageNumber} width={dialogWidth} />
                            </Document>
                            {numPages > 0 ?
                                <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
                                    <div style={{ ...styles.BlockRowRaw, height: buttonSize + 20, backgroundColor: global.theme_medium_gray, borderRadius: (buttonSize + 20) / 2, marginTop: 20, marginBottom: 20 }}>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber > 1 ?
                                                <IconButton onClick={() => PrevPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleLeft} />
                                                </IconButton>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.Block, width: 100, height: buttonSize + 20 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_white }}>{pageNumber} / {numPages}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                            {pageNumber < numPages ?
                                                <IconButton onClick={() => NextPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleRight} />
                                                </IconButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    */}
                    <iframe width={'100%'} height={'100%'} style={{ border: '0px' }} src={props.file}></iframe>

                </div>

            </Paper>
        </Backdrop >
    );

    /*
                <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}  >
                            <Page pageNumber={pageNumber} width={dialogWidth} />
                        </Document>
                        {numPages > 0 ?
                            <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
                                <div style={{ ...styles.BlockRowRaw, height: buttonSize + 20, backgroundColor: global.theme_medium_gray, borderRadius: (buttonSize + 20) / 2, marginTop: 20, marginBottom: 20 }}>
                                    <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                        {pageNumber > 1 ?
                                            <IconButton onClick={() => PrevPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleLeft} />
                                            </IconButton>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, width: 100, height: buttonSize + 20 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_white }}>{pageNumber} / {numPages}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 60, height: buttonSize + 20 }}>
                                        {pageNumber < numPages ?
                                            <IconButton onClick={() => NextPage()} style={{ width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: global.theme_black }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faAngleRight} />
                                            </IconButton>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
    */
}


export const DialogHelp = (props) => {
    /*
            PDF SUBOR

            USAGE:
            <DialogHelp remoteApp={props.remoteApp} radius={props.radius} lang={props.lang} func={HelpResult.bind(this)} />

            const HelpResult = () => {                
            }

    */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.help}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                    <iframe width={'100%'} height={'100%'} style={{ border: '0px' }} src={props.user.typ == global.account_salon || props.user.typ == global.account_medical ? lang.help_link : lang.help_link_rep}></iframe>

                </div>
            </Paper>
        </Backdrop >
    );

}

export const DialogHelpVideo = (props) => {
    /*
            PDF SUBOR

            USAGE:
            <DialogHelpVideo remoteApp={props.remoteApp} radius={props.radius} lang={props.lang} func={HelpResult.bind(this)} />

            const HelpResult = () => {                
            }

    */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const titleHeight = 60;
    const dialogWidth = width >= 1130 ? 1130 : width;
    const dialogHeight = props.isSmall == true ? height >= titleHeight + 320 ? titleHeight + 320 : height : height >= titleHeight + 640 ? titleHeight + 640 : height;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '90%', height: titleHeight, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.help_video_sk}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - titleHeight, backgroundColor: global.theme_black, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {/* BODY */}
                    <video controls style={{ maxWidth: '100%' }}>
                        <source src={'https://esthedermsalon.com/public/uploads/video/sal_2_0.mp4'} type="video/mp4" />
                    </video>
                </div>
            </Paper>
        </Backdrop>

    );

}

export const EmptyList = (props) => {
    /*
            ŽIADNE POLOŽKY - IKONA

            USAGE:
            <EmptyList row small lang={lang} />

            ------------------------------------------------------------------------
            row     => ikona a text su v riadku
            small   => ikona je menšia
            text    => text pod ikonov (nepovinné)
            ------------------------------------------------------------------------
    */

    const size = 100;
    const size_small = 50;

    const empty = require('./react/app/empty_list.png');

    return (
        <div style={{ ...styles.Block }}>
            {props.row == true ?
                <div style={{ ...styles.BlockRowRaw }}>
                    <img src={empty} style={{ transform: 'rotate(-7deg)', width: '100%', height: '100%', width: props.small == true ? size_small : size, height: props.small == true ? size_small : size, objectFit: 'contain' }}></img>
                    <p style={{ ...styles.TextNormal, fontSize: props.small == true ? 14 : 18, marginTop: props.small == true ? (size_small / 2) - 5 : (size / 2) - 7 }}>{props.text != undefined ? props.text : props.lang.empty_list}</p>
                </div>
                :
                <div style={{ ...styles.Block }}>
                    <img src={empty} style={{ width: '100%', height: '100%', width: props.small == true ? size_small : size, height: props.small == true ? size_small : size, objectFit: 'contain' }}></img>
                    <p style={{ ...styles.TextNormal, fontSize: props.small == true ? 14 : 18, marginTop: 10 }}>{props.text != undefined ? props.text : props.lang.empty_list}</p>
                </div>
            }
        </div>

    );
}

export const Voucher = (props) => {
    /*
            VERNOSTNÁ POUKÁ6KA

            USAGE:
            <Voucher value={'15.00'} lang={lang} />

            ------------------------------------------------------------------------
            lang    -> language
            used    -> TRUE označená
            ------------------------------------------------------------------------

    */

    const size = 40;

    const voucher = require('./react/app/voucher.png');

    return (
        <Paper elevation={5} style={{ ...styles.Block, position: 'relative', width: 300, height: 135, borderRadius: 10 }}>
            <img src={voucher} style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 135, objectFit: 'contain', borderRadius: 10, opacity: props.used == true ? 0.2 : 1.0 }}></img>
            <p style={{ ...styles.TextSmall, position: 'absolute', left: 15, top: 100, opacity: props.used == true ? 0.2 : 1.0 }}>{props.lang.value} {props.value} {props.lang.money}</p>
            {props.used == true ?
                <FontAwesomeIcon style={{ position: 'absolute', left: (300 / 2) - (size / 2), top: (135 / 2) - (size / 2), height: size, color: global.theme_dark_green }} icon={faCheckCircle} />
                : null}
        </Paper>

    );
}


export const SelectYear = (props) => {
    /*
            VYBER OBDOBIA DO STATISTIKY
            
            USAGE:
            <SelectYear min={2020} value={value} func={SelectYearResult.bind(this)} />

            ----------------------------------------------------------------------------------------
            min     -> minimalna hodnota - rok
            ----------------------------------------------------------------------------------------

            const SelectYearResult = (year, month) => {
                console.log(year, month)
            }
    */

    const [value, setValue] = useState(props.value);
    const [nowYear, setNowYear] = useState(GetTodayYear());

    let { func } = props;

    const width = 140;
    const height = 36;

    const timer = useRef();

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        var month = 12;
        if (value == GetTodayYear()) {
            month = GetTodayMonth() + 1;
        }
        func(value, month);
    }

    const Process = (add) => {
        var old_value = parseInt(value);
        var number = parseInt(parseInt(value) + parseInt(add));
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }

        } else {
            if (number < 0) {
                number = 0;
            }
        }

        if (number > GetTodayYear()) {
            number = GetTodayYear();
        }
        setValue(number);

        // opozdovacia rutina
        if (old_value != number) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                Press(number);
            }, 300);
        }
    }


    return (
        <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: global.theme_white, borderRadius: height / 2 }}>
            <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, backgroundColor: props.min == undefined ? global.theme_dark : value == props.min ? global.theme_light_gray : global.theme_dark }}>
                <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faMinus} />
            </IconButton>
            <div style={{ ...styles.Block, width: width - (height * 2), height: height }}>
                <p style={{ ...styles.TextSmall }}>{value}</p>
            </div>
            <IconButton onClick={() => Process(1)} style={{ width: height, height: height, backgroundColor: nowYear == value ? global.theme_light_gray : global.theme_dark }}>
                <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faPlus} />
            </IconButton>
        </div >
    );
}

export const SelectMonth = (props) => {
    /*
            VYBER MESIACA DO STATISTIKY
            
            USAGE:
            <SelectMonth value={value} func={SelectMonthResult.bind(this)} />

            ----------------------------------------------------------------------------------------

            const SelectMonthResult = (month) => {
                console.log(year, month)
            }
    */

    const [value, setValue] = useState(props.value);

    let { func } = props;
    let lang = props.lang;

    const width = 200;
    const height = 36;

    const timer = useRef();

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Process = (add) => {
        var number = parseInt(parseInt(value) + parseInt(add));
        if (number < 0) {
            number = 0;
        }
        if (number > 12) {
            number = 12;
        }
        setValue(number);
        func(number);
    }


    return (
        <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: global.theme_white, borderRadius: height / 2 }}>
            <IconButton onClick={() => Process(-1)} style={{ width: height, height: height, backgroundColor: value == 0 ? global.theme_light_gray : global.theme_dark }}>
                <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faMinus} />
            </IconButton>
            <div style={{ ...styles.Block, width: width - (height * 2), height: height }}>
                <p style={{ ...styles.TextSmall }}>{value > 0 ? lang.months[value - 1] : lang.all_months}</p>
            </div>
            <IconButton onClick={() => Process(1)} style={{ width: height, height: height, backgroundColor: value == 12 ? global.theme_light_gray : global.theme_dark }}>
                <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faPlus} />
            </IconButton>
        </div >
    );
}


export const DialogEmail = (props) => {
    /*
            ODOSLAT e-mail vsetkým zákazníkom
            
            USAGE:
            <DialogEmails lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogEmailResult.bind(this)} />

            const DialogEmailResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const [header, setHeader] = useState(' ');
    const [body, setBody] = useState('');

    const blocksFromHTML = convertFromHTML('');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);
    const [photo, setPhoto] = useState(false);
    const [filename, setFilename] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1100 ? 1100 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const headerHeight = 100;
    const errorHeight = 60;
    const buttonHeight = 80;
    const editorHeight = dialogHeight - headerHeight - global.dialog_bar_height - errorHeight - buttonHeight;
    const imageSize = editorHeight - buttonHeight - 50;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const inputRef = useRef(null);

    var running = false;
    useEffect(() => {

        if (running == false) {
            running = true;
        }

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }, []);

    const db_send_clients = async (data) => {
        // ODOSLAT HROMADNY EMAIL
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'clients_emails', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.emails_sended + ' ' + json.count_ok);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send_client = async (data) => {
        // ODOSLAT MAIL JEDNEMU ZAKAZNIKOVI
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.email_sended);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_photo_delete = async (file) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'upload_image_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    file: file
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setPhoto(false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Process = () => {
        setError('');
        setInfo('');

        let html = convertToHTML(editorState.getCurrentContent());

        var data = {
            salon_id: props.user_id,
            header: header.trim(),
            body: html,
            photo: photo,
            filename: filename
        }

        if (IsEmpty(header)) {
            setError(lang.email_header_error);
        } else {
            if (props.client == undefined) {
                db_send_clients(data);
            } else {
                data.client_id = props.client.id;
                db_send_client(data);
            }
        }
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
            setFilename(file);
        }
    }

    const DeletePhoto = () => {
        db_photo_delete(photo);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faEnvelope} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>

                    {/* BODY */}
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: dialogWidth - imageSize, backgroundColor: global.theme_back, height: editorHeight + headerHeight }}>
                            <div style={{ ...styles.Block, width: '95%', height: headerHeight, backgroundColor: global.theme_back }}>
                                <TextInput inputRef={inputRef} required redraw enabled={true} lang={lang} value={header} label={lang.email_header} func={(txt) => setHeader(txt)} />
                            </div>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                defaultContentState={contentState}
                                onContentStateChange={setContentState}

                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"

                                editorStyle={{ backgroundColor: global.theme_white, width: dialogWidth - 30 - imageSize, height: editorHeight - 60, border: '1px solid ' + global.theme_light_gray }}
                                toolbarStyle={{ backgroundColor: global.theme_back, width: dialogWidth - 50 - imageSize }}

                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: false },
                                    textAlign: { inDropdown: false },
                                    history: { inDropdown: false },
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    blockType: {
                                        inDropdown: false,
                                        options: ['Normal', 'H1', 'H2', 'H3'],
                                    },
                                    list: {
                                        inDropdown: false,
                                        options: ['unordered', 'ordered'],
                                    },
                                }}
                            />
                        </div>

                        <div style={{ ...styles.BlockCenter, width: imageSize, height: editorHeight + headerHeight, backgroundColor: global.theme_back }}>

                            <div style={{ ...styles.Block, height: headerHeight + 45, justifyContent: 'flex-end' }}>
                                <p style={{ ...styles.TextNormal }}>{lang.addon}</p>
                            </div>
                            <div style={{ ...styles.Block, width: imageSize, height: imageSize }}>
                                <img src={photo == false ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize - 40, maxHeight: imageSize - 40, objectFit: 'contain' }}></img>
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                {photo == false ?
                                    <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_gray, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                        {lang.galery_add}
                                    </Button>
                                    :
                                    <Button onClick={() => DeletePhoto()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 200 }}>
                                        {lang.delete}
                                    </Button>
                                }
                            </div>

                        </div>

                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: errorHeight + buttonHeight, marginBottom: 400 }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            {error != '' ?
                                <FormError error={error} />
                                : null}
                            {info != '' ?
                                <FormOK text={info} />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                {lang.send_email}
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={5} max_size={1200} width={1200} height={1200} full={true} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} offset={props.offset} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}



export const DialogNotify = (props) => {
    /*
            ODOSLAT notifikáciu vsetkým / jednému zákazníkom 
            
            USAGE:
            <DialogNotify lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogNotifyResult.bind(this)} />

            const DialogNotifyResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const [text, setText] = useState(' ');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 320 ? 320 : height;

    const errorHeight = 60;
    const buttonHeight = 80;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const inputRef = useRef(null);

    var running = false;
    useEffect(() => {

        if (running == false) {
            running = true;
        }

        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }, []);

    const db_send_notifies = async (data) => {
        // ODOSLAT HROMADNU SPRAVU
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'clients_notifies', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.notifies_sended + ' ' + json.count_ok);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send_notify = async (data) => {
        // ODOSLAT MAIL JEDNEMU ZAKAZNIKOVI
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client_notify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setInfo(lang.notify_sended);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Process = () => {
        setError('');
        setInfo('');

        if (IsEmpty(text) == false) {
            var data = {
                salon_id: props.user_id,
                language: props.language,
                text: text.trim()
            }
            if (props.client != undefined) {
                data.client_id = props.client.id;
                db_send_notify(data);
            } else {
                db_send_notifies(data);
            }
        }
    }



    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faEnvelope} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {/* BODY */}
                    <div style={{ ...styles.Block, width: dialogWidth }}>
                        <div style={{ ...styles.Block, width: '95%', height: 100 }}>
                            <TextInput inputRef={inputRef} required redraw enabled={true} lang={lang} value={text} label={lang.notify_text} func={(txt) => setText(txt)} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <div style={{ ...styles.Block, height: errorHeight }}>
                            {error != '' ?
                                <FormError error={error} />
                                : null}
                            {info != '' ?
                                <FormOK text={info} />
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                {lang.notify_send}
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}


export const DialogAgreements = (props) => {
    /*
            GDPR, ....
            
            USAGE:
            <DialogAgreements typ={0} lang={lang} radius={props.radius} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogAgreementsResult.bind(this)} />
            -------------------------------------------------------
            typ:
                    0 - všeobecné obchodné podmienky Eshop / CLient
                    1 - GDPR
                    2 - cookies
                    3 - vernostný systém                                        
                    4 - GDPR súhlas so spracovaním osobných údajov
                    
            -------------------------------------------------------

            const DialogAgreementsResult = (typ,value) => {
                console.log(value)
            }
    */
    let titles = [
        props.lang.vop,
        props.lang.gdpr,
        props.lang.cookies,
        props.lang.bonus_system,
        props.lang.gdpr_agreement_doc,
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        props.lang.vop,
    ];

    const [title, setTitle] = useState(titles[props.typ]);
    const [isBusy, setBusy] = useState(true);
    const [html, setHtml] = useState(false);
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [showEnterEmail, setShowEnterEmail] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [radius, setRadius] = useState(height > 768 ? props.radius : 0);
    const inputRef = useRef(null);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const buttonHeight = isSmall == true ? 60 : 0;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_agreements();
        }

        return () => {
        };

    }, []);

    const db_agreements = async () => {
        try {
            const response = await fetch(
                global.db_url + 'agreements', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setHtml(json.html);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_agreements_print = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'agreements_print', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setPdfLink(json.filename);
                setTimeout(() => {
                    setBusy(false);
                    inputRef.current.click();
                }, 300);
            } else {
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_agreements_send = async (email) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'agreements_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: props.client == undefined ? 0 : props.client.id,
                    typ: props.typ,
                    email: email,
                    lang: {
                        label: titles[props.typ],
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Print = () => {
        db_agreements_print();
    }

    const Send = () => {
        var e_mail = '';
        if (props.client != undefined) {
            if (props.client.email != '') {
                e_mail = props.client.email;
            }
        }

        if (props.email == undefined) {
            if (e_mail == '') {
                setEmail('');
                setShowEnterEmail(true);
            } else {
                setEmail(e_mail);
                setShowEnterEmail(true);
            }
        } else {
            setShowEnterEmail(true);
        }
    }

    const EnterEmailResult = (value, text) => {
        setShowEnterEmail(false);

        if (value == true) {
            if (nonEmail(text) == false) {
                db_agreements_send(text);
            } else {
                setTimeout(() => {
                    setShowEmailError(true);
                }, 200);
            }
        }
    }


    const DialogInfoResult = () => {
        setShowEmailError(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    return (

        <Backdrop open={true} style={{  /*paddingLeft: width > global.max_screen ? global.menu_width : 0, */ backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faFile} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '55%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    {isSmall == true ? null :
                        <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                            <Button onClick={() => Send()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                        </div>
                    }
                    {isSmall == true ? null :
                        <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                            <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ textDecoration: 'none' }} target='_blank'></a>
                            <Button onClick={() => Print()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                {lang.print}
                            </Button>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>

                </div>
                {isSmall ?
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Send()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                            <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ textDecoration: 'none' }} target='_blank'></a>
                            <Button onClick={() => Print()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 160, marginLeft: 20 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                {lang.print}
                            </Button>
                        </div>
                    </div>
                    : null}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height, overflowY: 'scroll' }}>
                        {/* BODY */}
                        {html != false ?
                            <div style={{ ...styles.TextSmall, width: '95%', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: html }} />
                            : null}
                    </div>
                </div>
            </Paper>

            {showEnterEmail == true ?
                <DialogEnterText theme={props.theme} rows={1} radius={props.radius} title={lang.email} sub_title={lang.gdpr_enter_email} text={email} icon={faEnvelope} button={props.lang.send} func={EnterEmailResult.bind(this)} />
                : null}

            {showEmailError == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.format_error} sub_title={''} text={lang.email_wrong_format} icon={faEnvelope} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={DialogInfoResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} center func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading theme={props.theme} center></Loading>
                : null}


        </Backdrop >
    );
}



export const DialogGDPR = (props) => {
    /*
            GDPR, ....
            
            USAGE:
            <DialogGDPR lang={lang} user={props.user} radius={props.radius} func={DialogGDPRResult.bind(this)} />
 
            const DialogGDPRResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [showGDPR, setShowGDPR] = useState(false);
    const [showVOP, setShowVOP] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 800;
    const dialogHeight = 350;
    var lang = props.lang;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_save = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);
            Press(true);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        func(value);
    }

    const Save = () => {
        let data = {
            id: props.user.id,
            gdpr_v2: true
        }

        db_save(data);
    }


    return (
        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faQuestion} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '95%' : '90%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.gdpr_vop}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                            }
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        </div>
                    }
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{lang.gdpr_agree_text}</p>
                    <p style={{ ...styles.TextNormal, textAlign: 'center', fontWeight: '600', marginTop: 10 }}>{lang.gdpr_vop_agree_question}</p>
                    <Button onClick={() => setShowGDPR(true)} style={{ ...styles.ButtonLink, width: 350, marginTop: 40 }}>{lang.gdpr_info}...</Button>
                    <Button onClick={() => setShowVOP(true)} style={{ ...styles.ButtonLink, width: 350, marginTop: 10 }}>{lang.vop}...</Button>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_darker_green, width: 170 }}>{lang.agree}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 170 }}>{lang.disagree}</Button>
                    </div>

                </div>
            </Paper>

            {showGDPR == true ?
                <DialogAgreements theme={props.theme} typ={1} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPR(false)} />
                : null}

            {showVOP == true ?
                <DialogAgreements theme={props.theme} typ={0} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowVOP(false)} />
                : null}

            {isBusy == true ?
                <Loading center ></Loading>
                : null}

        </Backdrop>
    );
}



export const DialogDesktopSettings = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogDesktopSettings lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogDesktopSettingsResult.bind(this)} />
 
            const DialogDesktopSettingsResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // PRACOVNA PLOCHA
    const [calendar, setCalendar] = useState(false);
    const [todo, setTodo] = useState(false);
    const [eshop, setEshop] = useState(false);
    const [birthday, setBirthday] = useState(false);
    const [reserveSystem, setReserveSystem] = useState(false);
    const [bookingProducts, setBookingProducts] = useState(false);
    const [bookingBonus, setBookingBonus] = useState(false);
    const [reklamacie, setReklamacie] = useState(false);

    // SKRATKY
    const [shortcuts, setShortcuts] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 1000;
    const dialogHeight = props.isSmall ? height : 650;
    const rowOffset = 20;
    const rowHeight = 40;
    const buttonHeight = 80;
    const cellWidth = 225;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let data = GetDefaultDesktop(lang, props.user.typ)

            setShortcuts(data.shortcuts);

            db_get(data.shortcuts, data.desktop);
        }

        return () => {
        };

    }, []);


    const db_get = async (shortcuts_, desktop_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page_settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (json.settings != false) {
                    if (json.settings.desktop_settings != null && json.settings.desktop_settings != false) {
                        let settings = JSON.parse(json.settings.desktop_settings);
                        shortcuts_.forEach(item => {
                            var n = 0;
                            while (n < settings.shortcuts.length) {
                                if (settings.shortcuts[n].tid == item.tid) {
                                    item.value = settings.shortcuts[n].value;
                                    break;
                                }
                                n++;
                            }
                        });

                        setEshop(settings.desktop.eshop == true ? true : false);
                        setCalendar(settings.desktop.calendar == true ? true : false);
                        setTodo(settings.desktop.todo == true ? true : false);
                        setBirthday(settings.desktop.birthday == true ? true : false);
                        setReserveSystem(settings.desktop.reserve == true ? true : false);
                        setBookingBonus(settings.desktop.bonus == true ? true : false);
                        setBookingProducts(settings.desktop.products == true ? true : false);
                        setReklamacie(settings.desktop.reklamacie == true ? true : false);
                        setShortcuts(shortcuts_);

                    } else {
                        setEshop(desktop_.eshop == true ? true : false);
                        setCalendar(desktop_.calendar == true ? true : false);
                        setTodo(desktop_.todo == true ? true : false);
                        setBirthday(desktop_.birthday == true ? true : false);
                        setReserveSystem(desktop_.reserve == true ? true : false);
                        setBookingBonus(desktop_.bonus == true ? true : false);
                        setBookingProducts(desktop_.products == true ? true : false);
                        setReklamacie(desktop_.reklamacie == true ? true : false);
                        setShortcuts(shortcuts_);
                    }
                }
                setRedraw(!redraw);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_save = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'page_settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                Press(true, data);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeValue = (item) => {
        let tmp = shortcuts.find(x => x.id == item.id);
        tmp.value = !item.value;

        setRedraw(!redraw);
    }

    const Save = () => {
        let data = {
            salon_id: props.user.id,
            desktop_settings: {
                desktop: {
                    calendar: calendar,
                    todo: todo,
                    eshop: eshop,
                    birthday: birthday,
                    reserve: reserveSystem,
                    products: bookingProducts,
                    bonus: bookingBonus,
                    reklamacie: reklamacie
                },
                shortcuts: shortcuts
            }
        };

        db_save(data);
    }

    return (

        <Backdrop open={true} style={{ backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faDesktop} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.settings_desktop}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 10 }}>{lang.settings_dektop_text}</p>
                        <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: rowOffset }}>{lang.desktop}</p>
                        {props.user != false ? props.user.typ == global.account_salon ?
                            // SALON
                            <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10, marginTop: 10, flexWrap: 'wrap', backgroundColor: '#FFFFFF80', borderRadius: global.radius / 2 }}>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.settings_calendar}</p>}
                                        control={<Checkbox checked={reserveSystem} size={'small'} onChange={() => setReserveSystem(!reserveSystem)} />}
                                    />
                                </div>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.eshop_orders}</p>}
                                        control={<Checkbox checked={eshop} size={'small'} onChange={() => setEshop(!eshop)} />}
                                    />
                                </div>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.calendar}</p>}
                                        control={<Checkbox checked={calendar} size={'small'} onChange={() => setCalendar(!calendar)} />}
                                    />
                                </div>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.birthday}</p>}
                                        control={<Checkbox checked={birthday} size={'small'} onChange={() => setBirthday(!birthday)} />}
                                    />
                                </div>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.todo}</p>}
                                        control={<Checkbox checked={todo} size={'small'} onChange={() => setTodo(!todo)} />}
                                    />
                                </div>
                            </div>
                            : props.user.typ == global.account_medical ?
                                // MEDICAL
                                <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10, marginTop: 10, flexWrap: 'wrap', backgroundColor: '#FFFFFF80', borderRadius: global.radius / 2 }}>

                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.todo}</p>}
                                            control={<Checkbox checked={todo} size={'small'} onChange={() => setTodo(!todo)} />}
                                        />
                                    </div>
                                </div>
                                :
                                // AGENT
                                <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10, marginTop: 10, flexWrap: 'wrap', backgroundColor: '#FFFFFF80', borderRadius: global.radius / 2 }}>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.desktop_booking_products}</p>}
                                            control={<Checkbox checked={bookingProducts} size={'small'} onChange={() => setBookingProducts(!bookingProducts)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.desktop_booking_bonus}</p>}
                                            control={<Checkbox checked={bookingBonus} size={'small'} onChange={() => setBookingBonus(!bookingBonus)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.reklamacie}</p>}
                                            control={<Checkbox checked={reklamacie} size={'small'} onChange={() => setReklamacie(!reklamacie)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: cellWidth, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.todo}</p>}
                                            control={<Checkbox checked={todo} size={'small'} onChange={() => setTodo(!todo)} />}
                                        />
                                    </div>
                                </div>

                            : null}

                        <div>
                            <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: rowOffset * 2 }}>{lang.shortcuts}</p>
                            <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10, marginTop: 10, flexWrap: 'wrap', backgroundColor: '#FFFFFF80', borderRadius: global.radius / 2 }}>
                                {shortcuts != false ? shortcuts.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.BlockLeft, height: rowHeight, width: 225, marginTop: 10, marginLeft: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.label}</p>}
                                            control={<Checkbox checked={item.value} size={'small'} onChange={() => ChangeValue(item)} />}
                                        />
                                    </div>
                                )) : null}
                            </div>
                        </div>

                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                    </div>
                </div>
            </Paper>

            {
                isBusy == true ?
                    <Loading theme={props.theme} center></Loading>
                    : null
            }
        </Backdrop >
    );
}


export const GDPRDetails = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <GDPRDetails data={data} lang={lang} radius={props.radius}  func={GDPRDetailsResult.bind(this)} />
 
            const DialogResult = (typ,item) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [basicData, setBasicData] = useState(true /* props.data.basic_data == false ? false : true */);
    const [gallery, setGallery] = useState(props.data.gallery == false ? false : true);
    const [notes, setNotes] = useState(props.data.notes == false ? false : true);
    const [diagnostic, setDiagnostic] = useState(props.data.diagnostic == false ? false : true);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 700;
    const dialogHeight = props.isSmall ? height : 550;
    const divWidth = props.isSmall ? '96%' : 450;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false);
    }

    const Process = () => {
        let items = {
            basic_data: true,/* basicData,*/
            notes: notes,
            gallery: gallery,
            diagnostic: diagnostic
        }
        Press(true, items);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.gdpr_details}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.gdpr}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockLeft, height: 60, width: divWidth, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.gdpr_settings_text}</p>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: divWidth }}>
                            <FormControlLabel style={{ margin: 0, padding: 0 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_basic}</p>}
                                control={<Checkbox disabled checked={basicData} size={'small'} onChange={() => setBasicData(!basicData)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_basic_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_notes}</p>}
                                control={<Checkbox checked={notes} size={'small'} onChange={() => setNotes(!notes)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_notes_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_gallery}</p>}
                                control={<Checkbox checked={gallery} size={'small'} onChange={() => setGallery(!gallery)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_gallery_text}</p>
                            <FormControlLabel style={{ margin: 0, padding: 0, marginTop: 10 }} label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.gdpr_settings_diagnostic}</p>}
                                control={<Checkbox checked={diagnostic} size={'small'} onChange={() => setDiagnostic(!diagnostic)} />}
                            />
                            <p style={{ ...styles.TextTiny, marginLeft: 36, textAlign: 'left' }}>{lang.gdpr_settings_diagnostic_text}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}




export const DialogGdprLog = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogGdprLog lang={lang} radius={props.radius} func={DialogGdprLogResult.bind(this)} />
 
            const DialogGdprLogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState(false);
    const [showAskExport, setShowAskExport] = useState(false);
    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 800;
    const dialogHeight = props.isSmall ? height : 600;
    const rowOffset = 20;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        try {
            const response = await fetch(
                global.db_url + 'gdpr_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                var tmp = json.items;
                var last = false;

                tmp.forEach(item => {
                    var d = BinaryToData(item.action);

                    let data = {
                        basic_data: d.d1,
                        notes: d.d2,
                        gallery: d.d3,
                        diagnostic: d.d4
                    }

                    if (last != false) {
                        data.basic_data_changed = (data.basic_data == last.basic_data ? false : true);
                        data.notes_changed = (data.notes == last.notes ? false : true);
                        data.gallery_changed = (data.gallery == last.gallery ? false : true);
                        data.diagnostic_changed = (data.diagnostic == last.diagnostic ? false : true);
                    } else {
                        data.basic_data_changed = false;
                        data.notes_changed = false;
                        data.gallery_changed = false;
                        data.diagnostic_changed = false;
                    }

                    item.data = data;
                    item.datum = GetDatumTime(lang.months, item.date);
                    if (item.typ != 4 && item.typ != 5 && item.typ != 6) {
                        last = data;
                    }
                });

                setItems(tmp);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_send = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'gdpr_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user.id,
                    items: items,
                    lang: {
                        www_eshop: lang.www_eshop,
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_,
                        gdpr_logs: lang.gdpr_logs,
                        gdpr_logs_send_email_text: lang.gdpr_logs_send_email_text,
                        header: lang.gdpr_logs,
                        created: lang.created,
                        gdpr_logs_type: lang.gdpr_logs_type,
                        client_agreements: lang.client_agreements,
                        date: lang.date,
                        gdpr_settings_basic: lang.gdpr_settings_basic,
                        gdpr_settings_gallery: lang.gdpr_settings_gallery,
                        gdpr_settings_notes: lang.gdpr_settings_notes,
                        gdpr_settings_diagnostic: lang.gdpr_settings_diagnostic,
                        yes: lang.yes,
                        no: lang.no,
                        permission_added: lang.permission_added,
                        permission_removed: lang.permission_removed,
                        action: lang.action,
                        gdpr_logs_send_table_text: lang.gdpr_logs_send_table_text,
                        email: lang.email_
                    }
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Export = (typ) => {
        setShowAskExport(false);
        if (typ == true) {
            db_send();
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '70%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.gdpr_logs}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.gdpr}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                        {items != false ?
                            <Button onClick={() => setShowAskExport(true)} style={{ ...styles.ButtonDark, width: 160 }}>
                                <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                {lang.send}
                            </Button>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container' >
                        {/* BODY */}
                        {items != false ? items.map((item, index) => (
                            <div key={item.id} style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, marginTop: 5, marginBottom: 5 }}>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft, width: props.isSmall ? '100%' : 220 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.datum}</p>
                                </div>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft, width: props.isSmall ? '100%' : '60%' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.gdpr_logs_type[item.typ]}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.email_}: {item.email}</p>
                                </div>
                                {item.typ != 4 && item.typ != 5 && item.typ != 6 ?
                                    <div style={{ ...styles.BlockLeft, width: props.isSmall ? '90%' : '30%', marginTop: props.isSmall ? 20 : 0 }}>
                                        <div style={{ ...styles.BlockLeft, paddingBottom: 5, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.client_agreements}:</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.basic_data_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_basic}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.basic_data_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.basic_data == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.basic_data_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.basic_data == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.notes_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_notes}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.notes_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.notes == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.notes_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.notes == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.gallery_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_gallery}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.gallery_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.gallery == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.gallery_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.gallery == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.diagnostic_changed == true ? global.theme_dark_red : global.theme_black }}>{lang.gdpr_settings_diagnostic}:</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                <p style={{ ...styles.TextTiny, color: item.data.diagnostic_changed == true ? global.theme_dark_red : global.theme_black }}>{item.data.diagnostic == true ? lang.yes : lang.no}</p>
                                            </div>
                                        </div>
                                        {item.data.diagnostic_changed == true ?
                                            <p style={{ ...styles.TextXTiny, marginLeft: 5 }}>({item.data.diagnostic == true ? lang.permission_added : lang.permission_removed})</p>
                                            : null}

                                    </div>

                                    : <div style={{ ...styles.Block, width: '30%' }}></div>}

                            </div>
                        )) :
                            <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                {isBusy == false ?
                                    <EmptyList lang={lang} />
                                    : null}
                            </div>
                        }
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

            {showAskExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gdpr_logs_send_label} sub_title={''} text={lang.gdpr_logs_send_text} sub_text={lang.gdpr_export_question_ + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={Export.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}
        </Backdrop>
    );
}


export const DialogHelpSelect = (props) => {
    /*
            VYBER - pomocník / kontaktujte nás
            
            USAGE:
            <DialogHelpSelect lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={HelpSelectResult.bind(this)} />

            const HelpSelectResult = (typ,value) => {
                console.log(value)
            }
    */

    const [visible, setVisible] = useState(true);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall == true ? width - 20 : 800;
    const dialogHeight = props.isSmall == true ? 500 : 280;
    const butonSize = props.isSmall == true ? 80 : 100;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ) => {
        setVisible(false);
        var timer = setTimeout(() => {
            func(typ);

        }, global.dialog_close_delay);
    }

    return (
        <Backdrop open={visible} transitionDuration={global.dialog_close_delay} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(0)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height }}>
                        <div style={{ ...props.isSmall == true ? styles.Block : styles.BlockRowRaw }}>
                            <Button onClick={() => Press(1)} style={{ ...styles.ButtonDark, width: butonSize * 2, height: butonSize, backgroundColor: global.themes[props.theme].theme_white }}>
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 36, color: global.theme_blue }} icon={faBookOpen} />
                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.helper}</p>
                                </div>
                            </Button>
                            <Button onClick={() => Press(2)} style={{ ...styles.ButtonDark, width: butonSize * 2, height: butonSize, backgroundColor: global.themes[props.theme].theme_white, marginLeft: props.isSmall == true ? 0 : 40, marginTop: props.isSmall == true ? 30 : 0 }}>
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 36, color: global.theme_blue }} icon={faAt} />
                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.help_contact_us}</p>
                                </div>
                            </Button>
                            <Button onClick={() => Press(3)} style={{ ...styles.ButtonDark, width: butonSize * 2, height: butonSize, backgroundColor: global.themes[props.theme].theme_white, marginLeft: props.isSmall == true ? 0 : 40, marginTop: props.isSmall == true ? 30 : 0 }}>
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 36, color: global.theme_blue }} icon={faFilm} />
                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.help_video}</p>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogContactUs = (props) => {
    /*
            VYBER - kontaktujte nás
            
            USAGE:
            <DialogContactUs theme={props.theme} lang={lang} radius={props.radius} func={ContactUsResult.bind(this)} />

            const ContactUsResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [message, setMessage] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 800;
    const dialogHeight = 500;
    const errorSize = 80;
    const butonSize = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_send = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'send_message', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    message: message
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.sended == true) {
                    setShowOK(true);
                } else {
                    setError(lang.send_err)
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const Process = () => {
        setError('');

        if (message.trim() != '') {
            db_send();
        }
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faAt} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.help_contact_us}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(0)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - butonSize - errorSize }}>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.contact_us_text1}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.contact_us_text2}</p>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <TextInput rows={6} redraw enabled={true} lang={lang} value={message} label={lang.contact_us_note} func={(txt) => { setMessage(txt) }} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: errorSize }}>
                        <FormError small error={error}></FormError>
                        {showOK == true ?
                            <FormOK small text={lang.notify_sended}></FormOK>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: butonSize }}>
                        <Button disabled={message.trim() == '' ? true : false} onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: message.trim() == '' ? global.theme_light_gray : global.themes[props.theme].button_dark, color: message.trim() == '' ? global.theme_medium_gray : global.theme_white }}>{lang.send}</Button>
                    </div>
                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}
        </Backdrop>
    );
}


export const SelectInput = (props) => {
    /* USAGE

        SINGLE SELECT:
            <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={-1} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

        MULTIPLE SELECT:
            <SelectInput multiple default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={[]} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

            items = [{ id: 1, label: 'Option 1 }, ... ]

            PROPS:
            -------------------------------------------------------------------------------
            default     -> disabled nadpis
            field       -> názov stĺpca, z ktorého sa berie názov do Select items
            multiple    -> multi výber hodnôt, POZOR: value musí byť Array []
            require     -> oznacvený cervený pásik na ľavo
            width       -> šírka
            disabled    -> prvá hodnota - default aktívna / neaktívna
    */


    const [value, setValue] = useState(props.value);
    const [redraw, setRedraw] = useState(false);

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme == undefined ? 0 : props.theme];
    let radius = props.radius;

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const ChangeValue = (id) => {
        setValue(id);
        func(id);
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block, width: props.width == undefined ? '100%' : props.width }}>
            <Select
                value={value}
                onChange={event => ChangeValue(event.target.value)}
                multiple={props.multiple == undefined ? false : props.multiple}
                size='small'
                style={{ textAlign: 'left', fontSize: global.font_xsmall, backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: props.radius == undefined ? 19 : props.radius }}
                MenuProps={{
                    PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } },
                    sx: {
                        width: 200, "& .MuiMenuItem-root.Mui-selected": { backgroundColor: props.multiple ? color.theme_light : color.theme_lighter, color: props.multiple ? color.white : undefined, borderRadius: 2, margin: 1 },
                        width: 200, "& .MuiMenuItem-root.Mui-selected:hover": { backgroundColor: props.multiple ? color.theme_lighter : undefined, color: props.multiple ? color.white : undefined },
                        zIndex: 30000,
                    }
                }}
                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: color.xxgray, borderLeft: props.require ? props.multiple ? value.length == 0 ? '3px solid #FF0000' : undefined : value == -1 ? '3px solid #FF0000' : undefined : undefined } }}>

                {props.default != undefined ?
                    <MenuItem disabled={props.disabled == undefined ? true : props.disabled} value={-1}><p style={{ ...styles.TextXSmall }}><em>{props.default}</em></p></MenuItem>
                    : null}

                {props.items != false ?
                    props.items.map((item, index) => (
                        <MenuItem style={{ fontSize: global.font_xsmall }} key={item.id} value={item.id}>{item[props.field]}</MenuItem>
                    ))
                    : null}
            </Select>

        </div >
    );
}


export const QuickHelp = (props) => {
    /*

        <QuickHelp text={lang.price_final} theme={props.theme} marginLeft={10} lang={props.lang} />

        PARAMS:
        --------------------------------------------------------------------------------------------------------
        text    ->  textová poznámka

    */

    const [showHelp, setShowHelp] = useState(false);

    let maxWidth = 300;
    let minWidth = 150;
    let minHeight = 50;
    let color = global.themes[props.theme];

    const [anchorEl, setAnchorEl] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const divRef = React.useRef();

    useEffect(() => {
        setAnchorEl(divRef.current);

        return () => {
        };

    }, []);


    return (
        <div style={{ ...styles.Block, width: props.width == undefined ? 20 : props.width, height: props.height == undefined ? 20 : props.height, marginLeft: props.marginLeft == undefined ? 0 : props.marginLeft }}>
            <IconButton ref={divRef} onClick={() => setShowHelp(!showHelp)} style={{ ...styles.ButtonIconThemed, width: 20, height: 20, backgroundColor: color.white }}>
                <FontAwesomeIcon style={{ height: 20, color: props.color == undefined ? global.theme_medium_xgray : props.color }} icon={faCircleInfo} />
            </IconButton>

            {showHelp == true ?
                <div onClick={() => setShowHelp(false)} style={{ position: 'absolute', top: 0, left: 0, width: width, height: height, zIndex: 55033 }}>
                </div>
                : null}

            <Popper
                style={{ zIndex: 55055 }}
                open={showHelp}
                anchorEl={anchorEl}
                placement={'bottom'}
            >
                <Paper elevation={3} onClick={() => setShowHelp(false)} style={{ ...styles.Block, minWidth: minWidth, maxWidth: maxWidth, minHeight: minHeight, marginTop: 2, padding: 5, borderRadius: 10, backgroundColor: global.theme_white, cursor: 'pointer' }}>
                    <p style={{ ...styles.TextTiny }}>{props.text}</p>
                </Paper>
            </Popper>

        </div>
    );
}


export const SelectPeriod = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <SelectPeriod lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} func={PeriodResult.bind(this)} />

            const PeriodResult = (typ,value) => {
                console.log(value)
            }
    */

    const [actualYear, setActualYear] = useState(props.year);
    const [actualMonth, setActualMonth] = useState(props.month);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 300;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme];
    var running = false;

    useEffect(() => {
        return () => {
        };

    }, []);


    const Close = () => {
        func(0, 0);
    }


    const SelectYearResult = (year, month) => {
        Debug(year);
        setActualYear(year);
    }

    const SelectMonthResult = (month) => {
        Debug(month);
        setActualMonth(month);
    }

    const Process = () => {
        func(true, actualMonth, actualYear);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA', zIndex: 1200 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: color.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: color.icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: 230, height: global.dialog_bar_height }}>
                                <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.month}</p>
                                <SelectMonth value={actualMonth} lang={lang} func={SelectMonthResult.bind(this)} />
                            </div>
                            <div style={{ ...styles.Block, width: 150, height: global.dialog_bar_height }}>
                                <p style={{ ...styles.TextXSmall, marginBottom: 5 }}>{lang.year}</p>
                                <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                            </div>
                        </div>

                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark }}>{lang.show}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogYoutube = (props) => {
    /*
            PDF SUBOR

            USAGE:
            <DialogYoutube link={lesson.youtube} radius={props.radius} lang={props.lang} func={YoutubeResult.bind(this)} />

            const HelpResult = () => {                
            }

    */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const titleHeight = 60;
    const dialogWidth = width >= 1130 ? 1130 : width;
    const dialogHeight = props.isSmall == true ? height >= titleHeight + 320 ? titleHeight + 320 : height : height >= titleHeight + 640 ? titleHeight + 640 : height;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faVideo} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '90%', height: titleHeight, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - titleHeight, backgroundColor: global.theme_black, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {/* BODY */}
                    {props.isSmall ?
                        <iframe width={dialogWidth} height="auto" src={props.link} title="YouTube video player" frameBorder="0" allow="autoplay;encrypted-media;" allowFullScreen></iframe>
                        :
                        <iframe width="1120" height="630" src={props.link} title="YouTube video player" frameBorder="0" allow="autoplay;encrypted-media;" allowFullScreen modelsbranding="1"></iframe>
                    }
                </div>
            </Paper>
        </Backdrop>

    );

}


export const TextLine = (props) => {

    /*
    
        <TextLine text={item.text} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={color.black} />

    */

    return (
        <div style={{ display: 'block', alignContent: 'flex-start', maxWidth: '98%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <span style={{
                ...styles.TextXSmall,
                fontSize: props.fontSize,
                textAlign: 'left',
                color: props.color,
                fontWeight: props.fontWeight != undefined ? props.fontWeight : '400',
                marginLeft: props.marginLeft != undefined ? props.marginLeft : 0,
                marginLeft: props.marginTop != undefined ? props.marginTop : 0,
                marginLeft: props.marginBottom != undefined ? props.marginBottom : 0,
            }}>
                {props.text}
            </span>
        </div>
    );
}

export const TextSearch = (props) => {
    /* USAGE

    */


    const [text, setText] = useState(props.value != undefined ? props.value : '');
    const timer = useRef();

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme == undefined ? 0 : props.theme];

    useEffect(() => {

        return () => {
        };

    }, []);

    const ChangeText = (txt) => {

        setText(txt);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            func(txt);
        }, 500);


    }

    return (
        <TextField
            value={text}
            onInput={e => ChangeText(e.target.value)}
            size="small"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            style={{ width: props.isSmall ? 150 : 250 }}
            placeholder={lang.search_in_category}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                }
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => ChangeText('')} style={{ width: 20, height: 20 }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </InputAdornment>,
                style: { backgroundColor: global.theme_white, borderRadius: 30 },
            }}
            variant="outlined"
        />
    );
}


