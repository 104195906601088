/*

    DATABASE: kh034500

*/

import { faCar, faCheck, faEdit, faExclamation, faExclamationTriangle, faEye, faEyeSlash, faHourglass, faInfo, faPaperPlane, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import img_background1 from './react/app/background.jpg';
import img_background2 from './react/app/background2.jpg';
import img_background3 from './react/app/background3.jpg';
import img_menu1 from './react/app/background_menu.jpg';
import img_menu2 from './react/app/background_menu2.jpg';
import img_menu3 from './react/app/background_menu3.jpg';

const icon_text = require('./react/app/icon_text.png');
const icon_image = require('./react/app/icon_image.png');
const icon_divider = require('./react/app/icon_divider.png');
const icon_product = require('./react/app/icon_product.png');
const icon_bonus = require('./react/app/icon_bonus.png');
const icon_service = require('./react/app/icon_service.png');
const icon_qrcode = require('./react/app/icon_qrcode.png');

global.testing = process.env.REACT_APP_ESTHEDERM_TEST == 0 ? false : true;
global.appstore_publishing = false;

global.demo_salon = 29; // ID demo účtu - vypnutie ponuky ANDROID v ucte "demo 12345678"
global.admin_salon = 1; // ID admin účtu

// DECODE KEY
global.code_key = '%E<gw=$0]1+W|4!Bt#;@:>.^}Q{K(*elq/G,po39zR&[)-Tr6iy_u8ADM7SJH5NPvIObhZCUFn2YVXjLksmxfdac';

// PRAVIDELNA KONTROLA - seconds
global.check_period = 1000 * 60 * 15; // 15 minút

// RELEASE VERZIA PRE PETKA
global.release = false;

global.max_screen = 1600;
global.app_name = 'Esthederm Salon';

// KEYBOARD PADDING -> ak je zobrazená klávesnica -> padding-bottom
global.keyboard_padding = 500;
global.keyboard_padding_small = 300;

// CHAT - DISKUSIA
global.chat_limit = 100;

// DATUM SPLATNOSTI NA FAKTURE
global.faktura_days = 14;

// DATABAZA
//global.web = 'https://vgvstudio.synology.me';
// PUBLIC IP ADDRESS

global.web = global.testing == true ? 'https://salony.datadream-test.sk' : 'https://esthedermsalon.com';
global.db_url = global.web + '/app_route/';
global.db_url_agent = global.web + '/app_route_agent/';
global.db_url_salon = global.web + '/app_salon/';
global.ipad_link = 'https://apps.apple.com/app/esthederm-salon/id6462401504';
global.android_link = 'https://play.google.com/store/apps/details?id=com.esthederm_salon';

// Images
global.web_images = 'https://www.kozmeticke-salony.sk/public';

global.web_pdf = 'https://www.kozmeticke-salony.sk/public/pdf/';
global.web_video = 'https://www.kozmeticke-salony.sk/public/video/osetrenia/';
global.web_uploads = 'https://www.kozmeticke-salony.sk/public/';

// LINK na exportované PDFs
global.pdf_exported = global.web + '/public/export/pdf/';

// WINDOW RADIUS / OFFSET
global.radius = 16;
global.row_radius = 16;
global.offset = 16;

// NAZVY LOKALNE ULOZENYCH SUBOROV
global.filename_settings = '';
global.filename_token = 'apptoken.tmp';

global.items_max = 24;

// PADDING -> Small screnn
global.smallOffset = 8;

// Cas potrebný na zatvorenie dialogového okna
global.dialog_close_delay = 150

// **********************************************************************
// TYPY UČTOV
// **********************************************************************
global.account_disabled = 0;
global.account_admin = 1;
global.account_salon = 2;
global.account_client = 3;
global.account_public = 4;
global.account_agent = 5;
global.account_agent_gm = 6;
global.account_test = 7;
global.account_salon_deleted = 8;
global.account_client_deleted = 9;
global.account_medical = 10;


// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_transparent = '#00000000';

global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_lighteen = '#D7EEFF';
global.theme_lighter = '#BBD6FD';
global.theme_light = '#95B2DC';
global.theme_medium = '#5F7AA1';
global.theme_dark = '#3D5273';
global.theme_darker = '#29364A';

global.theme_lighteen_blue = '#E2ECFF';
global.theme_lighter_blue = '#92BBEC';
global.theme_light_blue = '#2A8EFF';
global.theme_blue = '#205CCA';
global.theme_dark_blue = '#003BA4';
global.theme_darker_blue = '#041A47';

global.theme_lighteen_red = '#FFE4E2';
global.theme_highlight_red = '#FFD4D0';
global.theme_lighter_red = '#F99891';
global.theme_light_red = '#F77268';
global.theme_red = '#F44336';
global.theme_dark_red = '#C3170B';

global.theme_lighteen_green = '#E2FFE3';
global.theme_light_green = '#A6FA9D';
global.theme_green = '#46F436';
global.theme_dark_green = '#18BB18';
global.theme_darker_green = '#15A115';
global.theme_darkest_green = '#107010';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xxgray = '#CCCCCC';
global.theme_dark_gray = '#666666';
global.theme_darker_gray = '#252525';
global.theme_medium_gray = '#888888';
global.theme_medium_xgray = '#A0A0A0';
global.theme_light_gray = '#dedede';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_lighteen_violet = '#E9E2FF';
global.theme_light_cyan = '#80A0FF';
global.theme_violet = '#BB00BB';
global.theme_brown = 'brown';
global.theme_logo_background = '#D0DDEF';


// ******************************************************************************************
// GRADIENTS
// ******************************************************************************************
global.theme_back_gradient = 'linear-gradient(to bottom,#555555,#999999)';
global.theme_bar_gradient = 'linear-gradient(to bottom,#0C0C50 0%,#205CCA 50%)';
global.theme_titlebar_gradient = 'linear-gradient(to right,#041A47FF,#041A47BB)';
global.theme_titlebar_gradient2 = 'linear-gradient(to right,#664523FF,#0664523BB)';
global.theme_dialog_background = 'linear-gradient(220deg, #FFFFFF 0%, #92BBEC 66%)';
//global.theme_window_background = 'linear-gradient(220deg, #FFFFFFAA 0%, #92BBECAA 66%)';
global.theme_window_background = 'linear-gradient(220deg, #FFFFFFFF 0%, #92BBECFF 66%)';
global.theme_subwindow_background = 'linear-gradient(220deg, #67B8FD77 0%, #9FDAFDAA 66%)';
global.theme_button_dark = 'linear-gradient(180deg, #3D5273, #29364A)';
global.theme_leftmenu_background = 'linear-gradient(220deg, #92BBEC 0%, #92BBEC 66%)';
global.theme_line = 'linear-gradient(220deg, #5F7AA100 0%, #5F7AA1FF 50%, #5F7AA100 100%)';
global.theme_titlemenu_gradient = 'linear-gradient(to right,#525C97FF,#041A47FF)';
global.theme_rainbow = 'linear-gradient(220deg, #FF2222 50%, #0077FF 50%)';
global.theme_buttons_rainbow = 'linear-gradient(90deg, #4E5A6E 0%, #4E5A6E 100%)';
global.theme_buttons_rainbow2 = 'linear-gradient(90deg, #4E5A6E 0%, #4E5A6E 100%)';

// ******************************************************************************************
// DIALOG
// ******************************************************************************************
global.dialog_bar_height = 80;
global.dialog_icon_size = 42;
global.dialog_bar_color = 'linear-gradient(to right,#205CCA20,#0C346B10)';
global.window_bar_color = 'linear-gradient(to right,#FFFFFF50,#0C346B50)';


// ******************************************************************************************
// IKONY
// ******************************************************************************************
global.icon_background = 'linear-gradient(220deg,#0C346B,#205CCA)';
global.shortcut_background = 'linear-gradient(220deg,#0C346B,#205CCA)';
global.icon_wite_background = 'linear-gradient(315deg,#FFFFFF,#88BBFF)';
global.shortcut_green_background = 'linear-gradient(220deg,#007700,#15A115)';
global.shortcut_red_background = 'linear-gradient(220deg,#C3170B,#F44336)';

// ******************************************************************************************
// MENU
// ******************************************************************************************
global.menu_width = 260;
global.statusbar_height = 40;
global.topmenu_height = 50;
global.menutitle_height = 150;
global.menu_elevation = 3;
global.theme_menu_selected = '#015FBD';

// ******************************************************************************************
// PISMO
// ******************************************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_xsmall = 15;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_table = 14;


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 750; //650
global.device_medium = 1200;
global.screen_max = 950;
global.tablet_width = 1200;

// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    name: 'Datadream s.r.o.',
    street: 'Viničná 52/16',
    psc: '951 93',
    town: 'Machulince',
    state: 'Slovensko',
    mobil1: '0905 - 668 294',
    mobil2: '0944 - 411 144',
    call: '0905 - 668 294',
    email: 'info@datadream.sk',
    ico: '54084253',
    dic: '2121566524',
    district: 'Zlaté Moravce',
};


// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.color_table = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#777777' },
    { id: 2, color: '#CCCCCC' },
    { id: 3, color: '#F4F5FA' },
    { id: 4, color: '#FFF1D7' },
    { id: 5, color: '#FFDCD7' },
    { id: 6, color: '#FFD7F3' },
    { id: 7, color: '#DFD7FF' },

    { id: 8, color: '#0000AA' },
    { id: 9, color: '#0000FF' },
    { id: 10, color: '#509DF4' },
    { id: 11, color: '#FF00FF' },
    { id: 12, color: '#D7EFFF' },
    { id: 13, color: '#D7FFF8' },
    { id: 14, color: '#D7FFDD' },
    { id: 15, color: '#EDEDED' },


    { id: 16, color: '#FF0000' },
    { id: 17, color: '#F44336' },
    { id: 18, color: '#D8532B' },
    { id: 19, color: '#FFFF00' },
    { id: 20, color: '#FFDEA4' },
    { id: 21, color: '#FFBCB3' },
    { id: 22, color: '#FFBAEA' },
    { id: 23, color: '#C9BCFF' },

    { id: 24, color: '#004400' },
    { id: 25, color: '#4D7B4D' },
    { id: 26, color: '#00AA00' },
    { id: 27, color: '#00FF00' },
    { id: 28, color: '#AADDFF' },
    { id: 29, color: '#9FFFED' },
    { id: 30, color: '#A7FFB2' },
    { id: 31, color: '#D7D7D7' },
];

// **********************************************************************
// FARENE ZNACENIE
// **********************************************************************
//global.colors = ['#DDDDDD', '#FFDDDD', '#DDFFDD', '#FFFFDD', '#DDDDFF', '#DDFFFF', '#FFDDFF'];
//global.colors_dark = ['#DDDDDD', '#FF0000', '#00AA00', '#FFFF00', '#0000FF', '#00FFFF', '#FF00FF'];

global.colors = ['#DDDDDD', '#FFCCCC', '#CCFFCC', '#FFCCFF', '#CCCCFF']
global.colors_dark = ['#AAAAAA', '#FF0000', '#00AA00', '#FF00FF', '#0000FF']

// **********************************************************************
// FARENE ZNACENIE
// **********************************************************************
global.booking_color = [global.theme_light_red, global.theme_light, global.theme_dark_green, global.theme_light_blue, global.theme_light_blue, global.theme_white, global.theme_white, global.theme_light_blue, global.theme_light_blue, global.theme_light_blue];
global.booking_icon = [faHourglass, faEdit, faCar, faExclamation, faInfo, faCheck, faXmark, faEdit, faEdit, faEdit];

global.booking_color_agent = [global.theme_light_red, global.theme_light, global.theme_dark_green, global.theme_light_blue, global.theme_light_blue, global.theme_white, global.theme_white, global.theme_light_blue, global.theme_light_blue, global.theme_light_violet];
global.booking_icon_agent = [faHourglass, faEdit, faCar, faExclamation, faInfo, faCheck, faXmark, faPaperPlane, faEdit, faExclamationTriangle];

global.eshop_color = [global.theme_light_red, global.theme_light, global.theme_dark_green, global.theme_light_blue, global.theme_white, global.theme_white];
global.eshop_icon = [faHourglass, faEdit, faCheck, faCar, faCheck, faXmark];

global.reklamacia_icon = [faHourglass, faEdit, faCheck, faXmark];
global.reklamacia_back_color = [global.theme_light_red, global.theme_light, global.theme_darker_green, global.theme_dark_red];
global.reklamacia_color = [global.theme_black, global.theme_black, global.theme_white, global.theme_white];

global.actuality_color = [global.theme_red, global.theme_dark_green, global.theme_light_blue, global.theme_light_gray];
global.actuality_icon = [faEyeSlash, faEye, faPen, faXmark];


// **********************************************************************
// LINK NA KLIENTSKU APLIKACIUE
// **********************************************************************
global.link_iphone = 'https://apps.apple.com/sk/app/kozmetick%C3%A9-sal%C3%B3ny-klient/id1596728029?l=sk';
global.link_android = 'https://play.google.com/store/apps/details?id=com.salon_klient_app';


// **********************************************************************
// LINK NA KLIENTSKU APLIKACIUE
// **********************************************************************
global.lesson_published = 0;
global.lesson_draft = 1;
global.lesson_canceled = 2;

global.lesson_user_registered = 0;      // prihlásený na školenie / absolvoval
global.lesson_user_canceled = 2;        // zrušil prihlásenie
global.lesson_user_not_absolved = 3;    // prihlásený, ale školenie neabsolvoval

// **********************************************************************
// AKTUALITY - IKONY OBSAHU
// **********************************************************************
global.news_icons_images = [icon_text, icon_image, icon_product, icon_divider, icon_bonus, icon_qrcode, icon_service];

global.news_icons = [
    {
        id: 0,
        typ: 0,
        image: global.news_icons_images[0],
        enabled: true,
    },
    {
        id: 1,
        typ: 1,
        image: global.news_icons_images[1],
        enabled: true,
    },

    {
        id: 2,
        typ: 2,
        image: global.news_icons_images[2],
        enabled: true,
    },
    {
        id: 4,
        typ: 4,
        image: global.news_icons_images[4],
        enabled: true,
    },
    {
        id: 6,
        typ: 6,
        image: global.news_icons_images[6],
        enabled: true,
    },
    {
        id: 3,
        typ: 3,
        image: global.news_icons_images[3],
        enabled: true,
    },
    {
        id: 5,
        typ: 5,
        image: global.news_icons_images[5],
        enabled: true,
    },
];


global.themes = [
    {
        id: 0,
        background_image: img_background1,
        menu_image: img_menu1,
        title_card: '#BBD6FD', // Hlavná obrazovka title user card
        top_bar: 'linear-gradient(to right,#001122FF,#041A47BB)',
        theme_dialog_background: 'linear-gradient(220deg, #FFFFFF 0%, #92BBEC 66%)',
        theme_window_background: 'linear-gradient(220deg, #FFFFFFFF 0%, #92BBECFF 66%)',
        window_bar_color: 'linear-gradient(to right,#FFFFFF50,#0C346B50)',
        icon_background: 'linear-gradient(220deg,#0C346B,#205CCA)',
        shortcut_background: 'linear-gradient(220deg,#0C346B,#205CCA)',
        theme_leftmenu_background: 'linear-gradient(220deg, #CDDCED 0%, #CDDCED 66%)',
        theme_line: 'linear-gradient(220deg, #5F7AA100 0%, #5F7AA1FF 50%, #5F7AA100 100%)',

        // THEMED COLORS
        theme_lighteen: '#D7E8FF',
        theme_lighter: '#BBD6FD',
        theme_light: '#95B2DC',
        theme_medium: '#5F7AA1',
        theme_dark: '#3D5273',
        theme_darker: '#29364A',

        theme_white: '#FFFFFF',

        // BUTTONS
        button_dark: '#3D5273',
        close: '#5F7AA1',
    },
    {
        id: 1,
        background_image: img_background2,
        menu_image: img_menu2,
        title_card: '#EEDBCA', // Hlavná obrazovka title user card
        top_bar: 'linear-gradient(to right,#301A10FF,#664523DD)',
        theme_dialog_background: 'linear-gradient(220deg, #FFFFFF 0%, #E7D9CA 66%)',
        theme_window_background: 'linear-gradient(220deg, #FFFFFFFF 0%, #B1967AFF 66%)',
        window_bar_color: 'linear-gradient(to right,#FFFFFF50,#6B350C50)',
        icon_background: 'linear-gradient(220deg,#4A3429,#73533D)',
        shortcut_background: 'linear-gradient(220deg,#4A3429,#73533D)',
        theme_leftmenu_background: 'linear-gradient(220deg, #E5D5CC 0%, #E5D5CC 66%)',
        theme_line: 'linear-gradient(220deg, #A1805F00 0%, #A1805FFF 50%, #A1805F00 100%)',

        // THEMED COLORS
        theme_lighteen: '#FAE9DC',
        theme_lighter: '#EEDBCA',
        theme_light: '#D6B99B',
        theme_medium: '#A1805F',
        theme_dark: '#73533D',
        theme_darker: '#4A3429',

        theme_white: '#FFFFFF',

        // BUTTONS
        button_dark: '#73533D',
        close: '#A1805F',
    },
    {
        id: 2,
        background_image: img_background3,
        menu_image: img_menu3,
        title_card: '#EECADE', // Hlavná obrazovka title user card
        top_bar: 'linear-gradient(to right,#321624FF,#400930BB)',
        theme_dialog_background: 'linear-gradient(220deg, #FFFFFF 0%, #E7CADB 66%)',
        theme_window_background: 'linear-gradient(220deg, #FFFFFFFF 0%, #B17A98FF 66%)',
        window_bar_color: 'linear-gradient(to right,#FFFFFF50,#5B1C4A50)',
        icon_background: 'linear-gradient(220deg,#4A2937,#733D57)',
        shortcut_background: 'linear-gradient(220deg,#4A2937,#733D57)',
        theme_leftmenu_background: 'linear-gradient(220deg, #DDDDDD 0%, #DDDDDD 66%)',
        theme_line: 'linear-gradient(220deg, #A15F8100 0%, #A15F81FF 50%, #A15F8100 100%)',

        // THEMED COLORS
        theme_lighteen: '#FAE9DC',
        theme_lighter: '#F6C2DF',
        theme_light: '#DC95B5',
        theme_medium: '#A15F81',
        theme_dark: '#733D57',
        theme_darker: '#4A2937',

        theme_white: '#FFFFFF',

        // BUTTONS
        button_dark: '#0066A4',
        close: '#A15F81',
    }
]


// ########################################################################################################
// CONDITIONS
// ########################################################################################################

global.datatypes = [
    { id: 0, label: 'Decimal' },
    { id: 1, label: 'Bigint' },
    { id: 2, label: 'String' },
    { id: 3, label: 'Boolean' },
    { id: 4, label: 'Date' },
    { id: 5, label: 'Time' },
    { id: 6, label: 'Datetime' },
    { id: 7, label: 'Array' },
    { id: 8, label: 'Object' },
    { id: 9, label: 'Condition' },
    { id: 10, label: 'Statement' },
]

global.operators = [
    { id: 0, datatype: 0, label: '==' },
    { id: 1, datatype: 0, label: '>=' },
    { id: 2, datatype: 0, label: '<=' },
    { id: 3, datatype: 0, label: '>' },
    { id: 4, datatype: 0, label: '<' },
    { id: 5, datatype: 0, label: '!=' },
    { id: 6, datatype: 9, label: 'A' },
    { id: 7, datatype: 9, label: 'Alebo' },
]

global.reklamacne_oddelenie = ['zakaznicky.servis@sk.naos.com', 'zakaznicky.servis@cz.naos.com'];

global.expirations_colors = ['#404040', '#008000', '#D00000'];