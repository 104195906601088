import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper, Dialog, Backdrop, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faBell, faBook, faBookOpen, faCalendar, faCalendarDay, faCheck, faChevronLeft, faChevronRight, faExclamationTriangle, faEye, faIdCard, faInfo, faInfoCircle, faList, faPeace, faPen, faPlus, faQuestion, faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import { Colors, CreateDate, Debug, DialogInfo, DialogYesNo, EmployeeSelect, FormError, GetAge, GetDate2, GetDate3, GetDateDay, GetDateMonth, GetDateYear, GetDatum, GetDatum2, GetDatum4, Loading, PlusMinus, TextInput, Today } from './items.js';
import { ClientSelect } from './client_select.js';
import { ServiceSelect } from './service_select.js';
import './App.css';
import { ClientCard } from './client_card.js';
import { LessonDetails } from './lessons.js';


export const GetMinutesFunc = (time_) => {
    // vrati pocet minut z hodin
    var time = time_.split(':');
    return (parseInt(time[0]) * 60) + parseInt(time[1]);
}

export const GetHoursFunc = (minutes) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

export const CalendarDialog = (props) => {
    /*
        PROPS:
            listing = {false/true} -> zobrazí len listing v dnesný den - mini option


    */

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selected, setSelected] = useState([]);
    const [startMinutes, setStartMinutes] = useState(480);

    const [termins, setTermins] = useState([]); // zoznam z DB
    const [cells, setCells] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [days, setDays] = useState({ items: [] });
    const [typ, setTyp] = useState(props.listing == true ? 3 : 0);
    const [actualDate, setActualDate] = useState(Date());
    const [day, setDay] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [todayDay, setTodayDay] = useState(0);
    const [todayMonth, setTodayMonth] = useState(0);
    const [todayYear, setTodayYear] = useState(0);
    const [hours, setHours] = useState([]);
    const [redrawAll, setRedrawAll] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showRemind, setShowRemind] = useState(false);

    const isSmall = props.isSmall;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxWidth = 120;
    const boxHeight = 130;
    const dayHeight = 30;
    const titleHeight = isSmall ? 220 : 120;
    const defaultMinutes = 30; // predefinovaný cas sluzieb

    const minutes_offset = 120; // pri zmeneni casu od kolko pripocitat k casu do
    const start_line = '07:00'; // otvaracie hodiny OD casu
    const end_line = '19:00'; // otvaracie hodiny OD casu

    // COLORS
    const highLight = '#FFEFEF'; // DNESNY DEN - FARBA OPZADIA
    const bg_cell = '#D0E6FF'; // farba pozadia bunky
    const bg_cell_border = '#88ACD4'; // farba okraja bunky
    const bg_paper = '#FFFFFF';// '#FFF7D0'; // pozadie dna
    const bg_roung = '#AAAAAA'; // V mesacnom prehlade kruzok
    const bg_new = '#F25751'; // pridat novy zaznam
    const typ_highlight = '#8957F4'; // vyber typu - oznaceny

    var lang = props.lang;


    let params = useParams();
    let { func } = props;
    const listRef = useRef();

    useEffect(() => {

        var today = new Date();
        var d = today.getDate();
        var m = today.getMonth();
        var y = today.getFullYear();

        setTodayDay(d);
        setTodayMonth(m);
        setTodayYear(y);

        var n = 0;
        var arr = [];
        while (n < 24) {
            arr[n] = n.toString().padStart(2, '0') + ':00';
            n++;
        }
        setHours(arr);

        TodayDate();

        // HLADANIE VOLNYCH TERMINOV
        //db_item_free('2022-08-13', 420, 1140, 60);
        return () => {
        };

    }, [props.employee_id]);


    const Press = (typ) => {
        func(typ);
    }



    const db_items = async (typ_, arr_months, arr_weeks, arr_day, date) => {

        var arr = [];
        if (typ_ == 0) {
            var n = 0;
            arr_months.forEach(element => {
                arr[n] = element.year + '-' + (element.month + 1).toString().padStart(2, '0') + '-' + element.day.toString().padStart(2, '0')
                n++;
            });
        }

        if (typ_ == 1) {
            var n = 0;
            arr_weeks.forEach(element => {
                arr[n] = element.year + '-' + (element.month + 1).toString().padStart(2, '0') + '-' + element.day.toString().padStart(2, '0')
                n++;
            });
        }

        if (typ_ == 2 || typ_ == 3) {
            var d = (date.getDate()).toString().padStart(2, '0');
            var m = (date.getMonth() + 1).toString().padStart(2, '0');
            var y = (date.getFullYear());
            arr[0] = y + '-' + m + '-' + d;
        }


        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    arr: arr,
                    employee_id: props.employee_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var items = json.items;

                items.forEach(item => {
                    item.services = JSON.parse(item.services);
                });

                setTermins(items);
                Update(items, typ_, arr_months, arr_weeks, arr_day, date);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_item_update = async (data) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'calendar_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data,
                        lang: {
                            header: lang.calendar_email_header,
                            label: lang.calendar_email_label,
                            text: lang.reserve_accepted_text,
                            do_not_reply: lang.do_not_reply_text_,
                            date: lang.date,
                            time: lang.time,
                            google: lang.calendar_email_google,
                            eshop_link: lang.www_eshop
                        }
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    db_items(typ, cells, weeks, days, actualDate);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_holiday = async (data) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'calendar_holiday', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    db_items(typ, cells, weeks, days, actualDate);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Update = (items, typ_, arr_months, arr_weeks, arr_day, date) => {
        // UPDATE DAT V KALENDARY PO NACITANI Z DB
        var datum = GetDate(date);
        if (typ_ == 0) {
            // MESACNY PREHLAD
            arr_months.forEach(item => {
                var d = item.year.toString().padStart(2, '0') + '-' + (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = items.filter(x => x.date == d);
                item.count = tmp.length;

                tmp.sort((a, b) => {
                    if (parseInt(a.time_start) > parseInt(b.time_start)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                item.items = tmp;

                // hladanie sviatku                
                var d = (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = lang.sviatky.filter(x => x == d);
                if (tmp.length > 0) {
                    item.sviatok = true;
                } else {
                    item.sviatok = false;
                }
            });
            setCells(arr_months);
        }

        if (typ_ == 1) {
            // TYZDENNY PREHLAD
            arr_weeks.forEach(item => {
                var d = item.year.toString().padStart(2, '0') + '-' + (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = items.filter(x => x.date == d);
                item.count = tmp.length;

                tmp.sort((a, b) => {
                    if (parseInt(a.time_start) > parseInt(b.time_start)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                item.items = tmp;

                // hladanie sviatku                
                var d = (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = lang.sviatky.filter(x => x == d);
                if (tmp.length > 0) {
                    item.sviatok = true;
                } else {
                    item.sviatok = false;
                }
            });
            setWeeks(arr_weeks);
        }

        if (typ_ == 2 || typ_ == 3) {
            // DENNY PREHLAD
            var tmp = items.filter(x => x.date == datum);
            tmp.sort((a, b) => {
                if (parseInt(a.time_start) > parseInt(b.time_start)) {
                    return 1;
                } else {
                    return -1;
                }
            })
            arr_day.items = tmp;

            // hladanie sviatku                
            var d = (arr_day.month + 1).toString().padStart(2, '0') + '-' + arr_day.day.toString().padStart(2, '0');
            var tmp = lang.sviatky.filter(x => x == d);
            if (tmp.length > 0) {
                arr_day.sviatok = true;
            } else {
                arr_day.sviatok = false;
            }

            setDays(arr_day);
            if (typ_ == 2) {
                GoToStart();
            }
        }

        setRedrawAll(!redrawAll);
    }


    function Redraw(typ_, year, month, day, reload) {
        // PREKRESLENIE DO KALENDARA
        var arr_months = [];
        var arr_weeks = [];
        var arr_day = [];

        var date = new Date(year, month, day);
        setActualDate(date);

        var days_in_month = GetDays(year, month + 1);
        var day_of_week = GetDay(year, month, day);
        var first_day = GetDay(year, month, 1) == 0 ? 7 : GetDay(year, month, 1);

        setYear(year);
        setMonth(month);
        setDay(day);

        // ZISKANIE ARRAY - CELY MESIAC
        var n = 0;
        var value = false;
        var day_ = 1;
        var arr = [];
        var day_of_week_ = 0;
        while (n < first_day + days_in_month - 1) {
            if (n == first_day - 1) {
                value = true;
            }
            if (n == first_day - 1 + days_in_month) {
                value = false;
            }

            if (value == true) {
                var day_of_week_ = GetDay(year, month, day_);
            }
            var data = {
                id: n,
                active: value,
                day: value == true ? day_ : 0,
                day_name: lang.days_short[day_of_week_],
                month: month,
                year: year,
                color: global.theme_white,
                items: []
            }
            arr[n] = data;

            if (value == true) {
                day_++;
            }
            n++;
        }
        arr_months = arr;
        setCells(arr);

        // ZISKANIE ARRAY - JEDEN TYZDEN   
        arr = [];

        var curr = date; // get current date       
        var dow = curr.getDay();
        if (dow == 0) {
            dow = 7;
        }
        var first = curr.getDate() - dow;
        for (var i = 0; i < 7; i++) {
            var next = new Date(curr.getTime());
            next.setDate(first + i + 1);

            var d = next.getDate();
            var m = next.getMonth();
            var y = next.getFullYear();

            var data = {
                id: i,
                active: true,
                day: d,
                day_name: lang.days_short_label[i],
                month: m,
                year: y,
                color: global.theme_white,
                items: []
            }

            arr[i] = data;
        }
        arr_weeks = arr;
        setWeeks(arr);

        // ZISKANIE ARRAY - JEDEN DEN   
        var data = {
            id: 0,
            active: true,
            day: day,
            day_name: lang.days[day_of_week],
            month: month,
            year: year,
            color: global.theme_white,
            items: []
        }
        arr_day = data;
        setDays(data);


        if (reload == true) {
            db_items(typ_, arr_months, arr_weeks, arr_day, date);
        } else {
            Update(termins, typ_, arr_months, arr_weeks, arr_day, date);
        }
    }


    const Next = () => {
        if (typ == 0) {
            // MESACNY KALENDAR
            var d = day;
            var m = month + 1;
            var y = year;
            if (m > 11) {
                m = 0;
                y = y + 1;
            }
        }
        if (typ == 1) {
            // TYZDENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() + 7);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        if (typ == 2 || typ == 3) {
            // DENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() + 1);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        Redraw(typ, y, m, d, true);
    }

    const Prev = () => {
        if (typ == 0) {
            // MESACNY KALENDAR
            var d = day;
            var m = month - 1;
            var y = year;
            if (m < 0) {
                m = 11;
                y = y - 1;
            }
        }
        if (typ == 1) {
            // TYZDENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() - 7);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        if (typ == 2 || typ == 3) {
            // DENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() - 1);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        Redraw(typ, y, m, d, true);
    }

    const TodayDate = () => {
        var data = new Date();
        var y = data.getFullYear();
        var m = data.getMonth();
        var d = data.getDate();

        var data = new Date(y, m, d);
        setActualDate(data);

        Redraw(typ, y, m, d, true);
    }

    const ChangeTyp = (typ_) => {
        if (typ_ >= 0) {
            setTyp(typ_);

            var date = actualDate;

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            Redraw(typ_, y, m, d, true);
        }
    }


    const ChangeDay = (y, m, d) => {
        if (d > 0) {
            var data = new Date(y, m, d);
            setActualDate(data);
            Redraw(typ, y, m, d, false);
        }
    }

    const ChangeToDay = (y, m, d, t) => {
        if (d > 0) {
            var data = new Date(y, m, d);
            setActualDate(data);
            setTyp(t);
            Redraw(t, y, m, d, false);
        }
    }

    const SelectDay = (y, m, d) => {
        if (d > 0) {
            var data = new Date(y, m, d);
            setActualDate(data);
            setDay(d);
            setMonth(m);
            setYear(y);
            //Redraw(typ, y, m, d, false);
            setRedrawAll(!redrawAll);
        }
    }

    const GetDays = (year, month) => {
        // POCET DNI V MESIAC
        return new Date(year, month, 0).getDate();
    }


    const GetDay = (year, month, day) => {
        // DEN V TYZDNI
        return new Date(year, month, day).getDay();
    }

    const GetDayAdd = (year, month, day, add) => {
        // PRIDAT / ODPOCITAT DNI
        var date = new Date(year, month, day);
        date.setDate(date.getDate() + add);

        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();

        return [d, m, y];
    }

    const GetMinutes = (hour, minute) => {
        // vrati pocet minut z hodin
        return (hour * 60) + minute;
    }

    const GetMinutes2 = (time_) => {
        // vrati pocet minut z hodin
        var time = time_.split(':');
        return (parseInt(time[0]) * 60) + parseInt(time[1]);
    }

    const GetHours = (minutes) => {
        // vrati hodiny z minut
        var hour = parseInt(minutes / 60);
        var minute = minutes - (hour * 60);
        return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
    }

    const CheckIntersect = (list, data, offset) => {
        // KONTROLA, CI SA DVA TERMINY KRIZUJU
        var result = 0;
        var process = true;
        list.forEach(item => {
            if (data.id == item.id) {
                process = false
            }
            if (process == true) {
                if (parseInt(data.time_start) >= parseInt(item.time_start) && parseInt(data.time_start) < parseInt(item.time_end)) {
                    result++;
                }
            }
        });
        return result * offset;
    }


    const GetDate = (date) => {
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());

        return y + '-' + m + '-' + d;
    }

    const GetDatum = (date) => {
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());

        return d + '.' + m + '.' + y;
    }

    const GetMonthDay = (d_, m_) => {
        var d = String(d_).padStart(2, '0');
        var m = String(m_ + 1).padStart(2, '0');

        return m + '-' + d;
    }

    const AddNew = (status, item) => {
        setEditing(status);
        setStartMinutes(480);
        if (status == true) {
            setSelected(item);
        }
        setShowAddNew(true);
    }

    const AddNewHour = (hour) => {
        var minutes = GetMinutes2(hour);
        setStartMinutes(minutes);
        setEditing(false);
        setShowAddNew(true);
    }

    const NewTerminPress = (typ, data, holiday) => {
        if (typ == true) {
            // ULOZIT                        
            if (holiday == true) {
                // ulozenie dovolenky
                db_holiday(data);
            } else {
                // ulozenie udalost
                db_item_update(data);
            }
        }
        setShowAddNew(false);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('hours-7');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 350);
    }

    const Preview = (item) => {
        setSelected(item);
        setShowPreview(true);
    }

    const TerminPreviewResult = (typ, data) => {
        setShowPreview(false);
        selected.color = data.color;
        selected.verifed = data.verifed;
        if (typ == true) {
            // EDITACIA TERMINU
            setEditing(true);
            setTimeout(() => {
                setShowAddNew(true);
            }, 150);

        }
    }

    const Waiting = () => {
        return (
            <div style={{ ...styles.BlockRowRaw, width: 200, height: 26, justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_dark_red, borderRadius: 13 }}>
                <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_white, marginLeft: 10 }} icon={faExclamationTriangle} />
                <p style={{ ...styles.TextTiny, marginLeft: 5, marginRight: 10, color: global.theme_white }}>{isSmall ? lang.reserve_waiting_ : lang.reserve_waiting}</p>
            </div>
        )
    }

    const Canceled = () => {
        return (
            <div style={{ ...styles.BlockRowRaw, width: 200, height: 26, justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_dark_red, borderRadius: 13 }}>
                <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_white, marginLeft: 10 }} icon={faExclamationTriangle} />
                <p style={{ ...styles.TextXTiny, marginLeft: 5, marginRight: 10, color: global.theme_white }}>{isSmall ? lang.reserve_canceled_small : lang.reserve_canceled}</p>
            </div>
        )
    }


    /* *******************************************************************************************************************************
    **********************************************************************************************************************************
     
        RENDER - Main
     
    **********************************************************************************************************************************        
    ********************************************************************************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>
            <div style={{ ...styles.Block, height: titleHeight }}>
                {/* NADPIS */}
                {props.listing == false ?
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, maxWidth: isSmall ? '100%' : boxWidth * 7, flexWrap: 'wrap', paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '30%' }}>
                            {isSmall ? null :
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.Block, width: 60 }}>
                                        <Button onClick={() => Prev()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}>
                                            <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronLeft} />
                                        </Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: 60 }}>
                                        <Button onClick={() => Next()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0, borderTopRightRadius: 20, borderBottomRightRadius: 20 }}>
                                            <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronRight} />
                                        </Button>
                                    </div>
                                    <div style={{ ...styles.Block, width: 120 }}>
                                        <Button onClick={() => TodayDate()} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 40, backgroundColor: global.theme_transparent, paddingLeft: 0, paddingRight: 0, borderRadius: 0, borderTopRightRadius: 20, borderBottomRightRadius: 20 }}>
                                            <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.today}</p>
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ ...isSmall ? styles.BlockRowRaw : styles.Block, width: isSmall ? undefined : '40%', paddingBottom: isSmall ? 20 : 0, justifySelf: 'center' }}>
                            {isSmall ?
                                <div style={{ ...styles.BlockLeft, width: 50 }}>
                                    <div onClick={() => AddNew(false, [])} style={{ ...styles.BlockRound, backgroundColor: bg_new, paddingLeft: 0, paddingRight: 0 }}>
                                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faPlus} />
                                    </div>
                                </div>
                                : null}
                            <p style={{ ...styles.TextXXLarge, fontWeight: 'normal' }}><b>{typ == 2 || typ == 3 ? days.day + '.' : ''}{lang.months[month]}</b> {year}</p>
                        </div>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '30%' }}>
                            <div style={{ ...isSmall ? styles.BlockRow : styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, width: isSmall ? '25%' : 57, height: 40 }}>
                                    <IconButton onClick={() => ChangeTyp(0)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: typ == 0 ? 'bold' : 'normal', color: typ == 0 ? typ_highlight : global.theme_medium_gray }}>{lang.month.toLowerCase()}</p>
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: isSmall ? '25%' : 57, height: 40 }}>
                                    <IconButton onClick={() => ChangeTyp(1)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: typ == 1 ? 'bold' : 'normal', color: typ == 1 ? typ_highlight : global.theme_medium_gray }}>{lang.week.toLowerCase()}</p>
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: isSmall ? '25%' : 36, height: 40 }}>
                                    <IconButton onClick={() => ChangeTyp(2)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: typ == 2 ? 'bold' : 'normal', color: typ == 2 ? typ_highlight : global.theme_medium_gray }}>{lang.day}</p>
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: isSmall ? '25%' : 40, height: 40 }}>
                                    <IconButton onClick={() => ChangeTyp(3)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: typ == 3 ? typ_highlight : global.theme_medium_gray }} icon={faList} />
                                    </IconButton>
                                </div>
                                {isSmall ? null :
                                    <div style={{ ...styles.BlockRight, width: 50 }}>
                                        <div onClick={() => AddNew(false, [])} style={{ ...styles.BlockRound, backgroundColor: bg_new, paddingLeft: 0, paddingRight: 0, cursor: 'pointer' }}>
                                            <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faPlus} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {isSmall ?
                            <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                <div style={{ ...styles.Block, width: 60 }}>
                                    <Button onClick={() => Prev()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronLeft} />
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 60 }}>
                                    <Button onClick={() => Next()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronRight} />
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: 120 }}>
                                    <Button onClick={() => TodayDate()} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 40, backgroundColor: global.theme_light_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                        <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.today}</p>
                                    </Button>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}

                <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                    <Box sx={{ width: '100%', height: 8 }}>
                        {isBusy == true ?
                            <LinearProgress />
                            :
                            <div style={{ width: '100%', height: 8 }}></div>
                        }
                    </Box>
                </div>
                {typ == 2 ?
                    Today() <= GetDate3(actualDate) ?
                        <div style={{ ...styles.Block, paddingBottom: 10 }}>
                            {props.user.calendar_notify == false ?
                                <Button onClick={() => setShowRemind(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_lighter, width: 250 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faBell} />
                                    <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{lang.calendar_remind_day}</p>
                                </Button>
                                : null}
                        </div>
                        : null : null}

                {/* PONDELOK ... NEDELA */}
                {typ < 2 ?
                    <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                        {typ == 1 ? <div style={{ width: 42 }}></div> : null}
                        {lang.days_short_label.map((item, index) => (
                            <div key={index} style={{ ...styles.Block, width: isSmall ? typ == 1 ? '12.2%' : '15%' : typ == 1 ? boxWidth - 7 : boxWidth - 1, height: 25 }}>
                                <p style={{ ...styles.TextTiny, marginRight: 5, color: global.theme_dark_gray }}>{item}</p>
                            </div>
                        ))}
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.BlockCenter, height: props.height - titleHeight - 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* ****************************************************************************************************
                    KALENDARNY DNI 
                **************************************************************************************************** */}
                {typ == 0 ?
                    // MESACNY PREHLAD
                    <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : boxWidth * 7, flexWrap: 'wrap' }}>
                        {cells.map((item, index) => (
                            <div onClick={() => ChangeToDay(item.year, item.month, item.day, 2)} key={index} style={{ width: isSmall ? '13.6%' : '13.82%', height: isSmall ? boxHeight / 2 : boxHeight, border: item.day == day ? '1px solid ' + global.theme_red : '1px solid ' + global.theme_light_gray, backgroundColor: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? highLight : item.active ? item.color : global.theme_gray, cursor: 'pointer' }}>
                                {item.active == true ?
                                    <div style={{ ...styles.BlockRight, paddingTop: 3 }}>
                                        <div style={{ ...isSmall ? styles.BlockRight : styles.BlockRow }}>
                                            {isSmall ? null :
                                                <div style={{ width: '50%' }}>
                                                    {item.sviatok ?
                                                        <p style={{ ...styles.TextTiny, marginRight: 5, color: global.theme_red }}>{lang.sviatok}</p>
                                                        : null}
                                                </div>
                                            }
                                            <div style={{ ...styles.BlockRight, width: isSmall ? '100%' : '50%' }}>
                                                <p style={{ ...styles.TextSmall, marginRight: 5, color: item.sviatok ? global.theme_red : global.theme_black }}>{item.day}</p>
                                            </div>
                                        </div>
                                        {isSmall ? null :
                                            <div style={{ ...styles.BlockLeft, paddingTop: 5 }}>
                                                {item.items.map((element, index) => (
                                                    // ROZPIS V JEDNOM DNI
                                                    <div key={index} style={{ width: '100%' }}>
                                                        {index < 4 ?
                                                            <div style={{ ...styles.BlockLeft, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                                <p style={{ ...styles.TextXXTiny, marginLeft: 5 }}>{GetHours(element.time_start)} {element.verifed == false || element.deleted == true ? <FontAwesomeIcon style={{ width: 12, height: 12, color: global.theme_red }} icon={faExclamationTriangle} /> : <b>{element.label == '' ? element.name + ' ' + element.surname : element.label}</b>}</p>
                                                            </div>
                                                            :
                                                            index == 4 ?
                                                                <p style={{ ...styles.TextXXTiny, marginLeft: 5 }}>...</p>
                                                                : null}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {item.count > 4 || (isSmall && item.count > 0) ?
                                            // ZNACKA KOLKO JE TERMINOV V DNI
                                            <div style={{ ...isSmall ? styles.Block : styles.BlockRight, paddingTop: isSmall ? 5 : 0 }}>
                                                <div style={{ ...styles.BlockRound, width: 26, height: 26, backgroundColor: bg_roung, marginRight: isSmall ? 0 : 5 }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{item.count}</p>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    : null}
                            </div>
                        ))}
                    </div>
                    : null}

                {
                    typ == 1 ?
                        // TYZDENNY PREHLAD
                        <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : (boxWidth * 7), flexWrap: 'wrap' }}>
                            <div style={{ ...styles.Block, width: 42 }}>
                                <div style={{ height: 5 }}></div>
                                {hours.map((item, index) => (
                                    <div key={index} style={{ ...styles.Block, height: dayHeight + 1 }}>
                                        <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray }}>{item == '00:00' ? '' : item}</p>
                                    </div>
                                ))}
                            </div>

                            {weeks.map(item => (
                                <div key={item.id} onClick={() => ChangeToDay(item.year, item.month, item.day, 2)} style={{ width: isSmall ? '12.0%' : '13.3%', borderLeft: '1px solid ' + global.theme_gray, backgroundColor: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? highLight : global.theme_white, cursor: 'pointer' }}>
                                    <div style={{ ...styles.Block, height: 20, backgroundColor: item.day == day ? global.theme_gray : global.theme_back }}>
                                        <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, marginRight: 5, color: item.sviatok ? global.theme_red : global.theme_black }}>{item.day}.{item.month + 1}.</p>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        {hours.map((item, index) => (
                                            <div key={index} style={{ ...styles.Block, height: dayHeight, borderBottom: '1px solid ' + global.theme_gray, borderTop: item == start_line || item == end_line ? '1px solid black' : null }}></div>
                                        ))}

                                        {item.items.map(element => (
                                            <Paper key={element.id} onClick={() => Preview(element)} elevation={isSmall ? 1 : 3} style={{ ...styles.BlockLeft, position: 'absolute', width: isSmall ? '80%' : boxWidth - 32, height: element.time_end - element.time_start < 40 ? 40 / (60 / dayHeight) : (element.time_end - element.time_start) / (60 / dayHeight), top: ((element.time_start) * 1.03) / (60 / dayHeight), left: CheckIntersect(item.items, element, 5), backgroundColor: global.colors[element.color], border: '1px solid ' + global.theme_white, borderLeft: '2px solid ' + global.colors_dark[element.color], borderRadius: 10, overflow: 'hidden' }}>
                                                {element.verifed == false || element.deleted == true ?
                                                    <p style={{ ...styles.TextTiny, marginLeft: isSmall ? 0 : 5, marginTop: 3 }}>{GetHours(element.time_start)} <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_red }} icon={faExclamationTriangle} /></p>
                                                    :
                                                    <p style={{ ...styles.TextTiny, marginLeft: isSmall ? 0 : 5, marginTop: 3 }}>{GetHours(element.time_start)} {element.label == '' ? element.name + ' ' + element.surname : element.label}</p>
                                                }
                                            </Paper>
                                        ))}

                                    </div>
                                </div>
                            ))}
                        </div>
                        : null
                }

                {
                    typ == 2 ?
                        // DENNY PREHLAD
                        <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                            <p style={{ ...styles.TextLarge, marginBottom: 2, color: days.sviatok ? global.theme_red : global.theme_black }}>{days.day_name} {days.sviatok ? '(' + lang.sviatok + ')' : ''}</p>
                            <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : (boxWidth * 7) }}>
                                <div style={{ ...styles.Block, width: 42 }}>
                                    {hours.map((item, index) => (
                                        <div key={index} style={{ ...styles.BlockRaw, height: 61 }}>
                                            <p style={{ ...styles.TextXXTiny }}>{item == '00:00' ? '' : item}</p>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ ...styles.BlockLeft, position: 'relative', height: 24 * 61.3, width: isSmall ? '100%' : (boxWidth - 7) * 7, backgroundColor: bg_paper }}>
                                    <div style={{ ...styles.Block, paddingTop: 6 }}>
                                        {hours.map((item, index) => (
                                            <div id={'hours-' + index} key={index} onDoubleClick={() => AddNewHour(item)} style={{ ...styles.Block, height: 60, borderBottom: '1px dotted ' + global.theme_light_gray, borderTop: item == start_line || item == end_line ? '1px solid black' : null }}>
                                            </div>
                                        ))}
                                    </div>
                                    {days.items.map((item, index) => (
                                        <Paper key={item.id} elevation={isSmall ? 1 : 6} style={{ ...styles.BlockLeft, position: 'absolute', width: isSmall ? '90%' : '90%', height: item.time_end - item.time_start < 30 ? 30 : item.time_end - item.time_start, top: 4 + (item.time_start * 1.018), left: (index % 2) * 40, backgroundColor: global.colors[item.color], border: '1px solid ' + global.theme_white, borderLeft: '3px solid ' + global.colors_dark[item.color], borderRadius: 10, overflow: 'hidden', cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div onClick={() => Preview(item)} style={{ ...styles.BlockLeft, width: '90%', height: item.time_end - item.time_start < 30 ? 30 : item.time_end - item.time_start }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        {item.deleted == true ?
                                                            <Canceled />
                                                            :
                                                            item.verifed == true ? null :
                                                                <Waiting></Waiting>
                                                        }
                                                        {isSmall ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextTiny, marginLeft: 0, textAlign: 'left' }}><b>{GetHours(item.time_start)}-{GetHours(item.time_end)}</b></p>
                                                                {item.label != '' ?
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 0 }}>{item.label}</p>
                                                                    : null}
                                                                <p style={{ ...styles.TextTiny, marginLeft: 0, textAlign: 'left' }}><b>{item.name} {item.surname}</b></p>
                                                                {item.mobil != '' ?
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 0, textAlign: 'left' }}>{lang.mobil.toLowerCase() + ': ' + item.mobil}</p>
                                                                    : null}
                                                            </div>
                                                            :
                                                            <p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}><b>{GetHours(item.time_start)}-{GetHours(item.time_end)}</b>
                                                                <span style={{ marginLeft: 10 }}>{item.label}</span>
                                                                <span style={{ ...styles.TextTiny, marginLeft: 10, textAlign: 'left' }}><b>{item.name} {item.surname}</b><span style={{ marginLeft: 10 }}>{item.mobil != '' ? lang.mobil.toLowerCase() + ': ' + item.mobil : ''}</span></span>
                                                            </p>
                                                        }
                                                    </div>
                                                    {item.services.length > 0 ?
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, textAlign: 'left' }}>{lang.services}:</p>
                                                            {item.services.map((service) => (
                                                                <p key={service.id} style={{ ...styles.TextTiny, marginTop: 3, marginLeft: 5 }}>{service.label},</p>
                                                            ))}
                                                        </div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50 }}>
                                                    <IconButton onClick={() => AddNew(true, item)} style={{ width: 30, height: 30 }}>
                                                        <FontAwesomeIcon style={{ height: 12, color: global.theme_dark_gray }} icon={faPen} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Paper>
                                    ))}
                                </div>
                            </div>
                        </div>

                        : null
                }

                {
                    typ == 3 ?
                        // DENNY LISTING
                        <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                            <div style={{ ...styles.BlockLeft, position: 'relative', maxWidth: isSmall ? '100%' : boxWidth * 7, marginTop: 10, marginBottom: 20 }}>

                                {days.items.map((item, index) => (
                                    <Paper key={item.id} elevation={0} style={{ ...styles.BlockLeft, width: '98%', minHeight: 60, backgroundColor: global.colors[item.color], border: '1px solid ' + global.theme_white, borderLeft: '3px solid ' + global.colors_dark[item.color], borderRadius: 10, overflow: 'hidden', cursor: 'pointer', marginBottom: 5 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Preview(item)} style={{ ...styles.BlockLeft, width: '94%' }}>
                                                {item.deleted == true ?
                                                    <Canceled />
                                                    :
                                                    item.verifed == true ? null :
                                                        <Waiting></Waiting>
                                                }

                                                <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3 }}><b>{GetHours(item.time_start)} - {GetHours(item.time_end)}</b><span style={{ marginLeft: 10 }}>{item.label}</span></p>

                                                <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, textAlign: 'left' }}><b>{item.name} {item.surname}</b><span style={{ marginLeft: 10 }}>{item.mobil != '' ? lang.mobil.toLowerCase() + ': ' + item.mobil : ''}</span></p>
                                                {item.services.length > 0 ?
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, textAlign: 'left' }}>{lang.services}:</p>
                                                        {item.services.map((service) => (
                                                            <p key={service.id} style={{ ...styles.TextTiny, marginTop: 3, marginLeft: 5 }}>{service.label},</p>
                                                        ))}
                                                    </div>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 50 }}>
                                                <IconButton onClick={() => AddNew(true, item)} style={{ width: 30, height: 30 }}>
                                                    <FontAwesomeIcon style={{ height: 12, color: global.theme_dark_gray }} icon={faPen} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Paper>
                                ))}

                                {days.items.length == 0 ?
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_white, paddingTop: 20, paddingBottom: 20 }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.none}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        : null
                }
            </div>

            {
                showAddNew == true ?
                    <NewTermin isSmall={props.isSmall} defaultMinutes={defaultMinutes} remoteApp={props.remoteApp} start={GetHours(startMinutes)} offset={props.offset} theme={props.theme} radius={props.radius} user={props.user} user_id={props.user.id} admin={props.admin} token={props.token} employees={props.employees} employee_id={props.employee_id} lang={props.lang} language={props.language} lang_calendar={lang} start_minutes={startMinutes} actual={actualDate} item={selected} editing={editing} func={NewTerminPress.bind(this)} />
                    : null
            }

            {
                showPreview == true ?
                    <TerminPreview isSmall={props.isSmall} edit={true} item={selected} lang={lang} radius={props.radius} title={GetDatum(selected.date)} sub_title={GetHours(selected.time_start) + ' - ' + GetHours(selected.time_end)} backcolor={true} icon={faCalendarDay} background={true} employees={props.employees} employee_id={props.employee_id} user={props.user} language={props.language} offset={props.offset} theme={props.theme} func={TerminPreviewResult.bind(this)} />
                    : null
            }

            {
                showRemind == true ?
                    <CalendarReminder multi user={props.user} date={actualDate} radius={props.radius} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} func={() => setShowRemind(false)} />
                    : null
            }


            {/*
            isBusy == true ?
                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
                    <div style={{ ...styles.Block, marginLeft: 0, width: 50, height: 50, backgroundColor: global.theme_white, borderRadius: 25 }}>
                        <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={40} />
                    </div>
                </Backdrop>
                : null
            */}
        </div >
    )
};


/* ************************************************************************************************
 
        PREVIEW TERMINU
 
************************************************************************************************ */

export const TerminPreview = (props) => {
    /*
       OKNO - full screen
       
       USAGE:
       <TerminPreview lang={lang} radius={props.radius} title={'nazov'} backcolor={true} icon={faQuestion} background={false} func={TerminPreviewResult.bind(this)} />
 
       const TerminPreviewResult = (typ,data) => {
           console.log(typ,data)
       }
*/

    const [color, setColor] = useState(props.item.color);
    const [verifed, setVerifed] = useState(props.item.verifed);
    const [deleted, setDeleted] = useState(props.item.deleted);
    const [showCard, setShowCard] = useState(false);
    const [showLesson, setShowLesson] = useState(false);
    const [showLessonButton, setShowLessonButton] = useState(false);
    const [showRemind, setShowRemind] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 700;
    const dialogHeight = props.isSmall ? height : 550;
    const buttonHeight = props.isSmall ? 200 : 80;
    const reserveHeight = 70;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            if (props.item.lesson_id > 0) {
                setShowLessonButton(true);
            }

            running = true;
        }
        return () => {
        };

    }, []);



    const db_item_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'calendar_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    lang: {
                        header: lang.reserve_accepted_label,
                        label: lang.reserve_accepted_label,
                        text: lang.reserve_accepted_text,
                        do_not_reply: lang.do_not_reply_text_,
                        date: lang.date,
                        time: lang.time,
                        google: lang.calendar_email_google,
                        eshop_link: lang.www_eshop
                    }
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                if (data.verifed != true) {
                    setVerifed(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const Press = (typ, data) => {
        func(typ, data);
    }

    const Close = (typ) => {
        var data = {
            color: color,
            verifed: verifed
        }
        Press(typ, data);
    }

    const ColorsResult = (value) => {
        setColor(value);
        var data = {
            id: props.item.id,
            color: value
        }

        db_item_update(data);
    }

    const Card = () => {
        setShowCard(true);
    }

    const AcceptTermin = () => {
        var data = {
            id: props.item.id,
            verifed: true,
        }
        setVerifed(true);
        db_item_update(data);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 9999 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '25%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, fontWeight: '600' }}>{props.title}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: '45%', height: global.dialog_bar_height }}>
                            {verifed == true ?
                                <Colors small light value={color} func={ColorsResult.bind(this)} />
                                : null}
                        </div>
                    }
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        {props.edit == true ?
                            <IconButton onClick={() => Close(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ height: 12, color: global.themes[props.theme].theme_darker }} icon={faPen} />
                            </IconButton>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - reserveHeight - buttonHeight }}>
                        {/* BODY */}
                        {props.item.label != '' ?
                            <div style={{ ...styles.Block, marginTop: 0, height: 30, overflowY: 'hidden' }}>
                                <p style={{ ...styles.TextNormal }}>{props.item.label}</p>
                            </div>
                            : null}

                        {parseInt(props.item.client_id) > 0 ?
                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '500' }}>{props.item.name} {props.item.surname}</p>
                                <p style={{ ...styles.TextNormal, marginTop: 20 }}>{lang.mobil.toLowerCase()}: {props.item.mobil}</p>
                                <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.email_.toLowerCase()}: {props.item.email}</p>
                            </div>
                            : null}

                        {props.item.services.length > 0 ?
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.services}:</p>
                                {props.item.services.map((item, index) => (
                                    <p key={item.id} style={{ ...styles.TextSmall }}>{item.label}</p>
                                ))}
                            </div>
                            : null}

                        {props.item.note != '' ?
                            <div style={{ ...styles.Block, marginTop: 20, height: 60, overflowY: 'hidden' }}>
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.note}:</p>
                                    <p style={{ ...styles.TextSmall }}>{props.item.note}</p>
                                </div>
                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.Block, width: '95%', height: reserveHeight }}>
                        {verifed == false && deleted == false ?
                            <div style={{ ...styles.Block }}>
                                <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red, marginBottom: 5 }} icon={faExclamationTriangle} />
                                <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{lang.reserve_client_waiting}</p>
                            </div>
                            : null}

                        {deleted == true ?
                            <div style={{ ...styles.Block }}>
                                <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red, marginBottom: 5 }} icon={faExclamationTriangle} />
                                <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{lang.reserve_canceled_}</p>
                            </div>
                            : null}
                    </div>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '95%', height: buttonHeight }}>
                        {verifed == false && deleted == false ?
                            <div style={{ ...styles.Block, height: props.isSmall ? 60 : buttonHeight }}>
                                <Button onClick={() => AcceptTermin()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 200 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                        {lang.reserve_accept}
                                    </p>
                                </Button>
                            </div>
                            : null}
                        {parseInt(props.item.client_id) > 0 ?
                            <div style={{ ...styles.Block, height: props.isSmall ? 60 : buttonHeight }}>

                                <Button onClick={() => Card()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                        {lang.client_card}
                                    </p>
                                </Button>
                            </div>
                            : null}
                        {props.item.email != '' && Today() <= props.item.date && verifed == true ?
                            <div style={{ ...styles.Block, height: props.isSmall ? 60 : buttonHeight }}>
                                <Button onClick={() => setShowRemind(true)} style={{ ...styles.ButtonGreen, width: 200 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faBell} />
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                        {lang.remind}
                                    </p>
                                </Button>
                            </div>
                            : null}

                        {showLessonButton == true ?
                            <div style={{ ...styles.Block, height: props.isSmall ? 60 : buttonHeight }}>
                                <Button onClick={() => setShowLesson(true)} style={{ ...styles.ButtonGreen, width: 220 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faInfoCircle} />
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                        {lang.lesson_details}
                                    </p>
                                </Button>
                            </div>
                            : null}
                    </div>
                </div>
            </Paper>

            {showLesson == true ?
                <LessonDetails item={{ id: props.item.lesson_id }} remoteApp={props.remoteApp} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowLesson(false)} />
                : null}

            {showRemind == true ?
                <CalendarReminder user={props.user} date={props.item.date} item={props.item} radius={props.radius} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} func={() => setShowRemind(false)} />
                : null}

            {showCard == true ?
                <ClientCard isSmall={props.isSmall} employees={props.employees} employee_id={props.employee_id} user_id={props.user.id} user={props.user} client_id={props.item.client_id} lang={lang} language={props.language} radius={props.radius} offset={props.offset} theme={props.theme} func={() => setShowCard(false)} />
                : null}

        </Backdrop>
    );
}


export const CalendarMini = (props) => {
    /*
        PROPS:
            <CalendarMini cellSize={40} lang={props.lang} language={props.language} func={CalendarResult.bind(this)} />

            cellSize    -> velkost jedneho dna
            multi       -> označenie viacero dni (napr. pri dovolenke)
            elevation   -> tien (default = 0)
            background  -> farba pozadia

            const CalendarResult = (date, y, m, d) => {
                console.log(date);
            }

    */

    const [cells, setCells] = useState([]);
    const [day, setDay] = useState(0);
    const [selectedDays, setSelectedDay] = useState([]);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [todayDay, setTodayDay] = useState(0);
    const [todayMonth, setTodayMonth] = useState(0);
    const [todayYear, setTodayYear] = useState(0);

    const isSmall = props.isSmall;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxSize = props.cellSize == undefined ? 40 : props.cellSize;

    // COLORS
    const highLight = global.theme_light_blue

    var lang = props.lang;

    let { func } = props;

    useEffect(() => {

        var today = new Date();
        var d = today.getDate();
        var m = today.getMonth();
        var y = today.getFullYear();

        setTodayDay(d);
        setTodayMonth(m);
        setTodayYear(y);

        TodayDate();

        return () => {
        };

    }, []);


    function Redraw(typ_, year, month, day, reload) {
        // PREKRESLENIE DO KALENDARA
        var arr_months = [];

        var days_in_month = GetDays(year, month + 1);
        var first_day = GetDay(year, month, 1) == 0 ? 7 : GetDay(year, month, 1);

        setYear(year);
        setMonth(month);
        setDay(day);

        // ZISKANIE ARRAY - CELY MESIAC
        var n = 0;
        var value = false;
        var day_ = 1;
        var arr = [];
        var day_of_week_ = 0;
        while (n < first_day + days_in_month - 1) {
            if (n == first_day - 1) {
                value = true;
            }
            if (n == first_day - 1 + days_in_month) {
                value = false;
            }

            if (value == true) {
                var day_of_week_ = GetDay(year, month, day_);
            }
            var data = {
                id: n,
                active: value,
                day: value == true ? day_ : 0,
                day_name: lang.days_short[day_of_week_],
                month: month,
                year: year,
                color: global.theme_white,
                items: []
            }
            arr[n] = data;

            if (value == true) {
                day_++;
            }
            n++;
        }
        arr_months = arr;
        setCells(arr);
    }


    const Next = () => {
        var d = 0;
        var m = month + 1;
        var y = year;
        if (m > 11) {
            m = 0;
            y = y + 1;
        }

        Redraw(0, y, m, d, true);
    }

    const Prev = () => {
        var d = 0;
        var m = month - 1;
        var y = year;
        if (m < 0) {
            m = 11;
            y = y - 1;
        }

        Redraw(0, y, m, d, true);
    }

    const TodayDate = () => {
        var data = new Date();
        var y = data.getFullYear();
        var m = data.getMonth();
        var d = data.getDate();

        var data = new Date(y, m, d);

        Redraw(0, y, m, d, true);
    }

    const SelectDay = (y, m, d) => {
        if (d > 0) {
            setYear(y);
            setMonth(m);
            setDay(d);

            var dd = String(d).padStart(2, '0');
            var mm = String(m + 1).padStart(2, '0');
            var yy = String(y);
            let date = yy + '-' + mm + '-' + dd;

            if (props.multi == true) {

                var tmp = selectedDays.find(x => x.date == date);
                if (tmp == undefined) {
                    // ZAZNAM ESTE NIEJE V ARRAY
                    var id = 0;

                    selectedDays.forEach(item => {

                        if (item.id >= id) {
                            id = parseInt(item.id) + 1;
                        }
                    });

                    let data = {
                        id: id,
                        date: date,
                        year: y,
                        month: m,
                        day: d
                    }

                    selectedDays.push(data);
                } else {
                    // ZAZNAM UZ JE V ARRAY -> VYMAZAT
                    var tmp = selectedDays.filter(x => x.date != date);
                    setSelectedDay(tmp);
                }

            } else {
                func(date, y, m, d);
            }
        }
    }

    const GetDays = (year, month) => {
        // POCET DNI V MESIAC
        return new Date(year, month, 0).getDate();
    }


    const GetDay = (year, month, day) => {
        // DEN V TYZDNI
        return new Date(year, month, day).getDay();
    }

    const Save = () => {
        var result = false;

        if (selectedDays.length > 0) {
            var tmp = selectedDays;
            tmp.sort((a, b) => {
                if (a.date > b.date) {
                    return 1;
                } else {
                    return -1;
                }
            })
            result = tmp;
        }

        func(result);
    }

    const DayInArray = (item) => {
        var tmp = selectedDays.find(x => x.year == item.year && x.month == item.month && x.day == item.day);

        if (tmp == undefined) {
            return false;
        } else {
            return true;
        }
    }

    /* *******************************************************************************************************************************
    **********************************************************************************************************************************
     
        RENDER - Main
     
    **********************************************************************************************************************************        
    ********************************************************************************************************************************** */
    return (
        <Paper elevation={props.elevation == undefined ? 0 : props.elevation} style={{ ...styles.BlockCenter, width: boxSize * 7, backgroundColor: props.background == undefined ? global.theme_white : props.background, paddingTop: props.elevation == undefined ? 0 : 10, paddingBottom: props.elevation == undefined ? 0 : 10 }}>
            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.BlockRow, width: boxSize * 7 }}>
                    <div style={{ ...styles.Block, width: boxSize * 1 }}>
                        <IconButton onClick={() => Prev()} style={{ width: 36, height: 36, backgroundColor: '#00000000' }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faChevronLeft} />
                        </IconButton>
                    </div>

                    <div style={{ ...styles.Block, width: boxSize * 5 }}>
                        <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 7 }}><b>{lang.months[month]}</b> {year}</p>
                    </div>
                    <div style={{ ...styles.Block, width: boxSize * 1 }}>
                        <IconButton onClick={() => Next()} style={{ width: 36, height: 36, backgroundColor: '#00000000' }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faChevronRight} />
                        </IconButton>
                    </div>
                </div>
            </div>
            {/* PONDELOK ... NEDELA */}
            <div style={{ ...styles.BlockRow, maxWidth: boxSize * 7, marginTop: 10 }}>
                {lang.days_short_label.map((item, index) => (
                    <div key={index} style={{ ...styles.Block, width: boxSize, height: 25 }}>
                        <p style={{ ...styles.TextTiny, marginRight: 5, color: global.theme_dark_gray }}>{item[0]}</p>
                    </div>
                ))}
            </div>
            <div style={{ ...styles.BlockCenter, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                {/* ****************************************************************************************************
                    KALENDARNY DNI 
                **************************************************************************************************** */}
                <div style={{ ...styles.BlockRow, maxWidth: boxSize * 7, flexWrap: 'wrap' }}>
                    {cells.map((item, index) => (
                        <div key={index} style={{ ...styles.Block, width: boxSize, height: boxSize, cursor: item.active == true ? 'pointer' : 'default' }}>
                            {item.active == true ?
                                props.multi == true ?
                                    <div onClick={() => SelectDay(item.year, item.month, item.day)} style={{ width: boxSize - 4, height: boxSize - 4, backgroundColor: DayInArray(item) ? highLight : item.active ? item.color : '#00000000', borderRadius: boxSize - 2 / 2, border: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '1px solid ' + global.theme_dark_gray : '#00000000' }}>
                                        <div style={{ ...styles.Block, height: boxSize - 4 }}>
                                            <p style={{ ...styles.TextTiny, color: DayInArray(item) ? global.theme_white : global.theme_black, fontWeight: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '600' : '400' }}>{item.day}</p>
                                        </div>
                                    </div>
                                    :
                                    <div onClick={() => SelectDay(item.year, item.month, item.day)} style={{ width: boxSize - 4, height: boxSize - 4, backgroundColor: item.year == year && item.month == month && item.day == day ? highLight : item.active ? item.color : '#00000000', borderRadius: boxSize - 2 / 2, border: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '1px solid ' + global.theme_dark_gray : '#00000000' }}>
                                        <div style={{ ...styles.Block, height: boxSize - 4 }}>
                                            <p style={{ ...styles.TextTiny, color: item.year == year && item.month == month && item.day == day ? global.theme_white : global.theme_black, fontWeight: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? '600' : '400' }}>{item.day}</p>
                                        </div>
                                    </div>
                                : null}
                        </div>
                    ))}
                </div>
                {props.multi == true ?
                    <div style={{ ...styles.Block, marginTop: 20 }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonGray }}>{lang.continue}</Button>
                    </div>
                    : null}
            </div>
        </Paper >
    )
};


export const CalendarBirthday = (props) => {
    /*
        PROPS:
            <CalendarBirthday data={'2001-05-19} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} func={BirthdayResult.bind(this)} />

            elevation   -> tien (default = 0)
            background  -> farba pozadia

            const BirthdayResult = (value, date, y, m, d) => {
                console.log(date);
            }

    */

    const [cells, setCells] = useState([]);
    const [day, setDay] = useState(15);
    const [month, setMonth] = useState(6);
    const [year, setYear] = useState(2000);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 300;
    const dialogHeight = 370;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    var lang = props.lang;
    let { func } = props;

    useEffect(() => {

        setDay(GetDateDay(props.date));
        setMonth(GetDateMonth(props.date));
        setYear(GetDateYear(props.date));

        return () => {
        };

    }, []);

    const Close = () => {
        func(false, false);
    }

    const PlusMinusResult = (value) => {

    }

    const Process = () => {
        func(true, CreateDate(year, month, day), year, month, day);
    }

    /* *******************************************************************************************************************************
    **********************************************************************************************************************************
     
        RENDER - Main
     
    **********************************************************************************************************************************        
    ********************************************************************************************************************************** */
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCalendar} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.birthdate}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.age}: {GetAge(CreateDate(year, month, day))}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockRowRaw }}>
                            <PlusMinus radius_left dot label={lang.day} vertical min={1} max={31} value={day} func={(txt) => setDay(txt)} />
                            <PlusMinus dot label={lang.month} vertical min={1} max={12} value={month} func={(txt) => setMonth(txt)} />
                            <PlusMinus radius_right label={lang.year} vertical min={1900} value={year} func={(txt) => setYear(txt)} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>
                            {lang.continue}
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                        </Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    )
};



export const CalendarReminder = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <CalendarReminder radius={props.radius} lang={props.lang} language={props.language} func={RemindResult.bind(this)} />

            const RemindResult = () => {
                setShowRemind(false);
            }
    */
    const [isBusy, setBusy] = useState(false);
    const [sended, setSended] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const dialogWidth = 600;
    const dialogHeight = 300;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_remind = async (data) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'calendar_reminder', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: data
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    if (props.multi != true && json.sended == false) {
                        setError(true);
                    }
                    setSended(json.sended);
                    setShowResult(true);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Press = (value) => {
        func(value);
    }

    const Send = () => {
        setError(false);

        let data = {
            multi: props.multi == true ? true : false,
            date: GetDate3(props.date),
            datum: GetDatum4(lang.months, GetDate3(props.date)),
            salon_id: props.user.id,
            termin_id: props.multi == true ? 0 : props.item.id,
            language: props.language,
            texts: {
                header: lang.calendar_email_header,
                label: lang.calendar_email_label,
                do_not_reply: lang.do_not_reply_text_,
                date: lang.date,
                time: lang.time,
                google: lang.calendar_email_google,
                eshop_link: lang.www_eshop
            }
        }

        db_remind(data);
    }


    const DialogInfoResult = (value) => {
        setShowResult(false);
        Press(true);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBell} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.calendar_remind_label}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.multi == true ? lang.calendar + ' (' + GetDatum4(lang.months, props.date) + ')' : lang.calendar}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {props.multi == true ?
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.calendar_remind_text1a}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.calendar_remind_text1b}</p>
                        </div>
                        :
                        <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.calendar_remind_text2}</p>
                        </div>
                    }
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Send()} style={{ ...styles.ButtonRed }}>{lang.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.no}</Button>
                    </div>
                </div>
            </Paper>

            {showResult == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.calendar_remind_result_label} text={props.multi == true ? lang.calendar_remind_result_text_multi + ' ' + sended : error == false ? lang.calendar_remind_result_text : lang.calendar_remind_result_error} sub_title={''} icon={faInfo} button={props.lang.close} icon_show={error == false ? faCheck : faXmark} icon_color={error == false ? global.theme_darker_green : global.theme_dark_red} func={DialogInfoResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}


/* ************************************************************************************************
     
    UPRAVA / PRIDANIE NOVEHO TERMINU
     
    ************************************************************************************************ */
const NewTermin = (props) => {

    let editing = props.editing;
    let defaultMinutes = props.defaultMinutes;
    let isSmall = props.isSmall;

    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [redraw, setRedraw] = useState(false);

    const [date, setDate] = useState('2020-01-01');
    const [timeStart, setTimeStart] = useState(editing == true ? props.item.time_start : props.start);
    const [timeEnd, setTimeEnd] = useState(editing == true ? props.item.time_end : GetHoursFunc(GetMinutesFunc(props.start) + defaultMinutes));
    const [label, setLabel] = useState('');
    const [note, setNote] = useState('');
    const [color, setColor] = useState(0);
    const [client, setClient] = useState(false);
    const [employeeID, setEmployeeID] = useState(props.employee_id);
    const [showClients, setShowClients] = useState(false);
    const [services, setServices] = useState([]);
    const [showServices, setShowServices] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showClientCard, setShowClientCard] = useState(false);

    // DNI DOVOLENKY
    const [showHoliday, setShowHoliday] = useState(false);
    const [holiday, setHoliday] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 768 ? 768 : height;
    const offset = 20;
    const rowHeight = 40;
    const rowHolidayHeight = 50;
    const lineColor = global.theme_light_gray;

    const buttonHeight = 60;

    let { func } = props;
    var lang = props.lang_calendar;
    const inputRef = useRef(null);

    useEffect(() => {

        if (props.editing == false) {
            var date = new Date(props.actual);
            setDate(GetDate(date));
        } else {
            var today = new Date(props.item.date);
            setDate(GetDate(today));
            setTimeStart(GetHoursFunc(props.item.time_start));
            setTimeEnd(GetHoursFunc(props.item.time_end));
            setLabel(props.item.label);
            if (parseInt(props.item.client_id) > 0) {
                var data = {
                    id: props.item.client_id,
                    name: props.item.name,
                    surname: props.item.surname,
                    mobil: props.item.mobil,
                    email: props.item.email,
                }
                setClient(data);
            }
            setServices(props.item.services);
            setNote(props.item.note);
            setColor(props.item.color);
            setEmployeeID(props.employee_id);
        }

        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const Press = (typ, value, holiday) => {
        func(typ, value, holiday);
    }

    const Close = () => {
        Press(0, 0, false);
    }

    const GetDate = (date) => {
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());

        return y + '-' + m + '-' + d;
    }

    const Save = () => {
        // kontrola ci je END > START

        if (index == 0) {
            // DATA, KTORE SA NAHRAJU DO DB
            var data = {
                id: editing == false ? 0 : props.item.id,
                salon_id: props.user.id,
                worker_id: employeeID < 0 ? 0 : employeeID,
                client_id: client == false ? 0 : client.id,
                date: date,
                year: GetDateYear(date),
                month: GetDateMonth(date),
                day: GetDateDay(date),
                hour: parseInt(GetMinutesFunc(timeStart) / 60),
                minute: parseInt(GetMinutesFunc(timeStart) % 60),
                time: timeStart,
                end_time: timeEnd,
                time_start: GetMinutesFunc(timeStart),
                time_end: GetMinutesFunc(timeEnd),
                name: client == false ? '' : client.name,
                surname: client == false ? '' : client.surname,
                mobil: client == false ? '' : client.mobil,
                email: client == false ? '' : client.email,
                label: label,
                note: note,
                color: color,
                verifed: true,
                group_id: 0,
                services: services,
            }

            Press(true, data, false);
        } else {
            var array = [];

            if (holiday != false) {
                holiday.forEach(item => {
                    var data = {
                        id: 0,
                        salon_id: props.user.id,
                        time: '00:01',
                        date: item.date,
                        year: GetDateYear(item.date),
                        month: GetDateMonth(item.date),
                        day: GetDateDay(item.date),
                        hour: 0,
                        minute: 1,
                        client_id: 0,
                        name: '',
                        surname: '',
                        mobil: '',
                        email: '',
                        note: '',
                        service_id: 0,
                        end_time: '23:59',
                        label: label,
                        group_id: 0,
                        service_name: '',
                        service_time: 0,
                        verified: true,
                        service2_id: 0,
                        service3_id: 0,
                        service4_id: 0,
                        service5_id: 0,
                        service_time2: 0,
                        service_time3: 0,
                        service_time4: 0,
                        service_time5: 0,
                        service_name2: '',
                        service_name3: '',
                        service_name4: '',
                        service_name5: '',
                        group2_id: 0,
                        group3_id: 0,
                        group4_id: 0,
                        group5_id: 0,
                        worker_id: employeeID < 0 ? 0 : employeeID,
                        reserve_system: 0,
                        color: color,
                    }

                    array.push(data);
                });

                Press(true, array, true);
            }
        }

    }

    const ClientsResult = (typ, item) => {
        if (typ == true) {

            setClient(item);
        }
        setShowClients(false);
    }

    const ColorsResult = (value) => {
        setColor(value)
    }

    const ServiceSelectResult = (typ, item) => {
        var tmp = services.filter(x => x.id == item.id);
        if (tmp.length == 0) {
            if (typ == true) {
                var data = {
                    id: item.id,
                    label: item.label,
                    time: item.time
                }
                services.push(data);
            }

            var minutes = GetServicesTime(services);
            minutes = minutes + GetMinutesFunc(timeStart);
            setTimeEnd(GetHoursFunc(minutes));
        }
        setShowServices(false);
    }

    const DeleteService = (item) => {
        var tmp = services.filter(x => x.id != item.id);
        setServices(tmp);
        var minutes = GetServicesTime(tmp);
        minutes = minutes + GetMinutesFunc(timeStart);
        setTimeEnd(GetHoursFunc(minutes));
    }

    const GetServicesTime = (services_) => {
        var result = 0;

        services_.forEach(item => {
            result += parseInt(item.time);
        });

        if (result < 10) {
            result = 10;
        }
        return result;
    }

    const TimeStart = () => {
        var minutes = GetServicesTime(services);
        if (minutes < defaultMinutes) {
            minutes = defaultMinutes;
        }
        minutes = minutes + GetMinutesFunc(timeStart);
        setTimeEnd(GetHoursFunc(minutes));
    }

    const TimeEnd = () => {
        var start = GetMinutesFunc(timeStart);
        var end = GetMinutesFunc(timeEnd);
        if (start >= end) {
            setTimeEnd(GetHoursFunc(start + defaultMinutes));
        }
    }

    const DeleteTermin = (value) => {
        setShowDelete(false);
        if (value == true) {
            var data = {
                id: editing == false ? 0 : props.item.id,
                salon_id: 0,
            }
            Press(true, data, false);
        }
    }

    const EmployeeSelectResult = (value) => {
        setEmployeeID(value);
    }

    const CalendarResult = (date, y, m, d) => {
        setHoliday(date);
        setShowHoliday(false);
    }

    const DeleteHoliday = (item) => {
        var tmp = holiday.filter(x => x.date != item.date);

        if (tmp.length == 0) {
            tmp = false;
        }
        setHoliday(tmp);

        setRedraw(!redraw);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        if (id == 1) {
            setLabel(lang.calendar_holiday);
        }
    }
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.editing == true ? lang.edit : lang.new}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '50%', height: global.dialog_bar_height }}>
                        <Colors small light value={color} func={ColorsResult.bind(this)} />
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, paddingBottom: index == 0 ? props.isSmall ? global.keyboard_padding_small : global.keyboard_padding : 20, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {props.employees.length > 1 ?
                            <div style={{ ...styles.Block, backgroundColor: global.themes[props.theme].theme_lighter }}>
                                <div style={{ ...styles.BlockRowRaw, marginTop: offset / 2, marginBottom: offset / 2 }}>
                                    <p style={{ ...styles.TextInput, marginTop: 10, marginRight: 10 }}>{lang.employee}</p>
                                    <div style={{ ...styles.Block, width: 300 }}>
                                        <EmployeeSelect theme={props.theme} select small radius background={global.theme_lighter} width={300} employees={props.employees} value={employeeID} func={EmployeeSelectResult.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: rowHeight }}>
                            <div onClick={() => ChangeIndex(0)} style={{ ...styles.Block, width: '50%', height: rowHeight, cursor: 'pointer', backgroundColor: index == 0 ? global.theme_white : global.themes[props.theme].theme_lighter }}>
                                <p style={{ ...styles.TextTiny, color: index == 0 ? global.theme_black : global.themes[props.theme].theme_medium }}>{lang.event}</p>
                            </div>
                            <div onClick={() => ChangeIndex(1)} style={{ ...styles.Block, width: '50%', height: rowHeight, cursor: 'pointer', backgroundColor: index == 1 ? global.theme_white : global.themes[props.theme].theme_lighter }}>
                                <p style={{ ...styles.TextTiny, color: index == 1 ? global.theme_black : global.themes[props.theme].theme_medium }}>{lang.calendar_holiday}</p>
                            </div>
                        </div>
                        {/* NAZOV */}
                        <div style={{ ...styles.Block, width: '95%', marginTop: offset / 2 }}>
                            <TextInput inputRef={inputRef} redraw enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => { setLabel(txt) }} />
                        </div>
                        {index == 0 ?
                            <div style={{ ...styles.Block }}>

                                <div style={{ ...styles.Block, height: 1, backgroundColor: lineColor, marginTop: offset, marginBottom: offset - 5 }}></div>

                                {/* ZAKAZNIK */}
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.client}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                                            <Button onClick={() => setShowClients(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium }}>{lang.client_select}</Button>
                                        </div>
                                    </div>
                                    {client != false ?
                                        <div style={{ ...styles.BlockLeft, marginLeft: 24 }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <IconButton onClick={() => setClient(false)} style={{ width: 30, height: 30, marginRight: 10 }}>
                                                    <FontAwesomeIcon style={{ height: 14, color: global.theme_dark }} icon={faXmark} />
                                                </IconButton>
                                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 7 }}>{client.name} {client.surname}</p>
                                            </div>
                                            <p style={{ ...styles.TextTiny, marginTop: 5, marginLeft: 40 }}>{lang.mobil}: {client.mobil}</p>
                                            {client.email != '' && client.email != null ?
                                                <p style={{ ...styles.TextTiny, marginLeft: 40 }}>{lang.email_}: {client.email}</p>
                                                : null}
                                            <Button onClick={() => setShowClientCard(true)} style={{ ...styles.ButtonGraySmall, marginLeft: 36, marginTop: 12 }}>
                                                {lang.client_card}
                                            </Button>

                                        </div>
                                        : null}
                                </div>

                                <div style={{ ...styles.Block, height: 1, backgroundColor: lineColor, marginTop: offset, marginBottom: offset - 5 }}></div>

                                {/* SLUZBA */}
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.service}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                                            {services.length < 5 ?
                                                <Button onClick={() => setShowServices(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium }}>{lang.service_select}</Button>
                                                : null}
                                        </div>
                                    </div>
                                    {services.length > 0 ?
                                        <div style={{ ...styles.BlockLeft, marginLeft: 24 }}>
                                            {services.map((item, index) => (
                                                <div key={index} style={{ ...styles.BlockLeft }}>
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <IconButton onClick={() => DeleteService(item)} style={{ width: 30, height: 30, marginRight: 10 }}>
                                                            <FontAwesomeIcon style={{ height: 14, color: global.theme_dark }} icon={faXmark} />
                                                        </IconButton>
                                                        <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 7 }}><b>{item.label}</b><span style={{ marginLeft: 10 }}>{lang.time.toLowerCase()}: {item.time} {lang.minutes}</span></p>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                        : null}
                                </div>

                                <div style={{ ...styles.Block, height: 1, backgroundColor: lineColor, marginTop: offset, marginBottom: offset - 5 }}></div>

                                {/* DATUM + CAS */}
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 200 }}>
                                        <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.date}</p>
                                        <TextField
                                            value={date}
                                            onInput={e => setDate(e.target.value)}
                                            size="small"
                                            autoCapitalize="off"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            style={{ width: '100%' }}
                                            placeholder={lang.label}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                }
                                            }}
                                            InputProps={{
                                                style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                            }}
                                            type={'date'}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, marginLeft: isSmall ? 0 : 140, marginTop: isSmall ? 20 : 0 }}>
                                        <div style={{ ...styles.BlockLeft, width: 150 }}>
                                            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.start}</p>
                                            <TextField
                                                value={timeStart}
                                                onInput={e => setTimeStart(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                onBlur={() => TimeStart()}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                    }
                                                }}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                                }}
                                                type={'time'}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 20 }}>
                                            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.end}</p>
                                            <TextField
                                                value={timeEnd}
                                                onInput={e => setTimeEnd(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                onBlur={() => TimeEnd()}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                    }
                                                }}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, borderRadius: 20 },
                                                }}
                                                type={'time'}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ ...styles.Block, height: 1, backgroundColor: lineColor, marginTop: offset, marginBottom: offset - 5 }}></div>


                                {/* POZNAMKA */}
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <TextInput redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => { setNote(txt) }} />
                                </div>

                            </div>
                            : null}

                        {index == 1 ?
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                {showHoliday == true ?
                                    <CalendarMini multi elevation={1} cellSize={50} background={global.theme_back} lang={props.lang} language={props.language} func={CalendarResult.bind(this)} />
                                    :
                                    <div style={{ ...styles.Block }}>
                                        {holiday != false ?
                                            <p style={{ ...styles.TextTiny, marginBottom: 10, fontWeight: '600' }}>{lang.calendar_holiday_list}:</p>
                                            : null}
                                        {holiday != false ? holiday.map((item, index) => (
                                            <div key={item.id} style={{ ...styles.Block, width: '60%', height: rowHolidayHeight, backgroundColor: global.theme_white, marginTop: 5, marginBottom: 5, borderRadius: rowHolidayHeight / 2 }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.BlockLeft, height: rowHolidayHeight, width: '90%', justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextSmall, marginLeft: 20 }}>{GetDatum2(item.date)}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, height: rowHolidayHeight, width: 60, justifyContent: 'center' }}>
                                                        <IconButton onClick={() => DeleteHoliday(item)} style={{ width: 34, height: 34 }}>
                                                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                        <div style={{ ...styles.Block, marginTop: 40 }}>
                                            <Button onClick={() => setShowHoliday(true)} style={{ ...styles.ButtonGray, width: 200 }}>{lang.calendar_holiday_show}</Button>
                                        </div>
                                    </div>
                                }

                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.Block, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <div style={{ ...styles.BlockRow }}>
                            {props.editing == true ?
                                <div style={{ ...styles.Block, height: buttonHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => setShowDelete(true)} style={{ ...styles.ButtonRed }}>{lang.delete}</Button>
                                </div>
                                : null}
                            {index == 0 || (index == 1 && showHoliday == false && holiday != false) ?
                                <div style={{ ...styles.Block, height: buttonHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>

                {showClients == true ?
                    <ClientSelect isSmall={props.isSmall} employees={props.employees} employee_id={props.employee_id} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ClientsResult.bind(this)} />
                    : null}

                {showServices == true ?
                    <ServiceSelect isSmall={props.isSmall} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ServiceSelectResult.bind(this)} />
                    : null}

                {showClientCard == true ?
                    <ClientCard isSmall={props.isSmall} remoteApp={props.remoteApp} employees={props.employees} employee_id={props.employeeID} user_id={props.user.id} user={props.user} admin={props.admin} client_id={client.id} lang={props.lang} language={props.language} radius={props.radius} offset={props.offset} theme={props.theme} token={props.token} func={() => setShowClientCard(false)} />
                    : null}

                {showDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={props.lang.calendar_delete} sub_title={''} text={props.lang.calendar_delete_item} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteTermin.bind(this)} />
                    : null}

            </Paper>



        </Backdrop>
    )
}


